import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import courseTrainer, { CourseTrainer, Value } from '../model/courseTrainer';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/constants';
import moment from 'moment';

const logger = new Logger('backend.CourseTrainer');
export interface BackendCourseTrainer {
  getCourseTrainer(id: string): AxiosPromise<CourseTrainer>;
  getCourseTrainers: (
    searchParams: SearchParams,
    trainerId: string,
    dateTimeId: string
  ) => AxiosPromise<Value>;
  getCoursesWithDateTime: (
    searchParams: SearchParams,
    bookableAsSeries?: boolean,
    afterDate?: string
  ) => AxiosPromise<Value>;
  deleteCourseTrainer(id: string): AxiosPromise;
  updateCourseTrainer(CourseTrainer: CourseTrainer[]): AxiosPromise<any>;
  updateCourseTrainerForTrainer(
    CourseTrainer: CourseTrainer[]
  ): AxiosPromise<any>;
}

export const defaultBackendCourseTrainer: BackendCourseTrainer = {
  getCourseTrainer(id: string): AxiosPromise<CourseTrainer> {
    let url = `${URLS.courseTrainerOdata}/${id}`;
    return instance.get<CourseTrainer>(url);
  },
  getCourseTrainers(
    searchParams: SearchParams,
    trainerId: string,
    dateTimeId: string
  ): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    if (trainerId != CONST.emptyGuid) {
      odfb.eq(CONST.TrainerId, trainerId, false);
    }

    if (dateTimeId != CONST.emptyGuid) {
      odfb.eq(CONST.DateTimeId, dateTimeId, false);
    }

    let url = DefaultBackendHelper.makeUrl(
      `${URLS.courseTrainerOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );

    logger.log(`getCourseTrainers${url}`);
    return instance.get<Value>(url);
  },
  getCoursesWithDateTime(
    searchParams: SearchParams,
    bookableAsSeries?: boolean,
    afterDate?: string
  ): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');

    if (bookableAsSeries != undefined) {
      odfb.eq(CONST.BookableAsSeries, bookableAsSeries, false);
    }

    if (afterDate != undefined) {
      odfb.ge(CONST.End, moment(afterDate).startOf('day').toISOString(), false);
    }

    let url = DefaultBackendHelper.makeUrl(
      `${URLS.coursesWithDateTime}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getCourseTrainers${url}`);
    return instance.get<Value>(url);
  },

  deleteCourseTrainer(id: string): AxiosPromise {
    logger.debug('deleteCourseTrainer');
    return instance.delete(`${URLS.courseTrainer}/${id}`);
  },
  updateCourseTrainer(CourseTrainer: CourseTrainer[]): AxiosPromise<any> {
    logger.debug('updateCourseTrainer');
    return instance.post<CourseTrainer>(
      `${URLS.courseTrainer}/AddMultiple/false`,
      courseTrainer.arrayToAPI(CourseTrainer)
    );
  },
  updateCourseTrainerForTrainer(
    CourseTrainer: CourseTrainer[]
  ): AxiosPromise<any> {
    logger.debug('updateCourseTrainer');
    return instance.post<CourseTrainer>(
      `${URLS.courseTrainer}/AddMultiple/true`,
      courseTrainer.arrayToAPI(CourseTrainer)
    );
  },
};
