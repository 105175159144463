import {
  HubConnectionBuilder,
  HubConnection,
  LogLevel,
  HubConnectionState,
} from '@microsoft/signalr';
import { onBeforeMount, onUnmounted, ref } from 'vue';
import { Logger } from 'fsts';

export interface SignalRFunctions {
  joinSignalRCompanyGroup: Function;
  leaveSignalRCompanyGroup: Function;
  joinSignalRAppointmentChat: Function;
  leaveSignalRAppointmentChat: Function;
  onEvent: Function;
  offEvent: Function;
}

const logger = new Logger('SignalR');

export default function useSignalR(): SignalRFunctions {
  let startedPromise: Promise<void> | null = null;
  let connectedPromise: Promise<void> | null = null;
  let connection: HubConnection | null = null;
  const manuallyClosed = ref(false);
  const joinedCompanyChat = ref(false);

  // Starting SignalR on component creation (app-component)
  function startSignalR() {
    connection = new HubConnectionBuilder()
      .withUrl(`/hub/bapi/BackendHub`)
      .configureLogging(LogLevel.Information)
      .build();

    /*
     * Checking for connected state by polling the value of the connection.state
     * This is very cumbersome, but I currently do not know another way to
     * determine if a connection has been established.
     * startedPromise cannot be used, as it is fulfilled before the connection has been established
     */
    connectedPromise = new Promise<void>((resolve, reject) => {
      const connectedCheck = () => {
        if (connection?.state == HubConnectionState.Connected) {
          resolve();
          return;
        }
        setTimeout(connectedCheck, 100);
      };
      connectedCheck();
    });

    function start() {
      startedPromise = connection!.start().catch((err: any) => {
        logger.error('Failed to connect with hub', err);
      });
    }

    connection.onclose(() => {
      if (!manuallyClosed.value) start();
    });

    manuallyClosed.value = false;
    start();
  }
  onBeforeMount(startSignalR);

  // Automatically stopping SignalR when the page is closed (app-component)
  function stopSignalR() {
    if (!startedPromise) return;

    manuallyClosed.value = true;
    return startedPromise
      .then(() => connection!.stop())
      .then(() => {
        startedPromise = null;
      });
  }
  onUnmounted(stopSignalR);
  window.addEventListener('beforeunload', stopSignalR);

  function joinSignalRCompanyGroup(companyId: string) {
    if (!joinedCompanyChat.value) {
      joinedCompanyChat.value = true;

      connectedPromise
        ?.then(() => {
          connection!.invoke('JoinUserGroupCompany', companyId);
        })
        ?.catch((err: any) => {
          logger.error(err);
          joinedCompanyChat.value = false;
        });
    }
  }

  function leaveSignalRCompanyGroup(companyId: string) {
    if (joinedCompanyChat.value) {
      joinedCompanyChat.value = false;

      connectedPromise
        ?.then(() => {
          connection!.invoke('LeaveUserGroupCompany', companyId);
        })
        ?.catch((err: any) => {
          logger.error(err);
          joinedCompanyChat.value = false;
        });
    }
  }

  function joinSignalRAppointmentChat(appointmentId: string) {
    connectedPromise
      ?.then(() => {
        connection!.invoke('JoinUserGroupAppointmentChat', appointmentId);
      })
      ?.catch((err: any) => {
        logger.error(err);
      });
  }

  function leaveSignalRAppointmentChat(appointmentId: string) {
    connectedPromise
      ?.then(() => {
        connection!.invoke('LeaveUserGroupAppointmentChat', appointmentId);
      })
      ?.catch((err: any) => {
        logger.error(err);
      });
  }

  function onEvent(eventName: string, callback: any) {
    if (!startedPromise) {
      logger.warn(
        'hubConnection not started yet. no event registering possible'
      );
      return;
    }
    startedPromise.then(() => {
      connection!.on(eventName, callback);
    });
  }

  function offEvent(eventName: string) {
    if (!startedPromise) {
      logger.warn(
        'hubConnection not started yet. No event-unregistering possible'
      );
      return;
    }
    startedPromise.then(() => {
      connection!.off(eventName);
    });
  }

  return {
    joinSignalRCompanyGroup,
    leaveSignalRCompanyGroup,
    joinSignalRAppointmentChat,
    leaveSignalRAppointmentChat,
    onEvent,
    offEvent,
  };
}
