import { Component, Vue, toNative, Setup } from 'vue-facing-decorator';
import { useI18n } from 'vue-i18n';

@Component
class AskingDialogBox extends Vue {
  @Setup((props, ctx) =>
    useI18n({
      messages: {
        de: { ok: 'Ok', cancel: 'Abbrechen' },
        en: { ok: 'Ok', cancel: 'Cancel' },
      },
    })
  )
  private i18n!: any;

  dialog = false;

  messageBox = false;

  resolve: any = null;
  reject: any = null;

  message: string = '';
  title: string = '';
  options = {
    color: 'primary',
    width: 360,
    zIndex: 9999,
    cancelText: undefined,
    okText: undefined,
  };

  radioEntries: string[] = [];
  selectedRadioEntry: number = -1;

  resetOptions() {
    this.options = {
      color: 'primary',
      width: 360,
      zIndex: 9999,
      cancelText: undefined,
      okText: undefined,
    };
  }

  openConfirm(title: string, message: string, options?: any) {
    this.configureOptions(title, message, options);
    return new Promise((resolve: any, reject: any) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  openMessageBox(title: string, message: string, options?: any) {
    this.messageBox = true;
    this.configureOptions(title, message, options);
  }

  private configureOptions(title: string, message: string, options?: any) {
    this.dialog = true;
    this.title = title;
    this.message = message;
    this.resetOptions();
    if (options != undefined) {
      this.options = Object.assign(this.options, options);
    }

    this.radioEntries = options != undefined ? options.radioEntries ?? [] : [];
    this.selectedRadioEntry = this.radioEntries.length > 0 ? 0 : -1;
  }

  private agree() {
    if (!this.messageBox) {
      if (this.selectedRadioEntry != -1) {
        //The first entry returns 1, the second 2 and so on
        this.resolve(this.selectedRadioEntry + 1);
      } else {
        this.resolve(true);
      }
    }
    this.dialog = false;
    this.messageBox = false;
  }
  private cancel() {
    this.resolve(false);
    this.dialog = false;
    this.messageBox = false;
  }
}

export default toNative(AskingDialogBox);
