export interface Course {
  id?: string; // System.Guid
  logo: string;
  logoId?: string;
  name: string; // string
  color: string; // rgb - code
  type: CourseTypeEnum;
  bookableAsSeries: boolean;
  locationName: string;
  maxAttendees: number; // int
  minAttendees: number; // int
  trainerId?: string; // System.Guid?
  trainerFullName: string; // System.Guid?
  active: boolean; // bool
  isQrCodesCreated: boolean;
  locationId?: string; // System.Guid?
  tariffId?: string; // System.Guid?
  description: string; // string
  nr: string; // string
  responsibleId?: string; // System.Guid?
  responsibleFullName: string; // System.Guid?
  createdDate: string; // System.DateTime,
  companyId?: string; // System.Guid
  departmentNames: string;
}

export type CourseTypeEnum = 0 | 1 | 2;
export const CourseTypeEnum = {
  RegularCourse: 0 as CourseTypeEnum,
  TimeSlot: 1 as CourseTypeEnum,
  UniqueEvent: 2 as CourseTypeEnum,
};

export interface Value {
  value: Course[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<Course>): any {
  return {
    Id: data?.id || undefined,
    Name: data?.name || '',
    Color: data?.color || undefined,
    Type: data?.type ?? CourseTypeEnum.RegularCourse,
    BookableAsSeries: data?.bookableAsSeries || false,
    MaxAttendees: data?.maxAttendees || 0,
    MinAttendees: data?.minAttendees || 0,
    TrainerId: data?.trainerId || undefined,
    Active: data?.active || false,
    LocationId: data?.locationId || undefined,
    TariffId: data?.tariffId || undefined,
    Description: data?.description || '',
    ResponsibleId: data?.responsibleId || undefined,
    CompanyId: data?.companyId || undefined,
  };
}

function parse(data?: Partial<Course>): Course {
  let type =
    typeof data?.type == 'string'
      ? data.type
      : getCourseTypeText(data?.type ?? CourseTypeEnum.RegularCourse);

  return {
    id: data?.id || undefined,
    logo: data?.logo || '',
    logoId: data?.logoId || '',
    name: data?.name || '',
    color: data?.color || '#D0D0D0',
    type: CourseTypeEnum[type as keyof typeof CourseTypeEnum],
    bookableAsSeries: data?.bookableAsSeries || false,
    locationName: data?.locationName || '',
    maxAttendees: data?.maxAttendees || 0,
    minAttendees: data?.minAttendees || 0,
    trainerId: data?.trainerId || '',
    trainerFullName: data?.trainerFullName || '',
    active: data?.active || false,
    locationId: data?.locationId || undefined,
    tariffId: data?.tariffId || undefined,
    description: data?.description || '',
    nr: data?.nr || '',
    isQrCodesCreated: data?.isQrCodesCreated || false,
    responsibleId: data?.responsibleId || undefined,
    responsibleFullName: data?.responsibleFullName || '',
    createdDate: data?.createdDate || new Date().toISOString().substring(0, 10),
    companyId: data?.companyId || undefined,
    departmentNames: data?.departmentNames || '',
  };
}

export const EmptyCourse = parse({});

export default {
  parse,
  toAPI: toAPI,
};

export function getCourseTypeText(type: CourseTypeEnum) {
  return Object.keys(CourseTypeEnum)[
    Object.values(CourseTypeEnum).findIndex((entry) => entry == type)
  ];
}
