import { createApp } from 'vue';
import App from './components/app/app.vue';
import './registerServiceWorker';
import router from './router/router';
import i18n from './i18n';
import store from './shared/store';
import vuetify from './plugins/vuetify';
import './plugins/vee-validate';
import { Form, Field } from 'vee-validate';
import { ROUTES } from './router/routesEnum';
import { filters } from './shared/utils/filters';
import GeneralUtils, {
  availabilityColor,
  isEmptyId,
} from './shared/utils/generalUtils';
import { Logger } from 'fsts';
import VueChartkick from 'vue-chartkick';
import 'chartkick/chart.js';

const app = createApp(App);

app.use(i18n);
app.use(store);
app.use(router);
app.use(vuetify);
app.use(VueChartkick);

// Components that should be available in all templates without explicit inclusion
// https://vee-validate.logaretm.com/v4/guide/overview/
app.component('vee-validate-form', Form);
app.component('vee-validate-field', Field);

// Functions globally accessible in all templates
app.config.globalProperties.$ROUTES = ROUTES;
app.config.globalProperties.$filters = filters;
app.config.globalProperties.$functions = {
  availabilityColor,
  isEmptyId,
};

if (GeneralUtils.isDevEnvironment()) Logger.LOG_LEVEL = 'DEBUG';

app.mount('#app');
