export interface TariffRef {
  id?: string; // System.Guid
  tariffId?: string; // System.Guid
  membershipTypeId?: string; // System.Guid
  membershipTypeName: string;
  costs: number; // float
  costType: CostTypeEnum;
  companyId?: string; // System.Guid
  creatorId?: string; // System.Guid
  createdDate: string; // System.DateTime
}

export interface Value {
  value: TariffRef[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

export type CostTypeEnum = 0 | 1;
export const CostTypeEnum = {
  Single: 0 as CostTypeEnum,
  PerCourse: 1 as CostTypeEnum,
};

// init api object
function toAPI(data: Partial<TariffRef>): any {
  return {
    Id: data?.id || undefined,
    TariffId: data?.tariffId || undefined,
    MembershipTypeId: data?.membershipTypeId || undefined,
    Costs: data?.costs || 0,
    CompanyId: data?.companyId || undefined,
    CostType: data?.costType || CostTypeEnum.Single,
  };
}

function parse(data?: Partial<TariffRef>): TariffRef {
  return {
    id: data?.id || undefined,
    tariffId: data?.tariffId || undefined,
    membershipTypeId: data?.membershipTypeId || undefined,
    membershipTypeName: data?.membershipTypeName || '',
    costs: data?.costs || 0,
    costType: data?.costType || CostTypeEnum.Single,
    companyId: data?.companyId || undefined,
    creatorId: data?.creatorId || undefined,
    createdDate: data?.createdDate || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
