import { ActionTree } from 'vuex';
import { TariffRefState } from './types';
import { RootState } from '../../types';
import { defaultBackendTariffRef } from '@/shared/backend/tariffRef';
import { Logger } from 'fsts';
import moduleUtils from '../moduleUtils';
import { Value, TariffRef } from '@/shared/model/tariffRef';
import { AxiosResponse } from 'axios';
import { i18nGlobal } from '@/i18n';
import { CONST } from '@/shared/utils/constants';

const logger = new Logger('actions.tariffRefs');
export const actions: ActionTree<TariffRefState, RootState> = {
  getAllTariffRefs({ commit }) {
    commit('setTariffRefsIsLoading', true);
    return defaultBackendTariffRef
      .getAllTariffRefs()
      .then((response: AxiosResponse<Value>) => {
        commit('setAllTariffRefs', response.data);
        commit('setTariffRefsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setTariffRefsIsLoading', false);
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  getTariffRefs(
    { commit, dispatch, getters, rootGetters },
    payload?: { searchParams?: any }
  ) {
    commit('setTariffRefsIsLoading', true);
    let searchParams =
      payload?.searchParams ?? getters.getTariffRefsSearchParams;
    let organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendTariffRef
      .getTariffRefs(searchParams, organisationId)
      .then((response: AxiosResponse<Value>) => {
        commit('setTariffRefs', response.data);
        commit('setTariffRefsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setTariffRefsIsLoading', false);
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  getTariffRef({ commit, dispatch }, id: string) {
    return defaultBackendTariffRef
      .getTariffRef(id)
      .then((response: AxiosResponse<TariffRef>) => {
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateTariffRef({ commit, dispatch }, file: TariffRef) {
    return defaultBackendTariffRef
      .updateTariffRef(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: TariffRef;
          }>
        ) => {
          moduleUtils.ok(i18nGlobal.t(`success.tariffRef_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteTariffRef({ commit, dispatch }, id: string) {
    return defaultBackendTariffRef
      .deleteTariffRef(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: TariffRef;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
};
