import { ActionTree } from 'vuex';
import { DateTimeManagement } from './types';
import { RootState } from '../../types';
import { defaultBackendDateTime } from '@/shared/backend/dateTime';
import { Logger } from 'fsts';
import moduleUtils from '../moduleUtils';
import { Value, DateTime } from '@/shared/model/dateTime';
import { AxiosResponse } from 'axios';
import { i18nGlobal } from '@/i18n';
import { isEmptyId } from '@/shared/utils/generalUtils';
import { createSearchParams } from '@/shared/model/searchParams';

const logger = new Logger('actions.dateTimes');
export const actions: ActionTree<DateTimeManagement, RootState> = {
  getDateTimes(
    { commit, dispatch, getters },
    payload?: {
      searchParams?: any;
      courseId?: string;
      locationId?: string;
      departmentId?: string;
    }
  ) {
    commit('setDateTimesIsLoading', true);
    let searchParams =
      payload?.searchParams ?? getters.getDateTimesSearchParams;
    let courseId = payload?.courseId;
    let locationId = payload?.locationId;
    let departmentId = payload?.departmentId;

    return defaultBackendDateTime
      .getDateTimes(searchParams, courseId, locationId, departmentId)
      .then((response: AxiosResponse<Value>) => {
        commit('setDateTimes', response.data);
        commit('setDateTimesIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setDateTimesIsLoading', false);
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  getDateTime({ commit, dispatch }, id: string) {
    return defaultBackendDateTime
      .getDateTime(id)
      .then((response: AxiosResponse<DateTime>) => {
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateDateTime({ commit, dispatch }, file: DateTime) {
    return defaultBackendDateTime
      .updateDateTime(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: DateTime;
          }>
        ) => {
          moduleUtils.ok(i18nGlobal.t(`success.dateTime_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteDateTime({ commit, dispatch }, id: string) {
    return defaultBackendDateTime
      .deleteDateTime(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: DateTime;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  async checkForNonCanceledFutureAppointments(
    { dispatch, rootState },
    id: string
  ): Promise<boolean> {
    if (isEmptyId(id)) return false;
    const payloadArgs = {
      searchParams: createSearchParams(1),
      dateTimeId: id,
    };
    await dispatch(
      'appointmentModule/getNonCanceledFutureAppointments',
      payloadArgs,
      { root: true }
    );

    return (rootState as any).appointmentModule.appointments.items.length > 0;
  },
  checkIfDateTimeIsBookedSeriesCourse({}, id: string) {
    return defaultBackendDateTime.checkIfDateTimeIsBookedSeriesCourse(id);
  }
};
