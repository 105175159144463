import { MutationTree } from 'vuex';
import { StripeState } from './types';
import {
  StripeCard,
  StripePlan,
  StripeSubscription,
  StripeCustomer,
  StripeSubscriptionItem,
  StripeInvoice,
} from '../../../model/stripe';
import { initialState } from './state';

export const mutations: MutationTree<StripeState> = {
  setPlans(state: StripeState, payload: StripePlan[]) {
    // Sort the plans by the provided amount of members.
    if (payload) {
      payload.sort((a,b) => {
        let maxMembersA = a.tiers ? a.tiers[0].up_to : 9999999;
        let maxMembersB = b.tiers ? b.tiers[0].up_to : 9999999;
        return maxMembersA - maxMembersB;
      });
    }
    state.plans = payload;
  },
  setPlan(state: StripeState, payload: StripePlan) {
    state.plan = payload;
  },
  reset(state) {
    const initState = initialState();
    Object.assign(state, initState);
  },
  updateOptions(state, payload: any) {
    state.options = payload;
  },
  setCards(state, payload: StripeCard[]) {
    state.cards = payload;
  },
  setCard(state: StripeState, payload: StripeCard) {
    state.card = payload;
  },
  setSubscription(state, payload: StripeSubscription) {
    state.subscription = payload;
    state.isLoadingSubscriptionDetails = false;
  },
  setSubscriptionItem(state, payload: StripeSubscriptionItem) {
    state.subscriptionItem = payload;
  },
  setCustomer(state: StripeState, payload: StripeCustomer) {
    state.customer = payload;
    state.default_source = payload.default_source;
  },
  setExistCustomer(state, payload: boolean) {
    state.existCustomer = payload;
  },
  setStripePublicKey(state: StripeState, payload: string) {
    state.stripePublicKey = payload;
  },
  setUpcomingInvoice(state, payload: StripeInvoice) {
    state.upcomingInvoice = payload;
    state.isLoadingSubscriptionDetails = false;
  },
  setInvoices(state, payload: StripeInvoice[]) {
    state.invoices = payload;
  },
  setIsLoadingSubscriptionDetails(state, payload: boolean) {
    state.isLoadingSubscriptionDetails = payload;
  },
};
