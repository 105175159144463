import { ActionTree } from 'vuex';
import { ReferenceParentState } from './types';
import { RootState } from '../../types';
import { defaultBackendReferenceParent } from '@/shared/backend/referenceParent';
import { Logger } from 'fsts';
import moduleUtils from '../moduleUtils';
import { Value, ReferenceParent } from '@/shared/model/referenceParent';
import { AxiosResponse } from 'axios';
import { i18nGlobal } from '@/i18n';
import { CONST } from '../../../utils/constants';

const logger = new Logger('actions.skillRefs');
export const actions: ActionTree<ReferenceParentState, RootState> = {
  getReferenceParents(
    { commit, dispatch, getters, rootGetters },
    payload?: {
      searchParams?: any;
      parentId?: string;
      filterRefParentTable?: string;
    }
  ) {
    commit('setReferenceParentsIsLoading', true);
    let searchParams =
      payload?.searchParams ?? getters.getReferenceParentsSearchParams;
    let parentId = payload?.parentId ?? CONST.emptyGuid;
    let filterRefParentTable = payload?.filterRefParentTable || undefined;
    return defaultBackendReferenceParent
      .getReferenceParents(searchParams, parentId, filterRefParentTable)
      .then((response: AxiosResponse<Value>) => {
        commit('setReferenceParents', response.data);
        commit('setReferenceParentsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setReferenceParentsIsLoading', false);
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },

  getAllReferenceParents(
    { commit, dispatch, getters, rootGetters },
    payload?: {
      parentId?: string;
      filterRefParentTable?: string;
      referenceId?: string;
      noStore?: boolean;
    }
  ) {
    commit('setReferenceParentsIsLoading', true);

    let parentId = payload?.parentId ?? undefined;
    let filterRefParentTable = payload?.filterRefParentTable ?? undefined;
    let referenceId = payload?.referenceId ?? undefined;

    return defaultBackendReferenceParent
      .getAllReferenceParents(parentId, filterRefParentTable, referenceId)
      .then((response: AxiosResponse<Value>) => {
        if (!payload?.noStore) commit('setAllReferenceParents', response.data);
        commit('setReferenceParentsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setReferenceParentsIsLoading', false);
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  async getReferenceParent({ commit, dispatch }, id: string) {
    return await defaultBackendReferenceParent
      .getReferenceParent(id)
      .then((response: AxiosResponse<ReferenceParent>) => {
        commit('setReferenceParent', response.data);
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateReferenceParent(
    { commit, dispatch },
    payload: {
      item: ReferenceParent;
      refParentTable: string;
      overrideMessage?: string;
    }
  ) {
    return defaultBackendReferenceParent
      .updateReferenceParent(payload.item, payload.refParentTable)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: ReferenceParent;
          }>
        ) => {
          if (payload.overrideMessage != undefined)
            moduleUtils.ok(payload.overrideMessage, commit);
          else moduleUtils.ok(i18nGlobal.t(`success.skillRef_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  addMultipleReferenceParent(
    { commit, dispatch },
    payload: {
      skillRefs: ReferenceParent[];
      tableName: string;
      overrideMessage?: string;
      noMessage?: boolean;
    }
  ) {
    return defaultBackendReferenceParent
      .addMultipleReferenceParents(payload.skillRefs, payload.tableName)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: ReferenceParent;
          }>
        ) => {
          if (payload.noMessage) return response.data;
          if (payload.overrideMessage != undefined)
            moduleUtils.ok(payload.overrideMessage, commit);
          else moduleUtils.ok(i18nGlobal.t(`success.skillRef_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteReferenceParent({ commit, dispatch }, id: string) {
    return defaultBackendReferenceParent
      .deleteReferenceParent(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: ReferenceParent;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  clearAllReferenceParents({ commit, dispatch }) {
    commit('setAllReferenceParents', []);
  },
};
