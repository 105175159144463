import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import presenceAttendance, {
  PresenceAttendance,
  Value,
} from '../model/presenceAttendance';
import ODataFilterBuilder from 'odata-filter-builder';
import { isEmptyId } from '../utils/generalUtils';
import attendance, { Attendance } from '../model/attendance';

const logger = new Logger('backend.PresenceAttendances');

export interface BackendPresenceAttendances {
  getPresenceAttendance(id: string): AxiosPromise<PresenceAttendance>;
  getPresenceAttendances: (searchParams: SearchParams) => AxiosPromise<Value>;
  deletePresenceAttendance(id: string): AxiosPromise;
  updatePresenceAttendance(
    PresenceAttendance: PresenceAttendance
  ): AxiosPromise<any>;
  updatePresenceAttendancesMultiple(payload: {
    presenceId: string;
    attendances: Attendance[];
  }): AxiosPromise<any>;
}

export const defaultBackendPresenceAttendances: BackendPresenceAttendances = {
  getPresenceAttendance(id: string): AxiosPromise<PresenceAttendance> {
    let url = `${URLS.presenceAttendancesOdata}/${id}`;
    return instance.get<PresenceAttendance>(url);
  },
  getPresenceAttendances(searchParams: SearchParams): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.presenceAttendancesOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getPresenceAttendances${url}`);
    return instance.get<Value>(url);
  },

  deletePresenceAttendance(id: string): AxiosPromise {
    logger.debug('deletePresenceAttendances');
    return instance.delete(`${URLS.presenceAttendances}/${id}`);
  },
  updatePresenceAttendance(item: PresenceAttendance): AxiosPromise<any> {
    logger.debug('updatePresenceAttendances');
    if (isEmptyId(item.id)) {
      return instance.post<PresenceAttendance>(
        `${URLS.presenceAttendances}`,
        presenceAttendance.toAPI(item)
      );
    }
    return instance.put<PresenceAttendance>(
      `${URLS.presenceAttendances}`,
      presenceAttendance.toAPI(item)
    );
  },
  updatePresenceAttendancesMultiple(payload: {
    presenceId: string;
    attendances: Attendance[];
  }): AxiosPromise<any> {
    logger.debug('updatePresenceAttendancesMultiple');
    return instance.post<PresenceAttendance>(
      `${URLS.presenceAttendances}/AddMultiple`,
      {
        presenceId: payload.presenceId,
        attendances: payload.attendances.map((x) => attendance.toAPI(x)),
      }
    );
  },
};
