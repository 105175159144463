import { GetterTree } from 'vuex';
import { TariffState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<TariffState, RootState> = {
  ['getTariffs']: state => state.tariffs,
  ['getTariffsIsLoading']: state => state.tariffs?.isLoading,
  ['getTariffsTotal']: state => state.tariffs?.total,
  ['getTariffsSearchParams']: state => state.tariffs?.searchParams,
};
