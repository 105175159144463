import { ActionTree } from 'vuex';
import { MembershipRequestState } from './types';
import { RootState } from '../../types';
import { defaultBackendMembershipRequest } from '@/shared/backend/membershipRequest';
import { Logger } from 'fsts';
import moduleUtils from '../moduleUtils';
import { AxiosResponse } from 'axios';
import { i18nGlobal } from '@/i18n';
import { SearchParams } from '@/shared/model/searchParams';
import {
  MembershipRequest,
  MembershipRequestStatus,
  Value,
} from '@/shared/model/membershipRequest';

const logger = new Logger('actions.membershipRequests');
export const actions: ActionTree<MembershipRequestState, RootState> = {
  getMembershipRequests(
    { commit, getters },
    payload: {
      searchParams?: SearchParams;
      status?: MembershipRequestStatus;
      applicationUserId?: string;
      startDate?: string;
      endDate?: string;
      locationId?: string;
      departmentId?: string;
    }
  ) {
    commit('setMembershipRequestsIsLoading', true);
    let searchParams =
      payload?.searchParams ?? getters.getMembershipRequestsSearchParams;

    return defaultBackendMembershipRequest
      .getMembershipRequests(
        searchParams,
        payload.status,
        payload.applicationUserId,
        payload.startDate,
        payload.endDate,
        payload.locationId,
        payload.departmentId
      )
      .then((response: AxiosResponse<Value>) => {
        commit('setMembershipRequests', response.data);
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => commit('setMembershipRequestsIsLoading', false));
  },
  updateMembershipRequest({ commit }, payload: MembershipRequest) {
    return defaultBackendMembershipRequest
      .updateMembershipRequest(payload)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: MembershipRequest;
          }>
        ) => {
          moduleUtils.ok(
            i18nGlobal.t(`success.membership_request_update`),
            commit
          );
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteMembershipRequest({ commit, dispatch }, id: string) {
    return defaultBackendMembershipRequest
      .deleteMembershipRequest(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: MembershipRequest;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  getMembershipRequestsTotalOpenCount(
    { commit },
    payload?: { locationId?: string; departmentId?: string; }
  ) {
    return defaultBackendMembershipRequest
      .getMembershipRequestsTotalOpenCount(
        payload?.locationId,
        payload?.departmentId
      ).then((response: AxiosResponse<Value>) => {
        commit('setMembershipRequestsTotalOpenCount', response.data);
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        commit('setMembershipRequestsTotalOpenCount', undefined);
        throw e;
      });
  },
};
