import { ActionTree } from 'vuex';
import { CompanyState } from './types';
import { RootState } from '../../types';
import { initialState } from './state';
import { i18nGlobal } from '../../../../i18n';
import { Company } from '../../../model/company';
import { defaultBackendCompany } from '../../../backend/company';
import { AxiosResponse } from 'axios';
import moduleUtils from '../moduleUtils';
import { Logger } from 'fsts';
import store from '../..';
import { StripeSubscription, StripeSubscriptionItem } from '@/shared/model/stripe';
import { isEmptyId } from '@/shared/utils/generalUtils';

const logger = new Logger('actions.editCompany');

export const actions: ActionTree<CompanyState, RootState> = {
  getCompanyNameById({ commit, dispatch }, id: string) {
    return defaultBackendCompany
      .getCompanyNameById(id)
      .then((response: AxiosResponse<string>) => {
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  async getCompany({ commit }) {
    try {
      const currentCompany: Company = (await defaultBackendCompany.getCompany())
        .data;
      commit('setCompany', currentCompany);
      return currentCompany;
    } catch (e) {
      console.log(e);
      commit('setCompany', initialState().currentCompany);
      commit(
        'setSnackbarError',
        {
          message: i18nGlobal.t(`error.load_data_failed`),
          duration: 5000,
        },
        { root: true }
      );
    }
  },

  async getCompanyCountry({ commit }) {
    try {
      const response = await defaultBackendCompany.getCompanyCountry();
      commit('setCompanyCountry', response.data);
    } catch (e) {
      console.log(e);
      commit('setCompanyCountry', initialState().companyCountry);
      commit(
        'setSnackbarError',
        {
          message: i18nGlobal.t(`error.load_data_failed`),
          duration: 5000,
        },
        { root: true }
      );
    }
  },

  async getTotalAttendees({ commit }) {
    try {
      const response = await defaultBackendCompany.getTotalAttendees();
      commit('setTotalAttendees', response.data);
    } catch (e) {
      console.log(e);
      commit('setTotalAttendees', initialState().totalAttendees);
      commit(
        'setSnackbarError',
        {
          message: i18nGlobal.t(`error.load_data_failed`),
          duration: 5000,
        },
        { root: true }
      );
    }
  },

  // This store action mostly takes place in the stripe module, but I've placed it here for convenience (together with getTotalAttendees)
  async getMaxAttendees({ dispatch, getters, commit }) {
    let currentCompany = <Company>getters['getCompany'];
    const currentCompanyId = <string|null>store.getters['auth/getCompanyId'];

    if (currentCompany.id != currentCompanyId) {
      await dispatch('getCompany');
      currentCompany = <Company>getters['getCompany'];
    }

    if (!currentCompany.stripeCustomerId) {
      commit('setMaxAttendees', undefined);
      return;
    }

    let currentSubscription = <StripeSubscription>store.getters['stripeModule/getSubscription'];
    if (currentSubscription.customerId != currentCompany.stripeCustomerId) {
      await store.dispatch('stripeModule/getSubscriptionDetails', currentCompany.stripeCustomerId);
      currentSubscription = <StripeSubscription>store.getters['stripeModule/getSubscription'];

      if (!currentSubscription.id) {
        commit('setMaxAttendees', undefined);
        return;
      }
    }

    // This action calls the setMaxAttendees - mutation of the company module after completed loading.
    await store.dispatch('stripeModule/getSubscriptionItem', currentSubscription.id);
  },

  async updateCompany({ commit }, payload: Company) {
    try {
      return defaultBackendCompany.updateCompany(payload).then((response) => {
        commit('setCompany', response.data);
        commit(
          'setSnackbarSuccess',
          {
            message: i18nGlobal.t(
              `editCompany_management.success.update_company`
            ),
            duration: 5000,
          },
          { root: true }
        );
      });
    } catch (e) {
      console.log(e);
      commit(
        'setSnackbarError',
        {
          message: i18nGlobal.t(`editCompany_management.error.update_company`),
          duration: 5000,
        },
        { root: true }
      );
    }
  },
  deleteCompany({ commit, dispatch }, payload: number) {
    return defaultBackendCompany
      .deleteCompany(payload)
      .then((response) => {
        commit('setCompany', initialState().currentCompany);
        commit(
          'setSnackbarSuccess',
          {
            message: i18nGlobal.t(
              `editCompany_management.success.delete_company`
            ),
            duration: 5000,
          },
          { root: true }
        );
      })
      .catch((e) => {
        console.log(e);
        if (e.response.data) {
          throw e.response.data;
        }
        commit(
          'setSnackbarError',
          {
            message: i18nGlobal.t(
              `editCompany_management.error.delete_company`
            ),
            duration: 5000,
          },
          { root: true }
        );
      });
  },

  async getCompanyById({ commit }, companyId: string) {
    try {
      const company: Company = (
        await defaultBackendCompany.getCompanyById(companyId)
      ).data;
      return company;
    } catch (e) {
      console.log(e);
      commit(
        'setSnackbarError',
        {
          message: i18nGlobal.t(`error.load_data_failed`),
          duration: 5000,
        },
        { root: true }
      );
    }
  },
  async getCompanies({ commit, dispatch }, payload?: any) {
    try {
      commit('setIsLoadingCompanies', true);
      const response = await defaultBackendCompany.getAllCompanies();
      commit('setCompanies', response.data);
    } catch (e) {
      commit('setCompanies', []);
      commit('setIsLoadingCompanies', false);
      commit(
        'setSnackbarError',
        {
          message: i18nGlobal.t(`error.load_data_failed`),
          duration: 5000,
        },
        { root: true }
      );
    }
  },
};
