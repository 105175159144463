import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import appointmentMessageFuncs, {
  AppointmentMessage,
} from '../model/appointmentMessage';
import { isEmptyId } from '../utils/generalUtils';
import { DefaultBackendHelper } from '../utils/backendHelper';

const logger = new Logger('backend.AppointmentMessages');
export interface BackendAppointmentMessage {
  getAppointmentMessagesByAppointmentId(
    appointmentId: string,
    fromDate?: string
  ): AxiosPromise<AppointmentMessage[]>;
  updateAppointmentMessage(
    appointmentMessage: AppointmentMessage
  ): AxiosPromise<any>;
}

export const defaultBackendAppointmentMessage: BackendAppointmentMessage = {
  getAppointmentMessagesByAppointmentId(appointmentId, fromDate?: string) {
    let url = `${URLS.appointmentMessage}/GetByAppointmentId/${appointmentId}`;
    if (fromDate != undefined) {
      url = DefaultBackendHelper.addParamsToUrl(url, [`fromDate=${fromDate}`]);
    }
    logger.log(`getAppointmentMessages: ${url}`);
    return instance.get<AppointmentMessage[]>(url);
  },
  updateAppointmentMessage(appointmentMessage: AppointmentMessage) {
    if (isEmptyId(appointmentMessage.id)) {
      return instance.post<AppointmentMessage>(
        `${URLS.appointmentMessage}`,
        appointmentMessageFuncs.toAPI(appointmentMessage)
      );
    }
    return instance.put<AppointmentMessage>(
      `${URLS.appointmentMessage}`,
      appointmentMessageFuncs.toAPI(appointmentMessage)
    );
  },
};
