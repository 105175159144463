import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys } from "vue"

export function render(_ctx, _cache) {
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")
  const _component_v_toolbar = _resolveComponent("v-toolbar")
  const _component_v_card_text = _resolveComponent("v-card-text")
  const _component_v_radio = _resolveComponent("v-radio")
  const _component_v_radio_group = _resolveComponent("v-radio-group")
  const _component_v_spacer = _resolveComponent("v-spacer")
  const _component_v_btn = _resolveComponent("v-btn")
  const _component_v_card_actions = _resolveComponent("v-card-actions")
  const _component_v_card = _resolveComponent("v-card")
  const _component_v_dialog = _resolveComponent("v-dialog")

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: _ctx.dialog,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.dialog) = $event)),
    persistent: "",
    "max-width": _ctx.options.width,
    "z-index": _ctx.options.zIndex,
    onKeydown: _cache[2] || (_cache[2] = _withKeys($event => (_ctx.dialog = false), ["esc"]))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_toolbar, {
            dark: "",
            color: _ctx.options.color,
            dense: "",
            flat: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_toolbar_title, { class: "text-white" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.title), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["color"]),
          _withDirectives(_createVNode(_component_v_card_text, { class: "pa-4" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.message), 1)
            ]),
            _: 1
          }, 512), [
            [_vShow, !!_ctx.message]
          ]),
          (_ctx.radioEntries.length > 0)
            ? (_openBlock(), _createBlock(_component_v_radio_group, {
                key: 0,
                modelValue: _ctx.selectedRadioEntry,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.selectedRadioEntry) = $event)),
                class: "ml-4 mt-n2"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.radioEntries, (entry, index) => {
                    return (_openBlock(), _createBlock(_component_v_radio, {
                      key: index,
                      value: index,
                      label: entry
                    }, null, 8, ["value", "label"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue"]))
            : _createCommentVNode("", true),
          _createVNode(_component_v_card_actions, { class: "pt-0" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_spacer),
              (!_ctx.messageBox)
                ? (_openBlock(), _createBlock(_component_v_btn, {
                    key: 0,
                    color: "grey",
                    onClick: _ctx.cancel
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.options.cancelText != undefined ? _ctx.options.cancelText :
        _ctx.i18n.t('cancel')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : _createCommentVNode("", true),
              _createVNode(_component_v_btn, {
                color: "primary darken-1",
                text: "",
                onClick: _ctx.agree
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.options.okText != undefined ? _ctx.options.okText : _ctx.i18n.t('ok')), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue", "max-width", "z-index"]))
}