import { CONST } from '../utils/constants';

export interface MembershipTypeCostOverride {
  id?: string; // System.Guid
  membershipTypeId?: string; // System.Guid
  memberStatusId?: string; // System.Guid
  memberStatusName: string;
  cost: number;
  companyId: string; // System.Guid
  creatorId?: string; // System.Guid
  createdDate: string; // System.DateTime
}

export interface Value {
  value: MembershipTypeCostOverride[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

function arrayToAPI(overrides: MembershipTypeCostOverride[]): any {
  return overrides.map((x) => {
    return {
      MemberStatusId: x.memberStatusId,
      Cost: x.cost,
    };
  });
}

function parse(
  data?: Partial<MembershipTypeCostOverride>
): MembershipTypeCostOverride {
  return {
    id: data?.id || undefined,
    membershipTypeId: data?.membershipTypeId || undefined,
    memberStatusId: data?.memberStatusId || undefined,
    memberStatusName: data?.memberStatusName ?? '',
    cost: data?.cost ?? 0,
    companyId: data?.companyId || CONST.emptyGuid,
    creatorId: data?.creatorId || undefined,
    createdDate: data?.createdDate || '',
  };
}

export default {
  parse,
  arrayToAPI,
};

export const EmptyMembershipTypeCostOverride: MembershipTypeCostOverride =
  parse({});
