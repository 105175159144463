import { MutationTree } from 'vuex';
import { QrCodeState } from './types';
import qrCode, { Value } from '@/shared/model/qrCode';

export const mutations: MutationTree<QrCodeState> = {
  setQrCodes(state, payload: Value) {
    state.qrCodes.items = payload.value.map((x) => qrCode.parse(x));
    state.qrCodes.total = payload['@odata.count'] || 0;
    state.qrCodes.isLoading = false;
  },
  setQrCodesTotal(state, payload: number) {
    state.qrCodes.total = payload;
  },
  setQrCodesIsLoading(state, payload: boolean) {
    state.qrCodes.isLoading = payload;
  },
  resetQrCodesPaging(state) {
    state.qrCodes.searchParams.dataOption.page = 1;
    state.qrCodes.searchParams.dataOption.itemsPerPage = 10;
    state.qrCodes.searchParams.dataOption.filter = '';
    state.qrCodes.searchParams.filter = '';
  },
};
