import { WebPushState } from './types';

export const state = (): WebPushState => initialState();

export const initialState = (): WebPushState => ({
  webPushSubscriptionStatus: 0,
  publicVapidKey: '',
  incompatibleBrowser: false,
  pushSubscription: null,
  permissionState: undefined,
  messages: {
    items: [],
    isLoading: false,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['createdDate'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: [],
    },
  },
});
