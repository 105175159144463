import { MutationTree } from 'vuex';
import { TariffState } from './types';
import tariff, { Value } from '@/shared/model/tariff';

export const mutations: MutationTree<TariffState> = {
  setTariffs(state, payload: Value) {
    state.tariffs.items = payload.value.map((x) => tariff.parse(x));
    state.tariffs.total = payload['@odata.count'] || 0;
    state.tariffs.isLoading = false;
  },
  setTariffsTotal(state, payload: number) {
    state.tariffs.total = payload;
  },
  setTariffsIsLoading(state, payload: boolean) {
    state.tariffs.isLoading = payload;
  },
  resetTariffsPaging(state) {
    state.tariffs.searchParams.dataOption.page = 1;
    state.tariffs.searchParams.dataOption.itemsPerPage = 10;
    state.tariffs.searchParams.dataOption.filter = '';
    state.tariffs.searchParams.filter = '';
  },
};
