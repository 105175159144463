import { GetterTree } from 'vuex';
import { WebPushState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<WebPushState, RootState> = {
  ['getWebPushSubscriptionStatus']: state => state.webPushSubscriptionStatus,
  ['getPublicVAPIDKey']: state => state.publicVapidKey,
  ['isIncompatibleBrowser']: state => state.incompatibleBrowser,
  ['getPushSubscription']: state => state.pushSubscription,
  ['hasPushSubscription']: state => state.pushSubscription != null,
  ['getPermissionState']: state => state.permissionState,
  ['getMessages']: state => state.messages,
  ['getMessagesTotal']: state => state.messages?.total,
  ['getMessagesSearchParams']: state => state.messages?.searchParams,
  ['getMessagesPage']: state => state.messages?.searchParams.dataOption.page,
  ['getMessagesMaxPage']: state =>
    Math.ceil(
      state.messages?.total /
        state.messages?.searchParams.dataOption.itemsPerPage
    ) || 1,
};
