import { ActionTree } from 'vuex';
import { LocationEmployeeState } from './types';
import { RootState } from '../../types';
import { defaultBackendLocationEmployee } from '@/shared/backend/locationEmployee';
import { Logger } from 'fsts';
import moduleUtils from '../moduleUtils';
import { Value, LocationEmployee } from '@/shared/model/locationEmployee';
import { AxiosResponse } from 'axios';
import { CONST } from '../../../utils/constants';
import { createSearchParams } from '@/shared/model/searchParams';

const logger = new Logger('actions.locationEmployees');
export const actions: ActionTree<LocationEmployeeState, RootState> = {
  getLocationEmployees(
    { commit, dispatch, getters, rootGetters },
    payload?: { searchParams?: any; locationId?: string; employeeId?: string }
  ) {
    commit('setLocationEmployeesIsLoading', true);
    let searchParams =
      payload?.searchParams ?? getters.getLocationEmployeesSearchParams;
    let locationId = payload?.locationId ?? CONST.emptyGuid;
    let employeeId = payload?.employeeId ?? CONST.emptyGuid;
    return defaultBackendLocationEmployee
      .getLocationEmployees(searchParams, locationId, employeeId)
      .then((response: AxiosResponse<Value>) => {
        commit('setLocationEmployees', response.data);
        commit('setLocationEmployeesIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setLocationEmployeesIsLoading', false);
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  getLocationEmployeesNonPaginated(
    { dispatch },
    payload: { employeeId: string }
  ) {
    return dispatch('getLocationEmployees', {
      employeeId: payload.employeeId,
      searchParams: createSearchParams(250),
    });
  },
  getLocationEmployee({ commit, dispatch }, id: string) {
    return defaultBackendLocationEmployee
      .getLocationEmployee(id)
      .then((response: AxiosResponse<LocationEmployee>) => {
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateLocationEmployee(
    { commit, dispatch },
    payload: { locationEmployees: LocationEmployee[]; forLocationId: string }
  ) {
    return defaultBackendLocationEmployee
      .updateLocationEmployee(payload.locationEmployees, payload.forLocationId)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: LocationEmployee;
          }>
        ) => {
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateLocationEmployeefromEmployeeView(
    { commit, dispatch },
    payload: { locationEmployees: LocationEmployee[]; forEmployeeId: string }
  ) {
    return defaultBackendLocationEmployee
      .updateLocationEmployeeFromEmployeeView(
        payload.locationEmployees,
        payload.forEmployeeId
      )
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: LocationEmployee;
          }>
        ) => {
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteLocationEmployee({ commit, dispatch }, id: string) {
    return defaultBackendLocationEmployee
      .deleteLocationEmployee(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: LocationEmployee;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
};
