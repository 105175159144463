import { MutationTree } from 'vuex';
import { RoomAvailabilityState } from './types';
import roomAvailability, { Value } from '@/shared/model/roomAvailability';

export const mutations: MutationTree<RoomAvailabilityState> = {
  setRoomAvailabilities(state, payload: Value) {
    state.roomAvailabilities.items = payload.value.map(x =>
      roomAvailability.parse(x)
    );
    state.roomAvailabilities.total = payload['@odata.count'] || 0;
    state.roomAvailabilities.isLoading = false;
  },
  setRoomAvailabilitiesTotal(state, payload: number) {
    state.roomAvailabilities.total = payload;
  },
  setRoomAvailabilitiesIsLoading(state, payload: boolean) {
    state.roomAvailabilities.isLoading = payload;
  },
};
