import { GetterTree } from 'vuex';
import { AttendeeRegisterByQrCodeState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<AttendeeRegisterByQrCodeState, RootState> = {
  ['getLocation']: state => state.location,
  ['getAttendee']: state => state.attendee,
  ['getCourseTrainers']: state => state.courseTrainers,
  ['getCourseTrainersIsLoading']: state => state.courseTrainers?.isLoading,
  ['getCourseTrainersTotal']: state => state.courseTrainers?.total,
  ['getCourseTrainersSearchParams']: state =>
    state.courseTrainers?.searchParams,

  ['getAppointments']: state => state.appointments,
  ['getAppointmentsIsLoading']: state => state.appointments?.isLoading,
  ['getAppointmentsTotal']: state => state.appointments?.total,
  ['getAppointmentsSearchParams']: state => state.appointments?.searchParams,
};
