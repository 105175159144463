import { CONST } from '../utils/constants';
import { formatFullName } from '../utils/generalUtils';
import { CourseTypeEnum, getCourseTypeText } from './course';
import DateUtils from '../utils/dateUtils';

export interface Attendance {
  id?: string; // System.Guid
  status: ParticipationStatusType;
  source: ParticipationSourceType;
  bookableAsSeries: boolean;
  roomName: string;
  attendeeFullName: string;
  attendeeRole: string;
  courseId?: string;
  trainerId?: string;
  dateTimeId: string;
  courseTrainerId?: string; // System.Guid
  courseName: string;
  trainerFullName: string;
  timeFrom: string;
  timeTill: string;
  readonly timeFromTill: string;
  readonly bookingDateAndTime: string;
  readonly isPassedWithTime: boolean;
  locationName: string;
  attendeeId?: string; // System.Guid?
  appointmentId?: string; // System.Guid?
  bookingDate: string; // System.DateTime
  ipAddress: string; // string
  createdDate: string; // System.DateTime
  creatorFullName: string;
  canceledDate: string; // System.DateTime
  canceledByFullName: string;
  appointmentMaxAttendeeCount: number;
  appointmentCurrentAttendeeCount: number;
  attendeeOnWaitingListNumber: number;
  departmentNames: string;
  checkedInDate: string; // System.DateTime
  courseType: CourseTypeEnum;
}

export type ParticipationStatusType = 0 | 1 | 2 | 3 | 4;
export const ParticipationStatusType = {
  NotConfirmed: 0 as ParticipationStatusType,
  Canceled: 1 as ParticipationStatusType,
  Confirmed: 2 as ParticipationStatusType,
  Requested: 3 as ParticipationStatusType,
  OnWaitingList: 4 as ParticipationStatusType,
};

export type ParticipationSourceType = 0 | 1;
export const ParticipationSourceType = {
  Manual: 0 as ParticipationSourceType,
  CheckIn: 1 as ParticipationSourceType,
};

export interface Value {
  value: Attendance[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<Attendance>): any {
  return {
    Id: data?.id || undefined,
    TrainerId: data?.trainerId || undefined,
    CourseTrainerId: data?.courseTrainerId || undefined,
    AttendeeId: data?.attendeeId || undefined,
    AppointmentId: data?.appointmentId || undefined,
    IpAddress: data?.ipAddress || '',
    Status: data?.status || ParticipationStatusType.NotConfirmed,
    Source: data?.source || ParticipationSourceType.Manual,
  };
}

// init api object
function arrayToAPI(data: Partial<Attendance[]>): any {
  return data.map((item?: Attendance) => {
    return {
      Id: item?.id || undefined,
      AttendeeId: item?.attendeeId || undefined,
      TrainerId: item?.trainerId || undefined,
      CourseTrainerId: item?.courseTrainerId || undefined,
      AppointmentId: item?.appointmentId || undefined,
      Source: item?.source || ParticipationSourceType.Manual,
      Status: item?.status || ParticipationStatusType.NotConfirmed,
    };
  });
}

function parse(data?: Partial<Attendance>): Attendance {
  let status = data?.status || 'NotConfirmed';
  let source = data?.source || 'Manual';
  let courseType =
    data?.courseType || getCourseTypeText(CourseTypeEnum.RegularCourse);
  return {
    id: data?.id || undefined,
    status:
      ParticipationStatusType[status as keyof typeof ParticipationStatusType],
    source:
      ParticipationSourceType[source as keyof typeof ParticipationSourceType],
    bookableAsSeries: data?.bookableAsSeries || false,
    roomName: data?.roomName || '',
    attendeeFullName: formatFullName(data?.attendeeFullName),
    attendeeRole: data?.attendeeRole || '',
    courseId: data?.courseId || undefined,
    trainerId: data?.trainerId || undefined,
    dateTimeId: data?.dateTimeId ?? CONST.emptyGuid,
    courseTrainerId: data?.courseTrainerId || undefined,
    attendeeId: data?.attendeeId || undefined,
    appointmentId: data?.appointmentId || undefined,
    bookingDate: data?.bookingDate || new Date().toISOString().substring(0, 10),
    ipAddress: data?.ipAddress || '',
    createdDate: data?.createdDate || '',
    courseName: data?.courseName || '',
    trainerFullName: formatFullName(data?.trainerFullName),
    timeFrom: data?.timeFrom?.substring(0, 5) || '',
    timeTill: data?.timeTill?.substring(0, 5) || '',
    get timeFromTill() {
      return `${this.timeFrom} - ${this.timeTill}`;
    },
    get bookingDateAndTime() {
      return `${this.bookingDate.substring(0, 10)} ${this.timeFrom}`;
    },
    get isPassedWithTime() {
      return (
        this.bookingDateAndTime < DateUtils.getCurrentLocalDateAsIsoString()
      );
    },
    locationName: data?.locationName || '',
    canceledDate: data?.canceledDate || '',
    canceledByFullName: data?.canceledByFullName || '',
    creatorFullName: data?.creatorFullName || '',
    appointmentMaxAttendeeCount: data?.appointmentMaxAttendeeCount || 0,
    appointmentCurrentAttendeeCount: data?.appointmentCurrentAttendeeCount || 0,
    attendeeOnWaitingListNumber: data?.attendeeOnWaitingListNumber || 0,
    departmentNames: data?.departmentNames || '',
    checkedInDate: data?.checkedInDate || '',
    courseType: CourseTypeEnum[courseType as keyof typeof CourseTypeEnum],
  };
}

export default {
  parse,
  arrayToAPI,
  toAPI: toAPI,
};

export function getStatusTypeText(status: ParticipationStatusType) {
  return Object.keys(ParticipationStatusType)[
    Object.values(ParticipationStatusType).findIndex((entry) => entry == status)
  ];
}
