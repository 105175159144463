import { ActionTree } from 'vuex';
import { MembershipApplicationState } from './types';
import { RootState } from '../../types';
import { Logger } from 'fsts';
import module_utils from '../moduleUtils';
import { AxiosResponse } from 'axios';
import { defaultBackendAttendeeRegisterFull as defaultBackendMembershipApplication } from '@/shared/backend/membershipApplication';
import { defaultBackendAttendeeRegisterByQrCode } from '@/shared/backend/attendeeRegisterByQrCode';
import company, { Company } from '@/shared/model/company';
import reference, { Reference } from '@/shared/model/reference';
import attendee from '@/shared/model/attendee';
import { PaymentMandateData } from '@/shared/model/paymentMandateData';
import { ApplicationUser } from '@/shared/model/applicationUser';
import { Contact } from '@/shared/model/contact';
import { MembershipTypeCostOverride } from '@/shared/model/membershipTypeCostOverride';
import { CostDiscount } from '@/shared/model/costDiscount';
import { ReferenceParent } from '@/shared/model/referenceParent';

const logger = new Logger('actions.attendeeRegisterFull');
export const actions: ActionTree<MembershipApplicationState, RootState> = {
  getCompanyByLocationId({ commit }, payload: string) {
    return defaultBackendMembershipApplication
      .getCompanyByLocationId(payload)
      .then((response: AxiosResponse<Company>) => {
        commit('setCompany', company.parse(response.data));
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getLocationByLocationId({ commit }, locationId: string) {
    return defaultBackendAttendeeRegisterByQrCode
      .getLocationInfoById(locationId)
      .then((response: AxiosResponse<Location>) => {
        commit('setLocation', response.data);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getAttendeeByFingerprintId(
    { commit },
    payload: { fingerprintId: string; locationId: string }
  ) {
    return defaultBackendAttendeeRegisterByQrCode
      .getAttendeeByFingerprintId(payload.fingerprintId, payload.locationId)
      .then((response: AxiosResponse) => {
        commit('setAttendee', attendee.parse(response.data.result));
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getMembershipTypesByCompanyId({ commit }, companyId: string) {
    return defaultBackendMembershipApplication
      .getMembershipTypesByCompanyId(companyId)
      .then((response: AxiosResponse<Reference[]>) => {
        commit(
          'setMembershipTypes',
          response.data.map((x) => reference.parse(x))
        );
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getCostsByCompanyId({ commit }, companyId: string) {
    return defaultBackendMembershipApplication
      .getCostsByCompanyId(companyId)
      .then((response: AxiosResponse<Reference[]>) => {
        commit('setCosts', response.data);
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getMemberStatusesByCompanyId({ commit }, companyId: string) {
    return defaultBackendMembershipApplication
      .getMemberStatusesByCompanyId(companyId)
      .then((response: AxiosResponse<Reference[]>) => {
        commit('setMemberStatuses', response.data);
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getMembershipTypeCostOverridesByCompanyId({ commit }, companyId: string) {
    return defaultBackendMembershipApplication
      .getMembershipTypeCostOverridesByCompanyId(companyId)
      .then((response: AxiosResponse<MembershipTypeCostOverride[]>) => {
        commit('setMembershipTypeCostOverrides', response.data);
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getCostDiscountsByCompanyId({ commit }, companyId: string) {
    return defaultBackendMembershipApplication
      .getActiveCostDiscountsByCompanyId(companyId)
      .then((response: AxiosResponse<CostDiscount[]>) => {
        commit('setCostDiscounts', response.data);
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getCostMembershipTypesByCompanyId({ commit }, companyId: string) {
    return defaultBackendMembershipApplication
      .getCostMembershipTypesByCompanyId(companyId)
      .then((response: AxiosResponse<ReferenceParent[]>) => {
        commit('setCostMembershipTypes', response.data);
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  submitMembershipApplication(
    { commit },
    payload: {
      newAttendee: ApplicationUser;
      locationId: string;
      membershipTypeIds: string[];
      paymentMandate: PaymentMandateData;
      contact: Contact;
    }
  ) {
    commit('setIsLoading', true);
    return defaultBackendMembershipApplication
      .submitMembershipApplication(
        payload.newAttendee,
        payload.locationId,
        payload.membershipTypeIds,
        payload.paymentMandate,
        payload.contact
      )
      .then((response) => {
        return response.data.id;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => commit('setIsLoading', false));
  },
  uploadDocuments(
    { commit },
    payload: {
      applicationUserId: string;
      companyId: string;
      documents: File[];
    }
  ) {
    let data = new FormData();
    data.append('ApplicationUserId', payload.applicationUserId);
    data.append('CompanyId', payload.companyId);
    payload.documents.forEach((x) => data.append('Documents', x, x.name));

    return defaultBackendMembershipApplication
      .uploadDocuments(data)
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
