import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { DefaultBackendHelper } from '../utils/backendHelper';
import { NewsRef, Value } from '../model/newsRef';
import { SearchParams } from '../model/searchParams';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/constants';

const logger = new Logger('backend.NewsRef');
export interface BackendNewsRef {
  getNewsRefs: (
    searchParams: SearchParams,
    newsId?: string
  ) => AxiosPromise<Value>;
  getAllNewsRefs: (newsId?: string) => AxiosPromise<NewsRef[]>;
  postMultipleNewsRefs(
    newsId: string,
    locationIds: string[],
    departmentIds: string[]
  ): AxiosPromise<any>;
}

export const defaultBackendNewsRef: BackendNewsRef = {
  getNewsRefs(
    searchParams: SearchParams,
    newsId?: string
  ): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');

    if (newsId != undefined) {
      odfb.eq(CONST.NewsId, newsId, false);
    }

    let url = DefaultBackendHelper.makeUrl(
      `${URLS.newsRefOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );

    logger.log(`getNewsRefs${url}`);
    return instance.get<Value>(url);
  },
  getAllNewsRefs(newsId?: string) {
    let url = newsId
      ? `${URLS.newsRef}/GetAll?newsId=${newsId}`
      : `${URLS.newsRef}/GetAll`;

    logger.log(`getNewsRefs${url}`);
    return instance.get<NewsRef[]>(url);
  },
  postMultipleNewsRefs(
    newsId: string,
    locationIds: string[],
    departmentIds: string[]
  ): AxiosPromise<any> {
    logger.debug('postMultipleNewsRefs');
    return instance.post(`${URLS.newsRef}/AddMultiple`, {
      NewsId: newsId,
      LocationIds: locationIds,
      DepartmentIds: departmentIds,
    });
  },
};
