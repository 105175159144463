import { GetterTree } from 'vuex';
import { CourseTrainerState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<CourseTrainerState, RootState> = {
  ['getCourseTrainers']: state => state.courseTrainers,
  ['getCourseTrainersIsLoading']: state => state.courseTrainers?.isLoading,
  ['getCourseTrainersTotal']: state => state.courseTrainers?.total,
  ['getCourseTrainersSearchParams']: state =>
    state.courseTrainers?.searchParams,

  ['getCourseTrainersWithDateTime']: state => state.courseTrainersWithDateTime,
  ['getCourseTrainersWithDateTimeIsLoading']: state =>
    state.courseTrainersWithDateTime?.isLoading,
  ['getCourseTrainersWithDateTimeTotal']: state =>
    state.courseTrainersWithDateTime?.total,
  ['getCourseTrainersWithDateTimeSearchParams']: state =>
    state.courseTrainersWithDateTime?.searchParams,
};
