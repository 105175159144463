export interface Skill {
  id: string;
  name: string;
}

export interface Value {
  value: Skill[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<Skill>): any {
  return {
    Id: data?.id || '',
    Name: data?.name || '',
  };
}

function parse(data?: Partial<Skill>): Skill {
  return {
    id: data?.id || '',
    name: data?.name || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
