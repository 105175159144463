import { MutationTree } from 'vuex';
import { MembershipTypeCostOverrideState } from './types';
import membershipTypeCostOverride, {
  Value,
} from '@/shared/model/membershipTypeCostOverride';

export const mutations: MutationTree<MembershipTypeCostOverrideState> = {
  setCostOverrides(state, payload: Value) {
    state.costOverrides.items = payload.value.map(x =>
      membershipTypeCostOverride.parse(x)
    );
    state.costOverrides.total = payload['@odata.count'] || 0;
  },
  setCostOverridesIsLoading(state, payload: boolean) {
    state.costOverrides.isLoading = payload;
  },
};
