import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import settings, {
  FileValue,
  ImportTemplate,
  Settings,
} from '../model/settings';
import ODataFilterBuilder from 'odata-filter-builder';
import { isEmptyId } from '../utils/generalUtils';

const logger = new Logger('backend.Settings');
export interface BackendSettings {
  getSettings(companyId: string): AxiosPromise<Settings>;
  getSettingsByLocationId(locationId: string): AxiosPromise<Settings>;
  deleteSettings(id: string): AxiosPromise;
  updateSettings(Settings: Settings): AxiosPromise<any>;
  updateLogo(file: any): AxiosPromise<any>;
  importExcel(uploadInfo: {
    file: any;
    importTemplate: ImportTemplate;
    culture: string;
  }): AxiosPromise<any>;
  exportExcel(uploadInfo: {
    importTemplate: ImportTemplate;
    culture: string;
  }): AxiosPromise<any>;
  getImportedFiles: (searchParams: SearchParams) => AxiosPromise<FileValue>;
  getExportedFiles: (searchParams: SearchParams) => AxiosPromise<FileValue>;
  downloadTemplate(
    importTemplate: ImportTemplate,
    culture: string
  ): AxiosPromise<any>;
  downloadFile(fileName: string): AxiosPromise<any>;
  downloadExportedFile(fileName: string): AxiosPromise<any>;
  deleteFile(fileName: string): AxiosPromise<any>;
  deleteExportedFile(fileName: string): AxiosPromise<any>;
}

export const defaultBackendSettings: BackendSettings = {
  getSettings(companyId: string): AxiosPromise<Settings> {
    let url = `${URLS.settings}`;
    if (!isEmptyId(companyId)) {
      url += `/GetAnonymous/${companyId}`;
    }
    return instance.get<Settings>(url);
  },
  getSettingsByLocationId(locationId: string): AxiosPromise<Settings> {
    let url = `${URLS.settings}/GetByLocationId/${locationId}`;
    logger.log(`getSettingsByLocationId ${url}`);
    return instance.get<Settings>(url);
  },
  deleteSettings(id: string): AxiosPromise {
    logger.debug('deleteSettings');
    return instance.delete(`${URLS.settings}/${id}`);
  },
  updateSettings(Settings: Settings): AxiosPromise<any> {
    logger.debug('updateSettings');
    if (!isEmptyId(Settings.id)) {
      return instance.put<Settings>(
        `${URLS.settings}`,
        settings.toAPI(Settings)
      );
    } else {
      return instance.post<Settings>(
        `${URLS.settings}`,
        settings.toAPI(Settings)
      );
    }
  },
  updateLogo(file: any): AxiosPromise<any> {
    return instance.post<any>(`${URLS.settings}/UpdateLogo`, file, {
      headers: { 'Content-Type': 'multipart/form-data' },
      transformRequest: (data) => data,
      // This workaround is unfortunately needed in the newer versions of Axios :(
      // https://github.com/axios/axios/issues/4406
    });
  },

  importExcel(uploadInfo: {
    file: any;
    importTemplate: ImportTemplate;
    culture: string;
  }): AxiosPromise<any> {
    let formData = new FormData();
    formData.append('File', uploadInfo.file, uploadInfo.file.name);
    formData.append('ImportTemplate', uploadInfo.importTemplate);
    formData.append('Culture', uploadInfo.culture);

    return instance.post<any>(`${URLS.settings}/ImportExcelFile`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      transformRequest: (data) => data,
      // This workaround is unfortunately needed in the newer versions of Axios :(
      // https://github.com/axios/axios/issues/4406
    });
  },

  exportExcel(uploadInfo: {
    importTemplate: ImportTemplate;
    culture: string;
  }): AxiosPromise<any> {
    return instance.post<any>(`${URLS.settings}/ExportExcelFile`, uploadInfo);
  },

  getImportedFiles(searchParams: SearchParams): AxiosPromise<FileValue> {
    var odfb = ODataFilterBuilder('and');
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.settingsOdata}/GetImportedFiles()`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getImportedFiles${url}`);
    return instance.get<FileValue>(url);
  },
  getExportedFiles(searchParams: SearchParams): AxiosPromise<FileValue> {
    var odfb = ODataFilterBuilder('and');
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.settingsOdata}/GetExportedFiles()`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getExportedFiles${url}`);
    return instance.get<FileValue>(url);
  },

  downloadTemplate(
    importTemplate: ImportTemplate,
    culture: string
  ): AxiosPromise<any> {
    return instance.get<any>(
      `${URLS.settings}/DownloadTemplate/${importTemplate}/${culture}`,
      {
        responseType: 'blob',
      }
    );
  },

  downloadFile(fileName: string): AxiosPromise<any> {
    return instance.get<any>(
      `${URLS.settings}/DownloadFile/${encodeURI(fileName)}`,
      {
        responseType: 'blob',
      }
    );
  },
  downloadExportedFile(fileName: string): AxiosPromise<any> {
    return instance.get<any>(
      `${URLS.settings}/DownloadExportedFile/${encodeURI(fileName)}`,
      {
        responseType: 'blob',
      }
    );
  },
  deleteFile(fileName: string): AxiosPromise<any> {
    return instance.delete<any>(
      `${URLS.settings}/DeleteFile/${encodeURI(fileName)}`,
      {
        responseType: 'blob',
      }
    );
  },
  deleteExportedFile(fileName: string): AxiosPromise<any> {
    return instance.delete<any>(
      `${URLS.settings}/DeleteExportedFile/${encodeURI(fileName)}`,
      {
        responseType: 'blob',
      }
    );
  },
};
