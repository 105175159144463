export enum TimeOfDay {
  Antemeridian = 'antemeridian',
  Noon = 'noon',
  Afternoon = 'afternoon',
  Evening = 'evening',
}

export enum TimeOfDayLastHour {
  Antemeridian = 10,
  Noon = 13,
  Afternoon = 16,
  Evening = 23,
}

// PeriodType enum used by the DateToggle component
export const enum TypeOfPeriod1 {
  Day = 0,
  Week = 1,
  Month = 2,
  Year = 3,
  All = 4,
}

// PeriodType enum used by DateTimeDm and most models
export type TypeOfPeriod2 = 1 | 2 | 3;
export const TypeOfPeriod2 = {
  Day: 1 as TypeOfPeriod2,
  Week: 2 as TypeOfPeriod2,
  Month: 3 as TypeOfPeriod2,
};

export type WeekDay = 0 | 1 | 2 | 3 | 4 | 5 | 6;
export const WeekDay = {
  Sunday: 0 as WeekDay,
  Monday: 1 as WeekDay,
  Tuesday: 2 as WeekDay,
  Wednesday: 3 as WeekDay,
  Thursday: 4 as WeekDay,
  Friday: 5 as WeekDay,
  Saturday: 6 as WeekDay,
};
