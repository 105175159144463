import DateUtils from "../utils/dateUtils";

export type StatisticsRequestClass =
  'AttendeeCountStatisticsRequest' |
  'AttendanceCountStatisticsRequest' |
  'RevenuesStatisticsRequest';
export const StatisticsRequestClass = {
  AttendeeCount: 'AttendeeCountStatisticsRequest' as StatisticsRequestClass,
  AttendanceCount: 'AttendanceCountStatisticsRequest' as StatisticsRequestClass,
  Revenues: 'RevenuesStatisticsRequest' as StatisticsRequestClass
};

export type StatisticsCriterionClass =
  'LocationStatisticsCriterion' |
  'DepartmentStatisticsCriterion' |
  'RoomStatisticsCriterion' |
  'TrainerStatisticsCriterion' |
  'CourseStatisticsCriterion' |
  'MembershipTypeStatisticsCriterion' |
  'GenderStatisticsCriterion' |
  'AgeStatisticsCriterion' |
  'CharacteristicStatisticsCriterion' |
  'SingleCostStatisticsCriterion' |
  'TimeStatisticsCriterion';
export const StatisticsCriterionClass = {
  Location: 'LocationStatisticsCriterion' as StatisticsCriterionClass,
  Department: 'DepartmentStatisticsCriterion' as StatisticsCriterionClass,
  Room: 'RoomStatisticsCriterion' as StatisticsCriterionClass,
  Trainer: 'TrainerStatisticsCriterion' as StatisticsCriterionClass,
  Course: 'CourseStatisticsCriterion' as StatisticsCriterionClass,
  MembershipType: 'MembershipTypeStatisticsCriterion' as StatisticsCriterionClass,
  Gender: 'GenderStatisticsCriterion' as StatisticsCriterionClass,
  Age: 'AgeStatisticsCriterion' as StatisticsCriterionClass,
  Characteristic: 'CharacteristicStatisticsCriterion' as StatisticsCriterionClass,
  SingleCost: 'SingleCostStatisticsCriterion' as StatisticsCriterionClass,
  Time: 'TimeStatisticsCriterion' as StatisticsCriterionClass,
};

export interface StatisticsRequest {
  $type: StatisticsRequestClass;
  startDate: string;
  endDate: string;
  rootCriterion?: StatisticsCriterion;
}

export interface StatisticsCriterion {
  $type: StatisticsCriterionClass;
  allowedDepartments?: string[];
  allowedLocations?: string[];
  allowedRooms?: string[];
  allowedTrainers?: string[];
  allowedCourses?: string[];
  allowedMembershipTypes?: string[];
  allowedCharacteristics?: string[];
  allowedCosts?: string[];
  ageRanges?: AgeRange[];
  timeRanges?: TimeRange[];
  nestedCriterion?: StatisticsCriterion;
}

export interface StatisticsResult {
  key: string;
  amount: number;
  nestedResults?: StatisticsResult[] | null;
}

export interface AgeRange {
  minAge: number;
  maxAge: number;
}

export interface TimeRange {
  timeFrom: string;
  timeTill: string;
}

export function parseStatisticsResult(statisticsResult: Partial<StatisticsResult>): StatisticsResult {
  return {
    key: statisticsResult.key ?? '',
    amount: statisticsResult.amount ?? 0,
    nestedResults: statisticsResult.nestedResults
      ? statisticsResult.nestedResults.map(x => parseStatisticsResult(x))
      : undefined
  };
}

export function statisticsRequestToAPI(statisticsRequest: Partial<StatisticsRequest>): any {
  return {
    $type: statisticsRequest.$type ?? StatisticsRequestClass.AttendeeCount,
    StartDate: statisticsRequest.startDate ?? DateUtils.getTodayDateAsIsoString(),
    EndDate: statisticsRequest.endDate ?? DateUtils.getTodayDateAsIsoString(),
    RootCriterion: statisticsCriterionToAPI(statisticsRequest.rootCriterion ?? {}),
  };
}

export function statisticsCriterionToAPI(statisticsCriterion: Partial<StatisticsCriterion>): any {
  return {
    $type: statisticsCriterion.$type ?? StatisticsCriterionClass.Location,
    AllowedDepartments: statisticsCriterion.allowedDepartments?.length
      ? statisticsCriterion.allowedDepartments
      : undefined,
    AllowedLocations: statisticsCriterion.allowedLocations?.length
      ? statisticsCriterion.allowedLocations
      : undefined,
    AllowedRooms: statisticsCriterion.allowedRooms?.length
      ? statisticsCriterion.allowedRooms
      : undefined,
    AllowedTrainers: statisticsCriterion.allowedTrainers?.length
      ? statisticsCriterion.allowedTrainers
      : undefined,
    AllowedCourses: statisticsCriterion.allowedCourses?.length
      ? statisticsCriterion.allowedCourses
      : undefined,
    AllowedMembershipTypes: statisticsCriterion.allowedMembershipTypes?.length
      ? statisticsCriterion.allowedMembershipTypes
      : undefined,
    AllowedCharacteristics: statisticsCriterion.allowedCharacteristics?.length
      ? statisticsCriterion.allowedCharacteristics
      : undefined,
    AllowedCosts: statisticsCriterion.allowedCosts?.length
      ? statisticsCriterion.allowedCosts
      : undefined,
    AgeRanges: statisticsCriterion.ageRanges,
    TimeRanges: statisticsCriterion.timeRanges,
    NestedCriterion: statisticsCriterion.nestedCriterion
      ? statisticsCriterionToAPI(statisticsCriterion.nestedCriterion)
      : undefined,
  };
}

export function createInitialStatisticsRequest(): StatisticsRequest {
  const todayDate = new Date(DateUtils.getTodayDateAsIsoString());
  const oneWeekAgoDate = DateUtils.getDateTimeByMinutesOffsetFromDate(todayDate, -(60*24*7));
  return {
    $type: StatisticsRequestClass.AttendeeCount,
    startDate: oneWeekAgoDate.toISOString(),
    endDate: todayDate.toISOString(),
    rootCriterion: undefined
  };
}
