import DateUtils from '../utils/dateUtils';

export interface News {
  id?: string; // System.Guid
  logo: string;
  logoId?: string;
  active: boolean; // bool
  subject: string; // string
  text: string; // string
  userPermissions: any; // string
  fromDate: string; // System.DateTime
  tillDate: string; // System.DateTime
  creatorFullName: string;
  companyId?: string; // System.Guid
  creatorId?: string; // System.Guid
  createdDate: string; // System.DateTime
}

export const PUBLISH_USER_PERMISSION = 'PUBLISH';

export interface Value {
  value: News[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<News>): any {
  return {
    Id: data?.id || undefined,
    Active: data?.active || false,
    Subject: data?.subject || '',
    Text: data?.text || '',
    UserPermissions: data?.userPermissions?.join() || undefined,
    FromDate: data?.fromDate || new Date().toISOString(),
    TillDate: data?.tillDate || undefined,
    CompanyId: data?.companyId || undefined,
  };
}

function parse(data?: Partial<News>): News {
  return {
    id: data?.id || undefined,
    logo: data?.logo || '',
    logoId: data?.logoId || '',
    active: data?.active || false,
    subject: data?.subject || '',
    text: data?.text || '',
    userPermissions:
      data?.userPermissions != undefined
        ? data?.userPermissions.split(',')
        : [],
    fromDate: data?.fromDate || new Date().toISOString().substring(0, 10),
    tillDate: DateUtils.parseDateFromBackend(data?.tillDate),
    creatorFullName: data?.creatorFullName || '',
    companyId: data?.companyId || undefined,
    creatorId: data?.creatorId || undefined,
    createdDate: data?.createdDate || new Date().toISOString().substring(0, 10),
  };
}

export default {
  parse,
  toAPI: toAPI,
};
