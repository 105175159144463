import { defineRule, configure } from 'vee-validate';
import {
  required,
  email,
  confirmed,
  regex,
  min,
  max,
  ext,
  size,
  numeric,
  integer,
  max_value,
  min_value,
} from '@vee-validate/rules';
import { localize } from '@vee-validate/i18n';
import en from '@vee-validate/i18n/dist/locale/en.json';
import de from '@vee-validate/i18n/dist/locale/de.json';
import { localeMessages } from '@/i18n';
import { instance } from '@/shared/backend';
import { CONST } from '@/shared/utils/constants';

defineRule('required', required);
defineRule('email', email);
defineRule('confirmed', confirmed);
defineRule('regex', regex);
defineRule('min', min);
defineRule('max', max);
defineRule('numeric', numeric);
defineRule('integer', integer);
defineRule('max_value', max_value);
defineRule('min_value', min_value);
defineRule('ext', ext);
defineRule('size', size);

// custom validators
defineRule('verify_password', (value: any) => {
  var strongRegex = new RegExp(
    '^(?=.*[a-z,ä,ö,ü])(?=.*[A-Z,Ä,Ö,Ü])(?=.*[0-9])(?=.*[!@_#?.$%^&*/\\\\])(?=.{8,})'
  );
  return strongRegex.test(value);
});

defineRule('verify_phone', (value: any) => {
  var strongRegex = /^[0-9()+-\/]+$/;
  return strongRegex.test(value);
});

defineRule('decimal', (value: any, args: any) => {
  var count = 1;
  if (args.length != 0 && parseInt(args[0])) {
    count = +args[0];
  }
  var strongRegex = new RegExp(`^[0-9]+(\.[0-9]{1,${count}})?$`);
  return strongRegex.test(value);
});

defineRule('verify_already_exist_username', (value: any, args: any) => {
  var editingUserId =
    args[0] == '' || args[0] == 'undefined' ? CONST.emptyGuid : args[0];

  var isForCustomer = args[1] == 'forCustomer' ? true : false;
  var url = '/api/ApplicationUsers/ValidateUserName';
  if (isForCustomer) {
    url = '/api/ApplicationUsers/ValidateUserNameForContact';
  }
  return instance
    .post<Promise<void>>(url, {
      username: value,
      id: editingUserId,
    })
    .then((response: any) => true)
    .catch((e) => {
      console.log(e.response.data);
      return false;
    });
});

defineRule('verify_username_characters', (value: any, args: any) => {
  if (
    typeof value == 'string' &&
    /^[-._@+abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789äöüÄÖÜßáéíñóúÁÉÍÑÓÚàâæèêëîïôœùûŒÇÀÂÆÈÊËÎÏÔÙÛìòÌÒåøÅØ]{1,}$/.test(
      value as string
    )
  ) {
    return true;
  }
  return false;
});

defineRule('verify_already_exist_email', async (value: any, args: any) => {
  var editingUserId =
    args[0] == '' || args[0] == 'undefined' ? CONST.emptyGuid : args[0];
  var companyId =
    args[1] == '' || args[1] == 'undefined' ? CONST.emptyGuid : args[1];
  var url = '/api/ApplicationUsers/ValidateExistingEmail';
  try {
    const response = await instance.post<Promise<void>>(url, {
      email: value,
      id: editingUserId,
      companyId: companyId,
    });
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
});

defineRule(
  'verify_already_exist_contactInternal_email',
  async (value: any, args: any) => {
    var editingUserId =
      args[0] == '' ? '00000000-0000-0000-0000-000000000000' : args[0];
    var url = '/api/ApplicationUsers/ValidateExistingContactInternalEmail';
    try {
      const response = await instance.post<Promise<void>>(url, {
        email: value,
        id: editingUserId,
      });
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }
);

defineRule(
  'verify_already_exist_membernumber',
  async (value: any, args: any) => {
    if (!value) return true;
    let attendeeId = args[1];
    let url = `/api/Attendees/IsUniqueMemberNumber/${value}${
      attendeeId ? '?attendeeId=' + attendeeId : ''
    }`;
    try {
      let res = await instance.get<boolean>(url);
      return res.data;
    } catch (e) {
      console.log(e);
      return false;
    }
  }
);

defineRule('verify_already_exist_companyname', (value: any, args: any) => {
  var editingCompanyId = args[0];
  editingCompanyId =
    args[0] == '' ? '00000000-0000-0000-0000-000000000000' : args[0];
  return instance
    .post<Promise<void>>('/api/Company/ValidateCompanyName', {
      name: value,
      id: editingCompanyId,
    })
    .then((response: any) => true)
    .catch((e) => {
      console.log(e.response.data);
      return false;
    });
});

defineRule('verify_hours', (value: any, args: any) => {
  // sometimes "value" is type of number and .replace() function - does not working.
  // so, forcibly cast to string
  value = value.toString();
  var locale = args[0];
  if (value == '0') {
    return true;
  }
  if (locale == 'de') {
    value = value.replace(',', '.');
  }
  var floatValue = parseFloat(value);
  if (floatValue > 24) {
    return false;
  }
  // format: 0 or 10 or 24
  if (/^\d\d?$/.test(value)) return true;
  //format: (0.5 or 10.5 or 10.50)
  if (locale == 'en') {
    return /^(([0-9])|(1[0-9])|2[0-4])\.[0-9][0-9]?$/.test(value);
  } else {
    return /^(([0-9])|(1[0-9])|2[0-4])\.[0-9][0-9]?$/.test(value);
  }
});

defineRule('url', (str: any) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', // fragment locator
    'i'
  );
  return !!pattern.test(str);
});

//https://regex101.com/
defineRule('verify_date_format', (value: any, args: any) => {
  var locale = args[0];
  if (locale == 'en') {
    return /^([0-9]{4}|[0-9]{2})[\/]([0]?[1-9]|[1][0-2])[\/]([0]?[1-9]|[1|2][0-9]|[3][0|1])$/.test(
      value
    ); //yyyy/MM/dd
  } else {
    return /^([0]?[1-9]|[1|2][0-9]|[3][0|1])[.]([0]?[1-9]|[1][0-2])[.]([0-9]{4}|[0-9]{2})$/.test(
      value
    ); //dd.MM.yyyy
  }
});

defineRule('verify_valid_iban', async (value: any, args: any) => {
  let url = '/api/PaymentData/ValidateIban';
  try {
    const response = await instance.post<Promise<void>>(url, { iban: value });
    return true;
  } catch (e) {
    return false;
  }
});

defineRule('verify_past_date', (value: any, args: any) => {
  let today = new Date();
  today.setUTCHours(0);
  today.setUTCMinutes(0);
  today.setUTCSeconds(0);
  today.setUTCMilliseconds(0);

  let passedDate = new Date(value);
  return passedDate <= today;
});

defineRule('verify_future_date', (value: any, args: any) => {
  let today = new Date();
  today.setUTCHours(0);
  today.setUTCMinutes(0);
  today.setUTCSeconds(0);
  today.setUTCMilliseconds(0);

  let passedDate = new Date(value);
  return passedDate >= today;
});

configure({
  generateMessage: localize({
    en,
    de,
  }),
});

configure({
  generateMessage: localize(localeMessages),
});
