import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import invoicePositionFunctions, {
  InvoicePosition,
  Value,
} from '../model/invoicePosition';
import { SearchParams } from '../model/searchParams';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '../utils/constants';
import { DefaultBackendHelper } from '../utils/backendHelper';
import { InvoicePaymentStatus, getPaymentStatusText } from '../model/invoice';

const logger = new Logger('backend.InvoicePositions');

export interface BackendInvoicePosition {
  getInvoicePositions(
    searchParams: SearchParams,
    invoiceId?: string,
    attendeeAppUserId?: string,
    filterByStati?: InvoicePaymentStatus[],
    locationId?: string,
    departmentId?: string
  ): AxiosPromise<Value>;
  addInvoicePosition(invoicePosition: InvoicePosition): AxiosPromise<any>;
  addInvoicePositionsByCosts(
    attendeeAppUserId: string,
    costIds: string[],
    customCostEntries: { euroCost: number; description: string }[],
    singleInvoice: boolean,
    paymentStatus: InvoicePaymentStatus
  ): AxiosPromise<any>;
  cancelInvoicePosition(invoicePositionId: string): AxiosPromise<any>;
  unCancelInvoicePosition(invoicePositionId: string): AxiosPromise<any>;
  updateInvoicePosition(invoicePosition: InvoicePosition): AxiosPromise<any>;
}

export const DefaultBackendInvoicePosition: BackendInvoicePosition = {
  getInvoicePositions(
    searchParams: SearchParams,
    invoiceId?: string,
    attendeeAppUserId?: string,
    filterByStati?: InvoicePaymentStatus[],
    locationId?: string,
    departmentId?: string
  ): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');

    if (invoiceId != undefined) {
      odfb.eq(CONST.InvoiceId, invoiceId, false);
    }

    if (attendeeAppUserId != undefined) {
      odfb.eq(CONST.AttendeeAppUserId, attendeeAppUserId, false);
    }

    if (filterByStati != undefined && filterByStati.length > 0) {
      let odfbStati = ODataFilterBuilder('or');
      filterByStati.forEach((x) =>
        odfbStati.eq(CONST.InvoicePaymentStatus, getPaymentStatusText(x))
      );
      odfb.and(odfbStati);
    }

    let baseUrl =
      locationId != undefined
        ? `${URLS.invoicePositionOdata}/GetInLocation(locationId=${locationId})`
        : departmentId != undefined
        ? `${URLS.invoicePositionOdata}/GetInDepartment(departmentId=${departmentId})`
        : URLS.invoicePositionOdata;

    let url = DefaultBackendHelper.makeUrl(
      baseUrl,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );

    logger.log(`getInvoicePositions${url}`);
    return instance.get<Value>(url);
  },
  addInvoicePosition(invoicePosition: InvoicePosition): AxiosPromise<any> {
    return instance.post<InvoicePosition>(
      URLS.invoicePosition,
      invoicePositionFunctions.toAPI(invoicePosition)
    );
  },
  addInvoicePositionsByCosts(
    attendeeAppUserId: string,
    costIds: string[],
    customCostEntries: { euroCost: number; description: string }[],
    singleInvoice: boolean = false,
    paymentStatus: InvoicePaymentStatus = InvoicePaymentStatus.Open
  ): AxiosPromise<any> {
    return instance.post(`${URLS.invoicePosition}/AddByCosts`, {
      AttendeeAppUserId: attendeeAppUserId,
      CostIds: costIds,
      CustomCostEntries: customCostEntries,
      SingleInvoice: singleInvoice,
      PaymentStatus: paymentStatus,
    });
  },
  cancelInvoicePosition(invoicePositionId: string): AxiosPromise<any> {
    return instance.post(`${URLS.invoicePosition}/Cancel/${invoicePositionId}`);
  },
  unCancelInvoicePosition(invoicePositionId: string): AxiosPromise<any> {
    return instance.post(
      `${URLS.invoicePosition}/UnCancel/${invoicePositionId}`
    );
  },
  updateInvoicePosition(invoicePosition: InvoicePosition): AxiosPromise<any> {
    return instance.put(
      URLS.invoicePosition,
      invoicePositionFunctions.toAPI(invoicePosition)
    );
  },
};
