import { MutationTree } from 'vuex';
import { InvoicePositionsState } from './types';
import invoicePosition, { Value } from '@/shared/model/invoicePosition';
import { SearchParams } from '@/shared/model/searchParams';
import { deepClone } from '@/shared/utils/generalUtils';
import { DataOptions } from '@/shared/model/dataOptions';

export const mutations: MutationTree<InvoicePositionsState> = {
  setInvoicePositions(state, payload: Value) {
    state.invoicePositions.items = payload.value.map((x) =>
      invoicePosition.parse(x)
    );
    state.invoicePositions.total = payload['@odata.count'] || 0;
    state.invoicePositions.isLoading = false;
  },
  setInvoicePositionsTotal(state, payload: number) {
    state.invoicePositions.total = payload;
  },
  setInvoicePositionsIsLoading(state, payload: boolean) {
    state.invoicePositions.isLoading = payload;
  },
  setSearchParams(state, payload: SearchParams) {
    state.invoicePositions.searchParams = deepClone(payload);
  },
  setPage(state, payload: number) {
    state.invoicePositions.searchParams.dataOption.page = payload;
  },
  setItemsPerPage(state, payload: number) {
    state.invoicePositions.searchParams.dataOption.itemsPerPage = payload;
  },
  setFilter(state, payload: string) {
    state.invoicePositions.searchParams.filter = payload;
  },
  setDataOption(state, payload: DataOptions) {
    state.invoicePositions.searchParams.dataOption = deepClone(payload);
  },
  resetInvoicePositionsPaging(state) {
    state.invoicePositions.searchParams.dataOption.page = 1;
    state.invoicePositions.searchParams.dataOption.itemsPerPage = 10;
    state.invoicePositions.searchParams.dataOption.filter = '';
    state.invoicePositions.searchParams.filter = '';
  },
  setFilterCriteria(state, payload: string[]) {
    state.invoicePositions.searchParams.orClauseFieldsIds = payload;
  },
};
