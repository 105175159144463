import { GetterTree } from 'vuex';
import { EmployeeState } from './types';
import { RootState } from '../../types';
import { i18nGlobal } from '@/i18n';
import { AUTHORITIES } from '../auth';

export const getters: GetterTree<EmployeeState, RootState> = {
  ['getEmployees']: (state) => state.employees,
  ['getEmployeesIsLoading']: (state) => state.employees?.isLoading,
  ['getEmployeesTotal']: (state) => state.employees?.total,
  ['getEmployeesSearchParams']: (state) => state.employees?.searchParams,
  ['getEmployeesPages']: (state) =>
    Math.ceil(
      (state.employees?.total || 1) /
        (state.employees?.searchParams.dataOption.itemsPerPage || 1)
    ),
  ['getEmployeeRoles']: (state) => [
    {
      id: AUTHORITIES.ADMIN,
      name: i18nGlobal.t('app.authorities.ROLE_ADMIN'),
    },
    {
      id: AUTHORITIES.MANAGER,
      name: i18nGlobal.t('app.authorities.ROLE_MANAGER'),
    },
    {
      id: AUTHORITIES.EMPLOYEE,
      name: i18nGlobal.t('app.authorities.ROLE_EMPLOYEE'),
    },
    {
      id: AUTHORITIES.TRAINER,
      name: i18nGlobal.t('app.authorities.ROLE_TRAINER'),
    },
  ],
};
