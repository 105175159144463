import { ActionTree } from 'vuex';
import { RootState } from '../../types';
import { Logger } from 'fsts';
import { StatisticsState } from './types';
import { defaultBackendStatistics } from '@/shared/backend/statistics';
import { StatisticsRequest } from '@/shared/model/statistics';
import moduleUtils from '../moduleUtils';
import { i18nGlobal } from '@/i18n';
import DownloadUtils from '@/shared/utils/downloadUtils';
import { SearchParams } from '@/shared/model/searchParams';
import { StatisticsPreset } from '@/shared/model/statisticsPreset';
import { AxiosResponse } from 'axios';

const logger = new Logger('actions.statistics');
export const actions: ActionTree<StatisticsState, RootState> = {
  fillStatistics({ commit, getters }) {
    commit('setStatisticsIsLoading', true);

    const statisticsRequest = <StatisticsRequest>(getters['getRequest']);
    return defaultBackendStatistics
      .fillStatistics(statisticsRequest)
      .then((res) => {
        commit('setStatisticsResult', res.data);
        return res;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setStatisticsIsLoading', false)
      });
  },
  exportExcel({ commit }, payload: any[]) {
    const lang = i18nGlobal.locale.value;

    return defaultBackendStatistics
      .exportExcel(payload, lang)
      .then((response) => {
        const fileName = DownloadUtils.getFileName(
          response.headers['content-disposition']
        );
        const type = response.headers['content-type'];
        if (DownloadUtils.isIeOrEdge(window)) {
          const file = new File([response.data], fileName, {
            type: type,
          });
          (window.navigator as any).msSaveOrOpenBlob(file, fileName);
        } else {
          const blob = new Blob([response.data], { type: type });
          const url = DownloadUtils.createDownloadLink(blob, fileName);
          window.URL.revokeObjectURL(url);
        }
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  getPresets({ commit, getters }, payload?: { searchParams?: SearchParams }) {
    commit('setPresetsIsLoading', true);
    const searchParams = payload?.searchParams ?? getters.getPresetsSearchParams;

    return defaultBackendStatistics
      .getPresets(searchParams)
      .then(response => {
          commit('setPresets', response.data);
          return response.data;
      }).catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      }).finally(() => {
        commit('setPresetsIsLoading', false);
      });
  },
  updatePreset({ commit }, payload: StatisticsPreset) {
    return defaultBackendStatistics
      .updatePreset(payload)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: any;
          }>
        ) => {
          moduleUtils.ok(i18nGlobal.t(`success.statistics_preset_add`), commit);
          return response.data.id;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  deletePreset({ commit }, id: string) {
    return defaultBackendStatistics
      .deletePreset(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: any;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
}