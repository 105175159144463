import { GetterTree } from 'vuex';
import { CompanyState } from './types';
import { RootState } from '../../types';
import { Company } from '../../../model/company';

export const getters: GetterTree<CompanyState, RootState> = {
  getCompany(state): Company | undefined {
    return state.currentCompany;
  },
  getTotalAttendees(state): number | undefined {
    return state.totalAttendees;
  },
  getMaxAttendees(state): number | undefined {
    return state.maxAttendees;
  },
  getCompanyCountry(state): string | undefined {
    return state.companyCountry;
  },
  getCompanies(state): Company[] {
    return state.companies;
  },
};
