import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams, createSearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import course, {
  Course,
  CourseTypeEnum,
  Value,
  getCourseTypeText,
} from '../model/course';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/constants';

const logger = new Logger('backend.Course');
export interface BackendCourse {
  getCourse(id: string): AxiosPromise<Course>;
  getCourses: (
    searchParams: SearchParams,
    locationIds?: string[],
    departmentId?: string,
    active?: boolean
  ) => AxiosPromise<Value>;
  getCoursesOdata: (
    locationIds: string[],
    departmentIds: string[],
    companyId: string
  ) => AxiosPromise<Value>;
  getCoursesWithDateTime: (searchParams: SearchParams) => AxiosPromise<Value>;
  getCoursesWithLogo(payload?: { companyId?: string }): AxiosPromise<Course[]>;
  deleteCourse(id: string): AxiosPromise;
  updateCourse(Course: Course): AxiosPromise<any>;
  getLogoByCourseId(courseId: string): AxiosPromise<Course>;
  makeCopyOfTheCourse(courseId: string): AxiosPromise;
  checkIfCourseHasFutureBookings(courseId: string): AxiosPromise<boolean>;
}

export const defaultBackendCourse: BackendCourse = {
  getCourse(id: string): AxiosPromise<Course> {
    let url = `${URLS.courseOdata}/${id}`;
    return instance.get<Course>(url);
  },
  getCoursesWithLogo(payload?: { companyId?: string }): AxiosPromise<Course[]> {
    const companyId = payload?.companyId || CONST.emptyGuid;
    let url = `${URLS.course}/GetWithLogoOnly`;
    if (companyId !== CONST.emptyGuid) {
      url = `${URLS.course}/GetWithLogoOnlyAnonymous/${companyId}`;
    }
    return instance.get<Course[]>(url);
  },
  getCourses(
    searchParams: SearchParams,
    locationIds?: string[],
    departmentId?: string,
    active?: boolean
  ): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');

    // Unique event "courses" are hidden in table view
    odfb.ne(CONST.Type, getCourseTypeText(CourseTypeEnum.UniqueEvent));

    if (locationIds != undefined && locationIds.length > 0) {
      var odfbLocations = ODataFilterBuilder('or');
      locationIds.forEach((x) => odfbLocations.eq(CONST.LocationId, x, false));
      odfb.and(odfbLocations);
    }

    if (active != undefined) {
      odfb.eq(CONST.Active, active);
    }

    let baseUrl;
    if (departmentId != undefined) {
      baseUrl = `${URLS.courseOdata}/GetInDepartment(departmentId=${departmentId})`;
    } else {
      baseUrl = `${URLS.courseOdata}`;
    }

    let url = DefaultBackendHelper.makeUrl(
      baseUrl,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getCourses${url}`);
    return instance.get<Value>(url);
  },
  getCoursesOdata(
    locationIds: string[],
    departmentIds: string[],
    companyId: string
  ): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');

    // Unique event "courses" are hidden in table view
    odfb.ne(CONST.Type, getCourseTypeText(CourseTypeEnum.UniqueEvent));

    var odfbLocations = ODataFilterBuilder('or');
    locationIds.forEach((x) => odfbLocations.eq(CONST.LocationId, x, false));

    var odfbDepartments = ODataFilterBuilder('or');
    departmentIds.forEach((id) =>
      odfbDepartments.or(
        `DepartmentRefs/any(DepartmentRef: DepartmentRef/DepartmentId eq ${id})`
      )
    );
    odfb.and(odfbLocations).and(odfbDepartments);

    const defaultUrl =
      companyId === CONST.emptyGuid
        ? `${URLS.course}/GetByOptions`
        : `${URLS.course}/GetByOptionsAnonymous/${companyId}`;
    let url = DefaultBackendHelper.makeUrl(
      defaultUrl,
      createSearchParams(-1, 1, ['name'], [false]).dataOption,
      undefined,
      undefined,
      odfb
    );
    logger.log(`getCourses${url}`);
    return instance.get<Value>(url);
  },
  getCoursesWithDateTime(searchParams: SearchParams): AxiosPromise<Value> {
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.courseOdata}/GetWithDateTime`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter
    );
    logger.log(`getCourses${url}`);
    return instance.get<Value>(url);
  },

  deleteCourse(id: string): AxiosPromise {
    logger.debug('deleteCourse');
    return instance.delete(`${URLS.course}/${id}`);
  },
  updateCourse(Course: Course): AxiosPromise<any> {
    logger.debug('updateCourse');
    console.log(Course);
    if (Course.id != '' && Course.id != undefined) {
      return instance.put<Course>(`${URLS.course}`, course.toAPI(Course));
    } else {
      return instance.post<Course>(`${URLS.course}`, course.toAPI(Course));
    }
  },
  getLogoByCourseId(courseId: string): AxiosPromise<Course> {
    logger.debug('getLogoByCourseId');
    return instance.get<Course>(`${URLS.course}/GetLogoByCourseId/${courseId}`);
  },
  makeCopyOfTheCourse(courseId): AxiosPromise {
    logger.debug('copyCourse');
    return instance.post<Course>(`${URLS.course}/CopyCourse`, { Id: courseId });
  },
  checkIfCourseHasFutureBookings(courseId: string): AxiosPromise<boolean> {
    return instance.get<boolean>(`${URLS.course}/CheckIfCourseHasFutureBookings/${courseId}`);
  }
};
