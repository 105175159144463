import { GetterTree } from 'vuex';
import { InvoicePositionsState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<InvoicePositionsState, RootState> = {
  ['getInvoicePositions']: state => state.invoicePositions,
  ['getInvoicePositionsIsLoading']: state => state.invoicePositions?.isLoading,
  ['getInvoicePositionsTotal']: state => state.invoicePositions?.total,
  ['getInvoicePositionsSearchParams']: state =>
    state.invoicePositions?.searchParams,
};
