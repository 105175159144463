import { GetterTree } from 'vuex';
import { RoomAvailabilityState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<RoomAvailabilityState, RootState> = {
  ['getRoomAvailabilities']: state => state.roomAvailabilities,
  ['getRoomAvailabilitiesIsLoading']: state =>
    state.roomAvailabilities?.isLoading,
  ['getRoomAvailabilitiesTotal']: state => state.roomAvailabilities?.total,
  ['getRoomAvailabilitiesSearchParams']: state =>
    state.roomAvailabilities?.searchParams,
};
