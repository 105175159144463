import { GetterTree } from 'vuex';
import { MembershipRequestState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<MembershipRequestState, RootState> = {
  ['getMembershipRequests']: state => state.membershipRequests,
  ['getMembershipRequestsIsLoading']: state =>
    state.membershipRequests?.isLoading,
  ['getMembershipRequestsTotal']: state => state.membershipRequests?.total,
  ['getMembershipRequestsSearchParams']: state =>
    state.membershipRequests?.searchParams,
};
