<div
  :class="{'d-flex justify-center margin-location-mobile' : mobile, 'd-flex justify-center' : !mobile}"
>
  <span
    v-if="ownUserLocations.length == 1 && ownUserDepartments.length == 0"
    :class="{'text-h6 font-weight-medium max-width' : mobile, 'text-h5 font-weight-medium max-width' : !mobile}"
  >
    <tooltip-text
      v-if="selectedOwnUserLocation != undefined"
      :maxLength="24"
      :text="selectedOwnUserLocation.name"
    >
    </tooltip-text>
  </span>
  <span
    v-else-if="ownUserLocations.length == 0 && ownUserDepartments.length == 1"
    :class="{'text-h6 font-weight-medium max-width' : mobile, 'text-h5 font-weight-medium max-width' : !mobile}"
  >
    <tooltip-text
      v-if="selectedOwnUserDepartment != undefined"
      :maxLength="24"
      :text="selectedOwnUserDepartment.name"
    >
    </tooltip-text>
  </span>
  <span v-else>
    <v-select
      :items="selectboxEntries"
      v-model="currentSelection"
      item-title="name"
      item-value="id"
      @update:modelValue="selectboxEntryChanged"
      hide-details
      variant="solo"
      flat
      class="max-width"
    >
      <template v-slot:prepend-item>
        <div class="selectbox-heading">{{ i18n.t('select-heading-text') }}</div>
        <v-radio-group
          v-model="mode"
          @change="modeChanged"
          class="radio-group-padding mt-3"
        >
          <v-radio
            :label="i18n.t('locations')"
            value="location"
            :disabled="ownUserLocations.length == 0"
          ></v-radio>
          <v-radio
            v-if="showDepartments"
            :label="i18n.t('departments')"
            value="department"
            :disabled="ownUserDepartments.length == 0"
          ></v-radio>
        </v-radio-group>
        <v-divider class="mt-n2"></v-divider>
      </template>
      <template v-slot:selection="{ item }">
        <tooltip-text
          v-if="item != undefined"
          :maxLength="24"
          :text="item.title"
          :class="{'location-entry-mobile text-h6 font-weight-medium' : mobile, 'location-entry-desktop text-h5 font-weight-medium' : !mobile}"
        >
        </tooltip-text>
      </template>
      <template v-slot:item="{ item, props }">
        <v-list-item v-if="item != undefined" v-bind="props">
          <template v-slot:title>
            <div
              :class="{'location-entry-mobile text-body-2 pa-2 font-weight-regular' : mobile, 'location-entry-desktop text-body-1 pa-2 font-weight-regular' : !mobile}"
            >
              {{ item.title }}
            </div>
          </template>
        </v-list-item>
      </template>
    </v-select>
  </span>
</div>
