import { ActionTree } from 'vuex';
import { TariffState } from './types';
import { RootState } from '../../types';
import { defaultBackendTariff } from '@/shared/backend/tariff';
import { Logger } from 'fsts';
import moduleUtils from '../moduleUtils';
import { Value, Tariff } from '@/shared/model/tariff';
import { AxiosResponse } from 'axios';
import { i18nGlobal } from '@/i18n';
import { createSearchParams } from '@/shared/model/searchParams';

const logger = new Logger('actions.tariffs');
export const actions: ActionTree<TariffState, RootState> = {
  getTariffs(
    { commit, dispatch, getters, rootGetters },
    payload?: { searchParams?: any }
  ) {
    commit('setTariffsIsLoading', true);
    let searchParams = payload?.searchParams ?? getters.getTariffsSearchParams;
    return defaultBackendTariff
      .getTariffs(searchParams)
      .then((response: AxiosResponse<Value>) => {
        commit('setTariffs', response.data);
        commit('setTariffsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setTariffsIsLoading', false);
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  getAllTariffsAlphabetically({ dispatch }) {
    return dispatch('getTariffs', {
      searchParams: createSearchParams(-1, 1, ['tariffName'], [false]),
    });
  },
  getTariff({ commit, dispatch }, id: string) {
    return defaultBackendTariff
      .getTariff(id)
      .then((response: AxiosResponse<Tariff>) => {
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateTariff({ commit, dispatch }, file: Tariff) {
    return defaultBackendTariff
      .updateTariff(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Tariff;
          }>
        ) => {
          moduleUtils.ok(i18nGlobal.t(`success.tariff_update`), commit);
          return response.data.result;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteTariff({ commit, dispatch }, id: string) {
    return defaultBackendTariff
      .deleteTariff(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Tariff;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
};
