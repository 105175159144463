import { PresenceState } from './types';

export const state = (): PresenceState => initialState();

export const initialState = (): PresenceState => ({
  presences: {
    items: [],
    isLoading: false,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['isOnline', 'dateEnter'],
        sortDesc: [true, true],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: ['fullName', 'locationName'],
    },
  },
});
