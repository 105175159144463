import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import ODataFilterBuilder from 'odata-filter-builder';
import { isEmptyId } from '../utils/generalUtils';
import membershipRequestFuncs, {
  MembershipRequest,
  MembershipRequestStatus,
  Value,
  getMembershipRequestStatusText,
} from '../model/membershipRequest';
import { CONST } from '../utils/constants';
import { createSearchParams } from '../model/searchParams';

const logger = new Logger('backend.MembershipRequest');
export interface BackendMembershipRequest {
  getMembershipRequests(
    searchParams: SearchParams,
    requestState?: MembershipRequestStatus,
    applicationUserId?: string,
    startDate?: string,
    endDate?: string,
    locationId?: string,
    departmentId?: string,
  ): AxiosPromise<Value>;
  updateMembershipRequest(
    membershipRequest: MembershipRequest
  ): AxiosPromise<any>;
  deleteMembershipRequest(id: string): AxiosPromise;
  getMembershipRequestsTotalOpenCount(
    locationId?: string,
    departmentId?: string,
  ): AxiosPromise<Value>;
}

export const defaultBackendMembershipRequest: BackendMembershipRequest = {
  getMembershipRequests(
    searchParams: SearchParams,
    requestStatus?: MembershipRequestStatus,
    applicationUserId?: string,
    startDate?: string,
    endDate?: string,
    locationId?: string,
    departmentId?: string
  ): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');

    if (requestStatus != undefined) {
      odfb.eq(CONST.Status, getMembershipRequestStatusText(requestStatus!));
    }

    if (applicationUserId != undefined) {
      odfb.eq(CONST.ApplicationUserId, applicationUserId, false);
    }

    if (startDate && endDate) {
      odfb.ge(CONST.CreatedDate, startDate, false);
      odfb.lt(CONST.CreatedDate, endDate, false);
    }

    let baseUrl = locationId != undefined
      ? `${URLS.membershipRequestOdata}/GetInLocation(locationId=${locationId})`
      : departmentId != undefined
      ? `${URLS.membershipRequestOdata}/GetInDepartment(departmentId=${departmentId})`
      : `${URLS.membershipRequestOdata}`;

    let url = DefaultBackendHelper.makeUrl(
      baseUrl,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );

    logger.log(`getMembershipRequests: ${url}`);
    return instance.get<Value>(url);
  },
  updateMembershipRequest(
    membershipRequest: MembershipRequest
  ): AxiosPromise<any> {
    logger.debug('updateMembershipRequest');
    if (isEmptyId(membershipRequest.id)) {
      return instance.post<MembershipRequest>(
        `${URLS.membershipRequest}`,
        membershipRequestFuncs.toAPI(membershipRequest)
      );
    } else {
      return instance.put<MembershipRequest>(
        `${URLS.membershipRequest}`,
        membershipRequestFuncs.toAPI(membershipRequest)
      );
    }
  },
  deleteMembershipRequest(id: string): AxiosPromise {
    logger.debug('deleteMembershipRequest');
    return instance.delete(`${URLS.membershipRequest}/${id}`);
  },
  getMembershipRequestsTotalOpenCount(
    locationId?: string,
    departmentId?: string,
  ): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    odfb.eq(
      CONST.Status,
      getMembershipRequestStatusText(MembershipRequestStatus.Open)
    );

    let baseUrl = locationId != undefined
      ? `${URLS.membershipRequestOdata}/GetInLocation(locationId=${locationId})`
      : departmentId != undefined
      ? `${URLS.membershipRequestOdata}/GetInDepartment(departmentId=${departmentId})`
      : `${URLS.membershipRequestOdata}`;

    const searchParams = createSearchParams(0);
    let url = DefaultBackendHelper.makeUrl(
      baseUrl,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );

    logger.log(`getMembershipRequestsTotalOpenCount: ${url}`);
    return instance.get<Value>(url);
  },
};
