import { MutationTree } from 'vuex';
import { CheckInState } from './types';
import courseTrainer, { Value } from '@/shared/model/courseTrainer';

export const mutations: MutationTree<CheckInState> = {
  setCourseTrainers(state, payload: Value) {
    state.courseTrainers.items = payload.value.map((x) =>
      courseTrainer.parse(x)
    );
    state.courseTrainers.total = payload['@odata.count'] || 0;
    state.courseTrainers.isLoading = false;
  },

  setCourseTrainersTotal(state, payload: number) {
    state.courseTrainers.total = payload;
  },
  setCourseTrainersIsLoading(state, payload: boolean) {
    state.courseTrainers.isLoading = payload;
  },
};
