import { ActionTree } from 'vuex';
import { NewsRefState } from './types';
import { RootState } from '../../types';
import { defaultBackendNewsRef } from '@/shared/backend/newsRef';
import { Logger } from 'fsts';
import moduleUtils from '../moduleUtils';
import { Value } from '@/shared/model/newsRef';
import { AxiosResponse } from 'axios';
import { SearchParams } from '@/shared/model/searchParams';

const logger = new Logger('actions.newsRefs');
export const actions: ActionTree<NewsRefState, RootState> = {
  getNewsRefs(
    { commit, getters },
    payload: {
      searchParams?: SearchParams;
      newsId?: string;
    }
  ) {
    commit('setNewsRefsIsLoading', true);
    let searchParams = payload.searchParams ?? getters.getNewsRefsSearchParams;
    return defaultBackendNewsRef
      .getNewsRefs(searchParams, payload?.newsId)
      .then((response: AxiosResponse<Value>) => {
        commit('setNewsRefs', response.data);
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setNewsRefsIsLoading', false);
      });
  },
  getAllNewsRefs({ commit }, payload: { newsId?: string }) {
    commit('setNewsRefsIsLoading', true);
    return defaultBackendNewsRef
      .getAllNewsRefs(payload.newsId)
      .then((response) => {
        commit('setAllNewsRefs', response.data);
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setNewsRefsIsLoading', false);
      });
  },
  clearNewsRefs({ commit }) {
    commit('clearNewsRefs');
  },
  postMultipleNewsRefs(
    { commit },
    payload: {
      newsId: string;
      locationIds: string[];
      departmentIds: string[];
    }
  ) {
    return defaultBackendNewsRef
      .postMultipleNewsRefs(
        payload.newsId,
        payload.locationIds,
        payload.departmentIds
      )
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
          }>
        ) => {
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
};
