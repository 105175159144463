import { MutationTree } from 'vuex';
import { CostDiscountState } from './types';
import costDiscount, { CostDiscount, Value } from '@/shared/model/costDiscount';

export const mutations: MutationTree<CostDiscountState> = {
  setCostDiscounts(state, payload: Value) {
    state.costDiscounts.items = payload.value.map(x => costDiscount.parse(x));
    state.costDiscounts.total = payload['@odata.count'] || 0;
  },
  setCostDiscountsIsLoading(state, payload: boolean) {
    state.costDiscounts.isLoading = payload;
  },
};
