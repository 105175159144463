import { GetterTree } from 'vuex';
import { CostDiscountState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<CostDiscountState, RootState> = {
  ['getCostDiscounts']: state => state.costDiscounts,
  ['getCostDiscountsIsLoading']: state => state.costDiscounts?.isLoading,
  ['getCostDiscountsTotal']: state => state.costDiscounts?.total,
  ['getCostDiscountsSearchParams']: state => state.costDiscounts?.searchParams,
};
