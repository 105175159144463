import { GetterTree } from 'vuex';
import { LocationResponsibleState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<LocationResponsibleState, RootState> = {
  ['getLocationResponsibles']: state => state.locationResponsibles,
  ['getLocationResponsiblesIsLoading']: state =>
    state.locationResponsibles?.isLoading,
  ['getLocationResponsiblesTotal']: state => state.locationResponsibles?.total,
  ['getLocationResponsiblesSearchParams']: state =>
    state.locationResponsibles?.searchParams,
};
