import { MutationTree } from 'vuex';
import { EmployeeState } from './types';
import employee, { Employee, Value } from '@/shared/model/employee';

export const mutations: MutationTree<EmployeeState> = {
  // Workaround!!
  // Filtering out the trainers on the frontend for now, since it's currently not possible to do that via an odata filter.
  // There are currently exceptions when trying to do comparisons against the Authorities - array.
  setEmployees(state, payload: Value) {
    state.employees.items = payload.value.map((x) => employee.parse(x));
    state.employees.total = payload['@odata.count'] || 0;
    state.employees.isLoading = false;
  },
  setAllEmployees(state, payload: Employee[]) {
    state.employees.items = payload.map((x) => employee.parse(x));
    state.employees.total = payload.length;
    state.employees.isLoading = false;
  },
  setEmployeesTotal(state, payload: number) {
    state.employees.total = payload;
  },
  setEmployeesIsLoading(state, payload: boolean) {
    state.employees.isLoading = payload;
  },
  resetEmployeesPaging(state) {
    state.employees.searchParams.dataOption.page = 1;
    state.employees.searchParams.dataOption.itemsPerPage = 10;
    state.employees.searchParams.dataOption.filter = '';
    state.employees.searchParams.filter = '';
  },
  setPage(state, payload: number) {
    state.employees.searchParams.dataOption.page = payload;
  },
};
