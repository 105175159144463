import { GetterTree } from 'vuex';
import { DateTimeManagement } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<DateTimeManagement, RootState> = {
  ['getDateTimes']: state => state.dateTimes,
  ['getDateTimesIsLoading']: state => state.dateTimes?.isLoading,
  ['getDateTimesTotal']: state => state.dateTimes?.total,
  ['getDateTimesSearchParams']: state => state.dateTimes?.searchParams,
};
