
import { GetterTree } from 'vuex';
import { SkillState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<SkillState, RootState> = {
  ['getSkills']: (state) => state.skills,
  ['getSkillsIsLoading']: (state) => state.skills?.isLoading,
  ['getSkillsTotal']: (state) => state.skills?.total,
  ['getSkillsSearchParams']: (state) => state.skills?.searchParams,
};
