import { AttendeeRegisterByQrCodeState } from './types';
import location from '@/shared/model/location';
import attendee from '@/shared/model/attendee';

export const state = (): AttendeeRegisterByQrCodeState => initialState();

export const initialState = (): AttendeeRegisterByQrCodeState => ({
  attendee: attendee.parse({}),
  location: location.parse({}),
  courseTrainers: {
    items: [],
    isLoading: false,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: ['lastName', 'firstName'],
    },
  },
  appointments: {
    items: [],
    isLoading: false,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: ['courseName'],
    },
  },
});
