import { GetterTree } from 'vuex';
import { LocationAttendeeState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<LocationAttendeeState, RootState> = {
  ['getLocationAttendees']: state => state.locationAttendees,
  ['getLocationAttendeesIsLoading']: state =>
    state.locationAttendees?.isLoading,
  ['getLocationAttendeesTotal']: state => state.locationAttendees?.total,
  ['getLocationAttendeesSearchParams']: state =>
    state.locationAttendees?.searchParams,
};
