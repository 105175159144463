import { CONST } from '../utils/constants';

export interface NewsRef {
  id: string; //Guid
  newsId: string; //Guid
  companyId: string; //Guid
  locationId: string; //Guid
  departmentId: string; //Guid
}

export interface Value {
  value: NewsRef[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

function parse(data?: Partial<NewsRef>): NewsRef {
  return {
    id: data?.id || CONST.emptyGuid,
    newsId: data?.newsId || CONST.emptyGuid,
    companyId: data?.companyId || CONST.emptyGuid,
    locationId: data?.locationId || CONST.emptyGuid,
    departmentId: data?.departmentId || CONST.emptyGuid,
  };
}

export default {
  parse,
};
