import { Logger } from 'fsts';
import { CONST } from './constants';
import { Salutation } from '../model/salutation';

const logger = new Logger('generalUtils');

export function deepClone(data: any) {
  return JSON.parse(JSON.stringify(data));
}

const DEFAULT_DEBOUNCE_TIME = 300;

export function debounce<T extends Function>(
  callback: T,
  wait = DEFAULT_DEBOUNCE_TIME
) {
  let timer = 0;
  return (...args: any) => {
    clearTimeout(timer);
    timer = setTimeout(() => callback(...args), wait);
  };
}

export function debounceAsync<T extends Function>(
  callback: T,
  wait = DEFAULT_DEBOUNCE_TIME
) {
  let timer = 0;
  let resolves: Function[] = [];
  let rejects: Function[] = [];

  return (...args: any): Promise<any> => {
    clearTimeout(timer);

    return new Promise<any>((resolve, reject) => {
      resolves.push(resolve);
      rejects.push(reject);

      timer = setTimeout(() => {
        callback(...args)
          .then((res: any) => {
            resolves.forEach((x) => x(res));
            resolves = [];
            rejects = [];
          })
          .catch((e: any) => {
            rejects.forEach((x) => x(e));
            resolves = [];
            rejects = [];
          });
      }, wait);
    });
  };
}

// From https://github.com/mattphillips/deep-object-diff
export const isDate = (d: any) => d instanceof Date;
export const isEmpty = (o: any) => Object.keys(o).length === 0;
export const isObject = (o: any) => o != null && typeof o === 'object';
export const properObject = (o: any) =>
  isObject(o) && !o.hasOwnProperty ? { ...o } : o;
export const currentQueryParam = (name: string) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return Number(urlParams.get(name));
};
export const isEmptyId = (id: string | null | undefined) =>
  id == undefined ||
  id == null ||
  id == '' ||
  id == CONST.emptyGuid ||
  !/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(
    id
  );

export const formatFullName = (fullName?: string) => {
  return fullName === ', ' || fullName == undefined || fullName == ''
    ? '-'
    : fullName;
};

// Returns only the values that have been changed in the updated object
const updatedDiff = (lhs: any, rhs: any) => {
  if (lhs === rhs) return {};

  if (!isObject(lhs) || !isObject(rhs)) return rhs;

  const l = properObject(lhs);
  const r = properObject(rhs);

  if (isDate(l) || isDate(r)) {
    if (l.valueOf() == r.valueOf()) return {};
    return r;
  }

  return Object.keys(r).reduce((acc, key) => {
    // eslint-disable-next-line no-prototype-builtins
    if (l.hasOwnProperty(key)) {
      const difference: any = updatedDiff(l[key], r[key]);

      if (isObject(difference) && isEmpty(difference) && !isDate(difference))
        return acc;

      return { ...acc, [key]: difference };
    }

    return acc;
  }, {});
};

export function availabilityColor(value: number, compareWith: number) {
  return value < compareWith
    ? 'green'
    : value == compareWith
    ? 'yellow'
    : 'grey';
}
export function formatFullNameWithSalutation(
  salutation: Salutation | undefined,
  title: string | undefined,
  lastName: string | undefined,
  firstName: string | undefined,
  t: Function
) {
  return `${salutation ? t(`app.salutation["${salutation}"]`) : '-'}. ${
    title ? title + '. ' : ''
  }${lastName ? lastName : ''}, ${firstName ? firstName : ''}`;
}

export function arraysAreEqual(
  array1: any[],
  array2: any[],
  ignoreOrder: boolean = true
) {
  if (array1.length != array2.length) {
    return false;
  }
  for (let i = 0; i < array1.length; i++) {
    if (ignoreOrder) {
      let count1 = array1.filter((x) => x == array1[i]).length;
      let count2 = array2.filter((x) => x == array1[i]).length;
      if (count1 != count2) {
        return false;
      }
    } else {
      if (array1[i] != array2[i]) {
        return false;
      }
    }
  }
  return true;
}

export function getArrayWithoutDuplicates(
  array: any[],
  comparisonMethod = (x: any, y: any) => x == y
): any[] {
  let arrayNoDuplicates: any[] = [];
  for (let entry of array) {
    if (
      arrayNoDuplicates.find((otherEntry) =>
        comparisonMethod(entry, otherEntry)
      ) == undefined
    ) {
      arrayNoDuplicates.push(entry);
    }
  }
  return arrayNoDuplicates;
}

export function formatCost(value: number, locale: string): string {
  if (isNaN(value)) return '0';
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'EUR',
  }).format(value);
}

//Copied from https://gist.github.com/Klerith/80abd742d726dd587f4bd5d6a0ab26b6
export function urlBase64ToUint8Array(base64String: string) {
  var padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  var base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');

  var rawData = window.atob(base64);
  var outputArray = new Uint8Array(rawData.length);

  for (var i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export function roundNumberToTwoDigits(number: number): number {
  // @ts-ignore
  // prettier-ignore
  return +(Math.round(number + "e+2") + "e-2");
}

export function capitalizeObjectKeys(value: any): any {
  if (typeof value == 'object') {
    if (Array.isArray(value)) {
      return (value as any[]).map(capitalizeObjectKeys);

    } else {
      const destinationObject: any = {};
      for (let key in value) {
        const keyUpperCase = key[0].toUpperCase() + key.substring(1);
        destinationObject[keyUpperCase] = capitalizeObjectKeys(value[key]);
      }
      return destinationObject;

    }
  }

  return value;
}

export default class GeneralUtils {
  public static isDevEnvironment(): boolean {
    return process.env.NODE_ENV == 'development';
  }
  public static isProdEnvironment(): boolean {
    return process.env.NODE_ENV == 'production';
  }

  public static getAppUrl(): string {
    return window.location.origin;
  }
  public static camelToSnakeCase(str: string) {
    let result = str
      .replace(/^[A-Z]/g, (letter) => `${letter.toLowerCase()}`)
      .replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
    logger.debug(`camelToSnake:${str}->${result}`);
    return result;
  }
  public static maxint = 2147483647;
  public static updatedDiff = (lhs: any, rhs: any) => {
    return updatedDiff(lhs, rhs);
  };
  public static emptyGuid: string = '00000000-0000-0000-0000-000000000000';
}
