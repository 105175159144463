import { ActionTree } from 'vuex';
import { EmployeeState } from './types';
import { RootState } from '../../types';
import { defaultBackendEmployee } from '@/shared/backend/employee';
import { Logger } from 'fsts';
import moduleUtils from '../moduleUtils';
import { Value, Employee } from '@/shared/model/employee';
import { AxiosResponse } from 'axios';
import { i18nGlobal } from '@/i18n';
import { EmployeePassword } from '@/shared/model/applicationUser';
import { SearchParams, createSearchParams } from '@/shared/model/searchParams';

const logger = new Logger('actions.employees');
export const actions: ActionTree<EmployeeState, RootState> = {
  getRandomPassword({ commit }) {
    return defaultBackendEmployee
      .getRandomPassword()
      .then((response: AxiosResponse<string>) => {
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  getProfileInfo({ commit }) {
    return defaultBackendEmployee
      .getProfileInfo()
      .then((response: AxiosResponse<Employee>) => {
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  getEmployees(
    { commit, dispatch, getters, rootGetters },
    payload?: {
      searchParams?: any;
      locationId?: string;
      departmentId?: string;
      includeTrainers?: boolean;
      employedAfter?: boolean;
      alwaysIncludedEmployeesIds?: string[];
    }
  ) {
    commit('setEmployeesIsLoading', true);
    let searchParams =
      payload?.searchParams ?? getters.getEmployeesSearchParams;
    return defaultBackendEmployee
      .getEmployees(
        searchParams,
        payload?.locationId,
        payload?.departmentId,
        payload?.employedAfter,
        payload?.alwaysIncludedEmployeesIds,
        payload?.includeTrainers
      )
      .then((response: AxiosResponse<Value>) => {
        commit('setEmployees', response.data);
        commit('setEmployeesIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setEmployeesIsLoading', false);
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  getAllEmployees({ commit, getters }) {
    commit('setEmployeesIsLoading', true);
    return defaultBackendEmployee
      .getAllEmployees()
      .then((response: AxiosResponse<Value>) => {
        commit('setAllEmployees', response.data);
        commit('setEmployeesIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setEmployeesIsLoading', false);
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  getAllEmployeesAlphabetically(
    { dispatch },
    payload: {
      employedAfter?: boolean;
      alwaysIncludedEmployeesIds?: string[];
      locationId?: string;
    }
  ) {
    return dispatch('getEmployees', {
      ...payload,
      searchParams: createSearchParams(
        -1,
        1,
        ['lastName', 'firstName'],
        [false, false]
      ),
    });
  },
  getEmployeesAndTrainers(
    { commit, getters },
    payload?: SearchParams | undefined
  ) {
    commit('setEmployeesIsLoading', true);
    let searchParams = payload ?? getters.getEmployeesSearchParams;
    return defaultBackendEmployee
      .getEmployeesAndTrainers(searchParams)
      .then((response: AxiosResponse<Value>) => {
        commit('setEmployees', response.data);
        commit('setEmployeesIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setEmployeesIsLoading', false);
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  getEmployee({ commit, dispatch }, id: string) {
    return defaultBackendEmployee
      .getEmployee(id)
      .then((response: AxiosResponse<Employee>) => {
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  getCountOfActiveAdminsManagers({ commit }) {
    return defaultBackendEmployee
      .getCountOfActiveAdminsManagers()
      .then((response: AxiosResponse<number>) => {
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateEmployee(
    { commit, dispatch },
    payload: { employee: Employee; overrideMessage?: string }
  ) {
    return defaultBackendEmployee
      .updateEmployee(payload.employee)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Employee;
          }>
        ) => {
          if (payload.overrideMessage)
            moduleUtils.ok(payload.overrideMessage, commit);
          else moduleUtils.ok(i18nGlobal.t(`success.employee_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateProfile({ commit }, payload: Employee) {
    return defaultBackendEmployee.updateProfile(payload).then((response) => {
      moduleUtils.ok(i18nGlobal.t(`success.profile_update`), commit);
    });
  },
  deleteEmployee({ commit, dispatch }, id: string) {
    return defaultBackendEmployee
      .deleteEmployee(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Employee;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  setNewPasswordViaEmail({ commit, dispatch }, payload: EmployeePassword) {
    return defaultBackendEmployee
      .setNewPasswordViaEmail(payload)
      .then((response) => {
        moduleUtils.ok(i18nGlobal.t(`success.password_changed`), commit);
      })
      .catch((e) => {
        console.log(e);
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
};
