import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import roomAvailabilityFuncs, {
  RoomAvailability,
  Value,
} from '../model/roomAvailability';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/constants';

const logger = new Logger('backend.RoomAvailability');
export interface BackendRoomAvailability {
  getRoomAvailabilities: (
    searchParams: SearchParams,
    roomId: string
  ) => AxiosPromise<Value>;
  deleteRoomAvailability(id: string): AxiosPromise;
  updateRoomAvailability(roomAvailability: RoomAvailability): AxiosPromise<any>;
}

export const defaultBackendRoomAvailability: BackendRoomAvailability = {
  getRoomAvailabilities(
    searchParams: SearchParams,
    roomId: string
  ): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.RoomId, roomId, false);
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.roomAvailabilityOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getRoomAvailabilities: ${url}`);
    return instance.get<Value>(url);
  },
  deleteRoomAvailability(id: string): AxiosPromise {
    logger.debug('deleteRoomAvailability');
    return instance.delete(`${URLS.roomAvailability}/${id}`);
  },
  updateRoomAvailability(
    roomAvailability: RoomAvailability
  ): AxiosPromise<any> {
    logger.debug('updateRoomAvailability');
    if (roomAvailability.id != '') {
      return instance.put<RoomAvailability>(
        `${URLS.roomAvailability}`,
        roomAvailabilityFuncs.toAPI(roomAvailability)
      );
    } else {
      return instance.post<RoomAvailability>(
        `${URLS.roomAvailability}`,
        roomAvailabilityFuncs.toAPI(roomAvailability)
      );
    }
  },
};
