import DateUtils from '../utils/dateUtils';
import { TypeOfPeriod2 as TypeOfPeriod } from './periodTypes';

export interface RoomAvailability {
  id: string; //System.Guid
  name: string;
  roomId: string;
  allDay: boolean;
  timeFrom: string;
  timeTill: string;
  timeFromString?: string;
  timeTillString?: string;
  dateFrom: string;
  dateTill: string;
  period: TypeOfPeriod;
  periodValues: any;
  companyId?: string;
  creatorId?: string;
  createdDate?: string;
}

export interface Value {
  value: RoomAvailability[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

function toAPI(data: Partial<RoomAvailability>): any {
  return {
    Id: data?.id || undefined,
    Name: data?.name || '',
    RoomId: data?.roomId || undefined,
    AllDay: data?.allDay || false,
    TimeFrom: data?.allDay ? '00:00' : data?.timeFrom || '',
    TimeTill: data?.allDay ? '23:59' : data?.timeTill || '',
    DateFrom: data?.dateFrom || DateUtils.getTodayDateAsIsoString(),
    DateTill: DateUtils.dateNullableToApi(data?.dateTill),
    Period: data?.period || '',
    PeriodValues: data?.periodValues.join(',') || undefined,
    CreatorId: data?.creatorId || undefined,
    CompanyId: data?.companyId || undefined,
  };
}

function parse(data?: Partial<RoomAvailability>): RoomAvailability {
  let period = data?.period || 'Day';
  return {
    id: data?.id || '',
    name: data?.name || '',
    roomId: data?.roomId || '',
    allDay: data?.allDay || false,
    timeFrom: data?.timeFromString?.substring(0, 5) || '09:00',
    timeTill: data?.timeTillString?.substring(0, 5) || '12:00',
    dateFrom: data?.dateFrom || DateUtils.getTodayDateAsIsoString(),
    dateTill: data?.dateTill || '',
    period: TypeOfPeriod[period as keyof typeof TypeOfPeriod],
    periodValues: data?.periodValues?.split(',').map(Number) || [],
    companyId: data?.companyId || '',
    creatorId: data?.creatorId || '',
    createdDate: data?.createdDate || DateUtils.getTodayDateAsIsoString(),
  };
}

export default {
  parse,
  toAPI: toAPI,
};
