<div class="main-element d-flex justify-center w-100">
  <v-container>
    <v-card v-if="show">
      <v-card-title class="bg-red text-white">
        {{ i18n.t('title') }}
      </v-card-title>
      <v-card-text class="mt-2">
        <div v-if="isTrialExpired">
          <p class="font-weight-bold text-body-1 text-red mb-2">
            {{ i18n.t(isAdmin || isManager
              ? 'trial-period-expired-info-admin'
              : 'trial-period-expired-info-others') }}
          </p>
        </div>
        <div v-else>
          <p class="font-weight-bold text-body-1">
            {{ i18n.t('expiry-deadline-info', {days: trialDaysLeft}) }}
          </p>
          <p class="my-3">
            {{ i18n.t('expiry-consequence-info') }}
          </p>
        </div>
        <p v-if="isAdmin || isManager">
          {{ i18n.t('subscription-appeal') }}
        </p>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="onClose">{{ i18n.t('close') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</div>