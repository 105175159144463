import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { key: 2 }
const _hoisted_2 = { class: "selectbox-heading" }

export function render(_ctx, _cache) {
  const _component_tooltip_text = _resolveComponent("tooltip-text")
  const _component_v_radio = _resolveComponent("v-radio")
  const _component_v_radio_group = _resolveComponent("v-radio-group")
  const _component_v_divider = _resolveComponent("v-divider")
  const _component_v_list_item = _resolveComponent("v-list-item")
  const _component_v_select = _resolveComponent("v-select")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({'d-flex justify-center margin-location-mobile' : _ctx.mobile, 'd-flex justify-center' : !_ctx.mobile})
  }, [
    (_ctx.ownUserLocations.length == 1 && _ctx.ownUserDepartments.length == 0)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass({'text-h6 font-weight-medium max-width' : _ctx.mobile, 'text-h5 font-weight-medium max-width' : !_ctx.mobile})
        }, [
          (_ctx.selectedOwnUserLocation != undefined)
            ? (_openBlock(), _createBlock(_component_tooltip_text, {
                key: 0,
                maxLength: 24,
                text: _ctx.selectedOwnUserLocation.name
              }, null, 8, ["text"]))
            : _createCommentVNode("", true)
        ], 2))
      : (_ctx.ownUserLocations.length == 0 && _ctx.ownUserDepartments.length == 1)
        ? (_openBlock(), _createElementBlock("span", {
            key: 1,
            class: _normalizeClass({'text-h6 font-weight-medium max-width' : _ctx.mobile, 'text-h5 font-weight-medium max-width' : !_ctx.mobile})
          }, [
            (_ctx.selectedOwnUserDepartment != undefined)
              ? (_openBlock(), _createBlock(_component_tooltip_text, {
                  key: 0,
                  maxLength: 24,
                  text: _ctx.selectedOwnUserDepartment.name
                }, null, 8, ["text"]))
              : _createCommentVNode("", true)
          ], 2))
        : (_openBlock(), _createElementBlock("span", _hoisted_1, [
            _createVNode(_component_v_select, {
              items: _ctx.selectboxEntries,
              modelValue: _ctx.currentSelection,
              "onUpdate:modelValue": [
                _cache[1] || (_cache[1] = $event => ((_ctx.currentSelection) = $event)),
                _ctx.selectboxEntryChanged
              ],
              "item-title": "name",
              "item-value": "id",
              "hide-details": "",
              variant: "solo",
              flat: "",
              class: "max-width"
            }, {
              "prepend-item": _withCtx(() => [
                _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.i18n.t('select-heading-text')), 1),
                _createVNode(_component_v_radio_group, {
                  modelValue: _ctx.mode,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.mode) = $event)),
                  onChange: _ctx.modeChanged,
                  class: "radio-group-padding mt-3"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_radio, {
                      label: _ctx.i18n.t('locations'),
                      value: "location",
                      disabled: _ctx.ownUserLocations.length == 0
                    }, null, 8, ["label", "disabled"]),
                    (_ctx.showDepartments)
                      ? (_openBlock(), _createBlock(_component_v_radio, {
                          key: 0,
                          label: _ctx.i18n.t('departments'),
                          value: "department",
                          disabled: _ctx.ownUserDepartments.length == 0
                        }, null, 8, ["label", "disabled"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["modelValue", "onChange"]),
                _createVNode(_component_v_divider, { class: "mt-n2" })
              ]),
              selection: _withCtx(({ item }) => [
                (item != undefined)
                  ? (_openBlock(), _createBlock(_component_tooltip_text, {
                      key: 0,
                      maxLength: 24,
                      text: item.title,
                      class: _normalizeClass({'location-entry-mobile text-h6 font-weight-medium' : _ctx.mobile, 'location-entry-desktop text-h5 font-weight-medium' : !_ctx.mobile})
                    }, null, 8, ["text", "class"]))
                  : _createCommentVNode("", true)
              ]),
              item: _withCtx(({ item, props }) => [
                (item != undefined)
                  ? (_openBlock(), _createBlock(_component_v_list_item, _normalizeProps(_mergeProps({ key: 0 }, props)), {
                      title: _withCtx(() => [
                        _createElementVNode("div", {
                          class: _normalizeClass({'location-entry-mobile text-body-2 pa-2 font-weight-regular' : _ctx.mobile, 'location-entry-desktop text-body-1 pa-2 font-weight-regular' : !_ctx.mobile})
                        }, _toDisplayString(item.title), 3)
                      ]),
                      _: 2
                    }, 1040))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["items", "modelValue", "onUpdate:modelValue"])
          ]))
  ], 2))
}