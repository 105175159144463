import { MutationTree } from 'vuex';
import { PresenceAttendanceState } from './types';
import presenceAttendances, { Value } from '@/shared/model/presenceAttendance';

export const mutations: MutationTree<PresenceAttendanceState> = {
  setPresenceAttendances(state, payload: Value) {
    state.presenceAttendances.items = payload.value.map((x) =>
      presenceAttendances.parse(x)
    );
    state.presenceAttendances.total = payload['@odata.count'] || 0;
    state.presenceAttendances.isLoading = false;
  },
  setPresenceAttendancesTotal(state, payload: number) {
    state.presenceAttendances.total = payload;
  },
  setPresenceAttendancesIsLoading(state, payload: boolean) {
    state.presenceAttendances.isLoading = payload;
  },
};
