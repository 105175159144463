import { MutationTree } from 'vuex';
import { RoomPresenceState } from './types';
import roomPresence, { Value } from '@/shared/model/roomPresence';

export const mutations: MutationTree<RoomPresenceState> = {
  setRoomPresences(state, payload: Value) {
    state.roomPresences.items = payload.value.map((x) => roomPresence.parse(x));
    state.roomPresences.total = payload['@odata.count'] || 0;
    state.roomPresences.isLoading = false;
  },
  setRoomPresencesTotal(state, payload: number) {
    state.roomPresences.total = payload;
  },
  setRoomPresencesIsLoading(state, payload: boolean) {
    state.roomPresences.isLoading = payload;
  },
};
