import { GetterTree } from 'vuex';
import { SettingsState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<SettingsState, RootState> = {
  ['getSettingss']: state => state.settingss,
  ['getImportedFiles']: state => state.importedFiles,
  ['getImportedFilesSearchParams']: state => state.importedFiles.searchParams,
  ['getImportedFilesTotal']: state => state.importedFiles.total,
  ['getImportedFilesIsLoading']: state => state.importedFiles.isLoading,

  ['getExportedFiles']: state => state.exportedFiles,
  ['getExportedFilesSearchParams']: state => state.exportedFiles.searchParams,
  ['getExportedFilesTotal']: state => state.exportedFiles.total,
  ['getExportedFilesIsLoading']: state => state.exportedFiles.isLoading,
};
