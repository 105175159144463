import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { createSearchParams, SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import trainer, {
  Trainer,
  Value,
  TrainerSkillsCoverage,
} from '../model/trainer';
import ODataFilterBuilder from 'odata-filter-builder';
import { i18nGlobal } from '@/i18n';
import { isEmptyId } from '../utils/generalUtils';
import { CONST } from '../utils/constants';

const logger = new Logger('backend.Trainer');
export interface BackendTrainer {
  getTrainer(id: string, companyId?: string): AxiosPromise<Trainer>;
  getTrainers: (
    searchParams: SearchParams,
    locationId?: string,
    departmentId?: string,
    skillIds?: string[],
    trainerIds?: string[],
  ) => AxiosPromise<Value>;
  getAllTrainers(
    locationIds?: string[],
    departmentIds?: string[],
    employedAfter?: string,
    employedBefore?: string,
    alwaysIncludedTrainersIds?: string[],
    companyId?: string
  ): AxiosPromise<Value>;
  getTrainersWithAllLocations(
    locationIds?: string[],
    employedAfter?: string
  ): AxiosPromise<Value>;
  deleteTrainer(id: string): AxiosPromise;
  updateTrainer(Trainer: Trainer): AxiosPromise<any>;
  getSkillCoverages(
    skillIds: string[],
    trainerIds: string[]
  ): AxiosPromise<TrainerSkillsCoverage[]>;
}

export const defaultBackendTrainer: BackendTrainer = {
  getTrainer(id: string, companyId?: string): AxiosPromise<Trainer> {
    let url = `${URLS.trainerOdata}/${id}`;
    if (companyId != undefined) {
      url = DefaultBackendHelper.addParamsToUrl(url, [
        `companyId=${companyId}`,
      ]);
    }
    return instance.get<Trainer>(url);
  },
  getTrainers(
    searchParams: SearchParams,
    locationId?: string,
    departmentId?: string,
    skillIds?: string[],
    trainerIds?: string[],
  ): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');

    let skillIdsString = `[${
      skillIds?.length ? skillIds.map((id) => `'${id}'`).join(',') : ''
    }]`;

    if (trainerIds != undefined) {
      let odfbInner = ODataFilterBuilder('or');
      trainerIds.forEach((x) => odfbInner.eq(CONST.Id, x, false));
      odfb.and(odfbInner);
    }

    let baseUrl;
    if (locationId != undefined) {
      baseUrl = `${URLS.trainerOdata}/GetInLocation(locationId=${locationId},skillIds=${skillIdsString})`;
    } else if (departmentId != undefined) {
      baseUrl = `${URLS.trainerOdata}/GetInDepartment(departmentId=${departmentId},skillIds=${skillIdsString})`;
    } else if (skillIds?.length) {
      baseUrl = `${URLS.trainerOdata}/GetBySkills(skillIds=${skillIdsString})`;
    } else {
      baseUrl = `${URLS.trainerOdata}`;
    }

    let url = DefaultBackendHelper.makeUrl(
      baseUrl,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );

    logger.log(`getTrainers${url}`);
    return instance.get<Value>(url);
  },
  getAllTrainers(
    locationIds?: string[],
    departmentIds?: string[],
    employedAfter?: string,
    employedBefore?: string,
    alwaysIncludedTrainersIds?: string[],
    companyId?: string
  ): AxiosPromise<Value> {
    let baseUrl = `${URLS.trainer}/GetAll`;
    if (!isEmptyId(companyId)) {
      baseUrl = `${URLS.trainer}/GetAllAnonymous/${companyId}`;
    }
    let odfbOuter = ODataFilterBuilder('or');
    let odfbInner = ODataFilterBuilder('and');

    if (locationIds != undefined && locationIds.length > 0) {
      let odfbLocations = ODataFilterBuilder('or');

      locationIds.forEach((id) =>
        odfbLocations.or(
          `ApplicationUser/ApplicationUserLocations/any(Location: Location/LocationId eq ${id})`
        )
      );

      odfbInner.and(odfbLocations);
    }

    if (departmentIds != undefined && departmentIds.length > 0) {
      let odfbDepartments = ODataFilterBuilder('or');

      departmentIds.forEach((id) =>
        odfbDepartments.or(
          `ApplicationUser/DepartmentRefs/any(Department: Department/DepartmentId eq ${id})`
        )
      );

      odfbInner.and(odfbDepartments);
    }

    if (employedAfter != undefined) {
      let odfbEmployedAfter = ODataFilterBuilder('or');

      odfbEmployedAfter.eq('ApplicationUser/TillDate', null, false);
      odfbEmployedAfter.ge('ApplicationUser/TillDate', employedAfter, false);

      odfbInner.and(odfbEmployedAfter);
    }

    if (employedBefore != undefined) {
      odfbInner.le('ApplicationUser/FromDate', employedBefore, false);
    }

    if (alwaysIncludedTrainersIds != undefined) {
      for (let trainerId of alwaysIncludedTrainersIds) {
        odfbOuter.eq('ApplicationUserId', trainerId, false);
      }
    }

    odfbOuter.or(odfbInner);

    let dataOptionSortedByLastAndFirstName = createSearchParams(
      -1,
      -1,
      ['ApplicationUser/LastName', 'ApplicationUser/FirstName'],
      [false, false]
    ).dataOption;

    let url = DefaultBackendHelper.makeUrl(
      baseUrl,
      dataOptionSortedByLastAndFirstName,
      undefined,
      undefined,
      odfbOuter
    );

    logger.log(`getAllTrainers${url}`);
    return instance.get<Value>(url);
  },
  getTrainersWithAllLocations(
    locationIds: string[],
    employedAfter?: string
  ): AxiosPromise<Value> {
    const baseUrl = `${URLS.trainer}/GetAll`;
    let odfb = ODataFilterBuilder('and');

    locationIds.forEach((id) =>
      odfb.and(
        `ApplicationUser/ApplicationUserLocations/any(Location: Location/LocationId eq ${id})`
      )
    );

    if (employedAfter != undefined) {
      let odfbEmployedAfter = ODataFilterBuilder('or');

      odfbEmployedAfter.eq('ApplicationUser/TillDate', null, false);
      odfbEmployedAfter.ge('ApplicationUser/TillDate', employedAfter, false);

      odfb.and(odfbEmployedAfter);
    }

    let dataOptionSortedByLastAndFirstName = createSearchParams(
      -1,
      -1,
      ['ApplicationUser/LastName', 'ApplicationUser/FirstName'],
      [false, false]
    ).dataOption;

    let url = DefaultBackendHelper.makeUrl(
      baseUrl,
      dataOptionSortedByLastAndFirstName,
      undefined,
      undefined,
      odfb
    );

    logger.log(`getTrainersWithAllLocations${url}`);
    return instance.get<Value>(url);
  },
  deleteTrainer(id: string): AxiosPromise {
    logger.debug('deleteTrainer');
    return instance.delete(`${URLS.trainer}/${id}`);
  },
  updateTrainer(Trainer: Trainer): AxiosPromise<any> {
    logger.debug('updateTrainer');
    if (Trainer.id != undefined) {
      return instance.put<Trainer>(
        `${URLS.trainer}/${i18nGlobal.locale.value}`,
        trainer.toAPI(Trainer)
      );
    } else {
      return instance.post<Trainer>(
        `${URLS.trainer}/${i18nGlobal.locale.value}`,
        trainer.toAPI(Trainer)
      );
    }
  },
  getSkillCoverages(
    skillIds: string[],
    trainerIds: string[]
  ): AxiosPromise<TrainerSkillsCoverage[]> {
    let url = `${URLS.trainer}/GetSkillCoverages`;

    //ToDo: Find Solution for when there are too many skillIds and trainerIds to be fitted into the Get-Params of the URL.
    let param1 = `skillIds=${skillIds.join(',')}`;
    let param2 = `trainerIds=${trainerIds.join(',')}`;

    url = DefaultBackendHelper.addParamsToUrl(url, [param1, param2]);
    return instance.get<TrainerSkillsCoverage[]>(url);
  },
};
