import { ActionTree } from 'vuex';
import { NewsState } from './types';
import { RootState } from '../../types';
import { defaultBackendNews } from '@/shared/backend/news';
import { Logger } from 'fsts';
import moduleUtils from '../moduleUtils';
import { Value, News } from '@/shared/model/news';
import { AxiosResponse } from 'axios';
import { i18nGlobal } from '@/i18n';
import { CONST } from '@/shared/utils/constants';

const logger = new Logger('actions.newss');
export const actions: ActionTree<NewsState, RootState> = {
  getNewss(
    { commit, dispatch, getters, rootGetters },
    payload?: { searchParams?: any }
  ) {
    commit('setNewssIsLoading', true);
    let searchParams = payload?.searchParams ?? getters.getNewssSearchParams;
    return defaultBackendNews
      .getNewss(searchParams)
      .then((response: AxiosResponse<Value>) => {
        commit('setNewss', response.data);
        commit('setNewssIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setNewssIsLoading', false);
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  getNewsAnonymous(
    { commit, dispatch, getters, rootGetters },
    payload?: { searchParams?: any; companyId?: string; locationId?: string }
  ) {
    commit('setNewssIsLoading', true);
    const companyId = payload?.companyId || CONST.emptyGuid;
    const locationId = payload?.locationId || CONST.emptyGuid;
    let searchParams = payload?.searchParams ?? getters.getNewssSearchParams;
    return defaultBackendNews
      .getNewsAnonymous(searchParams, companyId, locationId)
      .then((response: AxiosResponse<Value>) => {
        commit('setNewss', response.data);
        commit('setNewssIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setNewssIsLoading', false);
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },

  getNewssForCurrentUser(
    { commit, dispatch, getters, rootGetters },
    payload: {
      searchParams?: any;
      locationIds: string[];
      departmentIds: string[];
    }
  ) {
    commit('setNewssIsLoading', true);
    let searchParams = payload?.searchParams ?? getters.getNewssSearchParams;
    return defaultBackendNews
      .getNewssForCurrentUser(
        searchParams,
        payload.locationIds,
        payload.departmentIds
      )
      .then((response: AxiosResponse<Value>) => {
        commit('setNewss', response.data);
        commit('setNewssIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setNewssIsLoading', false);
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  getNews({ commit, dispatch }, id: string) {
    return defaultBackendNews
      .getNews(id)
      .then((response: AxiosResponse<News>) => {
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateNews({ commit, dispatch }, file: News) {
    return defaultBackendNews
      .updateNews(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: News;
          }>
        ) => {
          moduleUtils.ok(i18nGlobal.t(`success.news_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteNews({ commit, dispatch }, id: string) {
    return defaultBackendNews
      .deleteNews(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: News;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  sendWebPushMessages(
    { commit },
    payload: { newsId: string; locationIds: string[]; departmentIds: string[] }
  ) {
    return defaultBackendNews
      .sendWebPushMessages(
        payload.newsId,
        payload.locationIds,
        payload.departmentIds
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
};
