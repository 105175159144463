import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { PaymentMandateData } from '../model/paymentMandateData';
import paymentMandateFuncs from '../model/paymentMandateData';

export interface BackendPaymentData {
  getPaymentMandate(applicationUserId: string): AxiosPromise<PaymentMandateData>;
  updatePaymentMandate(paymentMandate: PaymentMandateData): AxiosPromise<any>;
}

export const DefaultBackendPaymentData: BackendPaymentData = {
  getPaymentMandate(applicationUserId) {
    return instance.get<PaymentMandateData>(
      `${URLS.paymentData}/GetPaymentMandate/${applicationUserId}`
    );
  },
  updatePaymentMandate(paymentMandate) {
    return instance.post<any>(
      `${URLS.paymentData}/UpdatePaymentMandate`,
      paymentMandateFuncs.toAPI(paymentMandate)
    );
  },
};
