import { TypeOfPeriod2 as TypeOfPeriod } from './periodTypes';

export interface Availability {
  id?: string;
  type: AvailabilityType;
  name: string;
  text: string;
  trainerId?: string;
  allDay: boolean;
  timeFrom: string;
  timeTill: string;
  timeFromString?: string;
  timeTillString?: string;
  dateFrom: string;
  dateTill: string;
  period: TypeOfPeriod;
  periodValues: any;
  companyId?: string;
  creatorId?: string;
  createdDate?: string;
}

export type AvailabilityType = 0 | 1;
export const AvailabilityType = {
  Availability: 0 as AvailabilityType,
  Absence: 1 as AvailabilityType,
};

export interface Value {
  value: Availability[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<Availability>): any {
  return {
    Id: data?.id || undefined,
    Name: data?.name || '',
    Text: data?.text || '',
    Type: data?.type || AvailabilityType.Availability,
    TrainerId: data?.trainerId || undefined,
    AllDay: data?.allDay || false,
    TimeFrom: data?.allDay ? '00:00' : data?.timeFrom || '',
    TimeTill: data?.allDay ? '23:59' : data?.timeTill || '',
    DateFrom: data?.dateFrom || new Date().toISOString(),
    DateTill: data?.dateTill || new Date().toISOString(),
    Period: data?.period || '',
    PeriodValues: data?.periodValues.join(',') || undefined,
    CreatorId: data?.creatorId || undefined,
    CompanyId: data?.companyId || undefined,
  };
}

function parse(data?: Partial<Availability>): Availability {
  let period = data?.period || 'Day';
  let type = data?.type || 'Availability';
  return {
    id: data?.id || '',
    type:
      AvailabilityType[type as keyof typeof AvailabilityType] ||
      AvailabilityType.Availability,
    name: data?.name || '',
    text: data?.text || '',
    trainerId: data?.trainerId || '',
    allDay: data?.allDay || false,
    timeFrom: data?.timeFromString?.substring(0, 5) || '09:00',
    timeTill: data?.timeTillString?.substring(0, 5) || '12:00',
    dateFrom: data?.dateFrom || new Date().toISOString().substring(0, 10),
    dateTill: data?.dateTill || new Date().toISOString().substring(0, 10),
    period: TypeOfPeriod[period as keyof typeof TypeOfPeriod],
    periodValues: data?.periodValues?.split(',').map(Number) || [],
    companyId: data?.companyId || '',
    creatorId: data?.creatorId || '',
    createdDate: data?.createdDate || new Date().toISOString().substring(0, 10),
  };
}

export default {
  parse,
  toAPI: toAPI,
};
