import { ActionTree } from 'vuex';
import { AppointmentState } from './types';
import { RootState } from '../../types';
import { defaultBackendAppointment } from '@/shared/backend/appointment';
import { Logger } from 'fsts';
import moduleUtils from '../moduleUtils';
import {
  Value,
  Appointment,
  AppointmentNotificationSetting,
} from '@/shared/model/appointment';
import { AxiosResponse } from 'axios';
import { i18nGlobal } from '@/i18n';
import { SearchParams } from '@/shared/model/searchParams';
import { DataOptions } from '@/shared/model/dataOptions';
import { Filters } from '@/shared/model/calendar';
import { CourseTypeEnum } from '@/shared/model/course';
import { AppointmentTrainerRoomAvailabilityODataRes } from '@/shared/model/appointmentTrainerRoomAvailability';

const logger = new Logger('actions.appointments');
export const actions: ActionTree<AppointmentState, RootState> = {
  getAppointments(
    { commit, getters },
    payload?: {
      searchParams?: any;
      dateTimeId?: string;
      locationId?: string;
      locationIds?: string[];
      courseId?: string;
      dateToCompare?: string;
      dateMaxToCompare?: string;
      bookableAsSeries?: boolean;
      trainerId?: string;
      timeFrom?: string;
      timeTill?: string;
      courseType?: CourseTypeEnum;
      departmentId?: string;
      courseActive?: boolean;
      noStore?: boolean;
      isCancelled?: boolean;
      attendeeId?: string;
    }
  ) {
    commit('setAppointmentsIsLoading', true);
    let searchParams =
      payload?.searchParams ?? getters.getAppointmentsSearchParams;

    let locationIds = payload?.locationIds ?? [];
    if (payload?.locationId != undefined) {
      locationIds.push(payload!.locationId!);
    }

    let dateTimeId = payload?.dateTimeId;
    let courseId = payload?.courseId;
    let dateToCompare = payload?.dateToCompare;
    let dateMaxToCompare = payload?.dateMaxToCompare;

    let bookableAsSeries = payload?.bookableAsSeries;
    let trainerId = payload?.trainerId;
    let timeFrom = payload?.timeFrom;
    let timeTill = payload?.timeTill;
    let courseType = payload?.courseType;
    let departmentId = payload?.departmentId;
    let courseActive = payload?.courseActive;
    let noStore = payload?.noStore ?? false;
    let isCancelled = payload?.isCancelled;
    let attendeeId = payload?.attendeeId;

    return defaultBackendAppointment
      .getAppointments(
        searchParams,
        dateTimeId,
        locationIds,
        courseId,
        dateToCompare,
        dateMaxToCompare,
        bookableAsSeries,
        trainerId,
        timeFrom,
        timeTill,
        courseType,
        departmentId,
        courseActive,
        isCancelled,
        attendeeId
      )
      .then((response: AxiosResponse<Value>) => {
        commit('setAppointmentsIsLoading', false);
        if (noStore) return response.data;
        commit('setAppointments', response.data);
        return response.data;
      })
      .catch((e: any) => {
        commit('setAppointmentsIsLoading', false);
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  getAppointmentsWithConflict(
    { commit, getters },
    payload: {
      searchParams?: SearchParams;
      locationId?: string;
      departmentId?: string;
      noStore?: boolean;
    }
  ) {
    commit('setAppointmentsIsLoading', true);
    let searchParams =
      payload?.searchParams ?? getters.getAppointmentsSearchParams;

    return defaultBackendAppointment
      .getAppointmentsWithConflicts(
        searchParams,
        payload?.locationId,
        payload?.departmentId
      )
      .then((response: AxiosResponse<Value>) => {
        if (payload?.noStore) return response.data;
        commit('setAppointments', response.data);
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => commit('setAppointmentsIsLoading', false));
  },
  getAppointment({ commit }, id: string) {
    return defaultBackendAppointment
      .getAppointment(id)
      .then((response: AxiosResponse<Appointment>) => {
        commit('setAppointment', response.data);
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  getAppointmentAnonymous({ commit }, id: string) {
    return defaultBackendAppointment
      .getAppointmentAnonymous(id)
      .then((response: AxiosResponse<Appointment>) => {
        commit('setAppointment', response.data);
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  getRoomAndTrainerAvailabilities({ commit }, dateTimeId: string) {
    return defaultBackendAppointment
      .getRoomAndTrainerAvailabilities(dateTimeId)
      .then((response: AxiosResponse<object>) => {
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  getRoomAndTrainerAvailabilitiesByAppointmentIds(
    { commit },
    appointmentIds: string[]
  ) {
    return defaultBackendAppointment
      .getRoomAndTrainerAvailabilitiesByAppointmentIds(appointmentIds)
      .then(
        (
          response: AxiosResponse<AppointmentTrainerRoomAvailabilityODataRes>
        ) => {
          return response.data.value;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  getNonCanceledFutureAppointments(
    { commit, getters },
    payload: {
      searchParams?: SearchParams;
      courseId?: string;
      dateTimeId?: string;
    }
  ) {
    let searchParams =
      payload?.searchParams ?? getters.getAppointmentsSearchParams;
    return defaultBackendAppointment
      .getNonCanceledFutureAppointments(
        searchParams,
        payload.courseId,
        payload.dateTimeId
      )
      .then((response: AxiosResponse<Value>) => {
        commit('setAppointments', response.data);
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => commit('setAppointmentsIsLoading', false));
  },
  updateAppointment(
    { commit },
    payload: {
      appointment: Appointment;
      notificationSetting?: AppointmentNotificationSetting;
    }
  ) {
    return defaultBackendAppointment
      .updateAppointment(
        payload.appointment,
        payload.notificationSetting ?? AppointmentNotificationSetting.None
      )
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Appointment;
          }>
        ) => {
          moduleUtils.ok(i18nGlobal.t(`success.appointment_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  addAppointmentByDateTimeId({ commit }, dateTimeId: string) {
    return defaultBackendAppointment
      .addAppointmentByDateTimeId(dateTimeId)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Appointment;
          }>
        ) => {
          moduleUtils.ok(
            i18nGlobal.t(`success.appointment_for_datetime_update`),
            commit
          );
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateAppointmentsByDateTimeId(
    { commit },
    payload: { dateTimeId: string; doNotChangeExistingAppointments?: boolean }
  ) {
    return defaultBackendAppointment
      .updateAppointmentsByDateTimeId(
        payload.dateTimeId,
        payload.doNotChangeExistingAppointments ?? false
      )
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Appointment;
          }>
        ) => {
          moduleUtils.ok(
            i18nGlobal.t(`success.appointment_for_datetime_update`),
            commit
          );
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteAppointment({ commit }, id: string) {
    return defaultBackendAppointment
      .deleteAppointment(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Appointment;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },

  async cancelAppointment({ commit }, id: string) {
    try {
      const response = await defaultBackendAppointment.cancelAppointment(id);
      return response;
    } catch (e) {
      moduleUtils.error('error', commit, e, logger);
      throw e;
    }
  },
  updateSearchParams({ commit }, payload: SearchParams) {
    commit('setSearchParams', payload);
  },
  setPage({ commit }, page: number) {
    commit('setPage', page);
  },
  setFilter({ commit }, filter: string) {
    commit('setFilter', filter);
  },
  setDataOption({ commit }, dataOption: DataOptions) {
    commit('setDataOption', dataOption);
  },
  updateFiltersForCalendar({ commit }, filters: Filters) {
    commit('setSelectedFiltersForCalendar', filters);
  },
};
