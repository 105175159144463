import { MutationTree } from 'vuex';
import { MembershipRequestState } from './types';
import membershipRequest, { Value } from '@/shared/model/membershipRequest';

export const mutations: MutationTree<MembershipRequestState> = {
  setMembershipRequests(state, payload: Value) {
    state.membershipRequests.items = payload.value.map(x =>
      membershipRequest.parse(x)
    );
    state.membershipRequests.total = payload['@odata.count'] || 0;
    state.membershipRequests.isLoading = false;
  },
  setMembershipRequestsTotal(state, payload: number) {
    state.membershipRequests.total = payload;
  },
  setMembershipRequestsIsLoading(state, payload: boolean) {
    state.membershipRequests.isLoading = payload;
  },
  resetMembershipRequestsPaging(state) {
    state.membershipRequests.searchParams.dataOption.page = 1;
    state.membershipRequests.searchParams.dataOption.itemsPerPage = 10;
  },
  setMembershipRequestsTotalOpenCount(state, payload: Value) {
    state.membershipRequestsTotalOpenCount = payload['@odata.count'] ?? 0;
  },
};
