import { MutationTree } from 'vuex';
import { TaskRequestState } from './types';
import taskRequest, { TaskRequest, Value } from '@/shared/model/taskRequest';

export const mutations: MutationTree<TaskRequestState> = {
  setTaskRequests(state, payload: Value) {
    state.taskRequests.items = payload.value.map((x) => taskRequest.parse(x));
    state.taskRequests.total = payload['@odata.count'] || 0;
    state.taskRequests.isLoading = false;
  },
  setTaskRequestsTotal(state, payload: number) {
    state.taskRequests.total = payload;
  },
  setTaskRequestsIsLoading(state, payload: boolean) {
    state.taskRequests.isLoading = payload;
  },
  setTaskRequestsAttendancesOpenCount(state, payload: Value) {
    state.taskRequestsAttendancesOpenCount = payload['@odata.count'] ?? 0;
  },
  setTaskRequestsTotalOpenCount(state, payload: Value) {
    state.taskRequestsTotalOpenCount = payload['@odata.count'] ?? 0;
  },
};
