import { CONST } from '../utils/constants';
import DateUtils from '../utils/dateUtils';

export interface AppointmentMessage {
  id?: string; //System.Guid
  appointmentId: string; //System.Guid
  applicationUserId?: string; //System.Guid
  attendeeId?: string; //System.Guid
  recipientApplicationUserId?: string; //System.Guid
  recipientAttendeeId?: string; //System.Guid
  text: string;
  createdDate: string; //System.DateTime
  lastEditedDate?: string; //System.DateTime
  authorLogo?: string; // base64 string
  authorFullName: string;
  recipientFullName: string;
  authorRoles: string[];
  recipientRoles: string[];
}

function toAPI(data: Partial<AppointmentMessage>): any {
  return {
    Id: data?.id || CONST.emptyGuid,
    AppointmentId: data?.appointmentId || CONST.emptyGuid,
    ApplicationUserId: data?.applicationUserId || undefined,
    AttendeeId: data?.attendeeId || undefined,
    Text: data?.text ?? '',
    RecipientApplicationUserId: data?.recipientApplicationUserId ?? undefined,
    RecipientAttendeeId: data?.recipientAttendeeId ?? undefined,
  };
}

function parse(data: Partial<AppointmentMessage>): AppointmentMessage {
  return {
    id: data?.id || undefined,
    appointmentId: data?.appointmentId || CONST.emptyGuid,
    applicationUserId: data?.applicationUserId || undefined,
    attendeeId: data?.attendeeId || undefined,
    text: data?.text ?? '',
    createdDate:
      data?.createdDate || DateUtils.getCurrentLocalDateAsIsoString(),
    lastEditedDate: data?.lastEditedDate || undefined,
    authorLogo: data?.authorLogo || undefined,
    authorFullName: data?.authorFullName ?? '',
    recipientFullName: data?.recipientFullName ?? '',
    recipientApplicationUserId: data?.recipientApplicationUserId ?? undefined,
    recipientAttendeeId: data?.recipientAttendeeId ?? undefined,
    authorRoles: data?.authorRoles ?? [],
    recipientRoles: data?.recipientRoles ?? []
  };
}

export default {
  parse,
  toAPI: toAPI,
};
