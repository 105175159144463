import { ActionTree } from 'vuex';
import { AttendeeRegisterByQrCodeState } from './types';
import { RootState } from '../../types';
import { defaultBackendAttendeeRegisterByQrCode } from '@/shared/backend/attendeeRegisterByQrCode';
import { Logger } from 'fsts';
import moduleUtils from '../moduleUtils';
import { AxiosResponse } from 'axios';
import { CONST } from '@/shared/utils/constants';
import { Value } from '@/shared/model/courseTrainer';
import { Attendee } from '@/shared/model/attendee';
import moment from 'moment';

const logger = new Logger('actions.attendeeRegisterByQrCode');
export const actions: ActionTree<AttendeeRegisterByQrCodeState, RootState> = {
  getLocationInfoById({ commit, dispatch }, id: string) {
    return defaultBackendAttendeeRegisterByQrCode
      .getLocationInfoById(id)
      .then((response: AxiosResponse<Location>) => {
        commit('setLocation', response.data);
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  getAppointments(
    { commit, dispatch, getters },
    payload?: {
      searchParams?: any;
      locationId?: string;
      courseId?: string;
    }
  ) {
    let searchParams =
      payload?.searchParams ?? getters.getCourseTrainersSearchParams;
    let locationId = payload?.locationId ?? CONST.emptyGuid;
    let courseId = payload?.courseId ?? CONST.emptyGuid;

    let timezoneOffset = moment().utcOffset();
    let bookingDate = moment.utc().add(timezoneOffset, 'minutes').toISOString();
    commit('setAppointmentsIsLoading', true);
    return defaultBackendAttendeeRegisterByQrCode
      .getAppointments(searchParams, locationId, courseId, bookingDate)
      .then((response: AxiosResponse<Value>) => {
        commit('setAppointments', response.data);
        commit('setAppointmentsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setAppointmentsIsLoading', false);
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  getCoursesByLocationId(
    { commit, dispatch, getters },
    payload?: {
      searchParams?: any;
      locationId?: string;
      courseId?: string;
    }
  ) {
    let searchParams =
      payload?.searchParams ?? getters.getCourseTrainersSearchParams;
    let locationId = payload?.locationId ?? CONST.emptyGuid;
    let courseId = payload?.courseId ?? CONST.emptyGuid;

    let timezoneOffset = moment().utcOffset();
    let bookingDate = moment.utc().add(timezoneOffset, 'minutes').toISOString();
    return defaultBackendAttendeeRegisterByQrCode
      .getCourseTrainers(searchParams, locationId, courseId, bookingDate)
      .then((response: AxiosResponse<Value>) => {
        commit('setCourseTrainers', response.data);
        commit('setCourseTrainersIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  getAttendeeByFingerprintId(
    { commit, dispatch },
    payload: { fingerprintId: string; locationId: string }
  ) {
    return defaultBackendAttendeeRegisterByQrCode
      .getAttendeeByFingerprintId(payload.fingerprintId, payload.locationId)
      .then((response: AxiosResponse) => {
        commit('setAttendee', response.data.result);
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  registerAttendee(
    { commit, dispatch },
    payload: { attendee: Attendee; locationId: string }
  ) {
    return defaultBackendAttendeeRegisterByQrCode
      .registerAttendeeWithFingerprintId(payload.attendee, payload.locationId)
      .then((response: AxiosResponse<Attendee>) => {
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
};
