import { DepartmentState } from './types';

export const state = (): DepartmentState => initialState();

export const initialState = (): DepartmentState => ({
  departments: {
    items: [],
    isLoading: false,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['name'],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: ['name'],
    },
  },
  ownUserDepartments: [],
  selectedOwnUserDepartmentIndex: -1,
  ownUserDepartmentsLoaded: false,
});
