export const getDefault: DataOptions = {
  page: 1,
  itemsPerPage: 5,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: false,
};

export interface DataOptions {
  page: number;
  itemsPerPage: number;
  sortBy: any[];
  sortDesc: any[] | boolean;
  groupBy: any[];
  groupDesc: any[];
  multiSort: boolean;
  mustSort: boolean;

  // This is a helper filter field whose value gets passed onto
  // the filter field of the surrounding SearchParams - object.
  filter?: string;
}

// The DataOptions - structure used by VDataTable in vuetify 3

export interface VuetifyTableDataOptions {
  page: number;
  itemsPerPage: number;
  sortBy: VuetifyTableSortItem[];
  groupBy: any[];
}

export interface VuetifyTableSortItem {
  key: string;
  order?: boolean | 'asc' | 'desc';
}

export function extractVuetifyTableSortByFromDataOptions(
  dataOptions: DataOptions
): VuetifyTableSortItem[] {
  return dataOptions.sortBy.map((entry, index) => {
    return {
      key: entry,
      order:
        dataOptions.sortDesc === true ||
        (dataOptions.sortDesc as boolean[])[index]
          ? 'desc'
          : 'asc',
    };
  });
}

export function translateVuetifyTableDataOptionsToDataOptions(
  vuetifyTableDataOptions: VuetifyTableDataOptions,
  existingDataOptions: DataOptions
): DataOptions {
  let changedDataOptions = { ...existingDataOptions };
  changedDataOptions.page = vuetifyTableDataOptions.page;
  changedDataOptions.itemsPerPage = vuetifyTableDataOptions.itemsPerPage;
  changedDataOptions.sortBy = vuetifyTableDataOptions.sortBy.map((x) => x.key);
  changedDataOptions.sortDesc = vuetifyTableDataOptions.sortBy.map(
    (x) => x.order === 'desc'
  );
  return changedDataOptions;
}

export default {
  getDefault: getDefault,
};
