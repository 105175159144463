import { GetterTree } from 'vuex';
import { CalendarState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<CalendarState, RootState> = {
  ['getCalendar']: state => state.items,
  ['getCalendarIsLoading']: state => state.items?.isLoading,
  ['getCalendarTotal']: state => state.items?.total,
  ['getCalendarSearchParams']: state => state.items?.searchParams,
};
