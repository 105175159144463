import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import locationEmployee, {
  LocationEmployee,
  Value,
} from '../model/locationEmployee';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '../utils/constants';

const logger = new Logger('backend.LocationEmployee');
export interface BackendLocationEmployee {
  getLocationEmployee(id: string): AxiosPromise<LocationEmployee>;
  getLocationEmployees: (
    searchParams: SearchParams,
    locationId: string,
    employeeId: string
  ) => AxiosPromise<Value>;
  deleteLocationEmployee(id: string): AxiosPromise;
  updateLocationEmployee(
    locationEmployees: LocationEmployee[],
    forLocationId: string
  ): AxiosPromise<any>;
  updateLocationEmployeeFromEmployeeView(
    locationEmployees: LocationEmployee[],
    forEmployeeId: string
  ): AxiosPromise<any>;
}

export const defaultBackendLocationEmployee: BackendLocationEmployee = {
  getLocationEmployee(id: string): AxiosPromise<LocationEmployee> {
    let url = `${URLS.locationEmployeeOdata}/${id}`;
    return instance.get<LocationEmployee>(url);
  },
  getLocationEmployees(
    searchParams: SearchParams,
    locationId: string,
    employeeId: string
  ): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    if (employeeId != CONST.emptyGuid) {
      odfb.eq(CONST.EmployeeId, employeeId, false);
    } else {
      odfb.eq(CONST.LocationId, locationId, false);
    }

    let url = DefaultBackendHelper.makeUrl(
      `${URLS.locationEmployeeOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getLocationEmployees${url}`);
    return instance.get<Value>(url);
  },

  deleteLocationEmployee(id: string): AxiosPromise {
    logger.debug('deleteLocationEmployee');
    return instance.delete(`${URLS.locationEmployee}/${id}`);
  },
  updateLocationEmployee(
    locationEmployees: LocationEmployee[],
    forLocationId: string
  ): AxiosPromise<any> {
    logger.debug('updateLocationEmployee');
    return instance.post<LocationEmployee>(
      `${URLS.locationEmployee}/AddMultiple`,
      {
        LocationEmployees: locationEmployee.arrayToAPI(locationEmployees),
        ForLocationId: forLocationId,
      }
    );
  },
  updateLocationEmployeeFromEmployeeView(
    locationEmployees: LocationEmployee[],
    forEmployeeId: string
  ): AxiosPromise<any> {
    logger.debug('updateLocationEmployee');
    return instance.post<LocationEmployee>(
      `${URLS.locationEmployee}/AddMultiple`,
      {
        LocationEmployees: locationEmployee.arrayToAPI(locationEmployees),
        ForEmployeeId: forEmployeeId,
      }
    );
  },
};
