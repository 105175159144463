import { Department } from '@/shared/model/department';
import { Location } from '@/shared/model/location';
import {
  Component,
  Prop,
  Vue,
  Watch,
  toNative,
  Setup,
} from 'vue-facing-decorator';
import { namespace } from 'vuex-facing-decorator';
import { useI18n } from 'vue-i18n';
import TooltipText from '@/components/_common/tooltipText/tooltipText.vue';

const name = 'own-user-location-department-select';
const locationModule = namespace('locationModule');
const departmentModule = namespace('departmentModule');
const locales = require('./locales.json');

enum SelectMode {
  Location = 'location',
  Department = 'department',
}

@Component({ name: name, components: { TooltipText } })
class OwnUserLocationDepartmentSelect extends Vue {
  @Setup((props, ctx) => useI18n({ messages: locales }))
  private i18n!: any;

  @Prop({ default: true })
  private showDepartments!: boolean;

  @locationModule.Getter('getOwnUserLocations')
  private ownUserLocations!: Location[];
  @locationModule.Getter('getSelectedOwnUserLocation')
  private selectedOwnUserLocation!: Location | undefined;
  @locationModule.Action('setSelectedOwnUserLocation')
  private actionSetOwnUserLocation!: any;

  @departmentModule.Getter('getOwnUserDepartments')
  private ownUserDepartments!: Department[];
  @departmentModule.Getter('getSelectedOwnUserDepartment')
  private selectedOwnUserDepartment!: Department | undefined;
  @departmentModule.Action('setSelectedOwnUserDepartment')
  private actionSetOwnUserDepartment!: any;

  private mode: SelectMode = SelectMode.Location;
  private selectboxEntries: { name: string; id: string }[] = [];
  private currentSelection: string = '';

  mounted() {
    this.createSelectboxEntries();
  }

  private modeChanged() {
    this.actionSetOwnUserDepartment(
      this.mode == SelectMode.Department
        ? this.ownUserDepartments[0]
        : undefined
    );
    this.actionSetOwnUserLocation(
      this.mode == SelectMode.Location ? this.ownUserLocations[0] : undefined
    );
    this.createSelectboxEntries();
  }

  private selectboxEntryChanged(newValue: string) {
    if (this.mode == SelectMode.Location) {
      this.actionSetOwnUserLocation(newValue);
      this.actionSetOwnUserDepartment('-');
    } else {
      this.actionSetOwnUserLocation('-');
      this.actionSetOwnUserDepartment(newValue);
    }
  }

  private createSelectboxEntries() {
    if (this.mode == SelectMode.Location) {
      this.selectboxEntries = this.ownUserLocations.map((loc) => ({
        name: loc.name,
        id: loc.id,
      }));
    } else {
      this.selectboxEntries = this.ownUserDepartments.map((dep) => ({
        name: dep.name,
        id: dep.id,
      }));
    }

    this.updateCurrentSelectboxEntry();
  }

  private updateCurrentSelectboxEntry() {
    let selectboxEntry;
    if (this.mode == SelectMode.Location) {
      selectboxEntry = this.selectboxEntries.find(
        (x) => x.id == this.selectedOwnUserLocation?.id
      );
    } else {
      selectboxEntry = this.selectboxEntries.find(
        (x) => x.id == this.selectedOwnUserDepartment?.id
      );
    }

    if (selectboxEntry == undefined) {
      this.currentSelection = '';
      return;
    }

    this.currentSelection = selectboxEntry?.id;
  }

  @Watch('ownUserLocations')
  private ownUserLocationsChanged() {
    if (this.mode == SelectMode.Location) {
      this.createSelectboxEntries();
    }
  }

  @Watch('ownUserDepartments')
  private ownUserDepartmentsChanged() {
    if (this.mode == SelectMode.Department) {
      this.createSelectboxEntries();
    }
  }

  @Watch('selectedOwnUserLocation')
  private selectedOwnUserLocationChanged() {
    if (this.selectedOwnUserLocation != undefined) {
      if (this.mode != SelectMode.Location) {
        this.mode = SelectMode.Location;
        this.createSelectboxEntries();
      }

      if (this.currentSelection != this.selectedOwnUserLocation.id) {
        this.updateCurrentSelectboxEntry();
      }
    }
  }

  @Watch('selectedOwnUserDepartment')
  private selectedOwnUserDepartmentChanged() {
    if (this.selectedOwnUserDepartment != undefined) {
      if (this.mode != SelectMode.Department) {
        this.mode = SelectMode.Department;
        this.createSelectboxEntries();
      }

      if (this.currentSelection != this.selectedOwnUserDepartment.id) {
        this.updateCurrentSelectboxEntry();
      }
    }
  }

  get mobile() {
    return this.$vuetify.display.mobile;
  }
}

export default toNative(OwnUserLocationDepartmentSelect);
