import location from '@/shared/model/location';
import { LocationState } from './types';

export const state = (): LocationState => initialState();

export const initialState = (): LocationState => ({
  locations: {
    items: [],
    isLoading: false,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['active', 'name'],
        sortDesc: [true, false],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: ['name', 'street', 'city', 'responsibleFullNames'],
    },
  },
  location: location.parse({}),
  ownUserLocations: [],
  selectedOwnUserLocationIndex: -1,
  ownUserLocationsLoaded: false,
});
