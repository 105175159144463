import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { DefaultBackendHelper } from '../utils/backendHelper';
import { Value } from '../model/departmentResponsible';
import { isEmptyId } from '../utils/generalUtils';
import { SearchParams } from '../model/searchParams';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/constants';

const logger = new Logger('backend.DepartmentResponsible');
export interface BackendDepartmentResponsible {
  getDepartmentResponsibles: (
    searchParams: SearchParams,
    departmentId?: string
  ) => AxiosPromise<Value>;
  postMultipleDepartmentResponsibles(
    responsibleIds: string[],
    departmentId: string
  ): AxiosPromise<any>;
}

export const defaultBackendDepartmentResponsible: BackendDepartmentResponsible = {
  getDepartmentResponsibles: (
    searchParams: SearchParams,
    departmentId?: string
  ): AxiosPromise<Value> => {
    var odfb = ODataFilterBuilder('and');

    if (!isEmptyId(departmentId)) {
      odfb.eq(CONST.DepartmentId, departmentId, false);
    }

    let url = DefaultBackendHelper.makeUrl(
      URLS.departmentResponsibleOdata,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );

    logger.log(`getDepartmentResponsibles${url}`);
    return instance.get<Value>(url);
  },
  postMultipleDepartmentResponsibles(
    responsibleIds: string[],
    departmentId: string
  ): AxiosPromise<any> {
    logger.debug('postMultipleDepartmentResponsibles');
    return instance.post(`${URLS.departmentResponsible}/AddMultiple`, {
      ResponsibleIds: responsibleIds,
      DepartmentId: departmentId,
    });
  },
};
