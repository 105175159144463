import { ActionTree } from 'vuex';
import { DepartmentRefState } from './types';
import { RootState } from '../../types';
import { defaultBackendDepartmentRef } from '@/shared/backend/departmentRef';
import { Logger } from 'fsts';
import moduleUtils from '../moduleUtils';
import { Value } from '@/shared/model/departmentRef';
import { AxiosResponse } from 'axios';
import { createSearchParams, SearchParams } from '@/shared/model/searchParams';

const logger = new Logger('actions.departmentRefs');
export const actions: ActionTree<DepartmentRefState, RootState> = {
  getDepartmentRefs(
    { commit, getters },
    payload: {
      searchParams?: SearchParams;
      userId?: string;
      courseId?: string;
      locationId?: string;
      attendeeId?: string;
    }
  ) {
    commit('setDepartmentRefsIsLoading', true);
    let searchParams =
      payload.searchParams ?? getters.getDepartmentRefsSearchParams;

    return defaultBackendDepartmentRef
      .getDepartmentRefs(
        searchParams,
        payload.userId,
        payload.courseId,
        payload.locationId,
        payload.attendeeId
      )
      .then((response: AxiosResponse<Value>) => {
        commit('setDepartmentRefs', response.data);
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setDepartmentRefsIsLoading', false);
      });
  },
  getDepartmentRefsNonPaginated(
    { dispatch },
    payload: {
      userId?: string;
      courseId?: string;
      locationId?: string;
      attendeeId?: string;
    }
  ) {
    let payloadArg = { ...payload, searchParams: createSearchParams(250) };
    return dispatch('getDepartmentRefs', payloadArg);
  },
  clearDepartmentRefs({ commit }) {
    commit('clearDepartmentRefs');
  },
  postMultipleDepartmentRefs(
    { commit },
    payload: {
      departmentIds: string[];
      userId?: string;
      courseId?: string;
      locationId?: string;
      attendeeId?: string;
    }
  ) {
    return defaultBackendDepartmentRef
      .postMultipleDepartmentRefs(
        payload.departmentIds,
        payload.userId,
        payload.courseId,
        payload.locationId,
        payload.attendeeId
      )
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
          }>
        ) => {
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
};
