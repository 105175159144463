import { GetterTree } from 'vuex';
import { DepartmentRefState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<DepartmentRefState, RootState> = {
  ['getDepartmentRefs']: state => state.departmentRefs,
  ['getDepartmentRefsIsLoading']: state => state.departmentRefs?.isLoading,
  ['getDepartmentRefsTotal']: state => state.departmentRefs?.total,
  ['getDepartmentRefsSearchParams']: state =>
    state.departmentRefs?.searchParams,
};
