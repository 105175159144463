import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { DefaultBackendHelper } from '../utils/backendHelper';
import costDiscount, { CostDiscount, Value } from '../model/costDiscount';
import { SearchParams } from '../model/searchParams';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/constants';

const logger = new Logger('backend.costDiscount');

export interface BackendCostDiscount {
  getCostDiscounts: (
    searchParams: SearchParams,
    costId?: string
  ) => AxiosPromise<Value>;
  postMultipleCostDiscounts(
    costId: string,
    costOverrides: CostDiscount[]
  ): AxiosPromise;
}

export const DefaultBackendCostDiscount: BackendCostDiscount = {
  getCostDiscounts(
    searchParams: SearchParams,
    costId?: string
  ): AxiosPromise<Value> {
    let odfb = ODataFilterBuilder('and');

    if (costId != undefined) {
      odfb.eq(CONST.CostId, costId, false);
    }

    let url = DefaultBackendHelper.makeUrl(
      `${URLS.costDiscountOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );

    logger.log(`getCostDiscounts: ${url}`);
    return instance.get<Value>(url);
  },
  postMultipleCostDiscounts(
    costId: string,
    costOverrides: CostDiscount[]
  ): AxiosPromise {
    let url = `${URLS.costDiscount}/AddMultiple`;

    logger.log(`postMultipleCostDiscounts: ${url}`);
    return instance.post(url, {
      CostId: costId,
      DiscountEntries: costDiscount.arrayToAPI(costOverrides),
    });
  },
};
