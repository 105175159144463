import { MutationTree } from 'vuex';
import { AttendeeCourseState } from './types';
import attendeeCourse, { Value } from '@/shared/model/attendeeCourse';

export const mutations: MutationTree<AttendeeCourseState> = {
  setAttendeeCourses(state, payload: Value) {
    state.attendeeCourses.items = payload.value.map((x) =>
      attendeeCourse.parse(x)
    );
    state.attendeeCourses.total = payload['@odata.count'] || 0;
    state.attendeeCourses.isLoading = false;
  },
  setAttendeeCoursesTotal(state, payload: number) {
    state.attendeeCourses.total = payload;
  },
  setAttendeeCoursesIsLoading(state, payload: boolean) {
    state.attendeeCourses.isLoading = payload;
  },
};
