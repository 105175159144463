import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import attendeeCourse, { AttendeeCourse, Value } from '../model/attendeeCourse';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/constants';
import DateUtils from '../utils/dateUtils';

const logger = new Logger('backend.AttendeeCourse');
export interface BackendAttendeeCourse {
  getAttendeeCourse(id: string): AxiosPromise<AttendeeCourse>;
  getAttendeeCourses: (
    searchParams: SearchParams,
    attendeeId: string
  ) => AxiosPromise<Value>;
  deleteAttendeeCourse(id: string): AxiosPromise;
  updateAttendeeCourse(AttendeeCourse: AttendeeCourse): AxiosPromise<any>;
  updateAttendeeCourseMultiple(
    AttendeeCourse: AttendeeCourse[]
  ): AxiosPromise<any>;
}

export const defaultBackendAttendeeCourse: BackendAttendeeCourse = {
  getAttendeeCourse(id: string): AxiosPromise<AttendeeCourse> {
    let url = `${URLS.attendeeCourseOdata}/${id}`;
    return instance.get<AttendeeCourse>(url);
  },
  getAttendeeCourses(
    searchParams: SearchParams,
    attendeeId: string
  ): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.AttendeeId, attendeeId, false);
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.attendeeCourseOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getAttendeeCourses${url}`);
    return instance.get<Value>(url);
  },
  deleteAttendeeCourse(id: string): AxiosPromise {
    logger.debug('deleteAttendeeCourse');
    return instance.delete(`${URLS.attendeeCourse}/${id}`);
  },
  updateAttendeeCourse(AttendeeCourse: AttendeeCourse): AxiosPromise<any> {
    logger.debug('updateAttendeeCourse');
    let attendeeCourseToApi = attendeeCourse.toAPI(AttendeeCourse);
    attendeeCourseToApi.ClientLocalDateTime =
      DateUtils.getCurrentLocalDateAsIsoString();

    if (AttendeeCourse.id != undefined) {
      return instance.put<AttendeeCourse>(
        `${URLS.attendeeCourse}`,
        attendeeCourseToApi
      );
    } else {
      return instance.post<AttendeeCourse>(
        `${URLS.attendeeCourse}`,
        attendeeCourseToApi
      );
    }
  },
  updateAttendeeCourseMultiple(
    AttendeeCourse: AttendeeCourse[]
  ): AxiosPromise<any> {
    logger.debug('updateAttendeeCourse');
    return instance.post<AttendeeCourse>(`${URLS.attendeeCourse}/AddMultiple`, {
      AttendeeCourses: attendeeCourse.arrayToAPI(AttendeeCourse),
      ClientLocalDateTime: DateUtils.getCurrentLocalDateAsIsoString(),
    });
  },
};
