import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import webPushSubscription, {
  WebPushSubscription,
  WebpushSubscriptionCheckResponse,
} from '../model/webPushSubscription';
import { Value } from '../model/webPushMessage';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import ODataFilterBuilder from 'odata-filter-builder';

const logger = new Logger('backend.WebPush');
export interface BackendWebPush {
  checkSubscriptionStatus(
    existingSubscriptionEndpoint?: string
  ): AxiosPromise<WebpushSubscriptionCheckResponse>;
  SaveSubscription(subscription: WebPushSubscription): AxiosPromise<any>;
  getMessages(searchParams: SearchParams): AxiosPromise<Value>;
  markAllMessagesRead(): AxiosPromise<any>;
}

export const defaultBackendWebPush: BackendWebPush = {
  checkSubscriptionStatus(
    existingSubscriptionEndpoint: string
  ): AxiosPromise<WebpushSubscriptionCheckResponse> {
    logger.log(
      `checkSubscriptionStatus: ${URLS.webPush}/CheckSubscriptionStatus`
    );
    return instance.post<WebpushSubscriptionCheckResponse>(
      `${URLS.webPush}/CheckSubscriptionStatus`,
      {
        WebPushSubscriptionEndpoint: existingSubscriptionEndpoint ?? '',
      }
    );
  },
  SaveSubscription(subscription: WebPushSubscription): AxiosPromise<any> {
    logger.log(`SaveSubscription: ${URLS.webPush}/SaveSubscription`);
    return instance.post<WebpushSubscriptionCheckResponse>(
      `${URLS.webPush}/SaveSubscription`,
      webPushSubscription.toAPI(subscription)
    );
  },
  getMessages(searchParams: SearchParams): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.webPushOdata}/GetMessages()`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getWebpushMessage: ${url}`);
    return instance.get<Value>(url);
  },
  markAllMessagesRead(): AxiosPromise<any> {
    return instance.post(`${URLS.webPush}/MarkAllMessagesRead`);
  },
};
