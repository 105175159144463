import { GetterTree } from 'vuex';
import { AttendanceState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<AttendanceState, RootState> = {
  ['getAttendances']: (state) => state.attendances,
  ['getAttendancesIsLoading']: (state) => state.attendances?.isLoading,
  ['getAttendancesTotal']: (state) => state.attendances?.total ?? 0,
  ['getAttendancesSearchParams']: (state) => state.attendances?.searchParams,
};
