import { onMounted, onUnmounted, ref } from 'vue';
import IdleJs from 'idle-js';

export function useIdleJS() {
  const isIdle = ref(false);

  // For now hard-coded configuration in here. Could be passed by parameter later
  const obj = new IdleJs({
    idle: 3000, // 3 seconds,
    startAtIdle: false,
    events: ['mousemove', 'keydown', 'mousedown', 'touchstart'],
    keepTracking: true,
    onIdle: () => (isIdle.value = true),
    onActive: () => (isIdle.value = false),
  });

  // a composable can also hook into its owner component's
  // lifecycle to setup and teardown side effects.
  onMounted(() => obj.start());
  onUnmounted(() => obj.stop());

  return { obj, isIdle };
}
