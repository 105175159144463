import { TypeOfPeriod2 as TypeOfPeriod } from './periodTypes';
import DateUtils from '../utils/dateUtils';
import { ReferenceParent } from './referenceParent';

interface CourseTrainerMembershipTypeAvailability {
  membershipTypeId: string;
  membershipTypeName: string;
  cost: number;
}

export interface CourseTrainer {
  id?: string; // System.Guid
  courseId?: string; // System.Guid
  courseName: string;
  courseText: string;
  courseNumber: string;
  courseMax: number;
  countOfAttendancesForTheDate: number;
  countOfSeriesCourseAttendees: number;
  locationName: string;
  roomName: string;
  period: TypeOfPeriod;
  periodValues: any;
  start: string; // System.DateTime
  end: string; // System.DateTime
  timeFrom: string;
  timeTill: string;
  timeFromString?: string;
  timeTillString?: string;
  durationInHours: any;
  trainerId?: string; // System.Guid
  trainerFullName: string;
  isSubstitute: boolean;
  dateTimeId?: string;
  companyId?: string; // System.Guid
  creatorId?: string; // System.Guid?
  createdDate: string; // System.DateTime
  membershipTypeAvailabilities: CourseTrainerMembershipTypeAvailability[];
  readonly availableForMembershipTypeNames: string[];
  isAvailableForAttendee(membershipTypes: ReferenceParent[]): boolean;
  getCostForAttendee(membershipTypes: ReferenceParent[]): number;
}

export interface Value {
  value: CourseTrainer[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<CourseTrainer>): any {
  return {
    Id: data?.id || undefined,
    CourseId: data?.courseId || undefined,
    TrainerId: data?.trainerId || undefined,
    DateTimeId: data?.dateTimeId || undefined,
    CompanyId: data?.companyId || undefined,
    CreatorId: data?.creatorId || undefined,
    CreatedDate: data?.createdDate || '',
    IsSubstitute: data?.isSubstitute ?? false,
  };
}

// init api object
function arrayToAPI(data: Partial<CourseTrainer[]>): any {
  return data.map((item?: CourseTrainer) => {
    return {
      Id: item?.id || undefined,
      TrainerId: item?.trainerId || undefined,
      DateTimeId: item?.dateTimeId || undefined,
      CourseId: item?.courseId || undefined,
      CompanyId: item?.companyId || undefined,
      IsSubstitute: item?.isSubstitute ?? false,
    };
  });
}

function parse(data?: Partial<CourseTrainer>): CourseTrainer {
  let period = data?.period || 'Day';
  return {
    id: data?.id || undefined,
    courseId: data?.courseId || undefined,
    courseName: data?.courseName || '',
    courseNumber: data?.courseNumber || '',
    courseText: data?.courseText || '',
    courseMax: data?.courseMax || 0,
    countOfAttendancesForTheDate: data?.countOfAttendancesForTheDate || 0,
    countOfSeriesCourseAttendees: data?.countOfSeriesCourseAttendees || 0,
    locationName: data?.locationName || '',
    roomName: data?.roomName || '',
    trainerId: data?.trainerId || undefined,
    trainerFullName: data?.trainerFullName || '',
    isSubstitute: data?.isSubstitute ?? false,
    dateTimeId: data?.dateTimeId || undefined,
    companyId: data?.companyId || undefined,
    creatorId: data?.creatorId || '',
    durationInHours: data?.durationInHours?.toFixed(2),
    createdDate: data?.createdDate || new Date().toISOString().substring(0, 10),
    period: TypeOfPeriod[period as keyof typeof TypeOfPeriod],
    periodValues: data?.periodValues?.split(',').map(Number) || [],
    start: data?.start || new Date().toISOString().substring(0, 10),
    end: DateUtils.parseDateFromBackend(data?.end),
    timeFrom: data?.timeFromString?.substring(0, 5) || '09:00',
    timeTill: data?.timeTillString?.substring(0, 5) || '12:00',
    timeFromString: data?.timeFromString,
    timeTillString: data?.timeTillString,
    membershipTypeAvailabilities: data?.membershipTypeAvailabilities || [],
    get availableForMembershipTypeNames(): string[] {
      return this.membershipTypeAvailabilities.map((x) => x.membershipTypeName);
    },
    isAvailableForAttendee(membershipTypes: ReferenceParent[]) {
      if (this.membershipTypeAvailabilities.length == 0) return true;
      let fromDate = DateUtils.formatDateIsoNoOffset(this.start) ?? '';
      let todayDate = DateUtils.getTodayDateAsIsoString();
      let comparisonDate = todayDate > fromDate ? todayDate : fromDate;

      let activeMembershipTypeIds = membershipTypes
        .filter((x) => x.isActiveAtDate(comparisonDate))
        .map((x) => x.referenceId!);

      let availability = this.membershipTypeAvailabilities.find((x) =>
        activeMembershipTypeIds.includes(x.membershipTypeId)
      );
      return availability != undefined;
    },
    getCostForAttendee(membershipTypes: ReferenceParent[]): number {
      if (this.membershipTypeAvailabilities.length == 0) return 0;
      let fromDate = DateUtils.formatDateIsoNoOffset(this.start) ?? '';
      let todayDate = DateUtils.getTodayDateAsIsoString();
      let comparisonDate = todayDate > fromDate ? todayDate : fromDate;

      let activeMembershipTypeIds = membershipTypes
        .filter((x) => x.isActiveAtDate(comparisonDate))
        .map((x) => x.referenceId!);

      let costs = this.membershipTypeAvailabilities
        .filter((x) => activeMembershipTypeIds.includes(x.membershipTypeId))
        .map((x) => x.cost)
        .sort();
      if (costs.length == 0) return 0;
      return costs[0];
    },
  };
}

export default {
  parse,
  toAPI: toAPI,
  arrayToAPI,
};
