import { onBeforeMount, ref, Ref } from 'vue';

export function useUpdate() {
  const refreshing = ref(false);
  const registration: Ref<any> = ref(null);
  const updateExists = ref(false);

  // Store the SW registration so we can send it a message
  // We use `updateExists` to control whatever alert, toast, dialog, etc we want to use
  // To alert the user there is an update they need to refresh for
  function updateAvailable(event: any) {
    registration.value = event.detail;
    updateExists.value = true;
  }

  // Called when the user accepts the update
  function refreshApp() {
    updateExists.value = false;
    // Make sure we only send a 'skip waiting' message if the SW is waiting
    if (!registration.value || !registration.value.waiting) return;
    // send message to SW to skip the waiting and activate the new SW
    registration.value.waiting.postMessage('SKIP_WAITING');
  }

  // a composable can also hook into its owner component's
  // lifecycle to setup and teardown side effects.
  onBeforeMount(function () {
    // Listen for our custom event from the SW registration
    document.addEventListener('swUpdated', updateAvailable, {
      once: true,
    });

    // Refresh all open app tabs when a new service worker is installed.
    if (navigator.serviceWorker) {
      // Prevent multiple refreshes
      navigator.serviceWorker.addEventListener('controllerchange', function () {
        if (refreshing.value) return;
        refreshing.value = true;
        // Here the actual reload of the page occurs
        location.reload();
      });
    }
  });

  return { updateExists, refreshApp };
}
