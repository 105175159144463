import { RoomPresenceState } from './types';

export const state = (): RoomPresenceState => initialState();

export const initialState = (): RoomPresenceState => ({
  roomPresences: {
    items: [],
    isLoading: false,
    total: 2,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['date', 'timeEnter'],
        sortDesc: [false, false],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: [],
    },
  },
});
