import { StatisticsState } from "./types";
import { GetterTree } from 'vuex';
import { RootState } from '../../types';

export const getters: GetterTree<StatisticsState, RootState> = {
  ['getRequest']: state => state.request,
  ['getResult']: state => state.result,
  ['getStatisticsIsLoading']: state => state.loading,
  ['getPresets']: state => state.presets,
  ['getPresetsIsLoading']: (state) => state.presets?.isLoading,
  ['getPresetsTotal']: (state) => state.presets?.total,
  ['getPresetsSearchParams']: (state) => state.presets?.searchParams,
};