import { CONST } from '../utils/constants';

export interface Room {
  id: string; // System.Guid
  name: string; // string
  locationId: string; // System.Guid
  locationName: string;
  capacity: number; // int
  isInUse: boolean;
  isAvailable: boolean;
  creatorFullName: string;
  companyId: string;
  createdDate: string; // System.DateTime
}

export interface Value {
  value: Room[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<Room>): any {
  return {
    Id: data?.id || CONST.emptyGuid,
    Name: data?.name || '',
    LocationId: data?.locationId || undefined,
    Capacity: data?.capacity || 0,
    CompanyId: data?.companyId || CONST.emptyGuid,
  };
}

function parse(data?: Partial<Room>): Room {
  return {
    id: data?.id || '',
    name: data?.name || '',
    locationId: data?.locationId || '',
    locationName: data?.locationName || '',
    capacity: data?.capacity || 0,
    isInUse: data?.isInUse ?? false,
    isAvailable: data?.isAvailable ?? true,
    creatorFullName: data?.creatorFullName || '',
    createdDate: data?.createdDate || '',
    companyId: data?.companyId || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
