import { MutationTree } from 'vuex';
import { CompanyState } from './types';
import company, { Company } from '../../../model/company';
import { initialState } from './state';

export const mutations: MutationTree<CompanyState> = {
  setCompany(state: CompanyState, payload: Company) {
    state.currentCompany = company.parse(payload);
  },
  setTotalAttendees(state: CompanyState, payload: number) {
    state.totalAttendees = payload;
  },
  setMaxAttendees(state: CompanyState, payload: number|undefined) {
    state.maxAttendees = payload;
  },
  setCompanyCountry(state: CompanyState, payload: string) {
    state.companyCountry = payload;
  },
  reset(state) {
    const initState = initialState();
    Object.assign(state, initState);
  },
  setCompanies(state, payload: Company[]) {
    state.companies = payload;
    state.isLoadingCompanies = false;
  },
  setIsLoadingCompanies(state, payload: boolean) {
    state.isLoadingCompanies = payload;
  },
};
