import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { DefaultBackendHelper } from '../utils/backendHelper';
import { Value } from '../model/departmentRef';
import { isEmptyId } from '../utils/generalUtils';
import { SearchParams } from '../model/searchParams';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/constants';

const logger = new Logger('backend.DepartmentRef');
export interface BackendDepartmentRef {
  getDepartmentRefs: (
    searchParams: SearchParams,
    userId?: string,
    courseId?: string,
    locationId?: string,
    attendeeId?: string
  ) => AxiosPromise<Value>;
  postMultipleDepartmentRefs(
    departmentIds: string[],
    userId?: string,
    courseId?: string,
    locationId?: string,
    attendeeId?: string
  ): AxiosPromise<any>;
}

export const defaultBackendDepartmentRef: BackendDepartmentRef = {
  getDepartmentRefs: (
    searchParams: SearchParams,
    userId?: string,
    courseId?: string,
    locationId?: string,
    attendeeId?: string
  ): AxiosPromise<Value> => {
    var odfb = ODataFilterBuilder('and');

    if (!isEmptyId(userId)) {
      odfb.eq(CONST.UserId, userId, false);
    }

    if (!isEmptyId(courseId)) {
      odfb.eq(CONST.CourseId, courseId, false);
    }

    if (!isEmptyId(locationId)) {
      odfb.eq(CONST.LocationId, locationId, false);
    }

    if (!isEmptyId(attendeeId)) {
      odfb.eq(CONST.AttendeeId, attendeeId, false);
    }

    let url = DefaultBackendHelper.makeUrl(
      URLS.departmentRefOdata,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );

    logger.log(`getDepartmentRefs${url}`);
    return instance.get<Value>(url);
  },
  postMultipleDepartmentRefs(
    departmentIds: string[],
    userId?: string,
    courseId?: string,
    locationId?: string,
    attendeeId?: string
  ): AxiosPromise<any> {
    logger.debug('postMultipleDepartmentRefs');
    return instance.post(`${URLS.departmentRef}/AddMultiple`, {
      DepartmentIds: departmentIds,
      UserId: userId,
      CourseId: courseId,
      LocationId: locationId,
      AttendeeId: attendeeId,
    });
  },
};
