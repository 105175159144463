import { ActionTree } from 'vuex';
import { RoomAvailabilityState } from './types';
import { RootState } from '../../types';
import { defaultBackendRoomAvailability } from '@/shared/backend/roomAvailability';
import { Logger } from 'fsts';
import moduleUtils from '../moduleUtils';
import { Value, RoomAvailability } from '@/shared/model/roomAvailability';
import { AxiosResponse } from 'axios';
import { i18nGlobal } from '@/i18n';
import { CONST } from '@/shared/utils/constants';

const logger = new Logger('actions.roomAvailabilities');
export const actions: ActionTree<RoomAvailabilityState, RootState> = {
  getRoomAvailabilities(
    { commit, getters },
    payload?: { searchParams?: any; roomId?: string }
  ) {
    commit('setRoomAvailabilitiesIsLoading', true);
    let searchParams =
      payload?.searchParams ?? getters.getRoomAvailabilitiesSearchParams;
    let roomId = payload?.roomId ?? CONST.emptyGuid;

    return defaultBackendRoomAvailability
      .getRoomAvailabilities(searchParams, roomId)
      .then((response: AxiosResponse<Value>) => {
        commit('setRoomAvailabilities', response.data);
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => commit('setRoomAvailabilitiesIsLoading', false));
  },
  updateRoomAvailability({ commit }, roomAvailability: RoomAvailability) {
    return defaultBackendRoomAvailability
      .updateRoomAvailability(roomAvailability)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: RoomAvailability;
          }>
        ) => {
          moduleUtils.ok(
            i18nGlobal.t(`success.room_availability_update`),
            commit
          );
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteRoomAvailability({ commit }, id: string) {
    return defaultBackendRoomAvailability
      .deleteRoomAvailability(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: RoomAvailability;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
};
