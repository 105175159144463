import { CONST } from '../utils/constants';

export interface PresenceAttendance {
  id: string; // System.Guid
  presenceId: string; // System.Guid
  attendancesId: string; // System.Guid
  createdDate: string; // System.DateTime
}

export interface Value {
  value: PresenceAttendance[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<PresenceAttendance>): any {
  return {
    Id: data?.id || undefined,
    PresenceId: data?.presenceId || undefined,
    AttendancesId: data?.attendancesId || undefined,
  };
}

function parse(data?: Partial<PresenceAttendance>): PresenceAttendance {
  return {
    id: data?.id || CONST.emptyGuid,
    presenceId: data?.presenceId || CONST.emptyGuid,
    attendancesId: data?.attendancesId || CONST.emptyGuid,
    createdDate: data?.createdDate || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
