import { ActionTree } from 'vuex';
import { CheckInState } from './types';
import { RootState } from '../../types';
import { defaultBackendCheckIn } from '@/shared/backend/checkIn';
import { Logger } from 'fsts';
import moduleUtils from '../moduleUtils';
import { AxiosResponse } from 'axios';
import { CONST } from '../../../utils/constants';
import { Value as CourseTrainerValue } from '@/shared/model/courseTrainer';

const logger = new Logger('actions.employees');
export const actions: ActionTree<CheckInState, RootState> = {
  getPerson({ commit, dispatch }, qrUrl: string) {
    return defaultBackendCheckIn
      .getPerson(qrUrl)
      .then((response: AxiosResponse) => {
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  getCourseTrainersByLocationId(
    { commit, dispatch, getters, rootGetters },
    payload?: { searchParams?: any; locationId?: string }
  ) {
    commit('setCourseTrainersIsLoading', true);
    let searchParams =
      payload?.searchParams ?? getters.getCourseTrainersSearchParams;
    let locationId = payload?.locationId ?? CONST.emptyGuid;

    return defaultBackendCheckIn
      .getCourseTrainersByLocationId(searchParams, locationId)
      .then((response: AxiosResponse<CourseTrainerValue>) => {
        commit('setCourseTrainers', response.data);
        commit('setCourseTrainersIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setCourseTrainersIsLoading', false);
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
};
