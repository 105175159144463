import report, { Report } from '../model/report';
import { AxiosPromise } from 'axios';
import { Logger } from 'fsts';
import { instance, URLS } from '.';

const logger = new Logger('backend.Report');
export interface BackendReport {
  fillReport(outputReport: Report): AxiosPromise<Report>;
  exportToExcel(outputReport: Report, culture: string): AxiosPromise<any>;
}

export const defaultBackendReport: BackendReport = {
  fillReport(outputReport: Report): AxiosPromise<Report> {
    let url = `${URLS.reports}/Fill`;
    logger.log(`fillReport${url}`);
    return instance.post<Report>(url, report.toAPI(outputReport));
  },
  exportToExcel(outputReport: Report, culture: string): AxiosPromise<any> {
    let url = `${URLS.reports}/ExportToExcel/${culture}`;
    logger.log(`exportToExcel${url}`);
    return instance.post<Report>(url, report.toAPI(outputReport), {
      responseType: 'blob',
    });
  },
};
