import DateUtils from './dateUtils';
import moment from 'moment';
import { formatCost } from './generalUtils';
import { i18nGlobal } from '@/i18n';

export const filters = {
  formatDateWithLocale(
    value: string,
    locale: string,
    yearAbbreviated: boolean = false
  ) {
    if (value) {
      const returnDate = DateUtils.formatDateWithLocale(
        value,
        locale,
        yearAbbreviated
      );

      return returnDate;
    }
  },
  formatDateTimeWithLocale(value: Date, locale: string) {
    if (value) {
      if (moment(String(value)).format('DD/MM/YYYY') == '01/01/0001') {
        return '-';
      }
      if (locale == 'de')
        return moment.utc(String(value)).format('DD.MM.YYYY HH:mm');
      else return moment.utc(String(value)).format('YYYY/MM/DD HH:mm');
    }
  },
  formatDateTimeWithLocaleAndTimezone(value: Date, locale: string) {
    if (value) {
      if (moment(String(value)).format('DD/MM/YYYY') == '01/01/0001') {
        return '-';
      }
      let valueString = value.toString().split('.')[0];
      let timezoneOffset = moment().utcOffset();
      if (locale == 'de')
        return moment
          .utc(valueString)
          .add(timezoneOffset, 'minutes')
          .format('DD.MM.YYYY HH:mm');
      else
        return moment
          .utc(valueString)
          .add(timezoneOffset, 'minutes')
          .format('YYYY/MM/DD HH:mm');
    }
  },
  applyFallback(value: any, fallback: any = '-') {
    if (value == undefined || value == null || value == '') {
      return fallback;
    }
    return value;
  },
  formatCost,
  formatSalutation(value: string) {
    let result = value;
    if (value === 'mr') {
      result = i18nGlobal.t('table_data.mr');
    } else if (value === 'ms') {
      result = i18nGlobal.t('table_data.ms');
    }
    return result;
  },
  formatStatusActive(value: boolean) {
    if (value === true) {
      return i18nGlobal.t('table_data.active');
    } else {
      return i18nGlobal.t('table_data.passive');
    }
  },
  formatStripeDate(
    value: number,
    locale: string
  ) {
    if (value) {
      const monthNames = locale == 'de' 
        ? [
        'Januar',
        'Februar',
        'März',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'Dezember',
      ]
        :[
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];
  
      const date = new Date(value * 1000);
      const day = date.getDate();
      const month = monthNames[date.getMonth()];
      const year = date.getFullYear();
      const hour = date.getHours();
      const minutes = date.getMinutes();

      const hourString = hour >= 10 ? hour : `0${hour}`;
      const minutesString = minutes >= 10 ? minutes : `0${minutes}`;

      return locale == 'de'
        ? `${day}. ${month} ${year}, ${hourString}:${minutesString}`
        : `${day} ${month}, ${year}, ${hourString}:${minutesString}`
    }
  },
};
