import { InvoicePositionsState } from './types';
import { ActionTree } from 'vuex';
import { RootState } from '../../types';
import { Logger } from 'fsts';
import { DefaultBackendInvoicePosition } from '@/shared/backend/invoicePosition';
import { InvoicePosition, Value } from '@/shared/model/invoicePosition';
import { AxiosResponse } from 'axios';
import moduleUtils from '../moduleUtils';
import { i18nGlobal } from '@/i18n';
import { InvoicePaymentStatus } from '@/shared/model/invoice';

const logger = new Logger('actions.invoices');
export const actions: ActionTree<InvoicePositionsState, RootState> = {
  getInvoicePositions(
    { commit, getters },
    payload?: {
      searchParams?: any;
      invoiceId?: string;
      attendeeAppUserId?: string;
      filterByStati?: InvoicePaymentStatus[];
      locationId?: string;
      departmentId?: string;
    }
  ) {
    commit('setInvoicePositionsIsLoading', true);
    let searchParams =
      payload?.searchParams ?? getters.getInvoicePositionsSearchParams;

    return DefaultBackendInvoicePosition.getInvoicePositions(
      searchParams,
      payload?.invoiceId,
      payload?.attendeeAppUserId,
      payload?.filterByStati,
      payload?.locationId,
      payload?.departmentId
    )
      .then((response: AxiosResponse<Value>) => {
        commit('setInvoicePositions', response.data);
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setInvoicePositionsIsLoading', false);
      });
  },
  addInvoicePosition({ commit }, invoicePosition: InvoicePosition) {
    return DefaultBackendInvoicePosition.addInvoicePosition(invoicePosition)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
          }>
        ) => {
          moduleUtils.ok(i18nGlobal.t(`success.invoiceposition_add`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  addInvoicePositionsByCosts(
    { commit },
    payload: {
      attendeeAppUserId: string;
      costIds: string[];
      customCostEntries?: { euroCost: number; description: string }[];
      singleInvoice?: boolean;
      paymentStatus?: InvoicePaymentStatus;
    }
  ) {
    return DefaultBackendInvoicePosition.addInvoicePositionsByCosts(
      payload.attendeeAppUserId,
      payload.costIds,
      payload.customCostEntries ?? [],
      payload.singleInvoice ?? false,
      payload.paymentStatus ?? InvoicePaymentStatus.Open
    )
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
          }>
        ) => {
          moduleUtils.ok(i18nGlobal.t(`success.invoiceposition_add`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  setInvoicePositionCanceled(
    { commit },
    payload: { invoicePositionId: string; canceled: boolean }
  ) {
    let backendCall = payload.canceled
      ? DefaultBackendInvoicePosition.cancelInvoicePosition(
          payload.invoicePositionId
        )
      : DefaultBackendInvoicePosition.unCancelInvoicePosition(
          payload.invoicePositionId
        );

    return backendCall
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
          }>
        ) => {
          moduleUtils.ok(
            i18nGlobal.t(
              payload.canceled
                ? `success.invoiceposition_cancel`
                : `success.invoiceposition_uncancel`
            ),
            commit
          );
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateInvoicePosition({ commit }, invoicePosition: InvoicePosition) {
    return DefaultBackendInvoicePosition.updateInvoicePosition(invoicePosition)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
          }>
        ) => {
          moduleUtils.ok(i18nGlobal.t('success.invoiceposition_edit'), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
};
