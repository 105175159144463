import { MembershipTypeCostOverrideState } from './types';

export const state = (): MembershipTypeCostOverrideState => initialState();

export const initialState = (): MembershipTypeCostOverrideState => ({
  costOverrides: {
    //TODO remove when was made backend
    items: [],
    isLoading: false,
    total: 0, //TODO remove when was made backend
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: -1,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: [],
    },
  },
});
