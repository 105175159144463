import { ActionTree } from 'vuex';
import { ReferenceState } from './types';
import { RootState } from '../../types';
import { defaultBackendReference } from '@/shared/backend/reference';
import { Logger } from 'fsts';
import moduleUtils from '../moduleUtils';
import { Value, Reference, CostTypeEnum } from '@/shared/model/reference';
import { AxiosResponse } from 'axios';
import { i18nGlobal } from '@/i18n';
import { createSearchParams } from '@/shared/model/searchParams';

const logger = new Logger('actions.references');
export const actions: ActionTree<ReferenceState, RootState> = {
  getAllReferences(
    { commit, dispatch, getters, rootGetters },
    payload: { tableName: string; noStore?: boolean }
  ) {
    commit('setReferencesIsLoading', true);

    return defaultBackendReference
      .getAllReferences(payload.tableName)
      .then((response: AxiosResponse<Value>) => {
        commit('setReferencesIsLoading', false);
        if (payload?.noStore) return response.data;

        commit('setAllReferences', response.data);
        return response.data;
      })
      .catch((e: any) => {
        commit('setReferencesIsLoading', false);
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  getReferences(
    { commit, dispatch, getters, rootGetters },
    payload?: {
      searchParams?: any;
      tableName?: string;
      costType?: CostTypeEnum;
      noStore?: boolean;
      onlyActive?: boolean;
    }
  ) {
    commit('setReferencesIsLoading', true);
    let searchParams =
      payload?.searchParams ?? getters.getReferencesSearchParams;
    let tableName = payload?.tableName || '';
    let costType = payload?.costType || undefined;
    let onlyActive = payload?.onlyActive ?? undefined;

    return defaultBackendReference
      .getReferences(
        searchParams,
        tableName,
        costType,
        onlyActive,
      )
      .then((response: AxiosResponse<Value>) => {
        commit('setReferencesIsLoading', false);
        if (payload?.noStore) return response.data;

        commit('setReferences', response.data);
        return response.data;
      })
      .catch((e: any) => {
        commit('setReferencesIsLoading', false);
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  getAllReferencesAlphabetically(
    { dispatch },
    payload?: { tableName?: string; noStore?: boolean, onlyActive?: boolean }
  ) {
    return dispatch('getReferences', {
      searchParams: createSearchParams(-1, 1, ['name'], [false]),
      tableName: payload?.tableName,
      noStore: payload?.noStore,
      onlyActive: payload?.onlyActive,
    });
  },
  getReference({ commit, dispatch }, id: string) {
    return defaultBackendReference
      .getReference(id)
      .then((response: AxiosResponse<Reference>) => {
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateReference(
    { commit, dispatch },
    payload: { reference: Reference; tableName: string }
  ) {
    return defaultBackendReference
      .updateReference(payload.reference, payload.tableName)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Reference;
          }>
        ) => {
          moduleUtils.ok(
            i18nGlobal.t(`success.reference_update.${payload.tableName}`),
            commit
          );
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteReference(
    { commit, dispatch },
    payload: { id: string; tableName: string }
  ) {
    return defaultBackendReference
      .deleteReference(payload.id, payload.tableName)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Reference;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
};
