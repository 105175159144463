import { MutationTree } from 'vuex';
import { AttendanceState } from './types';
import attendance, { Value } from '@/shared/model/attendance';

export const mutations: MutationTree<AttendanceState> = {
  setAttendances(state, payload: Value) {
    state.attendances.items = payload.value.map((x) => attendance.parse(x));
    state.attendances.total = payload['@odata.count'] || 0;
    state.attendances.isLoading = false;
  },
  setAttendancesTotal(state, payload: number) {
    state.attendances.total = payload;
  },
  setAttendancesIsLoading(state, payload: boolean) {
    state.attendances.isLoading = payload;
  },
  clearAttendances(state) {
    state.attendances.items = [];
    state.attendances.total = 0;
  },
  resetAttendancesPaging(state) {
    state.attendances.searchParams.dataOption.page = 1;
    state.attendances.searchParams.dataOption.itemsPerPage = 10;
    state.attendances.searchParams.dataOption.filter = '';
    state.attendances.searchParams.filter = '';
  },
};
