import { GetterTree } from 'vuex';
import { AppointmentState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<AppointmentState, RootState> = {
  ['getSelectedFiltersForCalendar']: state => state.selectedFiltersForCalendar,
  ['getAppointments']: state => state.appointments,
  ['getAppointment']: state => state.appointment,
  ['getAppointmentsIsLoading']: state => state.appointments?.isLoading,
  ['getAppointmentsTotal']: state => state.appointments?.total,
  ['getAppointmentsSearchParams']: state => state.appointments?.searchParams,
};
