import { ActionTree } from 'vuex';
import { DepartmentResponsibleState } from './types';
import { RootState } from '../../types';
import { Logger } from 'fsts';
import moduleUtils from '../moduleUtils';
import { AxiosResponse } from 'axios';
import { SearchParams } from '@/shared/model/searchParams';
import { defaultBackendDepartmentResponsible } from '@/shared/backend/departmentResponsible';
import { Value } from '@/shared/model/departmentResponsible';
import { createSearchParams } from '@/shared/model/searchParams';

const logger = new Logger('actions.departmentResponsible');
export const actions: ActionTree<DepartmentResponsibleState, RootState> = {
  getDepartmentResponsibles(
    { commit, getters },
    payload: {
      searchParams?: SearchParams;
      departmentId?: string;
    }
  ) {
    commit('setDepartmentResponsiblesIsLoading', true);
    let searchParams =
      payload.searchParams ?? getters.getDepartmentResponsiblesSearchParams;

    return defaultBackendDepartmentResponsible
      .getDepartmentResponsibles(searchParams, payload.departmentId)
      .then((response: AxiosResponse<Value>) => {
        commit('setDepartmentResponsibles', response.data);
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setDepartmentResponsiblesIsLoading', false);
      });
  },
  getDepartmentResponsiblesNonPaginated(
    { dispatch },
    payload: { departmentId: string }
  ) {
    return dispatch('getDepartmentResponsibles', {
      departmentId: payload.departmentId,
      searchParams: createSearchParams(-1),
    });
  },
  clearDepartmentResponsibles({ commit }) {
    commit('setDepartmentResponsibles', { value: [] });
  },
  postMultipleDepartmentResponsibles(
    { commit },
    payload: {
      responsibleIds: string[];
      departmentId: string;
    }
  ) {
    return defaultBackendDepartmentResponsible
      .postMultipleDepartmentResponsibles(payload.responsibleIds, payload.departmentId)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
          }>
        ) => {
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
};
