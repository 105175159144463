import { StatisticsRequest, StatisticsResult, statisticsRequestToAPI } from '../model/statistics';
import { AxiosPromise } from 'axios';
import { Logger } from 'fsts';
import { instance, URLS } from '.';
import { capitalizeObjectKeys, isEmpty, isEmptyId } from '@/shared/utils/generalUtils'
import statisticsPresetFuncs, { StatisticsPreset, Value } from '../model/statisticsPreset';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import ODataFilterBuilder from 'odata-filter-builder';

const logger = new Logger('backend.Statistics');
export interface BackendStatistics {
  fillStatistics(statisticsRequest: StatisticsRequest): AxiosPromise<StatisticsResult[]>;
  exportExcel(excelTableData: any[], lang: string): AxiosPromise;
  getPresets(searchParams: SearchParams): AxiosPromise<Value>;
  updatePreset(preset: StatisticsPreset): AxiosPromise;
  deletePreset(id: string): AxiosPromise;
}

export const defaultBackendStatistics : BackendStatistics = {
  fillStatistics(statisticsRequest: StatisticsRequest): AxiosPromise<StatisticsResult[]> {
    const url = `${URLS.statistics}/Fill`;
    logger.log(`fillStatistics: ${url}`);
    return instance.post<StatisticsResult[]>(url, statisticsRequestToAPI(statisticsRequest));
  },
  exportExcel(excelTableData: any[], lang: string): AxiosPromise {
    const url = `${URLS.statistics}/ExportToExcel/${lang}`;
    return instance.post(
      url,
      { Rows: capitalizeObjectKeys(excelTableData) },
      { responseType: 'blob' }
    );
  },
  getPresets(searchParams: SearchParams): AxiosPromise<Value> {
    const baseUrl = `${URLS.statisticsOdata}/GetPresets()`;
    const odfb = ODataFilterBuilder('and');

    const url = DefaultBackendHelper.makeUrl(
      baseUrl,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    return instance.get<Value>(url);
  },
  updatePreset(preset: StatisticsPreset): AxiosPromise {
    if (isEmptyId(preset.id)) {
      const url = `${URLS.statistics}/AddPreset`;
      return instance.post(
        url,
        statisticsPresetFuncs.toAPI(preset)
      );
    } else {
      const url = `${URLS.statistics}/UpdatePreset`;
      return instance.put(
        url,
        statisticsPresetFuncs.toAPI(preset)
      );
    }
  },
  deletePreset(id: string): AxiosPromise {
    const url = `${URLS.statistics}/DeletePreset/${id}`;
    return instance.delete(url);
  }
}