import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import { Value } from '../model/courseTrainer';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/constants';
import attendee, { Attendee } from '../model/attendee';

const logger = new Logger('backend.Attendee');
export interface BackendAttendeeRegisterByQrCode {
  getAppointments: (
    searchParams: SearchParams,
    locationId: string,
    courseId: string,
    bookingDate: string
  ) => AxiosPromise<Value>;
  getCourseTrainers: (
    searchParams: SearchParams,
    locationId: string,
    courseId: string,
    bookingDate: string
  ) => AxiosPromise<Value>;
  getLocationInfoById(id: string): AxiosPromise<Location>;
  getAttendeeByFingerprintId(
    fingerprintId: string,
    locationId: string
  ): AxiosPromise<Attendee>;
  registerAttendeeWithFingerprintId(
    attendee: Attendee,
    locationId: string
  ): AxiosPromise<Attendee>;
}

export const defaultBackendAttendeeRegisterByQrCode: BackendAttendeeRegisterByQrCode =
  {
    getAppointments(
      searchParams: SearchParams,
      locationId: string,
      courseId: string,
      bookingDate: string
    ): AxiosPromise<Value> {
      var odfb = ODataFilterBuilder('and');
      odfb.eq(CONST.CourseActive, true);
      odfb.eq(CONST.BookableAsSeries, false);

      let url = DefaultBackendHelper.makeUrl(
        `${URLS.attendeeRegisterByQrCodeOdata}/GetAppointments(locationId=${locationId},courseId=${courseId},bookingDate='${bookingDate}')`,
        searchParams.dataOption,
        searchParams.orClauseFieldsIds,
        searchParams.filter,
        odfb
      );
      logger.log(`getCourses${url}`);
      return instance.get<Value>(url);
    },
    getCourseTrainers(
      searchParams: SearchParams,
      locationId: string,
      courseId: string,
      bookingDate: string
    ): AxiosPromise<Value> {
      var odfb = ODataFilterBuilder('and');

      let url = DefaultBackendHelper.makeUrl(
        `${URLS.attendeeRegisterByQrCodeOdata}/GetCoursesByLocationId(locationId=${locationId},courseId=${courseId},bookingDate='${bookingDate}')`,
        searchParams.dataOption,
        searchParams.orClauseFieldsIds,
        searchParams.filter,
        odfb
      );
      logger.log(`getCourses${url}`);
      return instance.get<Value>(url);
    },
    getLocationInfoById(id: string): AxiosPromise<Location> {
      let url = `${URLS.attendeeRegisterByQrCode}/GetLocationInfoById/${id}`;
      return instance.get<Location>(url);
    },
    getAttendeeByFingerprintId(
      fingerprintId: string,
      locationId: string
    ): AxiosPromise<Attendee> {
      let url = `${URLS.attendeeRegisterByQrCode}/GetAttendeeByFingerprintId/${fingerprintId}/${locationId}`;
      return instance.get<Attendee>(url);
    },
    registerAttendeeWithFingerprintId(
      Attendee: Attendee,
      locationId: string
    ): AxiosPromise<Attendee> {
      let url = `${URLS.attendeeRegisterByQrCode}/RegisterAttendee/${Attendee.fingerprintId}/${locationId}`;
      return instance.post<Attendee>(url, attendee.toAPI(Attendee));
    },
  };
