import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { Value as CourseTrainerValue } from '../model/courseTrainer';
import { DefaultBackendHelper } from '../utils/backendHelper';

const logger = new Logger('backend.CheckIn');
export interface BackendCheckIn {
  getPerson(qrUrl: string): AxiosPromise;
  getCourseTrainersByLocationId: (
    searchParams: SearchParams,
    locationId: string
  ) => AxiosPromise<CourseTrainerValue>;
}

export const defaultBackendCheckIn: BackendCheckIn = {
  getPerson(qrUrl: string): AxiosPromise {
    logger.log(`getPerson ${qrUrl}`);
    return instance.get(qrUrl);
  },
  getCourseTrainersByLocationId(
    searchParams: SearchParams,
    locationId: string
  ): AxiosPromise<CourseTrainerValue> {
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.courseTrainerOdata}/GetCoursesForTodayByLocationId(locationId=${locationId})`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter
    );
    logger.log(`getCourseTrainers${url}`);
    return instance.get<CourseTrainerValue>(url);
  },
};
