import { CONST } from '../utils/constants';

export type PaymentInterval = 0 | 1 | 3;
export const PaymentInterval = {
  Monthly: 0 as PaymentInterval,
  Quarterly: 1 as PaymentInterval,
  Yearly: 3 as PaymentInterval,
};

export type PaymentType = 0 | 1;
export const PaymentType = {
  DebitMandate: 0 as PaymentType,
  Invoice: 1 as PaymentType,
}

export interface PaymentMandateData {
  companyId: string; //Guid
  applicationUserId: string; //Guid
  iban: string;
  accountHolder: string;
  type: PaymentType;
  interval: PaymentInterval;
  date: string;
}

function toAPI(data: Partial<PaymentMandateData>): any {
  return {
    CompanyId: data?.companyId || CONST.emptyGuid,
    ApplicationUserId: data?.applicationUserId || CONST.emptyGuid,
    IBAN: data?.iban || '',
    AccountHolder: data?.accountHolder || '',
    Type: data?.type ?? PaymentType.DebitMandate,
    Interval: data?.interval ?? PaymentInterval.Monthly,
    Date: data?.date || new Date().toISOString(),
  };
}

function parse(data?: Partial<PaymentMandateData>): PaymentMandateData {
  let intervalInput = data?.interval ?? 'Monthly';
  let interval: PaymentInterval;

  if (typeof intervalInput == 'string')
    interval = PaymentInterval[intervalInput as keyof typeof PaymentInterval];
  else interval = intervalInput as PaymentInterval;

  return {
    companyId: data?.companyId || '',
    applicationUserId: data?.applicationUserId || '',
    iban: data?.iban || '',
    accountHolder: data?.accountHolder || '',
    type: data?.type ?? PaymentType.DebitMandate,
    interval: interval ?? PaymentInterval.Monthly,
    date: data?.date || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};

export const EmptyPaymentMandateData = parse({});
