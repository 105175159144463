import { MutationTree } from 'vuex';
import { WebPushState } from './types';
import { WebpushSubscriptionCheckResponse } from '@/shared/model/webPushSubscription';
import webPushMessage, { Value } from '@/shared/model/webPushMessage';

export const mutations: MutationTree<WebPushState> = {
  setWebpushSubscriptionCheckResponse(
    state,
    payload: WebpushSubscriptionCheckResponse
  ) {
    state.publicVapidKey = payload.publicVapidKey;
    state.webPushSubscriptionStatus = payload.status;
  },
  markBrowserAsIncompatible(state) {
    state.incompatibleBrowser = true;
  },
  setPushSubscription(state, pushSubscription: PushSubscription) {
    state.pushSubscription = pushSubscription;
  },
  setPermissionState(state, permissionState: PermissionState) {
    state.permissionState = permissionState;
  },
  setMessages(state, payload: Value) {
    state.messages.items = payload.value.map(x => webPushMessage.parse(x));
    state.messages.total = payload['@odata.count'] || 0;
    state.messages.isLoading = false;
  },
  setMessagesPage(state, payload: number) {
    state.messages.searchParams.dataOption.page = payload;
  },
};
