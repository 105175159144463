import { MutationTree } from 'vuex';
import { LocationAttendeeState } from './types';
import locationAttendee, { Value } from '@/shared/model/locationAttendee';

export const mutations: MutationTree<LocationAttendeeState> = {
  setLocationAttendees(state, payload: Value) {
    state.locationAttendees.items = payload.value.map(x =>
      locationAttendee.parse(x)
    );
    state.locationAttendees.total = payload['@odata.count'] || 0;
    state.locationAttendees.isLoading = false;
  },
  setLocationAttendeesTotal(state, payload: number) {
    state.locationAttendees.total = payload;
  },
  setLocationAttendeesIsLoading(state, payload: boolean) {
    state.locationAttendees.isLoading = payload;
  },
};
