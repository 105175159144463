import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import tariffRef, { TariffRef, Value } from '../model/tariffRef';
import ODataFilterBuilder from 'odata-filter-builder';

const logger = new Logger('backend.TariffRef');
export interface BackendTariffRef {
  getTariffRef(id: string): AxiosPromise<TariffRef>;
  getAllTariffRefs(): AxiosPromise<Value>;
  getTariffRefs: (
    searchParams: SearchParams,
    organisationId: string
  ) => AxiosPromise<Value>;
  deleteTariffRef(id: string): AxiosPromise;
  updateTariffRef(TariffRef: TariffRef): AxiosPromise<any>;
}

export const defaultBackendTariffRef: BackendTariffRef = {
  getAllTariffRefs(): AxiosPromise<Value> {
    let url = `${URLS.tariffRef}/GetAll`;
    logger.log(`getAllTariffRefs${url}`);
    return instance.get<Value>(url);
  },
  getTariffRef(id: string): AxiosPromise<TariffRef> {
    let url = `${URLS.tariffRefOdata}/${id}`;
    return instance.get<TariffRef>(url);
  },
  getTariffRefs(
    searchParams: SearchParams,
    organisationId: string
  ): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    // odfb.eq(CONST.OrganisationId, organisationId, false);
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.tariffRefOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getTariffRefs${url}`);
    return instance.get<Value>(url);
  },

  deleteTariffRef(id: string): AxiosPromise {
    logger.debug('deleteTariffRef');
    return instance.delete(`${URLS.tariffRef}/${id}`);
  },
  updateTariffRef(TariffRef: TariffRef): AxiosPromise<any> {
    logger.debug('updateTariffRef');
    if (TariffRef.id != undefined) {
      return instance.put<TariffRef>(
        `${URLS.tariffRef}`,
        tariffRef.toAPI(TariffRef)
      );
    } else {
      return instance.post<TariffRef>(
        `${URLS.tariffRef}`,
        tariffRef.toAPI(TariffRef)
      );
    }
  },
};
