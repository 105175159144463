import { MutationTree } from 'vuex';
import { LocationEmployeeState } from './types';
import locationEmployee, { Value } from '@/shared/model/locationEmployee';

export const mutations: MutationTree<LocationEmployeeState> = {
  setLocationEmployees(state, payload: Value) {
    state.locationEmployees.items = payload.value.map((x) =>
      locationEmployee.parse(x)
    );
    state.locationEmployees.total = payload['@odata.count'] || 0;
    state.locationEmployees.isLoading = false;
  },
  setLocationEmployeesTotal(state, payload: number) {
    state.locationEmployees.total = payload;
  },
  setLocationEmployeesIsLoading(state, payload: boolean) {
    state.locationEmployees.isLoading = payload;
  },
  resetLocationEmployeesPaging(state) {
    state.locationEmployees.searchParams.dataOption.page = 1;
    state.locationEmployees.searchParams.dataOption.itemsPerPage = 10;
    state.locationEmployees.searchParams.dataOption.filter = '';
    state.locationEmployees.searchParams.filter = '';
  },
};
