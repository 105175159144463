import report, { Report } from '@/shared/model/report';
import { MutationTree } from 'vuex';
import { ReportState } from './types';

export const mutations: MutationTree<ReportState> = {
  setReport(state, payload: Report) {
    state.report = report.parse(payload);
  },
  setReportIsLoading(state, payload: boolean) {
    state.loading = payload;
  },
};
