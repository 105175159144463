
import { GetterTree } from 'vuex';
import { ReferenceState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<ReferenceState, RootState> = {
  ['getReferences']: (state) => state.references,
  ['getReferencesIsLoading']: (state) => state.references?.isLoading,
  ['getReferencesTotal']: (state) => state.references?.total,
  ['getReferencesSearchParams']: (state) => state.references?.searchParams,
};
