import { CalendarState } from './types';

export const state = (): CalendarState => initialState();

export const initialState = (): CalendarState => ({
  items: {
    items: [],
    isLoading: false,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['date'],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: ['Text', 'CourseName'],
    },
  },
});
