import { ActionTree } from 'vuex';
import { RoomPresenceState } from './types';
import { RootState } from '../../types';
import { defaultBackendRoomPresence } from '@/shared/backend/roomPresence';
import { Logger } from 'fsts';
import moduleUtils from '../moduleUtils';
import { Value, RoomPresence } from '@/shared/model/roomPresence';
import { AxiosResponse } from 'axios';

const logger = new Logger('actions.roomPresences');
export const actions: ActionTree<RoomPresenceState, RootState> = {
  getRoomPresences(
    { commit, getters },
    payload?: {
      searchParams?: any;
      attendeeId?: string;
      date?: string;
      locationId?: string;
    }
  ) {
    commit('setRoomPresencesIsLoading', true);
    let searchParams =
      payload?.searchParams ?? getters.getRoomPresencesSearchParams;
    return defaultBackendRoomPresence
      .getRoomPresences(
        searchParams,
        payload?.attendeeId,
        payload?.date,
        payload?.locationId
      )
      .then((response: AxiosResponse<Value>) => {
        commit('setRoomPresences', response.data);
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => commit('setRoomPresencesIsLoading', false));
  },
  updateRoomPresence({ commit }, roomPresence: RoomPresence) {
    return defaultBackendRoomPresence
      .updateRoomPresence(roomPresence)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: RoomPresence;
          }>
        ) => {
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
};
