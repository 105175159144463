import { MutationTree } from 'vuex';
import { DateTimeManagement } from './types';
import dateTime, { Value } from '@/shared/model/dateTime';

export const mutations: MutationTree<DateTimeManagement> = {
  setDateTimes(state, payload: Value) {
    state.dateTimes.items = payload.value.map((x) => dateTime.parse(x));
    state.dateTimes.total = payload['@odata.count'] || 0;
    state.dateTimes.isLoading = false;
  },
  setDateTimesTotal(state, payload: number) {
    state.dateTimes.total = payload;
  },
  setDateTimesIsLoading(state, payload: boolean) {
    state.dateTimes.isLoading = payload;
  },
  resetDateTimesPaging(state) {
    state.dateTimes.searchParams.dataOption.page = 1;
    state.dateTimes.searchParams.dataOption.itemsPerPage = 10;
    state.dateTimes.searchParams.dataOption.filter = '';
    state.dateTimes.searchParams.filter = '';
  },
};
