import { ROUTES } from '@/router/routesEnum';

export interface QrCode {
  id?: string; // System.Guid
  name: string; // string
  active: boolean;
  validFrom: string; // System.DateTime
  validTill: string; // System.DateTime
  address: string; // string
  lat: number; // double
  lng: number; // double
  locationId?: string;
  locationName?: string;
  courseId?: string;
  courseName?: string;
  readonly preferredDisplayName: string;
  qrCodeUrl: string;
  checkGps: boolean; // bool
  saveGpsByCheck: boolean; // bool
  securityPhrase: string; // string
  companyId?: string;
  createdDate: string; // System.DateTime
}

export interface Value {
  value: QrCode[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<QrCode>): any {
  return {
    Id: data?.id || undefined,
    Name: data?.name || '',
    Active: data?.active || false,
    ValidFrom: data?.validFrom || '',
    ValidTill: data?.validTill || '',
    Address: data?.address || '',
    Lat: data?.lat || 0,
    Lng: data?.lng || 0,
    LocationId: data?.locationId || undefined,
    CourseId: data?.courseId || undefined,
    QrCodeUrl: data?.qrCodeUrl || '',
    CheckGps: data?.checkGps || false,
    SaveGpsByCheck: data?.saveGpsByCheck || false,
    SecurityPhrase: data?.securityPhrase || '',
    CompanyId: data?.companyId || undefined,
  };
}

function parse(data?: Partial<QrCode>): QrCode {
  return {
    id: data?.id || undefined,
    active: data?.active || false,
    name: data?.name || '',
    validFrom: data?.validFrom || new Date().toISOString().substring(0, 10),
    validTill: data?.validTill || new Date().toISOString().substring(0, 10),
    address: data?.address || '',
    lat: data?.lat || 0,
    lng: data?.lng || 0,
    locationId: data?.locationId || undefined,
    locationName: data?.locationName || undefined,
    courseId: data?.courseId || undefined,
    courseName: data?.courseName || undefined,
    get preferredDisplayName() {
      return this.courseName ?? this.locationName ?? this.name;
    },
    qrCodeUrl: data?.qrCodeUrl || '',
    checkGps: data?.checkGps || false,
    saveGpsByCheck: data?.saveGpsByCheck || false,
    securityPhrase: data?.securityPhrase || '',
    companyId: data?.companyId || undefined,
    createdDate: data?.createdDate || new Date().toISOString().substring(0, 10),
  };
}

export function createQrCodePrintUrl(url: string, displayName: string): string {
  let qrCodeUrl = encodeURIComponent(btoa(url));
  let displayNameUrl = encodeURIComponent(btoa(displayName));
  return `/${ROUTES.print_qr_code}/${qrCodeUrl}/${displayNameUrl}`;
}

export default {
  parse,
  toAPI: toAPI,
};
