import { GetterTree } from 'vuex';
import { DocumentState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<DocumentState, RootState> = {
  ['getDocument']: state => state.document,
  ['getDocuments']: state => state.documents,
  ['getDocumentsIsLoading']: state => state.documentsIsLoading,
  ['getDocumentsTotal']: state => state.documentsTotal,
  ['getDocumentsSearchParams']: state => state.documentsSearchParams,
  ['getDocumentsNew']: state => state.documentsNew,
  ['getDocumentsNewIsLoading']: state => state.documentsNewIsLoading,
  ['getDocumentsNewTotal']: state => state.documentsNewTotal,
  ['getDocumentsNewSearchParams']: state => state.documentsNewSearchParams,
  ['getDocumentCategories']: state => state.documentCategories,
  ['getDocumentCategoriesIsLoading']: state =>
    state.documentCategoriesIsLoading,
  ['getDocumentCategoriesSearchParams']: state =>
    state.documentCategoriesSearchParams,
};
