import { MutationTree } from 'vuex';
import { LocationState } from './types';
import location, {
  Location,
  Value,
  AllLocation,
} from '@/shared/model/location';

export const mutations: MutationTree<LocationState> = {
  setLocations(state, payload: Value) {
    state.locations.items = payload.value.map((x) => location.parse(x));
    state.locations.total = payload['@odata.count'] || 0;
    state.locations.isLoading = false;
  },
  setLocation(state, payload: Location) {
    state.location = location.parse(payload);
  },
  setLocationsTotal(state, payload: number) {
    state.locations.total = payload;
  },
  setAllLocations(state, payload: Location[]) {
    state.locations.items = payload.map((x) => location.parse(x));
    state.locations.total = payload.length;
    state.locations.isLoading = false;
  },
  setLocationsIsLoading(state, payload: boolean) {
    state.locations.isLoading = payload;
  },
  setOwnUserLocations(state, payload: Partial<Location>[]) {
    state.ownUserLocations = payload.map((loc) => location.parse(loc));
  },
  setSelectedOwnUserLocationIndex(state, payload: number) {
    state.selectedOwnUserLocationIndex = payload;
  },
  addOwnUserAllLocation(state) {
    state.ownUserLocations.unshift({ ...AllLocation });
  },
  setOwnUserLocationsLoaded(state, payload: boolean) {
    state.ownUserLocationsLoaded = payload;
  },
  resetLocationsPaging(state) {
    state.locations.searchParams.dataOption.page = 1;
    state.locations.searchParams.dataOption.itemsPerPage = 10;
    state.locations.searchParams.dataOption.filter = '';
    state.locations.searchParams.filter = '';
  },
};
