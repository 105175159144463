import { ActionTree } from 'vuex';
import { AvailabilityState } from './types';
import { RootState } from '../../types';
import { defaultBackendAvailability } from '@/shared/backend/availability';
import { Logger } from 'fsts';
import moduleUtils from '../moduleUtils';
import { Value, Availability } from '@/shared/model/availability';
import { AxiosResponse } from 'axios';
import { i18nGlobal } from '@/i18n';
import { CONST } from '@/shared/utils/constants';

const logger = new Logger('actions.availabilities');
export const actions: ActionTree<AvailabilityState, RootState> = {
  getAvailabilities(
    { commit, dispatch, getters, rootGetters },
    payload?: { searchParams?: any; trainerId?: string }
  ) {
    commit('setAvailabilitiesIsLoading', true);
    let searchParams =
      payload?.searchParams ?? getters.getAvailabilitiesSearchParams;
    let trainerId = payload?.trainerId ?? CONST.emptyGuid;

    return defaultBackendAvailability
      .getAvailabilities(searchParams, trainerId)
      .then((response: AxiosResponse<Value>) => {
        commit('setAvailabilities', response.data);
        commit('setAvailabilitiesIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setAvailabilitiesIsLoading', false);
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  getAvailability({ commit, dispatch }, id: string) {
    return defaultBackendAvailability
      .getAvailability(id)
      .then((response: AxiosResponse<Availability>) => {
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateAvailability({ commit, dispatch }, file: Availability) {
    return defaultBackendAvailability
      .updateAvailability(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Availability;
          }>
        ) => {
          moduleUtils.ok(i18nGlobal.t(`success.availability_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteAvailability({ commit, dispatch }, id: string) {
    return defaultBackendAvailability
      .deleteAvailability(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Availability;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
};
