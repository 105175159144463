import { MutationTree } from 'vuex';
import { TariffRefState } from './types';
import tariffRef, { TariffRef, Value } from '@/shared/model/tariffRef';

export const mutations: MutationTree<TariffRefState> = {
  setTariffRefs(state, payload: Value) {
    state.tariffRefs.items = payload.value.map(x => tariffRef.parse(x));
    state.tariffRefs.total = payload['@odata.count'] || 0;
    state.tariffRefs.isLoading = false;
  },
  setAllTariffRefs(state, payload: TariffRef[]) {
    state.tariffRefs.items = payload.map(x => tariffRef.parse(x));
    state.tariffRefs.total = payload.length;
    state.tariffRefs.isLoading = false;
  },
  setTariffRefsTotal(state, payload: number) {
    state.tariffRefs.total = payload;
  },
  setTariffRefsIsLoading(state, payload: boolean) {
    state.tariffRefs.isLoading = payload;
  },
};
