import { ActionTree } from 'vuex';
import { PresenceAttendanceState } from './types';
import { RootState } from '../../types';
import { defaultBackendPresenceAttendances } from '@/shared/backend/presenceAttendance';
import { Logger } from 'fsts';
import moduleUtils from '../moduleUtils';
import { Value, PresenceAttendance } from '@/shared/model/presenceAttendance';
import { AxiosResponse } from 'axios';
import { Attendance } from '@/shared/model/attendance';

const logger = new Logger('actions.presenceAttendances');
export const actions: ActionTree<PresenceAttendanceState, RootState> = {
  getPresenceAttendances(
    { commit, dispatch, getters, rootGetters },
    payload?: { searchParams?: any }
  ) {
    commit('setPresenceAttendancesIsLoading', true);
    let searchParams =
      payload?.searchParams ?? getters.getPresenceAttendancesSearchParams;
    return defaultBackendPresenceAttendances
      .getPresenceAttendances(searchParams)
      .then((response: AxiosResponse<Value>) => {
        commit('setPresenceAttendances', response.data);
        commit('setPresenceAttendancesIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setPresenceAttendancesIsLoading', false);
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  getPresenceAttendance({ commit, dispatch }, id: string) {
    return defaultBackendPresenceAttendances
      .getPresenceAttendance(id)
      .then((response: AxiosResponse<PresenceAttendance>) => {
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  updatePresenceAttendance({ commit, dispatch }, file: PresenceAttendance) {
    return defaultBackendPresenceAttendances
      .updatePresenceAttendance(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: PresenceAttendance;
          }>
        ) => {
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  updatePresenceAttendancesMultiple(
    { commit, dispatch },
    payload: {
      presenceId: string;
      attendances: Attendance[];
    }
  ) {
    return defaultBackendPresenceAttendances
      .updatePresenceAttendancesMultiple(payload)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: PresenceAttendance;
          }>
        ) => {
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },

  deletePresenceAttendance({ commit, dispatch }, id: string) {
    return defaultBackendPresenceAttendances
      .deletePresenceAttendance(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: PresenceAttendance;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
};
