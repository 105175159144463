import { MutationTree } from 'vuex';
import { CalendarState } from './types';
import calendar, { Calendar, Value } from '@/shared/model/calendar';
import { initialState } from './state';

export const mutations: MutationTree<CalendarState> = {
  setItems(state, payload: Calendar[]) {
    state.items.items = payload.map(x => calendar.parse(x));
    state.items.total = payload?.length || 0;
    state.items.isLoading = false;
  },
  setItemsTotal(state, payload: number) {
    state.items.total = payload;
  },
  setItemsIsLoading(state, payload: boolean) {
    state.items.isLoading = payload;
  },
  clear(state) {
    const initState = initialState();
    Object.assign(state, initState);
  },
};
