import moment from 'moment';

export enum TimeFrameType {
  Hours,
  Days,
  Weeks,
  Months,
}

export enum TrainerAvailabilityStatus {
  AvailableFree = 0,
  Absent = 1,
  AvailableInCourse = 2,
  Unknown = 3,
}

export interface ReportFilter {
  $type: string;
  locations: string[];
  departments?: string[];
  courses?: string[];
  rooms?: string[];
  trainers?: string[];
  skills?: string[];
}

export interface ReportFraction {
  $type: string;
  dateTime: string;
  values?: number[];
  valueOwners?: string[];
  trainers?: string[];
  rooms?: string[];
  stati?: TrainerAvailabilityStatus[];
}

export interface Report {
  $type: string;
  timeframeType: TimeFrameType;
  startDateTime: string;
  endDateTime: string;
  timezoneOffset: number;
  fractions: ReportFraction[];
  filter: ReportFilter;
  page?: number;
  totalPages?: number;
}

function toAPI(data: Partial<Report>): any {
  return {
    $type: data?.$type || 'RegistrationCountReport',
    TimeframeType: data?.timeframeType ?? TimeFrameType.Days,
    StartDateTime: data?.startDateTime || new Date().toISOString(),
    EndDateTime: data?.endDateTime || new Date().toISOString(),
    TimezoneOffset: data?.timezoneOffset ?? 0,
    Fractions: (data?.fractions ?? []).map((x) => reportFractionToAPI(x)),
    Filter: reportFilterToAPI(data?.filter || {}),
    Page: data?.page || undefined,
  };
}

function reportFilterToAPI(data: Partial<ReportFilter>): any {
  return {
    $type: data?.$type || 'ReportFilter',
    Locations: data?.locations || [],
    Departments: data?.departments || undefined,
    Courses: data?.courses || undefined,
    Rooms: data?.rooms || undefined,
    Trainers: data?.trainers || undefined,
    Skills: data?.skills || undefined,
  };
}

function reportFractionToAPI(data: Partial<ReportFraction>): any {
  return {
    $type: data?.$type || 'OwnedNumberValuesReportFraction',
    DateTime: data?.dateTime || new Date().toISOString(),
    Values: data?.values || undefined,
    ValueOwners: data?.valueOwners || undefined,
    Trainers: data?.trainers || undefined,
    Rooms: data?.rooms || undefined,
    Stati: data?.stati || undefined,
  };
}

function parse(data: Partial<Report>): Report {
  return {
    $type: data?.$type || 'RegistrationCountReport',
    timeframeType: data?.timeframeType ?? TimeFrameType.Days,
    startDateTime: data?.startDateTime || new Date().toISOString(),
    endDateTime: data?.endDateTime || new Date().toISOString(),
    timezoneOffset: data?.timezoneOffset ?? 0,
    fractions: data?.fractions?.map((x) => parseReportFraction(x)) || [],
    filter: parseReportFilter(data?.filter || {}),
    page: data?.page || undefined,
    totalPages: data?.totalPages || undefined,
  };
}

function parseReportFilter(data: Partial<ReportFilter>): ReportFilter {
  return {
    $type: data?.$type || 'ReportFilter',
    locations: data?.locations || [],
    departments: data?.departments || undefined,
    courses: data?.courses || undefined,
    rooms: data?.rooms || undefined,
    trainers: data?.trainers || undefined,
    skills: data?.skills || undefined,
  };
}

function parseReportFraction(data: Partial<ReportFraction>): ReportFraction {
  return {
    $type: data?.$type || 'OwnedNumberValuesReportFraction',
    dateTime: data?.dateTime || new Date().toISOString(),
    values: data?.values || undefined,
    valueOwners: data?.valueOwners || undefined,
    trainers: data?.trainers || undefined,
    rooms: data?.rooms || undefined,
    stati: data?.stati || undefined,
  };
}

export default {
  toAPI: toAPI,
  parse,
};

export const InitialReport: Report = {
  $type: 'RegistrationCountReport',
  timeframeType: TimeFrameType.Days,
  startDateTime: moment().utc().startOf('week').add(1, 'days').toISOString(),
  endDateTime: moment().utc().endOf('week').add(1, 'days').toISOString(),
  timezoneOffset: moment().utcOffset() / 60,
  fractions: [],
  filter: parseReportFilter({}),
};
