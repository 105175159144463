export interface Tariff {
  id?: string; // System.Guid
  name: string;
  tariffName: string; // string
  creatorFullName: string;
  companyId?: string; // System.Guid
  creatorId?: string; // System.Guid
  createdDate: string; // System.DateTime
}

export interface Value {
  value: Tariff[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<Tariff>): any {
  return {
    Id: data?.id || undefined,
    Name: data?.name || '',
    CompanyId: data?.companyId || undefined,
  };
}

function parse(data?: Partial<Tariff>): Tariff {
  return {
    id: data?.id || undefined,
    name: data?.name || '',
    tariffName: data?.tariffName || '',
    creatorFullName: data?.creatorFullName || '',
    companyId: data?.companyId || undefined,
    creatorId: data?.creatorId || undefined,
    createdDate: data?.createdDate || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
