import { MutationTree } from 'vuex';
import {
  MembershipApplicationState,
  ApplicationConfirmationData,
} from './types';
import location, { Location } from '@/shared/model/location';
import attendee, { Attendee } from '@/shared/model/attendee';
import company, { Company } from '@/shared/model/company';
import reference, { Reference, Value } from '@/shared/model/reference';
import referenceParent, {
  ReferenceParent,
} from '@/shared/model/referenceParent';
import membershipTypeCostOverride, {
  MembershipTypeCostOverride,
} from '@/shared/model/membershipTypeCostOverride';
import costDiscount, { CostDiscount } from '@/shared/model/costDiscount';

export const mutations: MutationTree<MembershipApplicationState> = {
  setLocation(state, payload: Location) {
    state.location = location.parse(payload);
  },
  setAttendee(state, payload: Attendee) {
    state.attendee = attendee.parse(payload);
  },
  setCompany(state, payload: Company) {
    state.company = company.parse(payload);
  },
  setMembershipTypes(state, payload: Reference[]) {
    state.membershipTypes = payload.map((x) => reference.parse(x));
  },
  setCosts(state, payload: Reference[]) {
    state.costs = payload.map((x) => reference.parse(x));
  },
  setMemberStatuses(state, payload: Reference[]) {
    state.memberStatuses = payload.map((x) => reference.parse(x));
  },
  setMembershipTypeCostOverrides(state, payload: MembershipTypeCostOverride[]) {
    state.membershipTypeCostOverrides = payload.map((x) =>
      membershipTypeCostOverride.parse(x)
    );
  },
  setCostDiscounts(state, payload: CostDiscount[]) {
    state.costDiscounts = payload.map((x) => costDiscount.parse(x));
  },
  setCostMembershipTypes(state, payload: ReferenceParent[]) {
    state.costMembershipTypes = payload.map((x) => referenceParent.parse(x));
  },
  setIsLoading(state, payload: boolean) {
    state.isLoading = payload;
  },
  setApplicationConfirmationData(state, payload: ApplicationConfirmationData) {
    state.applicationConfirmationData = payload;
  },
};
