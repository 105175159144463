
import { GetterTree } from 'vuex';
import { RoomState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<RoomState, RootState> = {
  ['getRooms']: (state) => state.rooms,
  ['getRoomsIsLoading']: (state) => state.rooms?.isLoading,
  ['getRoomsTotal']: (state) => state.rooms?.total,
  ['getRoomsSearchParams']: (state) => state.rooms?.searchParams,
};
