<div>
  <div :id="expandableElementContainerName" class="clipped-text">
    <div :ref="divRefName"><slot></slot></div>
  </div>
  <div v-if="!lowContent">
    <v-btn
      v-if="mobile"
      variant="tonal"
      block
      flat
      max-height="25px"
      @click="toggle"
      ><v-icon v-if="!isOpened">mdi-chevron-down</v-icon
      ><v-icon v-else>mdi-chevron-up</v-icon></v-btn
    >
    <div v-else class="d-flex ml-auto justify-end">
      <v-btn variant="text" small color="primary" @click="toggle">
        {{ isOpened ? i18n.t('show_less') : i18n.t('show_more') }}
      </v-btn>
    </div>
  </div>
</div>
