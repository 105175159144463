import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Country, Value } from '../model/country';

export interface BackendCountry {
  getCountries: () => AxiosPromise<Country[]>;
}

export const defaultBackendCountry: BackendCountry = {
  getCountries(): AxiosPromise<Country[]> {
    return instance.get<Country[]>(`${URLS.country}/Get`);
  },
};
