import { ActionTree } from 'vuex';
import { RootState } from '../../types';
import { DefaultBackendPaymentData } from '@/shared/backend/paymentData';
import moduleUtils from '../moduleUtils';
import paymentMandate, {
  PaymentMandateData,
} from '@/shared/model/paymentMandateData';
import { AxiosResponse } from 'axios';
import { i18nGlobal } from '@/i18n';
import { BankDataState } from './types';
import { Logger } from 'fsts';

const logger = new Logger('actions.bankData');
export const actions: ActionTree<BankDataState, RootState> = {
  getPaymentMandate({ commit }, applicationUserId: string) {
    commit('setBankDataIsLoading', true);
    return DefaultBackendPaymentData.getPaymentMandate(applicationUserId)
      .then((resp: AxiosResponse<PaymentMandateData>) => {
        return paymentMandate.parse(resp.data);
      })
      .catch((e: any) => {
        return paymentMandate.parse();
      })
      .finally(() => commit('setBankDataIsLoading', false));
  },
  updatePaymentMandate({ commit }, paymentMandate: PaymentMandateData) {
    commit('setBankDataIsLoading', true);
    return DefaultBackendPaymentData.updatePaymentMandate(paymentMandate)
      .then(() => {
        moduleUtils.ok(i18nGlobal.t(`success.paymentmandate_update`), commit);
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => commit('setBankDataIsLoading', false));
  },
};
