import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/constants';
import roomPresenceFuncs, { RoomPresence, Value } from '../model/roomPresence';

const logger = new Logger('backend.RoomPresence');
export interface BackendRoomPresence {
  getRoomPresences: (
    searchParams: SearchParams,
    attendeeId?: string,
    date?: string,
    locationId?: string
  ) => AxiosPromise<Value>;
  updateRoomPresence(roomPresence: RoomPresence): AxiosPromise<any>;
}

export const defaultBackendRoomPresence: BackendRoomPresence = {
  getRoomPresences(
    searchParams: SearchParams,
    attendeeId?: string,
    date?: string,
    locationId?: string
  ): AxiosPromise<Value> {
    let odfb = ODataFilterBuilder('and');

    if (attendeeId) {
      odfb.eq(CONST.AttendeeId, attendeeId, false);
    }
    if (date) {
      odfb.eq(CONST.Date, date, false);
    }

    let baseUrl =
      locationId != undefined
        ? `${URLS.roomPresenceOdata}/GetByLocationId(locationId=${locationId})`
        : `${URLS.roomPresenceOdata}`;

    let url = DefaultBackendHelper.makeUrl(
      baseUrl,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );

    logger.log(`getRoomPresences: ${url}`);
    return instance.get<Value>(url);
  },
  updateRoomPresence(roomPresence: RoomPresence): AxiosPromise<any> {
    if (roomPresence.id) {
      return instance.put<any>(
        `${URLS.roomPresence}`,
        roomPresenceFuncs.toAPI(roomPresence)
      );
    } else {
      return instance.post<any>(
        `${URLS.roomPresence}`,
        roomPresenceFuncs.toAPI(roomPresence)
      );
    }
  },
};
