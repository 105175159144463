import { TariffState } from './types';

export const state = (): TariffState => initialState();

export const initialState = (): TariffState => ({
  tariffs: {
    items: [],
    isLoading: false,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['tariffName'],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: ['name'],
    },
  },
});
