<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  text: {
    default: '',
  },
  maxLength: {
    type: Number,
    default: 24,
  },
});

const needTooltip = computed(
  () => props.text.toString().length > props.maxLength
);
const computedText = computed(() =>
  needTooltip.value
    ? `${props.text.toString().slice(0, props.maxLength)}...`
    : props.text.toString()
);
</script>

<style lang="scss">
.pointerCursor {
  cursor: pointer;
}
</style>

<template>
  <div>
    <v-tooltip location="bottom" max-width="600" v-if="needTooltip">
      <template v-slot:activator="{ props }">
        <div v-bind="props" class="pointerCursor">
          {{ computedText }}
        </div>
      </template>
      <span>{{ text }}</span>
    </v-tooltip>

    <div v-else>
      {{ computedText }}
    </div>
  </div>
</template>
