import { GetterTree } from 'vuex';
import { AvailabilityState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<AvailabilityState, RootState> = {
  ['getAvailabilities']: (state) => state.availabilities,
  ['getAvailabilitiesIsLoading']: (state) => state.availabilities?.isLoading,
  ['getAvailabilitiesTotal']: (state) => state.availabilities?.total,
  ['getAvailabilitiesSearchParams']: (state) =>
    state.availabilities?.searchParams,
};
