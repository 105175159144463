import { ActionTree } from 'vuex';
import { StripeState } from './types';
import { RootState } from '../../types';
import { defaultBackendStripe } from '../../../backend/stripe';
import {i18nGlobal} from '../../../../i18n';
import moduleUtils from '../moduleUtils';
import store from '../..';

export const actions: ActionTree<StripeState, RootState> = {
  async existCustomer({ commit, dispatch }, payload: string) {
    try {
      const response = await defaultBackendStripe.existCustomer(payload);
      commit('setExistCustomer', response.data);
    } catch (e) {
      console.log(e);
      commit('setExistCustomer', {});
      commit(
        'setSnackbarError',
        {
          message: i18nGlobal.t(`error.load_data_failed`),
          duration: 5000,
        },
        { root: true }
      );
    }
  },

  async createCustomer(
    { commit, dispatch },
    payload: { email: string; fullName: string }
  ) {
    try {
      const response = await defaultBackendStripe.createCustomer(
        payload.email,
        payload.fullName
      );
    } catch (e) {
      console.log(e);
      commit(
        'setSnackbarError',
        {
          message: i18nGlobal.t(`error.load_data_failed`),
          duration: 5000,
        },
        { root: true }
      );
    }
  },

  async getPlans({ commit, dispatch }, payload: string) {
    try {
      const response = await defaultBackendStripe.getPlans(payload);
      commit('setPlans', response.data);
    } catch (e) {
      console.log(e);
      commit('setPlans', []);
      commit(
        'setSnackbarError',
        {
          message: i18nGlobal.t(`error.load_data_failed`),
          duration: 5000,
        },
        { root: true }
      );
    }
  },

  async updateOptions({ commit, dispatch }, payload?: any) {
    try {
      commit('updateOptions', payload);
    } catch (e) {
      console.log(e);
    }
  },

  async getCards({ commit, dispatch }, payload?: any) {
    try {
      const response = await defaultBackendStripe.getCards(payload);
      commit('setCards', response.data);
    } catch (e) {
      console.log(e);
      commit('setCards', []);
      commit(
        'setSnackbarError',
        {
          message: i18nGlobal.t(`error.load_data_failed`),
          duration: 5000,
        },
        { root: true }
      );
    }
  },

  async getCard(
    { commit, dispatch },
    payload: { customerId: string; cardId: string }
  ) {
    try {
      const response = await defaultBackendStripe.getCard(
        payload.customerId,
        payload.cardId
      );
      commit('setCard', response.data);
    } catch (e) {
      console.log(e);
      commit('setCard', {});
      commit(
        'setSnackbarError',
        {
          message: i18nGlobal.t(`error.load_data_failed`),
          duration: 5000,
        },
        { root: true }
      );
    }
  },

  async setCardUsingToken(
    { commit, dispatch },
    payload: { customerId: string; tokenId: string|undefined }
  ) {
    if (!payload.tokenId) {
      commit(
        'setSnackbarError',
        {
          message: i18nGlobal.t(`error.load_data_failed`),
          duration: 5000,
        },
        { root: true }
      );
      return;
    }

    try {
      const response = await defaultBackendStripe.setCardUsingToken(
        payload.customerId,
        payload.tokenId!
      );
    } catch (e) {
      console.log(e);

      commit(
        'setSnackbarError',
        {
          message: i18nGlobal.t(`error.load_data_failed`),
          duration: 5000,
        },
        { root: true }
      );
    }
  },

  async getSubscriptionDetails({ commit, dispatch }, payload: string) {
    try {
      commit('setIsLoadingSubscriptionDetails', true);
      const response = await defaultBackendStripe.getSubscriptionDetails(
        payload
      );
      commit('setSubscription', response.data);
    } catch (e) {
      console.log(e);
      commit('setIsLoadingSubscriptionDetails', false);
      commit('setSubscription', {});
      commit(
        'setSnackbarError',
        {
          message: i18nGlobal.t(`error.load_data_failed`),
          duration: 5000,
        },
        { root: true }
      );
    }
  },

  async getCustomer({ commit, dispatch }, payload: string) {
    try {
      const response = await defaultBackendStripe.getCustomer(payload);
      commit('setCustomer', response.data);
    } catch (e) {
      console.log(e);
      commit('setCustomer', {});
      commit(
        'setSnackbarError',
        {
          message: i18nGlobal.t(`error.load_data_failed`),
          duration: 5000,
        },
        { root: true }
      );
    }
  },

  async getSubscriptionItem({ commit, state }, payload: string) {
    try {
      const response = await defaultBackendStripe.getSubscriptionItem(payload);
      commit('setSubscriptionItem', response.data);
      store.commit(
        'companyModule/setMaxAttendees',
        state.subscriptionItem.plan.tiers
          ? state.subscriptionItem.quantity
          : undefined // undefined = unlimited
      );
    } catch (e) {
      console.log(e);
      commit('setSubscriptionItem', {});
      commit(
        'setSnackbarError',
        {
          message: i18nGlobal.t(`error.load_data_failed`),
          duration: 5000,
        },
        { root: true }
      );
    }
  },

  async subscribe(
    { commit, dispatch },
    payload: { customerId: string; planId: string; totalUsers: number }
  ) {
    try {
      const response = await defaultBackendStripe.subscribe(
        payload.customerId,
        payload.planId,
        payload.totalUsers
      );
    } catch (e) {
      console.log(e);
      commit(
        'setSnackbarError',
        {
          message: i18nGlobal.t(`error.load_data_failed`),
          duration: 5000,
        },
        { root: true }
      );
    }
  },

  async cancelSubscriptions(
    { commit, dispatch },
    payload: { customerId: string; subscriptionId: string }
  ) {
    try {
      const response = await defaultBackendStripe.cancelSubscriptions(
        payload.customerId,
        payload.subscriptionId
      );
      commit('reset');
    } catch (e) {
      console.log(e);
      commit(
        'setSnackbarError',
        {
          message: i18nGlobal.t(`error.load_data_failed`),
          duration: 5000,
        },
        { root: true }
      );
    }
  },

  async deleteSubscriptionItem(
    { commit, dispatch },
    payload: { subscriptionId: string; subscriptionItemId: string }
  ) {
    try {
      const response = await defaultBackendStripe.deleteSubscriptionItem(
        payload.subscriptionId,
        payload.subscriptionItemId
      );
    } catch (e) {
      console.log(e);
      commit(
        'setSnackbarError',
        {
          message: i18nGlobal.t(`error.load_data_failed`),
          duration: 5000,
        },
        { root: true }
      );
    }
  },

  async updateSubscriptionItemQuantity(
    { commit, dispatch },
    payload: { subscriptionItemId: string; newQuantity: number }
  ) {
    try {
      commit('setIsLoadingSubscriptionDetails', true);
      const response = await defaultBackendStripe.updateSubscriptionItemQuantity(
        payload.subscriptionItemId,
        payload.newQuantity
      );
      commit('setIsLoadingSubscriptionDetails', false);
    } catch (e) {
      console.log(e);
      commit('setIsLoadingSubscriptionDetails', false);
      commit(
        'setSnackbarError',
        {
          message: i18nGlobal.t(`error.load_data_failed`),
          duration: 5000,
        },
        { root: true }
      );
    }
  },

  async getStripePublicKey({ commit, dispatch }) {
    try {
      const response = await defaultBackendStripe.getStripePublicKey();
      commit('setStripePublicKey', response.data);
    } catch (e) {
      console.log(e);
      commit('setStripePublicKey', {});
      commit(
        'setSnackbarError',
        {
          message: i18nGlobal.t(`error.load_data_failed`),
          duration: 5000,
        },
        { root: true }
      );
    }
  },

  async getUpcomingInvoice({ commit, dispatch }, payload: string) {
    try {
      commit('setIsLoadingSubscriptionDetails', true);
      const response = await defaultBackendStripe.getUpcomingInvoice(payload);
      commit('setUpcomingInvoice', response.data);
    } catch (e) {
      console.log(e);
      commit('setIsLoadingSubscriptionDetails', false);
      commit('setUpcomingInvoice', {});
      commit(
        'setSnackbarError',
        {
          message: i18nGlobal.t(`error.load_data_failed`),
          duration: 5000,
        },
        { root: true }
      );
    }
  },

  async getInvoices({ commit, dispatch }, payload?: any) {
    try {
      const response = await defaultBackendStripe.getInvoices(payload);
      commit('setInvoices', response.data);
    } catch (e) {
      console.log(e);
      commit('setInvoices', []);
      commit(
        'setSnackbarError',
        {
          message: i18nGlobal.t(`error.load_data_failed`),
          duration: 5000,
        },
        { root: true }
      );
    }
  },
};
