import { MutationTree } from 'vuex';
import { ContactState } from './types';
import contact, { Value } from '@/shared/model/contact';

export const mutations: MutationTree<ContactState> = {
  setContacts(state, payload: Value) {
    state.contacts.items = payload.value.map(x => contact.parse(x));
    state.contacts.total = payload['@odata.count'] || 0;
    state.contacts.isLoading = false;
  },
  clearContacts(state) {
    state.contacts.items = [];
    state.contacts.total = 0;
    state.contacts.isLoading = false;
  },
  setContactsTotal(state, payload: number) {
    state.contacts.total = payload;
  },
  setContactsIsLoading(state, payload: boolean) {
    state.contacts.isLoading = payload;
  },
};
