import { ActionTree } from 'vuex';
import { ContactState } from './types';
import { RootState } from '../../types';
import { defaultBackendContact } from '@/shared/backend/contact';
import { Logger } from 'fsts';
import moduleUtils from '../moduleUtils';
import { Value, Contact } from '@/shared/model/contact';
import { AxiosResponse } from 'axios';
import { SearchParams } from '@/shared/model/searchParams';

const logger = new Logger('actions.contacts');
export const actions: ActionTree<ContactState, RootState> = {
  getContacts(
    { commit, getters },
    payload: { searchParams?: SearchParams; attendeeId: string }
  ) {
    let searchParams = payload.searchParams ?? getters.getContactsSearchParams;
    commit('setContactsIsLoading', true);
    return defaultBackendContact
      .getContacts(searchParams, payload.attendeeId)
      .then((response: AxiosResponse<Value>) => {
        commit('setContacts', response.data);
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => commit('setContactsIsLoading', false));
  },
  postMultipleContacts(
    { commit },
    payload: { attendeeId: string; contacts: Contact[] }
  ) {
    return defaultBackendContact
      .postMultipleContacts(payload.attendeeId, payload.contacts)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
          }>
        ) => {
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
};
