import { ActionTree } from 'vuex';
import { AttendeeCourseState } from './types';
import { RootState } from '../../types';
import { defaultBackendAttendeeCourse } from '@/shared/backend/attendeeCourse';
import { Logger } from 'fsts';
import moduleUtils from '../moduleUtils';
import { Value, AttendeeCourse } from '@/shared/model/attendeeCourse';
import { AxiosResponse } from 'axios';
import { i18nGlobal } from '@/i18n';
import { CONST } from '@/shared/utils/constants';

const logger = new Logger('actions.attendeeCourses');
export const actions: ActionTree<AttendeeCourseState, RootState> = {
  getAttendeeCourses(
    { commit, dispatch, getters, rootGetters },
    payload?: { searchParams?: any; attendeeId?: string }
  ) {
    commit('setAttendeeCoursesIsLoading', true);
    let searchParams =
      payload?.searchParams ?? getters.getAttendeeCoursesSearchParams;
    let attendeeId = payload?.attendeeId ?? CONST.emptyGuid;
    return defaultBackendAttendeeCourse
      .getAttendeeCourses(searchParams, attendeeId)
      .then((response: AxiosResponse<Value>) => {
        commit('setAttendeeCourses', response.data);
        commit('setAttendeeCoursesIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setAttendeeCoursesIsLoading', false);
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  getAttendeeCourse({ commit, dispatch }, id: string) {
    return defaultBackendAttendeeCourse
      .getAttendeeCourse(id)
      .then((response: AxiosResponse<AttendeeCourse>) => {
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateAttendeeCourse({ commit, dispatch }, file: AttendeeCourse) {
    return defaultBackendAttendeeCourse
      .updateAttendeeCourse(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: AttendeeCourse;
          }>
        ) => {
          moduleUtils.ok(i18nGlobal.t(`success.attendeeCourse_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateAttendeeCourseMultiple({ commit, dispatch }, items: AttendeeCourse[]) {
    return defaultBackendAttendeeCourse
      .updateAttendeeCourseMultiple(items)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: AttendeeCourse;
          }>
        ) => {
          moduleUtils.ok(i18nGlobal.t(`success.attendeeCourse_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteAttendeeCourse({ commit, dispatch }, id: string) {
    return defaultBackendAttendeeCourse
      .deleteAttendeeCourse(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: AttendeeCourse;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
};
