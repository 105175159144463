export interface LocationResponsible {
  id?: string; // System.Guid
  locationId: string; // System.Guid
  responsibleId: string; // System.Guid
  companyId?: string; // System.Guid
}

export interface Value {
  value: LocationResponsible[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

function parse(data?: Partial<LocationResponsible>): LocationResponsible {
  return {
    id: data?.id || undefined,
    locationId: data?.locationId || '',
    responsibleId: data?.responsibleId || '',
    companyId: data?.companyId || undefined,
  };
}

export default {
  parse,
};
