import axios from 'axios';
import router from '../../router/router';
import store from '../store';
import LSService from './localStorageService';

const lsService = LSService.getService();

export const instance = axios.create({});

// Add a request interceptor
instance.interceptors.request.use(
  (config) => {
    const token = lsService.getAccessToken();
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    store.dispatch('auth/logout');
    router.push({ name: 'login', params: { checkLogin: 'false' } });
  }
);
/**
 * Global error handler.
 */
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const originalRequest = error.config;
    const refreshToken = lsService.getRefreshToken();
    if (
      error.response.status === 401 &&
      !originalRequest._retry &&
      refreshToken
    ) {
      originalRequest._retry = true;

      const token = lsService.getAccessToken();
      return axios
        .post(
          '/api/users/refreshtoken',
          {
            refreshToken: refreshToken,
            accessToken: token,
          },
          {
            headers: {
              'Content-Type': 'application/json', // TODO: test if need this header (backend was set up to use JSON) + the same for `checkPasswordReset` action
            },
          }
        )
        .then((res) => {
          if (res.status === 201 || res.status == 200) {
            lsService.setToken(res.data);
            axios.defaults.headers.common['Authorization'] =
              'Bearer ' + lsService.getAccessToken();
            return axios(originalRequest);
          }

          return Promise.reject(error);
        });
    }
    throw error;
  }
);

export enum URLS {
  auditsOData = '/odata/audits',
  audits = '/api/audits',

  employeesDtoOData = '/odata/ApplicationUsersDto',
  employees = '/api/ApplicationUsers',

  files = '/api/DatabaseFile',
  csv = '/api/Csv',

  customersOData = '/odata/Customers',
  customers = '/api/Customers',

  editCompanyOData = '/odata/Company',
  editCompany = '/api/Company',

  countryOData = '/odata/CountryDto',
  country = '/api/Country',

  authenticate = '/api/Users/login2',

  account = '/api/Users',

  notificationOData = '/odata/Notifications',
  notifications = '/api/Notifications',

  stripe = '/api/Stripe',

  documentsOdata = '/odata/documentsOdata',
  documents = '/api/documents',
  documentCategoriesOdata = '/odata/documentCategoriesOdata',

  skillOdata = '/odata/skills',
  skill = '/api/skills',

  dateTimeOdata = '/odata/DateTimes',
  dateTime = '/api/DateTimes',

  locationOdata = '/odata/Locations',
  location = '/api/Locations',

  qrCodeOdata = '/odata/qrCodes',
  qrCode = '/api/qrCodes',

  roomOdata = '/odata/Rooms',
  room = '/api/Rooms',

  roomAvailabilityOdata = '/odata/RoomAvailabilities',
  roomAvailability = '/api/RoomAvailabilities',

  referenceParentOdata = '/odata/ReferenceParents',
  referenceParent = '/api/ReferenceParents',

  employeeOdata = '/odata/Employees',
  employee = '/api/Employees',

  courseOdata = '/odata/Courses',
  course = '/api/Courses',

  locationEmployeeOdata = '/odata/LocationEmployees',
  locationEmployee = '/api/LocationEmployees',

  locationAttendeeOdata = '/odata/LocationAttendees',
  locationAttendee = '/api/LocationAttendees',

  attendancesOdata = '/odata/Attendances',
  attendances = '/api/Attendances',

  trainerOdata = '/odata/Trainers',
  trainer = '/api/Trainers',

  courseTrainerOdata = '/odata/CourseTrainers',
  coursesWithDateTime = '/odata/CoursesWithDateTime',
  courseTrainer = '/api/CourseTrainers',

  attendeeOdata = '/odata/Attendees',
  attendee = '/api/Attendees',

  noteOdata = '/odata/Notes',
  note = '/api/Notes',

  attendeeCourseTrainerOdata = '/odata/AttendeeCourseTrainers',
  attendeeCourseTrainer = '/api/AttendeeCourseTrainers',

  attendeeRegisterByQrCodeOdata = '/odata/AttendeeRegisterByQrCode',
  attendeeRegisterByQrCode = '/api/AttendeeRegisterByQrCode',

  attendeeRegisterFull = '/api/AttendeeRegisterFull',

  availabilityOdata = '/odata/Availabilities',
  availability = '/api/Availabilities',

  newsOdata = '/odata/news',
  news = '/api/news',

  newsRefOdata = '/odata/NewsRefs',
  newsRef = '/api/NewsRefs',

  checkInOdata = '/odata/checkIn',
  checkIn = '/api/checkIn',

  referenceOdata = '/odata/references',
  reference = '/api/references',

  appointmentOdata = '/odata/Appointments',
  appointment = '/api/Appointments',

  appointmentTrainerOdata = '/odata/AppointmentTrainers',
  appointmentTrainer = '/api/AppointmentTrainers',

  attendeeCourseOdata = '/odata/AttendeeCourses',
  attendeeCourse = '/api/AttendeeCourses',

  departmentOdata = '/odata/Departments',
  department = '/api/Departments',

  departmentRefOdata = '/odata/DepartmentRefs',
  departmentRef = '/api/DepartmentRefs',

  calendar = '/api/Calendar',
  calendarOdata = '/odata/Calendar',

  tariffRefOdata = '/odata/tariffRefs',
  tariffRef = '/api/tariffRefs',

  tariffOdata = '/odata/tariffs',
  tariff = '/api/tariffs',

  settingsOdata = '/odata/settingss',
  settings = '/api/settingss',

  reports = '/api/reports',

  statistics = '/api/Statistics',
  statisticsOdata = '/odata/Statistics',

  invoiceOdata = '/odata/invoices',
  invoice = '/api/invoices',

  invoicePositionOdata = '/odata/invoicePositions',
  invoicePosition = '/api/invoicePositions',

  presenceOdata = '/odata/presences',
  presence = '/api/presences',

  presenceAttendancesOdata = '/odata/presenceAttendances',
  presenceAttendances = '/api/presenceAttendances',

  membershipTypeCostOverridesOdata = '/odata/MembershipTypeCostOverrides',
  membershipTypeCostOverrides = '/api/MembershipTypeCostOverrides',

  contactsOdata = '/odata/Contact',
  contacts = '/api/Contact',

  taskRequestOdata = '/odata/TaskRequests',
  taskRequestsAttendancesOdata = '/odata/TaskRequestsAttendances',

  taskRequest = '/api/TaskRequests',

  paymentData = '/api/PaymentData',

  roomPresenceOdata = '/odata/RoomPresences',
  roomPresence = '/api/RoomPresences',

  webPushOdata = '/odata/WebPush',
  webPush = '/api/WebPush',

  appointmentMessage = '/api/AppointmentMessages',

  membershipRequestOdata = '/odata/MembershipRequests',
  membershipRequest = '/api/MembershipRequests',

  costDiscountOdata = '/odata/CostDiscounts',
  costDiscount = '/api/CostDiscounts',

  departmentResponsibleOdata = '/odata/DepartmentResponsibles',
  departmentResponsible = '/api/DepartmentResponsibles',

  locationResponsibleOdata = '/odata/LocationResponsibles',
  locationResponsible = '/api/LocationResponsibles',
}

export enum URL_PARAMS {
  orderby = '$orderby',
  top = '$top',
  skip = '$skip',
  count = '$count',

  nameContains = '$filter',
  filterClause = '$filter',
}
