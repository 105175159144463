import { GetterTree } from 'vuex';
import { DepartmentState } from './types';
import { RootState } from '../../types';
import { isEmptyId } from '@/shared/utils/generalUtils';

export const getters: GetterTree<DepartmentState, RootState> = {
  ['getDepartments']: state => state.departments,
  ['getDepartmentsIsLoading']: state => state.departments?.isLoading,
  ['getDepartmentsTotal']: state => state.departments?.total,
  ['getDepartmentsSearchParams']: state => state.departments?.searchParams,
  ['getOwnUserDepartments']: state => state.ownUserDepartments,
  ['getSelectedOwnUserDepartment']: state =>
    state.ownUserDepartments[state.selectedOwnUserDepartmentIndex],
  ['getSelectedOwnUserDepartmentId']: state => {
    if (state.ownUserDepartments.length == 0) {
      return undefined;
    }
    if (state.selectedOwnUserDepartmentIndex == -1) {
      return undefined;
    }
    if (
      isEmptyId(
        state.ownUserDepartments[state.selectedOwnUserDepartmentIndex].id
      )
    ) {
      return undefined;
    }
    return state.ownUserDepartments[state.selectedOwnUserDepartmentIndex].id;
  },
  ['isOwnUserDepartmentsLoaded']: state => state.ownUserDepartmentsLoaded,
};
