import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import company, { Company, Value } from '../model/company';

export interface BackendCompany {
  getCompany: () => AxiosPromise<Company>;
  getTotalAttendees: () => AxiosPromise<number>;
  getCompanyCountry: () => AxiosPromise<string>;
  updateCompany: (company: Company) => AxiosPromise<any>;
  deleteCompany(companyId: number): AxiosPromise;
  getAllCompanies(): AxiosPromise<Company[]>;
  getCompanyById(companyId: string): AxiosPromise<Company>;
  getCompanyNameById(companyId: string): AxiosPromise<string>;
}

export const defaultBackendCompany: BackendCompany = {
  getCompany(): AxiosPromise<Company> {
    return instance.get<Company>(`${URLS.editCompany}/Get/`);
  },
  getCompanyById(companyId: string): AxiosPromise<Company> {
    return instance.get<Company>(`${URLS.editCompany}/Get/${companyId}`);
  },
  getCompanyNameById(companyId: string): AxiosPromise<string> {
    return instance.get<string>(`${URLS.editCompany}/GetNamebyId/${companyId}`);
  },
  getTotalAttendees(): AxiosPromise<number> {
    return instance.get<number>(`${URLS.editCompany}/GetTotalAttendees`);
  },
  getCompanyCountry(): AxiosPromise<string> {
    return instance.get<string>(`${URLS.editCompany}/GetCompanyCountry/`);
  },
  updateCompany(Company: Company): AxiosPromise<any> {
    return instance.post<Value>(`${URLS.editCompany}`, company.toAPI(Company));
  },

  deleteCompany(companyId: number): AxiosPromise {
    return instance.delete(`${URLS.editCompany}/${companyId}`);
  },
  getAllCompanies(): AxiosPromise<Company[]> {
    return instance.get<Company[]>(`${URLS.editCompany}/GetAll`);
  },
};
