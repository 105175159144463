import { Company } from '@/shared/model/company';
import { Component, Vue, toNative, Setup, Prop } from 'vue-facing-decorator';
import { useI18n } from 'vue-i18n';
import { namespace } from 'vuex-facing-decorator';

const name = 'trial-expiry-information-dialog';
const companyModule = namespace('companyModule');
const authModule = namespace('auth');
const locales = require('./locales.json');

@Component({ name: name })
class TrialExpiryInformationDialog extends Vue {
  @Setup((props, ctx) => useI18n({ messages: locales }))
  private i18n!: any;

  @companyModule.Getter('getCompany')
  private company: Company|undefined;

  @authModule.Getter('isLoggedIn')
  private isLoggedIn!: boolean;
  @authModule.State('isAdmin')
  private isAdmin!: boolean;
  @authModule.State('isManager')
  private isManager!: boolean;
  @authModule.Getter('isTrialBeforeEnd')
  private isTrialBeforeEnd!: boolean;
  @authModule.Getter('isTrialExpired')
  private isTrialExpired!: boolean;
  @authModule.Getter('trialDaysLeft')
  private trialDaysLeft!: number;

  private closed: boolean = false;

  private onClose() {
    this.closed = true;
  }

  private get show(): boolean {
    return this.isLoggedIn &&
      (this.isAdmin || this.isManager || this.isTrialExpired) &&
      this.isTrialBeforeEnd &&
      !this.closed;
  }
}

export default toNative(TrialExpiryInformationDialog);