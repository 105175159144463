import { GetterTree } from 'vuex';
import { MembershipTypeCostOverrideState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<MembershipTypeCostOverrideState, RootState> = {
  ['getCostOverrides']: state => state.costOverrides,
  ['getCostOverridesIsLoading']: state => state.costOverrides?.isLoading,
  ['getCostOverridesTotal']: state => state.costOverrides?.total,
  ['getCostOverridesSearchParams']: state => state.costOverrides?.searchParams,
};
