import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { DefaultBackendHelper } from '../utils/backendHelper';
import { Value } from '../model/locationAttendee';
import { isEmptyId } from '../utils/generalUtils';
import { SearchParams } from '../model/searchParams';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/constants';

const logger = new Logger('backend.LocationAttendee');
export interface BackendLocationAttendee {
  getLocationAttendees: (
    searchParams: SearchParams,
    attendeeId?: string
  ) => AxiosPromise<Value>;
  postMultipleLocationAttendees(
    locationIds: string[],
    attendeeId: string
  ): AxiosPromise<any>;
}

export const defaultBackendLocationAttendee: BackendLocationAttendee = {
  getLocationAttendees: (
    searchParams: SearchParams,
    attendeeId?: string
  ): AxiosPromise<Value> => {
    var odfb = ODataFilterBuilder('and');

    if (!isEmptyId(attendeeId)) {
      odfb.eq(CONST.AttendeeId, attendeeId, false);
    }

    let url = DefaultBackendHelper.makeUrl(
      URLS.locationAttendeeOdata,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );

    logger.log(`getDepartmentRefs${url}`);
    return instance.get<Value>(url);
  },
  postMultipleLocationAttendees(
    locationIds: string[],
    attendeeId: string
  ): AxiosPromise<any> {
    logger.debug('postMultipleLocationAttendees');
    return instance.post(`${URLS.locationAttendee}/AddMultiple`, {
      LocationIds: locationIds,
      AttendeeId: attendeeId,
    });
  },
};
