
import { GetterTree } from 'vuex';
import { TrainerState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<TrainerState, RootState> = {
  ['getTrainers']: (state) => state.trainers,
  ['getTrainersIsLoading']: (state) => state.trainers?.isLoading,
  ['getTrainersTotal']: (state) => state.trainers?.total,
  ['getTrainersSearchParams']: (state) => state.trainers?.searchParams,
};
