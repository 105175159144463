import { StripeState } from './types';

export const state = (): StripeState => initialState();

export const initialState = (): StripeState => ({
  customerId: '',
  default_source: '',
  customer: { id: '', name: '', default_source: '' },
  planId: '',
  existCustomer: false,
  hasSubscription: false,
  subscription: {
    id: '',
    customerId: '',
    current_period_end: 0,
    collection_method: '',
  },
  subscriptionItem: {
    id: '',
    plan: {
      id: '',
      productId: '',
      nickname: '',
      currency: '',
      billingScheme: '',
      trial_period_days: 0,
      amount: 0,
      features: [],
      metadata: null,
      tiers: [],
      interval: 'month',
    },
    subscription: '',
    quantity: 0,
  },
  plan: {
    id: '',
    productId: '',
    nickname: '',
    currency: '',
    billingScheme: '',
    trial_period_days: 0,
    amount: 0,
    features: [],
    metadata: null,
    tiers: [],
    interval: 'month',
  },
  plans: [],
  card: {
    expYear: 0,
    expMonth: 0,
    description: '',
    currency: '',
    last4: '',
    brand: '',
  },
  cards: [],
  stripePublicKey: '',
  upcomingInvoice: { id: '', customerId: '', total: 0, status: '', number: '', billing_reason: '' },
  invoices: [],
  isLoadingSubscriptionDetails: false,
});
