import { MutationTree } from 'vuex';
import { PresenceState } from './types';
import presence, { Value } from '@/shared/model/presence';

export const mutations: MutationTree<PresenceState> = {
  setPresences(state, payload: Value) {
    state.presences.items = payload.value.map((x) => presence.parse(x));
    state.presences.total = payload['@odata.count'] || 0;
    state.presences.isLoading = false;
  },
  setPresencesTotal(state, payload: number) {
    state.presences.total = payload;
  },
  setPresencesIsLoading(state, payload: boolean) {
    state.presences.isLoading = payload;
  },
  resetPresencesPaging(state) {
    state.presences.searchParams.dataOption.page = 1;
    state.presences.searchParams.dataOption.itemsPerPage = 10;
    state.presences.searchParams.dataOption.filter = '';
    state.presences.searchParams.filter = '';
  },
};
