import { ActionTree } from 'vuex';
import { CourseTrainerState } from './types';
import { RootState } from '../../types';
import { defaultBackendCourseTrainer } from '@/shared/backend/courseTrainer';
import { Logger } from 'fsts';
import moduleUtils from '../moduleUtils';
import { Value, CourseTrainer } from '@/shared/model/courseTrainer';
import { AxiosResponse } from 'axios';
import { CONST } from '../../../utils/constants';
import { SearchParams } from '@/shared/model/searchParams';

const logger = new Logger('actions.courseTrainers');
export const actions: ActionTree<CourseTrainerState, RootState> = {
  getCourseTrainers(
    { commit, dispatch, getters, rootGetters },
    payload?: {
      searchParams?: any;
      trainerId?: string;
      dateTimeId?: string;
      noStore?: boolean;
    }
  ) {
    commit('setCourseTrainersIsLoading', true);
    let searchParams =
      payload?.searchParams ?? getters.getCourseTrainersSearchParams;
    let trainerId = payload?.trainerId ?? CONST.emptyGuid;
    let dateTimeId = payload?.dateTimeId ?? CONST.emptyGuid;

    return defaultBackendCourseTrainer
      .getCourseTrainers(searchParams, trainerId, dateTimeId)
      .then((response: AxiosResponse<Value>) => {
        if (payload?.noStore) {
          commit('setCourseTrainersIsLoading', false);
          return response.data;
        }
        commit('setCourseTrainers', response.data);
        commit('setCourseTrainersIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setCourseTrainersIsLoading', false);
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  getCoursesWithDateTime(
    { commit, dispatch, getters, rootGetters },
    payload?: {
      searchParams?: any;
      bookableAsSeries?: boolean;
      afterDate?: string;
    }
  ) {
    commit('setCourseTrainersWithDateTimeIsLoading', true);
    let searchParams =
      payload?.searchParams ?? getters.getCourseTrainersSearchParams;
    let bookableAsSeries = payload?.bookableAsSeries;
    let afterDate = payload?.afterDate;

    return defaultBackendCourseTrainer
      .getCoursesWithDateTime(searchParams, bookableAsSeries, afterDate)
      .then((response: AxiosResponse<Value>) => {
        commit('setCourseTrainersWithDateTime', response.data);
        commit('setCourseTrainersWithDateTimeIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setCourseTrainersWithDateTimeIsLoading', false);
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  getCourseTrainer({ commit, dispatch }, id: string) {
    return defaultBackendCourseTrainer
      .getCourseTrainer(id)
      .then((response: AxiosResponse<CourseTrainer>) => {
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateCourseTrainer({ commit, dispatch }, courseTrainers: CourseTrainer[]) {
    return defaultBackendCourseTrainer
      .updateCourseTrainer(courseTrainers)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: CourseTrainer;
          }>
        ) => {
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateCourseTrainerForTrainer(
    { commit, dispatch },
    courseTrainers: CourseTrainer[]
  ) {
    return defaultBackendCourseTrainer
      .updateCourseTrainerForTrainer(courseTrainers)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: CourseTrainer;
          }>
        ) => {
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteCourseTrainer({ commit, dispatch }, id: string) {
    return defaultBackendCourseTrainer
      .deleteCourseTrainer(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: CourseTrainer;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateSearchParams({ commit }, payload: SearchParams) {
    commit('setSearchParams', payload);
  },
  resetCourseTrainers({ commit }) {
    commit('setCourseTrainers', { value: [] });
  },
};
