import { GetterTree } from 'vuex';
import { DepartmentResponsibleState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<DepartmentResponsibleState, RootState> = {
  ['getDepartmentResponsibles']: state => state.departmentResponsibles,
  ['getDepartmentResponsiblesIsLoading']: state =>
    state.departmentResponsibles?.isLoading,
  ['getDepartmentResponsiblesTotal']: state => state.departmentResponsibles?.total,
  ['getDepartmentResponsiblesSearchParams']: state =>
    state.departmentResponsibles?.searchParams,
};
