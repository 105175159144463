import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import availability, { Availability, Value } from '../model/availability';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/constants';

const logger = new Logger('backend.Availability');
export interface BackendAvailability {
  getAvailability(id: string): AxiosPromise<Availability>;
  getAvailabilities: (
    searchParams: SearchParams,
    trainerId: string
  ) => AxiosPromise<Value>;
  deleteAvailability(id: string): AxiosPromise;
  updateAvailability(Availability: Availability): AxiosPromise<any>;
}

export const defaultBackendAvailability: BackendAvailability = {
  getAvailability(id: string): AxiosPromise<Availability> {
    let url = `${URLS.availabilityOdata}/${id}`;
    return instance.get<Availability>(url);
  },
  getAvailabilities(
    searchParams: SearchParams,
    trainerId: string
  ): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.TrainerId, trainerId, false);
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.availabilityOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getAvailabilities${url}`);
    return instance.get<Value>(url);
  },

  deleteAvailability(id: string): AxiosPromise {
    logger.debug('deleteAvailability');
    return instance.delete(`${URLS.availability}/${id}`);
  },
  updateAvailability(Availability: Availability): AxiosPromise<any> {
    logger.debug('updateAvailability');
    if (Availability.id != '') {
      return instance.put<Availability>(
        `${URLS.availability}`,
        availability.toAPI(Availability)
      );
    } else {
      return instance.post<Availability>(
        `${URLS.availability}`,
        availability.toAPI(Availability)
      );
    }
  },
};
