import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import skill, { Skill, Value } from '../model/skill';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '../utils/constants';

const logger = new Logger('backend.Skill');
export interface BackendSkill {
  getSkill(id: string): AxiosPromise<Skill>;
  getSkills: (
    searchParams: SearchParams,
    onlyActive?: boolean,
  ) => AxiosPromise<Value>;
  getAllSkills(): AxiosPromise<Value>;
  deleteSkill(id: string): AxiosPromise;
  updateSkill(Skill: Skill): AxiosPromise<any>;
}

export const defaultBackendSkill: BackendSkill = {
  getSkill(id: string): AxiosPromise<Skill> {
    let url = `${URLS.skillOdata}/${id}`;
    return instance.get<Skill>(url);
  },
  getSkills(searchParams: SearchParams, onlyActive?: boolean): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');

    if (onlyActive != undefined) {
      odfb.eq(CONST.Active, true, false);
    }

    let url = DefaultBackendHelper.makeUrl(
      `${URLS.skillOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getSkills${url}`);
    return instance.get<Value>(url);
  },
  getAllSkills(): AxiosPromise<Value> {
    let url = DefaultBackendHelper.makeUrl(`${URLS.skill}/GetAll`);
    logger.log(`getAllSkills${url}`);
    return instance.get<Value>(url);
  },

  deleteSkill(id: string): AxiosPromise {
    logger.debug('deleteSkill');
    return instance.delete(`${URLS.skill}/${id}`);
  },
  updateSkill(Skill: Skill): AxiosPromise<any> {
    logger.debug('updateSkill');
    return instance.put<Skill>(`${URLS.skill}/update`, skill.toAPI(Skill));
  },
};
