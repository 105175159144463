
import { GetterTree } from 'vuex';
import { PresenceState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<PresenceState, RootState> = {
  ['getPresences']: (state) => state.presences,
  ['getPresencesIsLoading']: (state) => state.presences?.isLoading,
  ['getPresencesTotal']: (state) => state.presences?.total,
  ['getPresencesSearchParams']: (state) => state.presences?.searchParams,
};
