import { ActionTree } from 'vuex';
import { CountryManagementState } from './types';
import { RootState } from '../../types';
import { initialState } from './state';
import { i18nGlobal } from '../../../../i18n';
import { defaultBackendCountry } from '../../../backend/country';

export const actions: ActionTree<CountryManagementState, RootState> = {
  getCountries({ commit }) {
    return defaultBackendCountry
      .getCountries()
      .then((response) => {
        commit('setCountries', response.data);
        return response.data;
      })
      .catch((e) => {
        console.log(e);
        commit('setCountries', initialState().countries);
        commit(
          'setSnackbarError',
          {
            message: i18nGlobal.t(`error.load_data_failed`),
            duration: 5000,
          },
          { root: true }
        );
      });
  },
};
