import { MutationTree } from 'vuex';
import { ReferenceState } from './types';
import reference, { Reference, Value } from '@/shared/model/reference';

export const mutations: MutationTree<ReferenceState> = {
  setReferences(state, payload: Value) {
    state.references.items = payload.value.map((x) => reference.parse(x));
    state.references.total = payload['@odata.count'] || 0;
    state.references.isLoading = false;
  },
  setAllReferences(state, payload: Reference[]) {
    state.references.items = payload;
    state.references.total = payload.length;
    state.references.isLoading = false;
  },
  setReferencesTotal(state, payload: number) {
    state.references.total = payload;
  },
  setReferencesIsLoading(state, payload: boolean) {
    state.references.isLoading = payload;
  },
  setPage(state, payload: number) {
    state.references.searchParams.dataOption.page = payload;
  },
  resetReferencesPaging(state) {
    state.references.searchParams.dataOption.page = 1;
    state.references.searchParams.dataOption.itemsPerPage = 10;
    state.references.searchParams.dataOption.filter = '';
    state.references.searchParams.filter = '';
  },
};
