import { MutationTree } from 'vuex';
import { SkillState } from './types';
import skill, { Skill, Value } from '@/shared/model/skill';

export const mutations: MutationTree<SkillState> = {
  setSkills(state, payload: Value) {
    state.skills.items = payload.value.map((x) => skill.parse(x));
    state.skills.total = payload['@odata.count'] || 0;
    state.skills.isLoading = false;
  },
  setAllSkills(state, payload: Skill[]) {
    state.skills.items = payload;
    state.skills.total = payload.length;
    state.skills.isLoading = false;
  },
  setSkillsTotal(state, payload: number) {
    state.skills.total = payload;
  },
  setSkillsIsLoading(state, payload: boolean) {
    state.skills.isLoading = payload;
  },
};
