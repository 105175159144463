import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "main-element d-flex justify-center w-100" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "font-weight-bold text-body-1 text-red mb-2" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "font-weight-bold text-body-1" }
const _hoisted_6 = { class: "my-3" }
const _hoisted_7 = { key: 2 }

export function render(_ctx, _cache) {
  const _component_v_card_title = _resolveComponent("v-card-title")
  const _component_v_card_text = _resolveComponent("v-card-text")
  const _component_v_btn = _resolveComponent("v-btn")
  const _component_v_card_actions = _resolveComponent("v-card-actions")
  const _component_v_card = _resolveComponent("v-card")
  const _component_v_container = _resolveComponent("v-container")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_container, null, {
      default: _withCtx(() => [
        (_ctx.show)
          ? (_openBlock(), _createBlock(_component_v_card, { key: 0 }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, { class: "bg-red text-white" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.i18n.t('title')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_text, { class: "mt-2" }, {
                  default: _withCtx(() => [
                    (_ctx.isTrialExpired)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.i18n.t(_ctx.isAdmin || _ctx.isManager
              ? 'trial-period-expired-info-admin'
              : 'trial-period-expired-info-others')), 1)
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.i18n.t('expiry-deadline-info', {days: _ctx.trialDaysLeft})), 1),
                          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.i18n.t('expiry-consequence-info')), 1)
                        ])),
                    (_ctx.isAdmin || _ctx.isManager)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.i18n.t('subscription-appeal')), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_actions, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_btn, { onClick: _ctx.onClose }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.i18n.t('close')), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}