import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import note, { Note, Value, NoteTypeEnum } from '../model/note';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/constants';

const logger = new Logger('backend.Note');
export interface BackendNote {
  getNote(id: string): AxiosPromise<Note>;
  getNotes: (
    searchParams: SearchParams,
    parentId: string,
    forCheckin?: boolean
  ) => AxiosPromise<Value>;
  deleteNote(id: string): AxiosPromise;
  updateNote(Note: Note): AxiosPromise<any>;
}

export const defaultBackendNote: BackendNote = {
  getNote(id: string): AxiosPromise<Note> {
    let url = `${URLS.noteOdata}/${id}`;
    return instance.get<Note>(url);
  },
  getNotes(
    searchParams: SearchParams,
    parentId: string,
    forCheckin?: boolean
  ): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.ParentId, parentId, false);

    if (forCheckin) {
      odfb.eq(CONST.Type, note.getNoteTypeText(NoteTypeEnum.CheckInNote));
      odfb.eq(CONST.Status, 0);
    }

    let url = DefaultBackendHelper.makeUrl(
      `${URLS.noteOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getNotes${url}`);
    return instance.get<Value>(url);
  },

  deleteNote(id: string): AxiosPromise {
    logger.debug('deleteNote');
    return instance.delete(`${URLS.note}/${id}`);
  },
  updateNote(Note: Note): AxiosPromise<any> {
    logger.debug('updateNote');
    if (Note.id != undefined) {
      return instance.put<Note>(`${URLS.note}`, note.toAPI(Note));
    } else {
      return instance.post<Note>(`${URLS.note}`, note.toAPI(Note));
    }
  },
};
