import appointment, { Appointment } from './appointment';
import { CourseTypeEnum } from './course';

export interface Calendar {
  date: string;
  dayOfWeek: number;
  appointments: Appointment[];
  costs: number | null[];
}

export interface Filters {
  locations: string[];
  departments: string[];
  courses: string[];
  rooms: string[];
  trainers: string[];
  courseTypes: CourseTypeEnum[];
}

export interface Value {
  value: Calendar[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

function parse(data?: Partial<Calendar>): Calendar {
  return {
    date: data?.date || '',
    dayOfWeek: data?.dayOfWeek || 0,
    appointments: data?.appointments?.map((x) => appointment.parse(x)) || [],
    costs: data?.costs || [],
  };
}
function parseFilters(data?: Partial<Filters>): Filters {
  return {
    locations: data?.locations || [],
    departments: data?.departments || [],
    courses: data?.courses || [],
    rooms: data?.rooms || [],
    trainers: data?.trainers || [],
    courseTypes: data?.courseTypes || [],
  };
}

export default {
  parse,
  parseFilters: parseFilters,
};
