import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import appointment, {
  Appointment,
  AppointmentNotificationSetting,
  Value,
} from '../model/appointment';
import {
  AppointmentTrainerRoomAvailability,
  AppointmentTrainerRoomAvailabilityODataRes,
} from '../model/appointmentTrainerRoomAvailability';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/constants';
import DateUtils from '../utils/dateUtils';
import { i18nGlobal } from '@/i18n';
import { CourseTypeEnum, getCourseTypeText } from '../model/course';

const logger = new Logger('backend.Appointment');
export interface BackendAppointment {
  getAppointment(id: string): AxiosPromise<Appointment>;
  getAppointmentAnonymous(id: string): AxiosPromise<Appointment>;
  getAppointments: (
    searchParams: SearchParams,
    dateTimeId?: string,
    locationIds?: string[],
    courseId?: string,
    dateToCompare?: string,
    dateMaxToCompare?: string,
    bookableAsSeries?: boolean,
    trainerId?: string,
    timeFrom?: string,
    timeTill?: string,
    courseType?: CourseTypeEnum,
    departmentId?: string,
    courseActive?: boolean,
    isCancelled?: boolean,
    attendeeId?: string
  ) => AxiosPromise<Value>;
  getAppointmentsWithConflicts: (
    searchParams: SearchParams,
    locationId?: string,
    departmentId?: string
  ) => AxiosPromise<Value>;
  deleteAppointment(id: string): AxiosPromise;
  cancelAppointment(id: string): AxiosPromise;
  updateAppointment(
    Appointment: Appointment,
    notificationSetting: AppointmentNotificationSetting
  ): AxiosPromise<any>;
  addAppointmentByDateTimeId(dateTimeId: string): AxiosPromise<any>;
  updateAppointmentsByDateTimeId(
    dateTimeId: string,
    doNotChangeExistingAppointments: boolean
  ): AxiosPromise<any>;
  getRoomAndTrainerAvailabilities(
    dateTimeId: string
  ): AxiosPromise<AppointmentTrainerRoomAvailability>;
  getRoomAndTrainerAvailabilitiesByAppointmentIds(
    appointmentIds: string[]
  ): AxiosPromise<AppointmentTrainerRoomAvailabilityODataRes>;
  getNonCanceledFutureAppointments: (
    searchParams: SearchParams,
    courseId?: string,
    dateTimeId?: string
  ) => AxiosPromise<Value>;
}

export const defaultBackendAppointment: BackendAppointment = {
  getAppointment(id: string): AxiosPromise<Appointment> {
    let url = `${URLS.appointmentOdata}/${id}`;
    return instance.get<Appointment>(url);
  },

  getAppointmentAnonymous(id: string): AxiosPromise<Appointment> {
    let url = `${URLS.appointment}/GetAnonymous/${id}`;
    return instance.get<Appointment>(url);
  },

  getAppointments(
    searchParams: SearchParams,
    dateTimeId?: string,
    locationIds?: string[],
    courseId?: string,
    dateToCompare?: string,
    dateMaxToCompare?: string,
    bookableAsSeries?: boolean,
    trainerId?: string,
    timeFrom?: string,
    timeTill?: string,
    courseType?: CourseTypeEnum,
    departmentId?: string,
    courseActive?: boolean,
    isCancelled?: boolean,
    attendeeId?: string
  ): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    if (dateTimeId != undefined) {
      odfb.eq(CONST.DateTimeId, dateTimeId, false);
    }
    if (locationIds != undefined && locationIds.length > 0) {
      var odfbLocations = ODataFilterBuilder('or');
      locationIds.forEach((x) => odfbLocations.eq(CONST.LocationId, x, false));
      odfb.and(odfbLocations);
    }
    if (courseId != undefined) {
      odfb.eq(CONST.CourseId, courseId, false);
    }
    if (dateToCompare != undefined) {
      odfb.ge(CONST.Date, dateToCompare, false);
    }
    if (dateMaxToCompare != undefined) {
      odfb.le(CONST.Date, dateMaxToCompare, false);
    }
    if (timeFrom != undefined) {
      odfb.ge(CONST.TimeFromString, DateUtils.toTimespanString(timeFrom));
    }
    if (timeTill != undefined) {
      odfb.le(CONST.TimeFromString, DateUtils.toTimespanString(timeTill));
    }
    if (bookableAsSeries != undefined) {
      odfb.eq(CONST.BookableAsSeries, bookableAsSeries, false);
    }
    if (courseType != undefined) {
      odfb.eq(CONST.CourseType, getCourseTypeText(courseType));
    }
    if (courseActive != undefined) {
      odfb.eq(CONST.CourseActive, courseActive);
    }
    if (isCancelled != undefined) {
      odfb.eq(CONST.IsCancelled, isCancelled);
    }
    if (attendeeId != undefined) {
      odfb.contains(CONST.AttendeeIds, attendeeId);
    }

    let baseUrl;
    if (trainerId != undefined) {
      baseUrl = `${URLS.appointmentOdata}/GetByTrainerId(trainerId=${trainerId})`;
    } else if (departmentId != undefined) {
      baseUrl = `${URLS.appointmentOdata}/GetInDepartment(departmentId=${departmentId})`;
    } else {
      baseUrl = `${URLS.appointmentOdata}`;
    }

    let url = DefaultBackendHelper.makeUrl(
      baseUrl,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );

    logger.log(`getAppointments${url}`);
    return instance.get<Value>(url);
  },
  getAppointmentsWithConflicts(
    searchParams: SearchParams,
    locationId?: string,
    departmentId?: string
  ) {
    var odfb = ODataFilterBuilder('and');
    let param1 = DateUtils.getCurrentLocalDateAsIsoString(false);
    let param2 = locationId ?? 'null';
    let param3 = departmentId ?? 'null';
    let baseUrl = `${URLS.appointmentOdata}/GetWithConflicts(clientLocalDateTime=${param1},locationId=${param2},departmentId=${param3})`;

    let url = DefaultBackendHelper.makeUrl(
      baseUrl,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );

    logger.log(`getAppointmentsWithConflicts${url}`);
    return instance.get<Value>(url);
  },

  deleteAppointment(id: string): AxiosPromise {
    logger.debug('deleteAppointment');
    return instance.delete(`${URLS.appointment}/${id}`);
  },
  cancelAppointment(id: string): AxiosPromise {
    logger.debug('cancelAppointment');
    return instance.get(
      `${URLS.appointment}/Cancel/${id}/${i18nGlobal.locale.value}`
    );
  },
  updateAppointment(
    Appointment: Appointment,
    notificationSetting: AppointmentNotificationSetting
  ): AxiosPromise<any> {
    logger.debug('updateAppointment');
    let data = appointment.toAPI(Appointment);
    data.notificationSetting = notificationSetting;
    return instance.put<Appointment>(`${URLS.appointment}/update`, data);
  },
  addAppointmentByDateTimeId(dateTimeId: string): AxiosPromise<any> {
    logger.debug('addAppointmentByDateTimeId');
    return instance.post<Appointment>(`${URLS.appointment}/AddByDateTimeId`, {
      DateTimeId: dateTimeId,
    });
  },
  updateAppointmentsByDateTimeId(
    dateTimeId: string,
    doNotChangeExistingAppointments: boolean
  ): AxiosPromise<any> {
    logger.debug('updateAppointmentsByDateTimeId');
    return instance.post<Appointment>(
      `${URLS.appointment}/UpdateByDateTimeId`,
      {
        DateTimeId: dateTimeId,
        DoNotChangeExistingAppointments: doNotChangeExistingAppointments,
      }
    );
  },
  getRoomAndTrainerAvailabilities(
    dateTimeId: string
  ): AxiosPromise<AppointmentTrainerRoomAvailability> {
    logger.debug('getRoomAndTrainerAvailabilities');
    let url = `${URLS.appointment}/GetRoomAndTrainerAvailabilities/${dateTimeId}`;
    logger.debug(url);
    return instance.get<AppointmentTrainerRoomAvailability>(url);
  },
  getRoomAndTrainerAvailabilitiesByAppointmentIds(
    appointmentIds: string[]
  ): AxiosPromise<AppointmentTrainerRoomAvailabilityODataRes> {
    logger.debug('getRoomAndTrainerAvailabilitiesByAppointmentIds');

    let appointmentIdsString = `[${
      appointmentIds.length > 0
        ? appointmentIds.map((id) => `'${id}'`).join(',')
        : ''
    }]`;

    let url = `${URLS.appointmentOdata}/GetRoomAndTrainerAvailabilitiesByAppointmentIds(appointmentIds=${appointmentIdsString})`;
    logger.debug(url);
    return instance.get<AppointmentTrainerRoomAvailabilityODataRes>(url);
  },
  getNonCanceledFutureAppointments(
    searchParams: SearchParams,
    courseId?: string,
    dateTimeId?: string
  ): AxiosPromise<Value> {
    let odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.CanceledDate, null);

    if (courseId != undefined) {
      odfb.eq(CONST.CourseId, courseId, false);
    }

    if (dateTimeId != undefined) {
      odfb.eq(CONST.DateTimeId, dateTimeId, false);
    }

    let nowDate = new Date().toISOString().substring(0, 10);
    let odfbDateTimeCheck = ODataFilterBuilder('or');
    odfbDateTimeCheck.gt(CONST.Date, nowDate, false);

    let odfbDateTimeCheckInner = ODataFilterBuilder('and');
    odfbDateTimeCheckInner.eq(CONST.Date, nowDate, false);
    odfbDateTimeCheckInner.ge(
      CONST.TimeFromString,
      new Date().toLocaleTimeString().substring(0, 5)
    );

    odfbDateTimeCheck.or(odfbDateTimeCheckInner);
    odfb.and(odfbDateTimeCheck);

    let url = DefaultBackendHelper.makeUrl(
      `${URLS.appointmentOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );

    logger.log(`getNonCancelledFutureAppointments${url}`);
    return instance.get<Value>(url);
  },
};
