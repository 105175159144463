
import { GetterTree } from 'vuex';
import { LocationEmployeeState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<LocationEmployeeState, RootState> = {
  ['getLocationEmployees']: (state) => state.locationEmployees,
  ['getLocationEmployeesIsLoading']: (state) => state.locationEmployees?.isLoading,
  ['getLocationEmployeesTotal']: (state) => state.locationEmployees?.total,
  ['getLocationEmployeesSearchParams']: (state) => state.locationEmployees?.searchParams,
};
