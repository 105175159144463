import { ActionTree } from 'vuex';
import { NoteState } from './types';
import { RootState } from '../../types';
import { defaultBackendNote } from '@/shared/backend/note';
import { Logger } from 'fsts';
import moduleUtils from '../moduleUtils';
import { Value, Note } from '@/shared/model/note';
import { AxiosResponse } from 'axios';
import { i18nGlobal } from '@/i18n';
import { CONST } from '../../../utils/constants';
import { isEmptyId } from '@/shared/utils/generalUtils';

const logger = new Logger('actions.notes');
export const actions: ActionTree<NoteState, RootState> = {
  getNotes(
    { commit, getters },
    payload?: { searchParams?: any; parentId?: string; forCheckin?: boolean }
  ) {
    commit('setNotesIsLoading', true);
    let searchParams = payload?.searchParams ?? getters.getNotesSearchParams;
    let parentId = payload?.parentId ?? CONST.emptyGuid;
    let forCheckin = payload?.forCheckin ?? false;

    return defaultBackendNote
      .getNotes(searchParams, parentId, forCheckin)
      .then((response: AxiosResponse<Value>) => {
        commit('setNotes', response.data);
        commit('setNotesIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setNotesIsLoading', false);
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  getNote({ commit, dispatch }, id: string) {
    return defaultBackendNote
      .getNote(id)
      .then((response: AxiosResponse<Note>) => {
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateNote({ commit, dispatch }, file: Note) {
    return defaultBackendNote
      .updateNote(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Note;
          }>
        ) => {
          moduleUtils.ok(
            isEmptyId(file.id)
              ? i18nGlobal.t(`success.note_create`)
              : i18nGlobal.t(`success.note_update`),
            commit
          );
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteNote({ commit, dispatch }, id: string) {
    return defaultBackendNote
      .deleteNote(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Note;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  clearNotes({ commit }) {
    commit('setNotes', { value: [] });
  },
};
