import { ActionTree } from 'vuex';
import { SkillState } from './types';
import { RootState } from '../../types';
import { defaultBackendSkill } from '@/shared/backend/skill';
import { Logger } from 'fsts';
import moduleUtils from '../moduleUtils';
import { Value, Skill } from '@/shared/model/skill';
import { AxiosResponse } from 'axios';
import { i18nGlobal } from '@/i18n';
import { createSearchParams } from '@/shared/model/searchParams';

const logger = new Logger('actions.skills');
export const actions: ActionTree<SkillState, RootState> = {
  getSkills(
    { commit, dispatch, getters, rootGetters },
    payload?: { searchParams?: any, onlyActive?: boolean }
  ) {
    commit('setSkillsIsLoading', true);
    let searchParams = payload?.searchParams ?? getters.getSkillsSearchParams;
    let onlyActive = payload?.onlyActive ?? undefined;

    return defaultBackendSkill
      .getSkills(searchParams, onlyActive)
      .then((response: AxiosResponse<Value>) => {
        commit('setSkills', response.data);
        commit('setSkillsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setSkillsIsLoading', false);
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  getAllSkills({ commit, dispatch, getters, rootGetters }) {
    commit('setSkillsIsLoading', true);
    return defaultBackendSkill
      .getAllSkills()
      .then((response: AxiosResponse<Value>) => {
        commit('setAllSkills', response.data);
        commit('setSkillsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setSkillsIsLoading', false);
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  getAllSkillsAlphabetically({ dispatch }, onlyActive?: boolean) {
    return dispatch('getSkills', {
      searchParams: createSearchParams(-1, 1, ['name'], [false]),
      onlyActive: onlyActive,
    });
  },
  getSkill({ commit, dispatch }, id: string) {
    return defaultBackendSkill
      .getSkill(id)
      .then((response: AxiosResponse<Skill>) => {
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateSkill({ commit, dispatch }, file: Skill) {
    return defaultBackendSkill
      .updateSkill(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Skill;
          }>
        ) => {
          moduleUtils.ok(i18nGlobal.t(`success.skill_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteSkill({ commit, dispatch }, id: string) {
    return defaultBackendSkill
      .deleteSkill(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Skill;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
};
