import appointment from '@/shared/model/appointment';
import { AppointmentState } from './types';

export const state = (): AppointmentState => initialState();

export const initialState = (): AppointmentState => ({
  appointments: {
    items: [],
    isLoading: false,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['date', 'timeFromString'],
        sortDesc: [false, false],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: [
        'Text',
        'CourseName',
        'trainerFullNames',
        'locationName',
        'roomName',
      ],
    },
  },
  appointment: appointment.parse({}),
  selectedFiltersForCalendar: {
    locations: [],
    departments: [],
    courses: [],
    rooms: [],
    trainers: [],
    courseTypes: [],
  },
});
