import { CONST } from '../utils/constants';
import DateUtils from '../utils/dateUtils';
import { ParticipationStatusType } from './attendance';

export interface RoomPresence {
  id?: string;
  roomId: string;
  attendeeId: string;
  presenceId?: string;
  roomName: string;
  date: string;
  timeEnter: string;
  timeLeave?: string;
  timeEnterString?: string;
  timeLeaveString?: string;
  status: ParticipationStatusType;
  companyId?: string;
  creatorId?: string;
  createdDate?: string;
}

export interface Value {
  value: RoomPresence[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

function toAPI(data: Partial<RoomPresence>): any {
  return {
    Id: data?.id || undefined,
    RoomId: data?.roomId || CONST.emptyGuid,
    AttendeeId: data?.attendeeId || CONST.emptyGuid,
    PresenceId: data?.presenceId || undefined,
    Date: data?.date || DateUtils.getTodayDateAsIsoString(),
    TimeEnter: data?.timeEnter || '',
    TimeLeave: data?.timeLeave || undefined,
    Status: data?.status || ParticipationStatusType.NotConfirmed,
    CompanyId: data?.companyId || undefined,
    CreatorId: data?.creatorId || undefined,
    CreatedDate: data?.createdDate || undefined,
  };
}

function parse(data?: Partial<RoomPresence>): RoomPresence {
  let status = data?.status || 'NotConfirmed';
  return {
    id: data?.id || undefined,
    roomId: data?.roomId || CONST.emptyGuid,
    attendeeId: data?.attendeeId || CONST.emptyGuid,
    presenceId: data?.presenceId || undefined,
    roomName: data?.roomName || '',
    date: data?.date || '',
    timeEnter: data?.timeEnterString?.substring(0, 5) || '',
    timeLeave: data?.timeLeaveString?.substring(0, 5) || '',
    status:
      ParticipationStatusType[status as keyof typeof ParticipationStatusType],
    companyId: data?.companyId || undefined,
    creatorId: data?.creatorId || undefined,
    createdDate: data?.createdDate || undefined,
  };
}

export default {
  parse,
  toAPI: toAPI,
};
