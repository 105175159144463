import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import {
  StripeCard,
  StripePlan,
  StripeSubscription,
  StripeSubscriptionItem,
  StripeInvoice,
  StripeCustomerContactData
} from '../model/stripe';

export interface BackendStripe {
  existCustomer: (email: string) => AxiosPromise<boolean>;
  getCustomer: (customerId: string) => AxiosPromise<StripeCustomerContactData>;
  createCustomer: (email: string, fullName: string) => AxiosPromise<string>;
  getPlans: (interval: string) => AxiosPromise<StripePlan[]>;
  hasSubscription: (customerId: string) => AxiosPromise<boolean>;
  cancelSubscriptions: (
    customerId: string,
    subscriptionId: string
  ) => AxiosPromise<boolean>;
  deleteSubscriptionItem: (
    subscriptionId: string,
    subscriptionItemId: string
  ) => AxiosPromise<boolean>;
  updateSubscriptionItemQuantity: (
    subscriptionItemId: string,
    newQuantity: number
  ) => AxiosPromise<boolean>;
  getSubscriptionDetails: (customerId: string) => AxiosPromise<StripeSubscription>;
  getSubscriptionItem: (
    subscriptionId: string
  ) => AxiosPromise<StripeSubscriptionItem>;
  subscribe: (
    customerId: string,
    planId: string,
    totalUsers: number
  ) => AxiosPromise<string>;
  getCards: (customerId: string) => AxiosPromise<StripeCard[]>;
  getCard: (customerId: string, cardId: string) => AxiosPromise<StripeCard>;
  setCardUsingToken: (
    customerId: string,
    tokenId: string
  ) => AxiosPromise<boolean>;
  getStripePublicKey: () => AxiosPromise<string>;
  getUpcomingInvoice: (customerId: string) => AxiosPromise<StripeInvoice>;
  getInvoices: (customerId: string) => AxiosPromise<StripeInvoice[]>;
}

export const defaultBackendStripe: BackendStripe = {
  existCustomer(email: string): AxiosPromise<boolean> {
    return instance.get(`${URLS.stripe}/ExistCustomer/${email}`);
  },
  getCustomer(customerId: string): AxiosPromise<StripeCustomerContactData> {
    return instance.get(`${URLS.stripe}/GetCustomer/${customerId}`);
  },
  createCustomer(email: string, fullName: string): AxiosPromise<string> {
    return instance.post(`${URLS.stripe}/CreateCustomer/${email}/${fullName}`);
  },
  getPlans(interval: string): AxiosPromise<StripePlan[]> {
    return instance.get<StripePlan[]>(`${URLS.stripe}/GetPlans/${interval}`);
  },
  hasSubscription(customerId: string): AxiosPromise<boolean> {
    return instance.get<boolean>(
      `${URLS.stripe}/HasSubscription/${customerId}`
    );
  },
  cancelSubscriptions(
    customerId: string,
    subscriptionId: string
  ): AxiosPromise<boolean> {
    return instance.delete<boolean>(
      `${URLS.stripe}/CancelSubscriptions/${customerId}/${subscriptionId}`
    );
  },
  deleteSubscriptionItem(
    subscriptionId: string,
    subscriptionItemId: string
  ): AxiosPromise<boolean> {
    return instance.delete<boolean>(
      `${URLS.stripe}/DeleteSubscriptionItem/${subscriptionId}/${subscriptionItemId}`
    );
  },

  updateSubscriptionItemQuantity(
    subscriptionItemId: string,
    newQuantity: number
  ): AxiosPromise<boolean> {
    return instance.delete<boolean>(
      `${URLS.stripe}/UpdateSubscriptionItemQuantity/${subscriptionItemId}/${newQuantity}`
    );
  },

  getSubscriptionDetails(customerId: string): AxiosPromise<StripeSubscription> {
    return instance.get<StripeSubscription>(
      `${URLS.stripe}/GetSubscriptionDetails/${customerId}`
    );
  },
  getSubscriptionItem(subscriptionId: string): AxiosPromise<StripeSubscriptionItem> {
    return instance.get<StripeSubscriptionItem>(
      `${URLS.stripe}/GetSubscriptionItem/${subscriptionId}`
    );
  },
  subscribe(
    customerId: string,
    planId: string,
    totalUsers: number
  ): AxiosPromise<string> {
    return instance.post<string>(
      `${URLS.stripe}/Subscribe/${customerId}/${planId}/${totalUsers}`
    );
  },

  getCards(customerId: string): AxiosPromise<StripeCard[]> {
    return instance.get<StripeCard[]>(`${URLS.stripe}/GetCards/${customerId}`);
  },
  getCard(customerId: string, cardId: string): AxiosPromise<StripeCard> {
    return instance.get<StripeCard>(`${URLS.stripe}/GetCard/${customerId}/${cardId}`);
  },
  setCardUsingToken(
    customerId: string,
    tokenId: string
  ): AxiosPromise<boolean> {
    return instance.put<boolean>(
      `${URLS.stripe}/SetCardUsingToken/${customerId}/${tokenId}`
    );
  },
  getStripePublicKey(): AxiosPromise<string> {
    return instance.get<string>(`${URLS.stripe}/GetPublicStripeKey`);
  },
  getUpcomingInvoice(customerId: string): AxiosPromise<StripeInvoice> {
    return instance.get<StripeInvoice>(
      `${URLS.stripe}/GetUpcomingInvoice/${customerId}`
    );
  },
  getInvoices(customerId: string): AxiosPromise<StripeInvoice[]> {
    return instance.get<StripeInvoice[]>(`${URLS.stripe}/GetInvoices/${customerId}`);
  },
};
