import { GetterTree } from 'vuex';
import { TariffRefState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<TariffRefState, RootState> = {
  ['getTariffRefs']: state => state.tariffRefs,
  ['getTariffRefsIsLoading']: state => state.tariffRefs?.isLoading,
  ['getTariffRefsTotal']: state => state.tariffRefs?.total,
  ['getTariffRefsSearchParams']: state => state.tariffRefs?.searchParams,
};
