import { MutationTree } from 'vuex';
import { AttendeeRegisterByQrCodeState } from './types';
import Vue from 'vue';
import courseTrainer, { Value } from '@/shared/model/courseTrainer';
import appointment, {
  Value as AppointmentValue,
} from '@/shared/model/appointment';
import location, { Location } from '@/shared/model/location';
import attendee, { Attendee } from '@/shared/model/attendee';

export const mutations: MutationTree<AttendeeRegisterByQrCodeState> = {
  setLocation(state, payload: Location) {
    state.location = location.parse(payload);
  },
  setAttendee(state, payload: Attendee) {
    state.attendee = attendee.parse(payload);
  },
  setCourseTrainers(state, payload: Value) {
    state.courseTrainers.items = payload.value.map(x => courseTrainer.parse(x));
    state.courseTrainers.total = payload['@odata.count'] || 0;
    state.courseTrainers.isLoading = false;
  },
  setCourseTrainersTotal(state, payload: number) {
    state.courseTrainers.total = payload;
  },
  setCourseTrainersIsLoading(state, payload: boolean) {
    state.courseTrainers.isLoading = payload;
  },

  setAppointments(state, payload: AppointmentValue) {
    state.appointments.items = payload.value.map(x => appointment.parse(x));
    state.appointments.total = payload['@odata.count'] || 0;
    state.appointments.isLoading = false;
  },
  setAppointmentsTotal(state, payload: number) {
    state.appointments.total = payload;
  },
  setAppointmentsIsLoading(state, payload: boolean) {
    state.appointments.isLoading = payload;
  },
};
