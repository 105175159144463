const LocalStorageService = (function () {
  var _service: any;
  function _getService() {
    if (!_service) {
      _service = LocalStorageService;
      return _service;
    }
    return _service;
  }
  function _setToken(tokenObj: any) {
    localStorage.setItem('access_token', tokenObj.token.token);
    localStorage.setItem('refresh_token', tokenObj.refreshToken);
  }
  function _getAccessToken() {
    return localStorage.getItem('access_token');
  }
  function _getRefreshToken() {
    return localStorage.getItem('refresh_token');
  }
  function _clearToken() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
  }
  function _setInterestedId(id: string) {
    const isExist = localStorage.getItem('interested_ids');
    if (isExist === null) {
      localStorage.setItem('interested_ids', JSON.stringify([id]));
      return;
    }
    const arr = Array.from(JSON.parse(isExist));
    const set = new Set(arr);
    set.add(id);
    localStorage.setItem('interested_ids', JSON.stringify(Array.from(set)));
  }
  function _getInterestedId() {
    return new Set(JSON.parse(localStorage.getItem('interested_ids') ?? '[]'));
  }
  function _clearInterestedId() {
    return localStorage.removeItem('interested_ids');
  }
  return {
    getService: _getService,
    setToken: _setToken,
    getAccessToken: _getAccessToken,
    getRefreshToken: _getRefreshToken,
    clearToken: _clearToken,
    setInterestedId: _setInterestedId,
    getInterestedId: _getInterestedId,
    clearInterestedId: _clearInterestedId,
  };
})();
export default LocalStorageService;
