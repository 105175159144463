import { i18nGlobal } from '@/i18n';

export interface LocationEmployee {
  id?: string; // System.Guid
  firstName: string;
  lastName: string;
  email: string;
  authorities: string[];
  readonly authoritiesString: string;
  active: string;
  locationId: string; // System.Guid
  employeeId: string; // System.Guid
  companyId?: string; // System.Guid
  creatorId?: string; // System.Guid?
  createdDate: string; // System.DateTime
}

export interface Value {
  value: LocationEmployee[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<LocationEmployee>): any {
  return {
    Id: data?.id || undefined,
    LocationId: data?.locationId || undefined,
    EmployeeId: data?.employeeId || undefined,
    CompanyId: data?.companyId || undefined,
    CreatorId: data?.creatorId || '',
    CreatedDate: data?.createdDate || '',
  };
}

// init api object
function arrayToAPI(data: Partial<LocationEmployee[]>): any {
  return data.map((item?: LocationEmployee) => {
    return {
      Id: item?.id || undefined,
      LocationId: item?.locationId || undefined,
      EmployeeId: item?.employeeId || undefined,
      CompanyId: item?.companyId || undefined,
    };
  });
}

function parse(data?: Partial<LocationEmployee>): LocationEmployee {
  return {
    id: data?.id || undefined,
    firstName: data?.firstName || '',
    lastName: data?.lastName || '',
    email: data?.email || '',
    authorities: data?.authorities || [],
    get authoritiesString() {
      return this.authorities
        .map((x) => i18nGlobal.t(`app.authorities.${x}`))
        .join(', ');
    },
    active: data?.active || '',
    locationId: data?.locationId || '',
    employeeId: data?.employeeId || '',
    companyId: data?.companyId || undefined,
    creatorId: data?.creatorId || '',
    createdDate: data?.createdDate || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
  arrayToAPI,
};
