import { createInitialStatisticsRequest } from "@/shared/model/statistics";
import { StatisticsState } from "./types";

export const state = (): StatisticsState => initialState();

export const initialState = (): StatisticsState => ({
  request: createInitialStatisticsRequest(),
  result: [],
  loading: false,
  presets: {
    items: [],
    isLoading: false,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['name'],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: ['name'],
    },
  },
});
