import { InvoicesState } from './types';
import { ActionTree } from 'vuex';
import { RootState } from '../../types';
import { Logger } from 'fsts';
import { DefaultBackendInvoice } from '@/shared/backend/invoice';
import { Invoice, Value, InvoicePaymentStatus } from '@/shared/model/invoice';
import { AxiosResponse } from 'axios';
import moduleUtils from '../moduleUtils';
import { i18nGlobal } from '@/i18n';
import DownloadUtils from '@/shared/utils/downloadUtils';

const logger = new Logger('actions.invoices');
export const actions: ActionTree<InvoicesState, RootState> = {
  getInvoices(
    { commit, getters },
    payload?: {
      searchParams?: any;
      attendeeAppUserId?: string;
      filterByStati?: InvoicePaymentStatus[];
      dateFrom?: string;
      dateTill?: string;
      locationId?: string;
      departmentId?: string;
    }
  ) {
    commit('setInvoicesIsLoading', true);
    let searchParams = payload?.searchParams ?? getters.getInvoicesSearchParams;

    return DefaultBackendInvoice.getInvoices(
      searchParams,
      payload?.attendeeAppUserId,
      payload?.filterByStati,
      payload?.dateFrom,
      payload?.dateTill,
      payload?.locationId,
      payload?.departmentId
    )
      .then((response: AxiosResponse<Value>) => {
        commit('setInvoices', response.data);
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setInvoicesIsLoading', false);
      });
  },
  updateInvoice({ commit }, invoice: Invoice) {
    return DefaultBackendInvoice.updateInvoice(invoice)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
          }>
        ) => {
          moduleUtils.ok(i18nGlobal.t(`success.invoice_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  async exportCompleteInvoices(
    { commit },
    payload: { markInvoicesPaid: boolean }
  ) {
    commit('setInvoicesIsLoading', true);
    let culture = i18nGlobal.locale.value == 'de' ? 'de-DE' : 'en-US';
    try {
      await DefaultBackendInvoice.exportCompleteInvoices(
        payload.markInvoicesPaid,
        culture
      ).then((response) => {
        let fileName = DownloadUtils.getFileName(
          response.headers['content-disposition']
        );
        const type = response.headers['content-type'];
        if (DownloadUtils.isIeOrEdge(window)) {
          const file = new File([response.data], fileName, {
            type: type,
          });
          (window.navigator as any).msSaveOrOpenBlob(file, fileName);
        } else {
          const blob = new Blob([response.data], { type: type });
          const url = DownloadUtils.createDownloadLink(blob, fileName);
          window.URL.revokeObjectURL(url);
        }
        moduleUtils.ok(i18nGlobal.t(`success.invoice_export`), commit);
      });
    } catch (e) {
      logger.error(e);
      moduleUtils.error(i18nGlobal.t(`error.download_file`), commit, e, logger);
      throw e;
    } finally {
      commit('setInvoicesIsLoading', false);
    }
  },
};
