import { MutationTree } from 'vuex';
import { LocationResponsibleState } from './types';
import locationResponsible, { Value } from '@/shared/model/locationResponsible';

export const mutations: MutationTree<LocationResponsibleState> = {
  setLocationResponsibles(state, payload: Value) {
    state.locationResponsibles.items = payload.value.map(x =>
      locationResponsible.parse(x)
    );
    state.locationResponsibles.total = payload['@odata.count'] || 0;
    state.locationResponsibles.isLoading = false;
  },
  setLocationResponsiblesTotal(state, payload: number) {
    state.locationResponsibles.total = payload;
  },
  setLocationResponsiblesIsLoading(state, payload: boolean) {
    state.locationResponsibles.isLoading = payload;
  },
};
