import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import skillRef, { ReferenceParent, Value } from '../model/referenceParent';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/constants';

const logger = new Logger('backend.ReferenceParent');
export interface BackendReferenceParent {
  getReferenceParent(id: string): AxiosPromise<ReferenceParent>;
  getReferenceParents: (
    searchParams: SearchParams,
    trainerId: string,
    filterRefParentTable: string | undefined
  ) => AxiosPromise<Value>;
  getAllReferenceParents: (
    trainerId: string | undefined,
    filterRefParentTable: string | undefined,
    referenceId: string | undefined
  ) => AxiosPromise<Value>;
  deleteReferenceParent(id: string): AxiosPromise;
  updateReferenceParent(
    ReferenceParent: ReferenceParent,
    refParentTable: string
  ): AxiosPromise<any>;
  addMultipleReferenceParents(
    ReferenceParents: ReferenceParent[],
    tableName: string
  ): AxiosPromise<any>;
}

export const defaultBackendReferenceParent: BackendReferenceParent = {
  getReferenceParent(id: string): AxiosPromise<ReferenceParent> {
    let url = `${URLS.referenceParentOdata}/${id}`;
    return instance.get<ReferenceParent>(url);
  },
  getReferenceParents(
    searchParams: SearchParams,
    parentId: string,
    filterRefParentTable: string | undefined
  ): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.ParentId, parentId, false);
    if (filterRefParentTable != undefined) {
      odfb.contains(CONST.TypeDto, filterRefParentTable);
    }
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.referenceParentOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getReferenceParents${url}`);
    return instance.get<Value>(url);
  },

  getAllReferenceParents(
    parentId: string | undefined,
    filterRefParentTable: string | undefined,
    referenceId: string | undefined
  ): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    if (parentId != undefined) {
      odfb.eq(CONST.ParentId, parentId, false);
    }
    if (filterRefParentTable != undefined) {
      odfb.contains(CONST.Type, filterRefParentTable);
    }
    if (referenceId != undefined) {
      odfb.eq(CONST.ReferenceId, referenceId, false);
    }

    let url = DefaultBackendHelper.makeUrl(
      `${URLS.referenceParent}/GetAll`,
      undefined,
      undefined,
      undefined,
      odfb
    );
    logger.log(`getAllReferenceParents${url}`);
    return instance.get<Value>(url);
  },

  deleteReferenceParent(id: string): AxiosPromise {
    logger.debug('deleteReferenceParent');
    return instance.delete(`${URLS.referenceParent}/${id}`);
  },
  updateReferenceParent(
    ReferenceParent: ReferenceParent,
    refParentTable: string
  ): AxiosPromise<any> {
    logger.debug('updateReferenceParent');
    if (ReferenceParent.id != undefined) {
      return instance.put<ReferenceParent>(
        `${URLS.referenceParent}/${refParentTable}`,
        skillRef.toAPI(ReferenceParent)
      );
    } else {
      return instance.post<ReferenceParent>(
        `${URLS.referenceParent}/${refParentTable}`,
        skillRef.toAPI(ReferenceParent)
      );
    }
  },
  addMultipleReferenceParents(
    ReferenceParents: ReferenceParent[],
    tableName: string
  ): AxiosPromise<any> {
    logger.debug('addMultipleReferenceParent');
    return instance.post<ReferenceParent>(
      `${URLS.referenceParent}/AddMultiple/${tableName}`,
      skillRef.arrayToAPI(ReferenceParents)
    );
  },
};
