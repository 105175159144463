import { MutationTree } from 'vuex';
import { RoomState } from './types';
import room, { Room, Value } from '@/shared/model/room';

export const mutations: MutationTree<RoomState> = {
  setRooms(state, payload: Value) {
    state.rooms.items = payload.value.map((x) => room.parse(x));
    state.rooms.total = payload['@odata.count'] || 0;
    state.rooms.isLoading = false;
  },
  setRoomsTotal(state, payload: number) {
    state.rooms.total = payload;
  },
  setRoomsIsLoading(state, payload: boolean) {
    state.rooms.isLoading = payload;
  },
  resetRoomsPaging(state) {
    state.rooms.searchParams.dataOption.page = 1;
    state.rooms.searchParams.dataOption.itemsPerPage = 10;
    state.rooms.searchParams.dataOption.filter = '';
    state.rooms.searchParams.filter = '';
  },
};
