import { CONST } from '../utils/constants';

export type ContactType = 0 | 1 | 2 | 3 | 4 | 5 | 6;
export const ContactType = {
  Parent: 0 as ContactType,
  LegalGuardian: 1 as ContactType,
  SuperVisor: 2 as ContactType,
  MarriagePartner: 3 as ContactType,
  LifePartner: 4 as ContactType,
  Child: 5 as ContactType,
  Other: 6 as ContactType,
};

export interface Contact {
  id: string; // System.Guid
  attendeeId: string; // System.Guid
  contactAttendeeId?: string; // System.Guid
  contactType: ContactType;
  lastName: string;
  firstName: string;
  email: string;
  phone: string;
  memberNumber: string;
  companyId: string;
  creatorId?: string;
  createdDate: string; // System.DateTime
}

export interface Value {
  value: Contact[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

function toAPI(data: Partial<Contact>): any {
  return {
    Id: data?.id || CONST.emptyGuid,
    AttendeeId: data?.attendeeId || CONST.emptyGuid,
    ContactAttendeeId: data?.contactAttendeeId || undefined,
    ContactType: data?.contactType ?? ContactType.Other,
    LastName: data?.lastName || '',
    FirstName: data?.firstName || '',
    Email: data?.email || '',
    Phone: data?.phone || '',
    MemberNumber: data?.memberNumber || undefined,
    CompanyId: data?.companyId || CONST.emptyGuid,
    CreatorId: data?.creatorId || undefined,
    CreatedDate: data?.createdDate || undefined,
  };
}

function parse(data?: Partial<Contact>): Contact {
  let contactType: ContactType;
  if (typeof data?.contactType == 'string')
    contactType = ContactType[data.contactType as keyof typeof ContactType];
  else if (data?.contactType != undefined) contactType = data.contactType;
  else contactType = ContactType.Other;

  return {
    id: data?.id || '',
    attendeeId: data?.attendeeId || '',
    contactAttendeeId: data?.contactAttendeeId || undefined,
    contactType: contactType,
    lastName: data?.lastName || '',
    firstName: data?.firstName || '',
    email: data?.email || '',
    phone: data?.phone || '',
    memberNumber: data?.memberNumber || '',
    companyId: data?.companyId || '',
    creatorId: data?.creatorId || undefined,
    createdDate: data?.createdDate || '',
  };
}

export const EmptyContact = parse({ contactType: ContactType.LegalGuardian });

export default {
  parse,
  toAPI: toAPI,
};
