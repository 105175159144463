import { GetterTree } from 'vuex';
import { CountryManagementState } from './types';
import { RootState } from '../../types';
import { Country } from '../../../model/country';

export const getters: GetterTree<CountryManagementState, RootState> = {
  getCountries(state): Country[] | undefined {
    return state.countries;
  },
};
