import store from '../store';
import { ApplicationUser } from '../model/applicationUser';
import { AUTHORITIES } from '@/shared/store/modules/auth';

export default class AuthorityUtils {
  public static hasAnyRole(roles: string[]) {
    const employee: ApplicationUser = store.getters['auth/employee'];
    return (
      employee &&
      employee.authorities
        .split(',')
        .some((authorities) => roles.includes(authorities))
    );
  }
  public static isEmptyAccount() {
    return !store.getters['auth/isLoggedIn'];
  }
  public static isLoggedIn() {
    return store.getters['auth/isLoggedIn'];
  }
  public static async ensureAccountDetails() {
    if (this.isEmptyAccount()) {
      // wait for account details to be loaded
      await store.dispatch('auth/loadAccountDetails');
    }
  }

  public static async getApiInfo() {
    if (!store.getters['apiInfo']) {
      await store.dispatch('load_info');
    }
  }

  public static getAccountId() {
    const id: string = store.getters['auth/getAccountId'];
    return id ? id! : null;
  }

  public static getCurrentRole() {
    if (this.isAdmin()) {
      return AUTHORITIES.ADMIN;
    } else if (this.isManager()) {
      return AUTHORITIES.MANAGER;
    } else if (this.isDepartmentLeader() || this.isEmployee()) {
      return AUTHORITIES.EMPLOYEE;
    } else if (this.isTrainer()) {
      return AUTHORITIES.TRAINER;
    }

    return AUTHORITIES.ATTENDEE;
  }

  public static isAdmin() {
    return store.getters['auth/isAdmin'];
  }

  public static isManager() {
    return store.getters['auth/isManager'];
  }

  public static isDepartmentLeader() {
    return store.getters['auth/isDepartmentLeader'];
  }

  public static isEmployee() {
    return store.getters['auth/isEmployee'];
  }

  public static isTrainer() {
    return store.getters['auth/isTrainer'];
  }

  public static isAttendee() {
    return store.getters['auth/isAttendee'];
  }
}
