import { GetterTree } from 'vuex';
import { InvoicesState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<InvoicesState, RootState> = {
  ['getInvoices']: state => state.invoices,
  ['getInvoicesIsLoading']: state => state.invoices?.isLoading,
  ['getInvoicesTotal']: state => state.invoices?.total,
  ['getInvoicesSearchParams']: state => state.invoices?.searchParams,
};
