import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { DefaultBackendHelper } from '../utils/backendHelper';
import contact, { Contact, Value } from '../model/contact';
import { SearchParams } from '../model/searchParams';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/constants';

const logger = new Logger('backend.contact');
export interface BackendContact {
  getContacts(
    searchParams: SearchParams,
    attendeeId: string
  ): AxiosPromise<Value>;
  postMultipleContacts(
    attendeeId: string,
    contacts: Contact[]
  ): AxiosPromise<any>;
}

export const defaultBackendContact: BackendContact = {
  getContacts(
    searchParams: SearchParams,
    attendeeId: string
  ): AxiosPromise<Value> {
    let odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.AttendeeId, attendeeId, false);

    let url = DefaultBackendHelper.makeUrl(
      URLS.contactsOdata,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );

    logger.log(`getContacts: ${url}`);
    return instance.get<Value>(url);
  },
  postMultipleContacts(
    attendeeId: string,
    contacts: Contact[]
  ): AxiosPromise<any> {
    return instance.post<any>(`${URLS.contacts}/AddMultiple`, {
      AttendeeId: attendeeId,
      Contacts: contacts.map((x) => contact.toAPI(x)),
    });
  },
};
