import { GetterTree } from 'vuex';
import { NewsRefState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<NewsRefState, RootState> = {
  ['getNewsRefs']: state => state.newsRefs,
  ['getNewsRefsIsLoading']: state => state.newsRefs?.isLoading,
  ['getNewsRefsTotal']: state => state.newsRefs?.total,
  ['getNewsRefsSearchParams']: state => state.newsRefs?.searchParams,
};
