import { ActionTree } from 'vuex';
import { CalendarState } from './types';
import { RootState } from '../../types';
import { defaultBackendCalendar } from '@/shared/backend/calendar';
import { Logger } from 'fsts';
import moduleUtils from '../moduleUtils';
import { AxiosResponse } from 'axios';
import { Calendar } from '@/shared/model/calendar';
import { TypeOfPeriod1 as TypeOfPeriod } from '@/shared/model/periodTypes';

const logger = new Logger('actions.calendar');
export const actions: ActionTree<CalendarState, RootState> = {
  getCalendar(
    { commit, dispatch, getters, rootGetters },
    payload: {
      date: string;
      dateType: TypeOfPeriod;
      myBookings?: boolean;
      companyId?: string;
    }
  ) {
    const filters =
      rootGetters['appointmentModule/getSelectedFiltersForCalendar'];
    commit('setItemsIsLoading', true);
    return defaultBackendCalendar
      .getCalendar(payload, filters)
      .then((response: AxiosResponse<Calendar[]>) => {
        commit('setItems', response.data);
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setItemsIsLoading', false);
      });
  },
  getSingleAppointment({ commit, rootGetters }, payload: {
    date: string;
    dateType: TypeOfPeriod;
    myBookings?: boolean;
    companyId?: string;
    appointmentId: string;
  }) {
    const filters =
      rootGetters['appointmentModule/getSelectedFiltersForCalendar'];
    return defaultBackendCalendar
      .getCalendar(payload, filters)
      .then((response: AxiosResponse<Calendar[]>) => {
        const appointment = response.data.find(x => x.appointments.length > 0)?.appointments[0];
        return appointment;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  clear({ commit }) {
    commit('clear');
  },
};
