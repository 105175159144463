import { GetterTree } from 'vuex';
import { PresenceAttendanceState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<PresenceAttendanceState, RootState> = {
  ['getPresenceAttendances']: (state) => state.presenceAttendances,
  ['getPresenceAttendancesIsLoading']: (state) =>
    state.presenceAttendances?.isLoading,
  ['getPresenceAttendancesTotal']: (state) => state.presenceAttendances?.total,
  ['getPresenceAttendancesSearchParams']: (state) =>
    state.presenceAttendances?.searchParams,
};
