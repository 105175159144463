import { MutationTree } from 'vuex';
import { DepartmentRefState } from './types';
import departmentRef, { Value } from '@/shared/model/departmentRef';

export const mutations: MutationTree<DepartmentRefState> = {
  setDepartmentRefs(state, payload: Value) {
    state.departmentRefs.items = payload.value.map(x => departmentRef.parse(x));
    state.departmentRefs.total = payload['@odata.count'] || 0;
    state.departmentRefs.isLoading = false;
  },
  clearDepartmentRefs(state) {
    state.departmentRefs.items = [];
    state.departmentRefs.total = 0;
    state.departmentRefs.isLoading = false;
  },
  setDepartmentRefsTotal(state, payload: number) {
    state.departmentRefs.total = payload;
  },
  setDepartmentRefsIsLoading(state, payload: boolean) {
    state.departmentRefs.isLoading = payload;
  },
};
