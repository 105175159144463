import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { DefaultBackendHelper } from '../utils/backendHelper';
import membershipTypeCostOverride, {
  Value,
  MembershipTypeCostOverride,
} from '../model/membershipTypeCostOverride';
import { SearchParams } from '../model/searchParams';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/constants';

const logger = new Logger('backend.membershipTypeCostOverride');

export interface BackendMembershipTypeCostOverride {
  getMembershipTypeCostOverrides: (
    searchParams: SearchParams,
    membershipTypeId?: string
  ) => AxiosPromise<Value>;
  postMultipleMembershipTypeCostOverrides(
    membershipTypeId: string,
    costOverrides: MembershipTypeCostOverride[]
  ): AxiosPromise;
}

export const DefaultBackendMembershipTypeCostOverride: BackendMembershipTypeCostOverride =
  {
    getMembershipTypeCostOverrides(
      searchParams: SearchParams,
      membershipTypeId?: string
    ): AxiosPromise<Value> {
      let odfb = ODataFilterBuilder('and');

      if (membershipTypeId != undefined) {
        odfb.eq(CONST.MembershipTypeId, membershipTypeId, false);
      }

      let url = DefaultBackendHelper.makeUrl(
        `${URLS.membershipTypeCostOverridesOdata}`,
        searchParams.dataOption,
        searchParams.orClauseFieldsIds,
        searchParams.filter,
        odfb
      );

      logger.log(`getMembershipTypeCostOverrides: ${url}`);
      return instance.get<Value>(url);
    },
    postMultipleMembershipTypeCostOverrides(
      membershipTypeId: string,
      costOverrides: MembershipTypeCostOverride[]
    ): AxiosPromise {
      let url = `${URLS.membershipTypeCostOverrides}/AddMultiple`;

      logger.log(`postMultipleMembershipTypeCostOverrides: ${url}`);
      return instance.post(url, {
        MembershipTypeId: membershipTypeId,
        MemberStatusCostEntries:
          membershipTypeCostOverride.arrayToAPI(costOverrides),
      });
    },
  };
