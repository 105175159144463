import { ActionTree } from 'vuex';
import { RoomState } from './types';
import { RootState } from '../../types';
import { defaultBackendRoom } from '@/shared/backend/room';
import { Logger } from 'fsts';
import moduleUtils from '../moduleUtils';
import { Value, Room } from '@/shared/model/room';
import { AxiosResponse } from 'axios';
import { i18nGlobal } from '@/i18n';
import { CONST } from '../../../utils/constants';
import { deepClone } from '@/shared/utils/generalUtils';
import { createSearchParams } from '@/shared/model/searchParams';

const logger = new Logger('actions.rooms');
export const actions: ActionTree<RoomState, RootState> = {
  getRoomsOData(
    { commit, dispatch, getters, rootGetters },
    payload?: { searchParams?: any; locationIds?: string[]; companyId?: string }
  ) {
    commit('setRoomsIsLoading', true);
    const searchParams = deepClone(
      payload?.searchParams ?? getters.getRoomsSearchParams
    );

    const locationIds = payload?.locationIds ?? [];
    const companyId = payload?.companyId ?? CONST.emptyGuid;
    return defaultBackendRoom
      .getRoomsOData(searchParams, locationIds, companyId)
      .then((response: AxiosResponse<Value>) => {
        commit('setRooms', response.data);
        commit('setRoomsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setRoomsIsLoading', false);
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  getRooms(
    { commit, dispatch, getters, rootGetters },
    payload?: {
      searchParams?: any;
      locationId?: string;
      availableAt?: string;
      noStore?: boolean;
    }
  ) {
    commit('setRoomsIsLoading', true);
    let searchParams = payload?.searchParams ?? getters.getRoomsSearchParams;
    let locationId = payload?.locationId ?? CONST.emptyGuid;
    let availableAt = payload?.availableAt ?? undefined;
    let noStore = payload?.noStore ?? false;

    return defaultBackendRoom
      .getRooms(searchParams, locationId, availableAt)
      .then((response: AxiosResponse<Value>) => {
        if (!noStore) commit('setRooms', response.data);
        commit('setRoomsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setRoomsIsLoading', false);
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  getAllRoomsAlphabetically(
    { dispatch },
    payload?: {
      locationIds?: string[];
      locationId?: string;
      companyId?: string;
    }
  ) {
    return dispatch('getRoomsOData', {
      locationIds:
        payload?.locationIds ??
        (payload?.locationId != undefined ? [payload.locationId] : undefined),
      searchParams: createSearchParams(-1, 1, ['name'], [false]),
      companyId: payload?.companyId,
    });
  },
  getRoom({ commit, dispatch }, id: string) {
    return defaultBackendRoom
      .getRoom(id)
      .then((response: AxiosResponse<Room>) => {
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateRoom({ commit, dispatch }, file: Room) {
    return defaultBackendRoom
      .updateRoom(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Room;
          }>
        ) => {
          moduleUtils.ok(i18nGlobal.t(`success.room_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteRoom({ commit, dispatch }, id: string) {
    return defaultBackendRoom
      .deleteRoom(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Room;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
};
