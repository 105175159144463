import { GetterTree } from 'vuex';
import { MembershipApplicationState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<MembershipApplicationState, RootState> = {
  ['getLocation']: (state) => state.location,
  ['getAttendee']: (state) => state.attendee,
  ['getCompany']: (state) => state.company,
  ['getMembershipTypes']: (state) => state.membershipTypes,
  ['getCosts']: (state) => state.costs,
  ['getMemberStatuses']: (state) => state.memberStatuses,
  ['getMembershipTypeCostOverrides']: (state) =>
    state.membershipTypeCostOverrides,
  ['getCostDiscounts']: (state) => state.costDiscounts,
  ['getCostMembershipTypes']: (state) => state.costMembershipTypes,
  ['isLoading']: (state) => state.isLoading,
  ['getApplicationConfirmationData']: (state) =>
    state.applicationConfirmationData,
};
