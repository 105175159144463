import { CONST } from '../utils/constants';
import DateUtils from '../utils/dateUtils';
import { i18nGlobal } from '@/i18n';

export type InvoicePeriodType = 0 | 1 | 2 | 3;
export const InvoicePeriodType = {
  Month: 0 as InvoicePeriodType,
  Quarter: 1 as InvoicePeriodType,
  Single: 2 as InvoicePeriodType,
  Year: 3 as InvoicePeriodType,
};

export type InvoicePaymentStatus = 0 | 1 | 2;
export const InvoicePaymentStatus = {
  Open: 0 as InvoicePaymentStatus,
  Paid: 1 as InvoicePaymentStatus,
  Cancelled: 2 as InvoicePaymentStatus,
};

export const MidMonthThresholdDay = 15;

export interface Invoice {
  id: string; // System.Guid
  invoiceNumber: number;
  attendeeAppUserId: string; // System.Guid,
  attendeeSalutation: string;
  attendeeTitle: string;
  attendeeLastName: string;
  attendeeFirstName: string;
  attendeeFullName: string;
  attendeeZip: string;
  attendeeCity: string;
  attendeeStreet: string;
  periodStartDate: string; // System.DateTime
  periodTillDate: string; // System.DateTime
  periodType: InvoicePeriodType;
  paymentStatus: InvoicePaymentStatus;
  dueDate?: string; // System.DateTime
  exported: boolean;
  exportedDateTime?: string; // System.DateTime
  exportedByEmployeeId?: string; // System.Guid
  markedPaidDateTime?: string; // System.DateTime
  markedPaidByEmployeeId?: string; // System.Guid
  markedCancelledDateTime?: string; // System.DateTime
  markedCancelledByEmployeeId?: string; // System.Guid
  companyId: string; // System.Guid
  createdDate: string; // System.DateTime
  complete: boolean;
  centCostSum: number;
  memberNumber: string;
  readonly euroCostSum: number;
  readonly typeString: string;
  readonly periodString: string;
}

export interface Value {
  value: Invoice[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

function toAPI(data: Partial<Invoice>): any {
  return {
    Id: data?.id ?? CONST.emptyGuid,
    InvoiceNumber: data?.invoiceNumber ?? 0,
    AttendeeAppUserId: data?.attendeeAppUserId ?? CONST.emptyGuid,
    AttendeeSalutation: data?.attendeeSalutation ?? '',
    AttendeeTitle: data?.attendeeTitle ?? '',
    AttendeeLastName: data?.attendeeLastName ?? '',
    AttendeeFirstName: data?.attendeeFirstName ?? '',
    AttendeeZip: data?.attendeeZip ?? '',
    AttendeeStreet: data?.attendeeStreet ?? '',
    AttendeeCity: data?.attendeeCity ?? '',
    PeriodStartDate:
      data?.periodStartDate ?? new Date().toISOString().substring(0, 10),
    PeriodType: data?.periodType ?? InvoicePeriodType.Single,
    PaymentStatus: data?.paymentStatus ?? InvoicePaymentStatus.Open,
    DueDate: DateUtils.dateNullableToApi(data?.dueDate),
    Exported: data?.exported ?? false,
    ExportedDateTime: DateUtils.dateNullableToApi(data?.exportedDateTime),
    ExportedByEmployeeId: data?.exportedByEmployeeId ?? undefined,
    MarkedPaidDateTime: DateUtils.dateNullableToApi(data?.markedPaidDateTime),
    MarkedPaidByEmployeeId: data?.markedPaidByEmployeeId ?? undefined,
    MarkedCancelledDateTime: DateUtils.dateNullableToApi(
      data?.markedCancelledDateTime
    ),
    MarkedCancelledByEmployeeId: data?.markedCancelledByEmployeeId ?? undefined,
    CompanyId: data?.companyId ?? CONST.emptyGuid,
    CreatedDate: data?.createdDate ?? new Date().toISOString().substring(0, 10),
  };
}

function parse(data: Partial<Invoice>): Invoice {
  let periodType =
    data?.periodType ?? getPeriodTypeText(InvoicePeriodType.Single);
  let paymentStatus =
    data?.paymentStatus ?? getPaymentStatusText(InvoicePaymentStatus.Open);

  return {
    id: data?.id ?? CONST.emptyGuid,
    invoiceNumber: data?.invoiceNumber ?? 0,
    attendeeAppUserId: data?.attendeeAppUserId ?? CONST.emptyGuid,
    attendeeSalutation: data?.attendeeSalutation ?? '',
    attendeeTitle: data?.attendeeTitle ?? '',
    attendeeLastName: data?.attendeeLastName ?? '',
    attendeeFirstName: data?.attendeeFirstName ?? '',
    attendeeFullName: data?.attendeeFullName ?? '',
    attendeeZip: data?.attendeeZip ?? '',
    attendeeCity: data?.attendeeCity ?? '',
    attendeeStreet: data?.attendeeStreet ?? '',
    periodStartDate:
      data?.periodStartDate ?? new Date().toISOString().substring(0, 10),
    periodTillDate:
      data?.periodTillDate ?? new Date().toISOString().substring(0, 10),
    periodType: InvoicePeriodType[periodType as keyof typeof InvoicePeriodType],
    paymentStatus:
      InvoicePaymentStatus[paymentStatus as keyof typeof InvoicePaymentStatus],
    dueDate: data?.dueDate ?? undefined,
    exported: data?.exported ?? false,
    exportedDateTime: data?.exportedDateTime ?? undefined,
    exportedByEmployeeId: data?.exportedByEmployeeId ?? undefined,
    markedPaidDateTime: data?.markedPaidDateTime ?? undefined,
    markedPaidByEmployeeId: data?.markedPaidByEmployeeId ?? undefined,
    markedCancelledDateTime: data?.markedCancelledDateTime ?? undefined,
    markedCancelledByEmployeeId: data?.markedCancelledByEmployeeId ?? undefined,
    companyId: data?.companyId ?? CONST.emptyGuid,
    createdDate: data?.createdDate ?? new Date().toISOString().substring(0, 10),
    complete: data?.complete ?? false,
    centCostSum: data?.centCostSum ?? 0,
    memberNumber: data?.memberNumber ?? '',
    get euroCostSum(): number {
      return this.centCostSum * 0.01;
    },
    get typeString(): string {
      switch (this.periodType) {
        case InvoicePeriodType.Quarter:
          return i18nGlobal.t('app.invoice.collective-invoice-quarterly');
        case InvoicePeriodType.Month:
          return i18nGlobal.t('app.invoice.collective-invoice-monthly');
        case InvoicePeriodType.Year:
          return i18nGlobal.t('app.invoice.collective-invoice-yearly');
        default:
          return i18nGlobal.t('app.invoice.single-invoice');
      }
    },
    get periodString(): string {
      if (this.periodType == InvoicePeriodType.Single) {
        return `${DateUtils.formatDateWithLocale(this.periodStartDate)}`;
      }

      return `${DateUtils.formatDateWithLocale(
        this.periodStartDate
      )} - ${DateUtils.formatDateWithLocale(this.periodTillDate)}`;
    },
  };
}

function getPeriodTypeText(type: InvoicePeriodType) {
  return Object.keys(InvoicePeriodType)[
    Object.values(InvoicePeriodType).findIndex((entry) => entry == type)
  ];
}

export function getPaymentStatusText(type: InvoicePaymentStatus) {
  return Object.keys(InvoicePaymentStatus)[
    Object.values(InvoicePaymentStatus).findIndex((entry) => entry == type)
  ];
}

export default {
  parse,
  toAPI: toAPI,
};
