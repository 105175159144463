import { CONST } from '../utils/constants';
import { InvoicePaymentStatus } from './invoice';
import { getPaymentStatusText } from './invoice';

export interface InvoicePosition {
  id: string; // System.Guid
  invoiceId: string; // System.Guid
  description: string;
  note?: string;
  costInfo?: string;
  readonly euroCost: number;
  centCost: number;
  canceled: boolean;
  sourceCostId?: string; // System.Guid
  sourceDateTimeId?: string; // System.Guid
  sourceAppointmentId?: string; // System.Guid
  sourceMembershipTypeId?: string; // System.Guid
  companyId: string; // System.Guid
  createdDate: string; //System.DateTime
  canceledDate?: string; //System.DateTime
  canceledById?: string; //System.Guid
  canceledByFullName: string;
  editedDate?: string; //System.DateTime
  editedById?: string; //System.Guid
  invoicePaymentStatus: InvoicePaymentStatus;
  attendeeFullName: string;
  exported: boolean;
  memberNumber: string;
  invoiceNumberString: string;
}

export interface Value {
  value: InvoicePosition[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

function toAPI(data: Partial<InvoicePosition>): any {
  return {
    Id: data?.id ?? CONST.emptyGuid,
    InvoiceId: data?.invoiceId ?? CONST.emptyGuid,
    Description: data?.description ?? '',
    Note: data?.note ?? undefined,
    CentCost: data?.centCost ?? 0,
    Canceled: data?.canceled ?? false,
    SourceCostId: data?.sourceCostId ?? undefined,
    SourceDateTimeId: data?.sourceDateTimeId ?? undefined,
    SourceAppointmentId: data?.sourceAppointmentId ?? undefined,
    SourceMembershipTypeId: data?.sourceMembershipTypeId ?? undefined,
    CompanyId: data?.companyId ?? CONST.emptyGuid,
    CreatedDate: data?.createdDate ?? new Date().toISOString().substring(0, 10),
    CanceledDate: data?.canceledDate ?? undefined,
    CanceledById: data?.canceledById ?? undefined,
    EditedDate: data?.editedDate ?? undefined,
    EditedById: data?.editedById ?? undefined,
  };
}

function parse(data: Partial<InvoicePosition>): InvoicePosition {
  let paymentStatus =
    data?.invoicePaymentStatus ??
    getPaymentStatusText(InvoicePaymentStatus.Open);

  return {
    id: data?.id ?? CONST.emptyGuid,
    invoiceId: data?.invoiceId ?? CONST.emptyGuid,
    description: data?.description ?? '',
    note: data?.note ?? undefined,
    costInfo: data?.costInfo ?? undefined,
    get euroCost() {
      return this.centCost * 0.01;
    },
    centCost: data?.centCost ?? 0,
    canceled: data?.canceled ?? false,
    sourceCostId: data?.sourceCostId ?? undefined,
    sourceDateTimeId: data?.sourceDateTimeId ?? undefined,
    sourceAppointmentId: data?.sourceAppointmentId ?? undefined,
    sourceMembershipTypeId: data?.sourceMembershipTypeId ?? undefined,
    companyId: data?.companyId ?? CONST.emptyGuid,
    createdDate: data?.createdDate ?? new Date().toISOString().substring(0, 10),
    canceledDate: data?.canceledDate ?? undefined,
    canceledById: data?.canceledById ?? undefined,
    canceledByFullName: data?.canceledByFullName ?? '',
    editedDate: data?.editedDate ?? undefined,
    editedById: data?.editedById ?? undefined,
    invoicePaymentStatus:
      InvoicePaymentStatus[paymentStatus as keyof typeof InvoicePaymentStatus],
    attendeeFullName: data?.attendeeFullName ?? '',
    exported: data?.exported ?? false,
    memberNumber: data?.memberNumber ?? '',
    invoiceNumberString: data?.invoiceNumberString ?? '0',
  };
}

export const EmptyInvoicePosition = parse({});

export default {
  parse,
  toAPI: toAPI,
};
