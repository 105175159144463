import { MutationTree } from 'vuex';
import { CountryManagementState } from './types';
import { Country } from '../../../model/country';
import { initialState } from './state';

export const mutations: MutationTree<CountryManagementState> = {
  setCountries(state: CountryManagementState, payload: Country[]) {
    state.countries = payload;
  },
  reset(state) {
    const initState = initialState();
    Object.assign(state, initState);
  },
};
