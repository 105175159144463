import moment, { Moment } from 'moment';
import { CONST } from '../utils/constants';
import { i18nGlobal } from '@/i18n';

export interface Location {
  id: string;
  name: string;
  email: string;
  nr: number;
  lat: number;
  lng: number;
  active: boolean;
  zip: string;
  street: string;
  city: string;
  phone: string;
  responsibleIds: string[];
  responsibleFullNames: string;
  isQrCodesCreated: boolean;
  createdDate: string;
  currentUtcDateTime: Moment;
  companyId: string;
  departmentNames: string;
  companyName: string;
  readonly fullAddress: string;
}

export interface Value {
  value: Location[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<Location>): any {
  return {
    Id: data?.id || CONST.emptyGuid,
    Name: data?.name || '',
    Email: data?.email || '',
    Lat: data?.lat || 0,
    Lng: data?.lng || 0,
    Active: data?.active || false,
    Zip: data?.zip || '',
    Street: data?.street || '',
    City: data?.city || '',
    Phone: data?.phone || '',
    CompanyId: data?.companyId || CONST.emptyGuid,
  };
}

function parse(data?: Partial<Location>): Location {
  return {
    id: data?.id || '',
    name: data?.name || '',
    email: data?.email || '',
    nr: data?.nr || 0,
    lat: data?.lat || 0,
    lng: data?.lng || 0,
    active: data?.active || false,
    zip: data?.zip || '',
    street: data?.street || '',
    city: data?.city || '',
    phone: data?.phone || '',
    responsibleIds: data?.responsibleIds || [],
    responsibleFullNames: data?.responsibleFullNames || '',
    isQrCodesCreated: data?.isQrCodesCreated || false,
    currentUtcDateTime: moment(data?.currentUtcDateTime),
    createdDate: data?.createdDate || new Date().toISOString().substring(0, 10),
    companyId: data?.companyId || CONST.emptyGuid,
    departmentNames: data?.departmentNames || '',
    companyName: data?.companyName || '',
    fullAddress: `${data?.street}, ${data?.zip} ${data?.city}`,
  };
}

export const AllLocation: Location = parse({
  name: i18nGlobal.t('app.all_locations'),
});

export default {
  parse,
  toAPI: toAPI,
};
