import { CONST } from '../utils/constants';
import { formatFullName } from '../utils/generalUtils';
import { i18nGlobal } from '@/i18n';

export interface Department {
  id: string; // System.Guid
  name: string;
  responsibleFullNames: string;
  responsibleId?: string; // System.Guid
  creatorFullName: string;
  creatorId?: string; // System.Guid
  companyId?: string; // System.Guid
  createdDate: string; // System.DateTime
}

export interface Value {
  value: Department[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<Department>): any {
  return {
    Id: data?.id || CONST.emptyGuid,
    Name: data?.name || '',
    ResponsibleId: data?.responsibleId,
  };
}

function parse(data?: Partial<Department>): Department {
  return {
    id: data?.id || '',
    name: data?.name || '',
    responsibleFullNames: data?.responsibleFullNames || '-',
    responsibleId: data?.responsibleId,
    creatorFullName: formatFullName(data?.creatorFullName),
    creatorId: data?.creatorId,
    companyId: data?.companyId,
    createdDate: data?.createdDate || new Date().toISOString().substring(0, 10),
  };
}

export const EmptyDepartment = parse({});

export const AllDepartment: Department = parse({
  name: i18nGlobal.t('app.all_departments'),
});

export default {
  parse,
  toAPI: toAPI,
};
