import { MutationTree } from 'vuex';
import { ReferenceParentState } from './types';
import referenceParent, {
  ReferenceParent,
  Value,
} from '@/shared/model/referenceParent';

export const mutations: MutationTree<ReferenceParentState> = {
  setReferenceParent(state, payload: ReferenceParent) {
    state.referenceParent = referenceParent.parse(payload);
  },
  setReferenceParents(state, payload: Value) {
    state.referenceParents.items = payload.value.map((x) =>
      referenceParent.parse(x)
    );
    state.referenceParents.total = payload['@odata.count'] || 0;
    state.referenceParents.isLoading = false;
  },
  setAllReferenceParents(state, payload: ReferenceParent[]) {
    state.referenceParents.items = payload.map((x) => referenceParent.parse(x));
    state.referenceParents.total = payload.length;
    state.referenceParents.isLoading = false;
  },
  setReferenceParentsTotal(state, payload: number) {
    state.referenceParents.total = payload;
  },
  setReferenceParentsIsLoading(state, payload: boolean) {
    state.referenceParents.isLoading = payload;
  },
  resetReferenceParentsPaging(state) {
    state.referenceParents.searchParams.dataOption.page = 1;
    state.referenceParents.searchParams.dataOption.itemsPerPage = 10;
    state.referenceParents.searchParams.dataOption.filter = '';
    state.referenceParents.searchParams.filter = '';
  },
};
