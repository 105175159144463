import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import news, { News, PUBLISH_USER_PERMISSION, Value } from '../model/news';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/constants';

const logger = new Logger('backend.News');
export interface BackendNews {
  getNews(id: string): AxiosPromise<News>;
  getNewss: (searchParams: SearchParams) => AxiosPromise<Value>;
  getNewsAnonymous: (
    searchParams: SearchParams,
    companyId: string,
    locationId: string
  ) => AxiosPromise<Value>;
  getNewssForCurrentUser(
    searchParams: SearchParams,
    locationIds: string[],
    departmentIds: string[]
  ): AxiosPromise<Value>;
  deleteNews(id: string): AxiosPromise;
  updateNews(News: News): AxiosPromise<any>;
  sendWebPushMessages(
    newsId: string,
    locationIds: string[],
    departmentIds: string[]
  ): AxiosPromise<any>;
}

export const defaultBackendNews: BackendNews = {
  getNews(id: string): AxiosPromise<News> {
    let url = `${URLS.newsOdata}/${id}`;
    return instance.get<News>(url);
  },
  getNewss(searchParams: SearchParams): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.newsOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getNewss${url}`);
    return instance.get<Value>(url);
  },

  getNewsAnonymous(
    searchParams: SearchParams,
    companyId: string,
    locationId: string
  ): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    odfb.contains(CONST.UserPermissions, PUBLISH_USER_PERMISSION);
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.newsOdata}/GetNewsAnonymous(companyId='${companyId}',locationIds='${locationId}')`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getNewss${url}`);
    return instance.get<Value>(url);
  },

  getNewssForCurrentUser(
    searchParams: SearchParams,
    locationIds: string[],
    departmentIds: string[]
  ): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    let locationIdsString = locationIds.join(',');
    let departmentIdsString = departmentIds.join(',');

    let url = DefaultBackendHelper.makeUrl(
      `${URLS.newsOdata}/GetForCurrentUser(locationIds='${locationIdsString}',departmentIds='${departmentIdsString}')`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );

    logger.log(`getNewssForCurrentUser${url}`);
    return instance.get<Value>(url);
  },
  deleteNews(id: string): AxiosPromise {
    logger.debug('deleteNews');
    return instance.delete(`${URLS.news}/${id}`);
  },
  updateNews(News: News): AxiosPromise<any> {
    logger.debug('updateNews');
    if (News.id != undefined) {
      return instance.put<News>(`${URLS.news}`, news.toAPI(News));
    } else {
      return instance.post<News>(`${URLS.news}`, news.toAPI(News));
    }
  },
  sendWebPushMessages(
    newsId: string,
    locationIds: string[],
    departmentIds: string[]
  ): AxiosPromise<any> {
    logger.debug('sendWebPushMessages');
    return instance.post(`${URLS.news}/SendWebPushMessages`, {
      NewsId: newsId,
      LocationIds: locationIds,
      DepartmentIds: departmentIds,
    });
  },
};
