import company from '@/shared/model/company';
import { CompanyState } from './types';

export const state = (): CompanyState => initialState();
export const initialState = (): CompanyState => ({
  companies: [],
  currentCompany: company.parse({}),
  totalAttendees: 0,
  maxAttendees: undefined,
  companyCountry: '',
  isLoadingCompanies: false,
});
