
import { GetterTree } from 'vuex';
import { QrCodeState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<QrCodeState, RootState> = {
  ['getQrCodes']: (state) => state.qrCodes,
  ['getQrCodesIsLoading']: (state) => state.qrCodes?.isLoading,
  ['getQrCodesTotal']: (state) => state.qrCodes?.total,
  ['getQrCodesSearchParams']: (state) => state.qrCodes?.searchParams,
};
