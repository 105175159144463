import { MutationTree } from 'vuex';
import { TrainerState } from './types';
import trainer, { Trainer, Value } from '@/shared/model/trainer';

export const mutations: MutationTree<TrainerState> = {
  setTrainers(state, payload: Value) {
    state.trainers.items = payload.value.map((x) => trainer.parse(x));
    state.trainers.total = payload['@odata.count'] || 0;
    state.trainers.isLoading = false;
  },
  setTrainersTotal(state, payload: number) {
    state.trainers.total = payload;
  },
  setAllTrainers(state, payload: Trainer[]) {
    state.trainers.items = payload.map((x) => trainer.parse(x));
    state.trainers.total = payload.length;
    state.trainers.isLoading = false;
  },
  setTrainersIsLoading(state, payload: boolean) {
    state.trainers.isLoading = payload;
  },
  resetTrainersPaging(state) {
    state.trainers.searchParams.dataOption.page = 1;
    state.trainers.searchParams.dataOption.itemsPerPage = 10;
    state.trainers.searchParams.dataOption.filter = '';
    state.trainers.searchParams.filter = '';
  },
};
