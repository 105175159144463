import DateUtils from '../utils/dateUtils';

export enum DocumentTypes {
  logo = 'logo',
  document = 'document',
  employee = 'employee',
  location = 'location',
  trainer = 'trainer',
  trainer_license = 'trainer_license',
  course = 'course',
  attendee = 'attendee',
  terms_and_conditions = 'terms_and_conditions',
  privacy_policy = 'privacy_policy'
}

export interface Document {
  id?: string;
  companyId?: string;
  parentId?: string;

  title: string;
  text: string;
  textShortened: string;
  documentType: string; // extension
  documentName: string; // extension
  documentSize: string; // extension

  type: string; // TODO: ask Chaslau about Type, For and, Name + Description https://www.sketch.com/s/701c893e-9409-4dff-8b54-5137c57f969d/a/Vl34ja
  for: string;
  category: string;
  visibleForUser: boolean;

  priorityVariantId?: string;
  active: boolean;
  isAllowComment: boolean;
  responsibleId?: string;
  responsibleName: string;
  creatorUserId?: string;
  creatorUserName: string;

  lastActionDate: string;
  createdDate: string;

  groupDocumentsCount: number;
  notificationCount: number;
}

function getShortenedText(text: string) {
  if (text.length > 15) {
    return text.substring(0, 15) + '...';
  }

  return text;
}

export const DocumentEmpty: Document = {
  id: '',
  companyId: '',
  parentId: '',

  title: '',
  text: '',
  get textShortened() {
    return getShortenedText(this.text);
  },
  documentType: '', // extension
  documentName: '', // extension
  documentSize: '', // extension

  type: '',
  for: '',
  category: '',
  visibleForUser: true,

  priorityVariantId: '',
  active: false,
  isAllowComment: false,

  responsibleId: '',
  responsibleName: '',
  creatorUserId: '',
  creatorUserName: '',

  lastActionDate: '',
  createdDate: '',

  groupDocumentsCount: 0,
  notificationCount: 0,
};

export interface Value {
  value: Document[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<Document>): any {
  return {
    Id: data?.id || undefined,
    CompanyId: data?.companyId || undefined,
    ParentId: data?.parentId || undefined,

    Title: data?.title || '',
    Text: data?.text || '',
    DocumentType: data?.documentType || undefined,
    DocumentName: data?.documentName || undefined,
    DocumentSize: data?.documentSize || undefined,

    Type: data?.type || '',
    For: data?.for || '',
    Category: data?.category || null,
    VisibleForUser: data?.visibleForUser ?? true,

    PriorityVariantId: data?.priorityVariantId || undefined,
    Active: data?.active || false,
    IsAllowComment: data?.isAllowComment || false,

    ResponsibleId: data?.responsibleId || undefined,
    CreatorUserId: data?.creatorUserId || undefined,

    LastActionDate: DateUtils.formatDateTimeISO(data!.lastActionDate) || '',
    CreatedDate: DateUtils.formatDateTimeISO(data!.createdDate) || '',
  };
}

function parse(data?: Partial<Document>): Document {
  return {
    id: data!.id || undefined,
    companyId: data?.companyId || undefined,
    parentId: data?.parentId || undefined,

    title: data?.title || '',
    text: data?.text || '',
    get textShortened() {
      return getShortenedText(this.text);
    },
    documentType: data?.documentType || '',
    documentName: data?.documentName || '',
    documentSize: data?.documentSize || '',

    type: data?.type || '',
    for: data?.for || '',
    category: data?.category || '',
    visibleForUser: data?.visibleForUser ?? true,

    priorityVariantId: data?.priorityVariantId || undefined,
    active: data?.active || false,
    isAllowComment: data?.isAllowComment || false,
    responsibleId: data?.responsibleId || undefined,
    responsibleName: data?.responsibleName || '',
    creatorUserId: data?.creatorUserId || undefined,
    creatorUserName: data?.creatorUserName || '',

    lastActionDate: DateUtils.formatDateTimeISO(data!.lastActionDate) || '',
    createdDate: DateUtils.formatDateTimeISO(data!.createdDate) || '',

    groupDocumentsCount: data?.groupDocumentsCount || 0,
    notificationCount: data?.notificationCount || 0,
  };
}

export interface DocumentCategory {
  name: string;
  usageCount: number;
}

export interface DocumentCategoriesOData {
  value: DocumentCategory[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

export default {
  parse,
  toAPI: toAPI,
};
