import { MutationTree } from 'vuex';
import { DocumentState } from './types';
import document, {
  Document,
  Value,
  DocumentCategory,
} from '@/shared/model/document';

export const mutations: MutationTree<DocumentState> = {
  setDocument(state, payload?: Document) {
    state.document = payload;
  },
  resetDocument(state, payload?: Document) {
    state.document = Object.assign({}, payload);
  },
  setDocuments(state, payload: Value) {
    state.documents = payload.value.map((x) => document.parse(x));
    state.documentsTotal = payload['@odata.count'] || 0;
    state.documentsIsLoading = false;
  },
  setDocumentsTotal(state, payload: number) {
    state.documentsTotal = payload;
  },
  setDocumentsIsLoading(state, payload: boolean) {
    state.documentsIsLoading = payload;
  },
  setDocumentsNew(state, payload: Value) {
    state.documentsNew = payload.value.map((x) => document.parse(x));
    state.documentsNewTotal = payload['@odata.count'] || 0;
    state.documentsNewIsLoading = false;
  },
  setDocumentsNewTotal(state, payload: number) {
    state.documentsNewTotal = payload;
  },
  setDocumentsNewIsLoading(state, payload: boolean) {
    state.documentsNewIsLoading = payload;
  },
  setDocumentCategories(state, payload: DocumentCategory[]) {
    state.documentCategories = payload;
  },
  setDocumentCategoriesIsLoading(state, payload: boolean) {
    state.documentCategoriesIsLoading = payload;
  },
  resetDocumentsPaging(state) {
    state.documentsSearchParams.dataOption.page = 1;
    state.documentsSearchParams.dataOption.itemsPerPage = 10;
    state.documentsSearchParams.dataOption.filter = '';
    state.documentsSearchParams.filter = '';
  },
};
