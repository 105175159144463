import { MutationTree } from 'vuex';
import { StatisticsState } from './types';
import { StatisticsRequest, StatisticsResult } from '@/shared/model/statistics';
import { parseStatisticsResult, createInitialStatisticsRequest } from '@/shared/model/statistics';
import statisticsPreset from '@/shared/model/statisticsPreset';
import { Value } from '@/shared/model/statisticsPreset';

export const mutations: MutationTree<StatisticsState> = {
  setStatisticsResult(state, payload: StatisticsResult[]) {
    state.result = payload.map(x => parseStatisticsResult(x));
  },
  setStatisticsIsLoading(state, payload: boolean) {
    state.loading = payload;
  },
  resetStatisticsRequest(state) {
    state.request = createInitialStatisticsRequest();
  },
  setPresets(state, payload: Value) {
    state.presets.items = payload.value.map((x) => statisticsPreset.parse(x));
    state.presets.total = payload['@odata.count'] || 0;
    state.presets.isLoading = false;
  },
  setPresetsTotal(state, payload: number) {
    state.presets.total = payload;
  },
  setPresetsIsLoading(state, payload: boolean) {
    state.presets.isLoading = payload;
  },
  overrideRequest(state, request: StatisticsRequest) {
    Object.assign(state.request, request);
  },
};
