import { MutationTree } from 'vuex';
import { CourseTrainerState } from './types';
import courseTrainer, {
  CourseTrainer,
  Value,
} from '@/shared/model/courseTrainer';
import { SearchParams } from '@/shared/model/searchParams';
import { deepClone } from '@/shared/utils/generalUtils';

export const mutations: MutationTree<CourseTrainerState> = {
  setCourseTrainers(state, payload: Value) {
    state.courseTrainers.items = payload.value.map((x) =>
      courseTrainer.parse(x)
    );
    state.courseTrainers.total = payload['@odata.count'] || 0;
    state.courseTrainers.isLoading = false;
  },
  setAllCourseTrainers(state, payload: CourseTrainer[]) {
    state.courseTrainers.items = payload;
    state.courseTrainers.total = payload.length;
    state.courseTrainers.isLoading = false;
  },
  setCourseTrainersWithDateTime(state, payload: Value) {
    state.courseTrainersWithDateTime.items = payload.value.map((x) =>
      courseTrainer.parse(x)
    );
    state.courseTrainersWithDateTime.total = payload['@odata.count'] || 0;
    state.courseTrainersWithDateTime.isLoading = false;
  },
  setCourseTrainersTotal(state, payload: number) {
    state.courseTrainers.total = payload;
  },
  setCourseTrainersIsLoading(state, payload: boolean) {
    state.courseTrainers.isLoading = payload;
  },
  resetCourseTrainersPaging(state) {
    state.courseTrainers.searchParams.dataOption.page = 1;
    state.courseTrainers.searchParams.dataOption.itemsPerPage = 10;
    state.courseTrainers.searchParams.dataOption.filter = '';
    state.courseTrainers.searchParams.filter = '';
  },

  setCourseTrainersWithDateTimeIsLoading(state, payload: boolean) {
    state.courseTrainersWithDateTime.isLoading = payload;
  },
  setSearchParams(state, payload: SearchParams) {
    state.courseTrainersWithDateTime.searchParams = deepClone(payload);
  },
  setCourseTrainersWithDateTimePage(state, page: number) {
    state.courseTrainersWithDateTime.searchParams.dataOption.page = page;
  },
  resetCourseTrainersWithDateTimePaging(state) {
    state.courseTrainersWithDateTime.searchParams.dataOption.page = 1;
    state.courseTrainersWithDateTime.searchParams.dataOption.itemsPerPage = 5;
    state.courseTrainersWithDateTime.searchParams.dataOption.filter = '';
    state.courseTrainersWithDateTime.searchParams.filter = '';
  },
};
