import { MutationTree } from 'vuex';
import { CourseState } from './types';
import course, { Course, Value } from '@/shared/model/course';

export const mutations: MutationTree<CourseState> = {
  setCourses(state, payload: Value) {
    state.courses.items = payload.value.map((x) => course.parse(x));
    state.courses.total = payload['@odata.count'] || 0;
    state.courses.isLoading = false;
  },
  setCoursesByOptions(state, payload: Course[]) {
    state.courses.items = payload.map((x) => course.parse(x));
    state.courses.total = payload.length;
    state.courses.isLoading = false;
  },
  setCoursesTotal(state, payload: number) {
    state.courses.total = payload;
  },
  setCoursesIsLoading(state, payload: boolean) {
    state.courses.isLoading = payload;
  },
  resetCoursesPaging(state) {
    state.courses.searchParams.dataOption.page = 1;
    state.courses.searchParams.dataOption.itemsPerPage = 10;
    state.courses.searchParams.dataOption.filter = '';
    state.courses.searchParams.filter = '';
  },
};
