import {
  Vue,
  Component,
  toNative,
  Ref,
  Watch,
  Setup,
  Provide
} from 'vue-facing-decorator';
import { namespace, State, Action, Getter } from 'vuex-facing-decorator';
import { Ref as RefObject } from 'vue';
import { Snackbar } from '@/shared/model/snackbar';
import { Settings } from '@/shared/model/settings';
import { Credentials } from '@/shared/model/credentials';
import { Department } from '@/shared/model/department';
import { WebpushSubscriptionCheckStatus } from '@/shared/model/webPushSubscription';
import { WebPushMessage } from '@/shared/model/webPushMessage';
import { OdataItems } from '@/shared/model/odataItems';
import { localize } from '@vee-validate/i18n';
import { Logger } from 'fsts';
import moment from 'moment';
import { ROUTES } from '@/router/routesEnum';
import { isEmptyId } from '@/shared/utils/generalUtils';
import store from '@/shared/store';
import { defaultColor } from '@/plugins/vuetify';
import { Location } from '@/shared/model/location';
import { useIdleJS } from '@/shared/composables/useIdleJS';
import { useUpdate } from '@/shared/composables/useUpdate';
import useSignalR, { SignalRFunctions } from '@/shared/composables/useSignalR';
import OwnUserLocationDepartmentSelect from './ownUserLocationDepartmentSelect/ownUserLocationDepartmentSelect.vue';
import ExpandableElement from '../_common/expandableElement/expandableElement.vue';
import DialogBoxComponent from '../_common/dialogBox/dialogBox.vue';
import D4yImg from '../_common/d4yImg/d4yImg.vue';
import TermsAndConditionsGerman from '@/assets/pdf/AGB.pdf';
import TrialExpiryInformation from './trialExpiryInformation/trialExpiryInformation.vue';
import AskingDialogBox from './askingDialogBox/askingDialogBox.vue';

export interface DialogBoxFunctions {
  confirmOpen: Function;
  messageOpen: Function;
}

const authModule = namespace('auth');
const locationModule = namespace('locationModule');
const departmentModule = namespace('departmentModule');
const settingsManagement = namespace('settings');
const webPushModule = namespace('webPushModule');

@Component({
  components: {
    AskingDialogBox,
    OwnUserLocationDepartmentSelect,
    ExpandableElement,
    D4yImg,
    TrialExpiryInformation
  },
  emits: ['login'],
})
class AppComponent extends Vue {
  @Setup((props, ctx) => useIdleJS())
  private idleJS!: { isIdle: RefObject<boolean>; obj: any };
  @Setup((props, ctx) => useUpdate())
  private update!: { updateExists: RefObject<boolean>; refreshApp: Function };

  @Setup((props, ctx) => useSignalR())
  @Provide
  private signalR!: SignalRFunctions;

  @Provide
  private dialogBox: DialogBoxFunctions = {
    // Insert the proper functions after mounted
    confirmOpen: () => {},
    messageOpen: () => {},
  };

  @Provide
  private hasLoadedUserSettingsAfterLogin: boolean = false;

  @Ref('askingDialogBox')
  private dialogBoxElement: any;

  @State('snackbar') public stateSnackbar!: Snackbar;
  @Action('hideSnackbar') private releaseSnackbar: any;
  @authModule.State('isAdmin') private isAdmin!: boolean;
  @authModule.State('isTrainer') private isTrainer!: boolean;
  @authModule.State('isManager') private isManager!: boolean;
  @authModule.State('isEmployee') private isEmployee!: boolean;
  @authModule.State('isAttendee') private isAttendee!: boolean;
  @authModule.State('isDepartmentLeader') private isDepartmentLeader!: boolean;
  @authModule.Getter('isLoggedIn') private isLoggedIn!: boolean;
  @authModule.Action('logout') private actionLogout: any;
  @authModule.Getter('getUser') private getUser!: Credentials;
  @authModule.Getter('isEmailConfirmed') private isEmailConfirmed!: boolean;
  @authModule.Getter('getCompanyId') private getCompanyId!: string;
  @authModule.State('accountId') private stateAccountId!: string;
  @authModule.Action('resendConfirmationEmail')
  private actionResendConfirmationEmail: any;
  @authModule.Getter('getIsFacebookLogin')
  private getterIsFacebookLogin!: string;
  @authModule.Getter('getIsGoogleLogin')
  private getterIsGoogleLogin!: string;
  @authModule.Action('setLanguage')
  private actionSetLanguage!: any;

  @authModule.Getter('isTrialBeforeEnd') private isTrialBeforeEnd!: boolean;
  @authModule.Getter('isTrialExpired') private isTrialExpired!: boolean;
  @authModule.Getter('isNeedMoreLicenses') private isNeedMoreLicenses!: boolean;
  @authModule.Getter('trialDaysLeft')
  private trialDaysLeft!: number;

  @Getter('info')
  private apiInfo: any;
  @Getter('isKioskMode')
  private isKioskMode!: boolean;

  @settingsManagement.Action('getSettings') private actionGetSettings!: any;
  @settingsManagement.Action('resetSettingss')
  private actionResetSettingss!: any;

  @settingsManagement.Getter('getSettingss')
  private getterSettings!: Settings;

  @locationModule.Action('clearOwnUserLocations')
  private actionClearOwnUserLocations!: any;
  @locationModule.Action('getOwnUserLocations')
  private actionGetOwnUserLocations!: any;
  @locationModule.Action('getAllLocations')
  private actionGetAllLocations!: any;
  @locationModule.Action('setSelectedOwnUserLocation')
  private actionSetOwnUserLocation!: any;
  @locationModule.Getter('getOwnUserLocations')
  private ownUserLocations!: Location[];
  @locationModule.Getter('getSelectedOwnUserLocation')
  private selectedOwnUserLocation!: Location | undefined;

  @departmentModule.Action('clearOwnUserDepartments')
  private actionClearOwnUserDepartments!: any;
  @departmentModule.Action('getOwnUserDepartments')
  private actionGetOwnUserDepartments!: any;
  @departmentModule.Getter('getOwnUserDepartments')
  private ownUserDepartments!: Department[];
  @departmentModule.Getter('getSelectedOwnUserDepartment')
  private selectedOwnUserDepartment!: Department | undefined;
  @departmentModule.Action('setSelectedOwnUserDepartment')
  private actionSetOwnUserDepartment!: any;

  @webPushModule.Action('checkBrowserCompatibility')
  private actionCheckBrowserCompatibility!: any;
  @webPushModule.Action('retrievePermissionStateAndSubscriptionInBrowser')
  private actionRetrievePermissionStateAndSubscriptionInBrowser!: any;
  @webPushModule.Action('checkSubscriptionStatus')
  private actionCheckSubscriptionStatus!: any;
  @webPushModule.Action('askForNotificationPermission')
  private actionAskForNotificationPermission!: any;
  @webPushModule.Action('subscribe')
  private actionSubscribe!: any;
  @webPushModule.Action('deactivateSubscription')
  private actionDeactivateSubscription!: any;
  @webPushModule.Action('getMessages')
  private actionGetWebPushMessages!: any;
  @webPushModule.Action('markAllMessagesRead')
  private actionMarkAllWebPushMessagesRead!: any;
  @webPushModule.Mutation('setMessagesPage')
  private mutationSetWebPushMessagesPage!: any;
  @webPushModule.Getter('isIncompatibleBrowser')
  private isIncompatibleBrowser!: boolean;
  @webPushModule.Getter('getPermissionState')
  private webPushPermissionState!: PermissionState;
  @webPushModule.Getter('hasPushSubscription')
  private hasPushSubscription!: boolean;
  @webPushModule.Getter('getPushSubscription')
  private pushSubscription!: PushSubscription;
  @webPushModule.Getter('getWebPushSubscriptionStatus')
  private pushSubscriptionCheckStatus!: WebpushSubscriptionCheckStatus;
  @webPushModule.Getter('getMessages')
  private webPushMessages!: OdataItems<WebPushMessage>;
  @webPushModule.Getter('getMessagesPage')
  private webPushMessagesPage!: number;
  @webPushModule.Getter('getMessagesMaxPage')
  private webPushMessagesMaxPage!: number;

  private version = process.env.VERSION;
  private packageVersion = this.version + '-' + process.env.NODE_ENV;
  private versionInfo = `Version: ${this.version} (${process.env.GIT_VERSION})`;

  get apiVersionInfo() {
    return this.apiInfo
      ? this.apiInfo
      : {
          version: { SemVer: '', CommitDate: '', InformationalVersion: '' },
          commit: { Sha: '', ShortSha: '' },
        };
  }

  get termsUrl() {
    return TermsAndConditionsGerman;
  }

  get imprintUrl() {
    return 'https://attmag-4kgedlvufo.live-website.com/impressum/';
  }

  get supportMailToUrl() {
    return 'mailto:support@attmag.de';
  }

  private showMenu = false;

  private locales = [
    { text: 'Deutsch', value: 'de' },
    { text: 'Englisch', value: 'en' },
  ];
  private selectedLocale = this.locales[0];

  drawer = false;
  group = null;

  logger = new Logger('app.ts');

  private menuSubscription: boolean = true;
  private menuResendEmail: boolean = true;
  private askForWebPushSubscription: boolean = false;

  private comboboxWidthPixelFactor: number = 15;
  private comboboxWidthPixelOffset: number = 10;

  private appbarWidth: number | null = null;

  // computed
  get snackbar() {
    return this.stateSnackbar;
  }

  toggleMini = false;
  get mini() {
    return this.toggleMini;
  }

  get mobile() {
    return this.$vuetify.display.mobile;
  }

  set mini(newName) {}

  private setLocale(locale: string) {
    localStorage.setItem('locale', locale);
    localize(locale);
    this.$root!.$i18n.locale = locale;
    moment.locale(locale);

    //Save the language on the server aswell
    if (this.isLoggedIn) {
      this.actionSetLanguage(locale);
    }
  }

  mounted() {
    // Provide confirmOpen and messageOpen to all children components
    this.dialogBox.confirmOpen = this.dialogBoxElement.openConfirm;
    this.dialogBox.messageOpen = this.dialogBoxElement.openMessageBox;

    let locale = localStorage.getItem('locale') || this.$i18n.locale; // KPTM-388: if getItem('locale') is NULL on the 1st site visit, then take locale from the VueI18n config
    this.setLocale(locale);
    if (locale == 'en') {
      this.selectedLocale = this.locales[1];
    } else {
      this.selectedLocale = this.locales[0];
    }

    //The appbar width on a smartphone needs to be fixated at the beginning.
    //Its width changes when using the navigation menu.
    if (this.mobile) {
      this.appbarWidth = this.$vuetify.display.width;
    }

    console.log('================================');
    console.log(
      'Write window.LOG_LEVEL = "DEBUG" in browser console to view all messages.'
    );
    console.log('================================');
  }

  private logout() {
    this.leaveSignalRCompanyGroup(this.getCompanyId);
    this.actionResetSettingss();
    this.setThemeColor(defaultColor);
    this.actionLogout().then((result: any) => {
      this.$router.push('/login');
    });
  }

  get user() {
    return this.getUser == null ? '' : this.getUser;
  }

  private join() {
    let locationId = '';
    if (
      this.currentRouteName === ROUTES.public_view ||
      this.currentRouteName === ROUTES.attendee_registration_page ||
      (this.currentRouteName === ROUTES.attendee_registration &&
        this.$route.query.appointmentId) ||
      this.currentRouteName === ROUTES.attendee_registration_to_appointment
    ) {
      this.$router.push({
        name: 'login',
      });
      return;
    }
    if (this.$route.name == ROUTES.attendee_registration) {
      locationId = this.$route.query.locationId as string;
    } else if (this.$route.name == ROUTES.register_attendee) {
      locationId = this.$route.params.locationId as string;
    }

    if (!isEmptyId(locationId)) {
      this.$router.push({
        name: ROUTES.attendee_registration_page,
        query: { locationId: locationId },
      });
    } else {
      this.$router.push('/createAdmin');
    }
  }

  private resend() {
    this.actionResendConfirmationEmail(this.stateAccountId);
  }

  get actualMinutes() {
    return Math.floor(this.time / 60000);
  }
  get actualSeconds() {
    return (this.time % 60000) / 1000;
  }

  minutesToInactive = 30; //30 min
  time = this.minutesToInactive * 60000;
  startTimer() {
    let timerId = setInterval(() => {
      this.time -= 1000;
      if (!store.getters['autologoutTimeEnabled'] == true) {
        clearInterval(timerId);
      }
      if (!this.idleJS.isIdle.value == true) {
        this.time = this.minutesToInactive * 60000;
      }
      if (this.time < 1) {
        clearInterval(timerId);
        this.logout();
      }
    }, 1000);
  }

  @Watch('isLoggedIn', { immediate: true })
  async isLoggedinChange(newVal: boolean) {
    if (newVal) {
      this.hasLoadedUserSettingsAfterLogin = false;
      this.minutesToInactive = this.getUser.inactivityMinutes;
      this.startTimer();

      await Promise.all([
        this.actionGetOwnUserLocations(this.isAttendee),
        this.actionGetOwnUserDepartments(this.isAttendee),
        this.actionGetSettings(),
        this.checkWebPushSubscription(),
      ]);

      this.setOwnUserLocationOrDepartmentOnLogin();
      this.joinSignalRCompanyGroup(this.getCompanyId);

      this.setThemeColor(this.getterSettings.color);
      this.hasLoadedUserSettingsAfterLogin = true;
      return;
    }
  }

  private setThemeColor(color: string) {
    this.$vuetify.theme.themes['light'].colors.primary = color;
  }

  private setOwnUserLocationOrDepartmentOnLogin() {
    //Initially, if no own location or department is set, choose the first available one.
    if (
      this.selectedOwnUserDepartment == undefined &&
      this.selectedOwnUserLocation == undefined
    ) {
      if (this.ownUserLocations.length > 0) {
        this.actionSetOwnUserLocation(this.ownUserLocations[0].id);
      } else if (this.ownUserDepartments.length > 0) {
        this.actionSetOwnUserDepartment(this.ownUserDepartments[0].id);
      }
    }
    //If both are set at the same time, reset the selection to one of both
    else if (
      this.selectedOwnUserDepartment != undefined &&
      this.selectedOwnUserLocation != undefined
    ) {
      this.actionSetOwnUserDepartment('-');
    }
  }

  get showDialog() {
    return this.isLoggedIn && this.idleJS.isIdle.value && this.time / 60000 < 5;
  }

  // Set this to true, to enable a blocking overlay and let the user confirm
  // the native notification permission dialog
  private askForNativeNotificationPermission: boolean = false;

  async checkWebPushSubscription() {
    if (!this.isLoggedIn) {
      return;
    }

    this.actionCheckBrowserCompatibility();
    if (this.isIncompatibleBrowser) {
      return;
    }

    await this.actionRetrievePermissionStateAndSubscriptionInBrowser();
    if (this.webPushPermissionState == 'denied') return;

    await this.actionCheckSubscriptionStatus({
      existingSubscriptionEndpoint: this.hasPushSubscription
        ? this.pushSubscription.endpoint
        : '',
    });

    this.askForWebPushSubscription =
      this.pushSubscriptionCheckStatus ===
        WebpushSubscriptionCheckStatus.NonSubscribed ||
      this.pushSubscriptionCheckStatus ===
        WebpushSubscriptionCheckStatus.SubscribedSameDeviceOtherUser ||
      this.pushSubscriptionCheckStatus ===
        WebpushSubscriptionCheckStatus.SubscribedSameUserOtherDevice;

    //If there is an active subscription for this user, get all currently unread messages.
    if (
      this.pushSubscriptionCheckStatus ===
      WebpushSubscriptionCheckStatus.Subscribed
    ) {
      this.getWebPushMessages();
    }
  }

  webPushSubscriptionDeny() {
    this.askForWebPushSubscription = false;
    this.actionDeactivateSubscription();
  }

  async webPushSubscriptionGrant() {
    this.askForWebPushSubscription = false;

    //Enable an overlay to help the user not overlooking the native browser confirmation dialog
    this.askForNativeNotificationPermission = true;
    let notificationPermissionGiven =
      await this.actionAskForNotificationPermission().finally(
        () => (this.askForNativeNotificationPermission = false)
      );

    if (notificationPermissionGiven) {
      await this.actionSubscribe();
    }
  }

  unreadWebpushMessages: boolean = false;
  async getWebPushMessages() {
    await this.actionGetWebPushMessages();
    this.unreadWebpushMessages =
      this.webPushMessages.items.find((x) => x.read === false) != undefined;
  }

  onClickPushMessagesBell() {
    if (this.unreadWebpushMessages) {
      this.actionMarkAllWebPushMessagesRead();
    }
    this.unreadWebpushMessages = false;
  }

  increaseWebPushMessagesPage() {
    this.mutationSetWebPushMessagesPage(this.webPushMessagesPage + 1);
    this.actionGetWebPushMessages();
  }

  decreaseWebPushMessagesPage() {
    this.mutationSetWebPushMessagesPage(this.webPushMessagesPage - 1);
    this.actionGetWebPushMessages();
  }

  get showWebPushMessagesBell(): boolean {
    return (
      this.pushSubscriptionCheckStatus ===
      WebpushSubscriptionCheckStatus.Subscribed
    );
  }

  //#region timerControl events
  private elapsedTime = 0;
  private started = false;
  eventOffAutologout() {
    store.dispatch('off_autologoutTimeEnabled'); //stop aoutologout timer
  }

  eventPushToTimesheets(timeParamater: number) {
    this.eventShowHideMinTimer({ elapsedTime: 0, started: false });
    this.$router.push({
      name: 'timesheets',
      params: { openNewTimesheetAddHours: `${timeParamater}` },
      query: { type: `${timeParamater}` },
    });
  }
  eventShowHideMinTimer({ elapsedTime, started }: any) {
    this.elapsedTime = elapsedTime;
    this.started = started;
    store.dispatch(
      'set_showMinTimerControls',
      !store.getters['showMinTimerControls']
    );
  }

  //#endregion

  //#region : SignalR processing

  private joinSignalRCompanyGroup(companyId: string) {
    this.signalR.joinSignalRCompanyGroup(companyId);
    this.signalR.onEvent('UpdatePushMessages', (affectedUserIds: string[]) => {
      if (affectedUserIds.includes(this.stateAccountId)) {
        this.getWebPushMessages();
      }
    });
  }

  private leaveSignalRCompanyGroup(companyId: string) {
    this.signalR.offEvent('UpdatePushMessages');
    this.signalR.leaveSignalRCompanyGroup(companyId);
  }

  //#endregion

  sendFeedbackEmail() {
    window.location.href = `mailto:uservoice@attmag.de?subject=${this.$t(
      'feedback_email.subject'
    )}`;
  }

  toAccount() {
    this.menuSubscription = false;
    this.$router.push({ path: '/account' });
  }

  onClickScanner() {
    this.$router.push({
      name: ROUTES.checkIn_scanner,
    });
  }

  get navdrawerLinks() {
    return {
      my_profile: `/${ROUTES.my_profile}`,
      my_company: `/${ROUTES.my_company}`,
      my_invoices: `/${ROUTES.my_invoices}`,
      my_documents: `/${ROUTES.my_documents}`,
      my_membership_subscriptions: `/${ROUTES.my_membership_subscriptions}`,
    };
  }

  get currentRouteName(): string {
    return (this.$route.name as string) || '';
  }

  @Watch('currentRouteName')
  public async onCurrentRouteNameChanged(newV: string) {
    if (newV === ROUTES.public_view) {
      await this.actionGetAllLocations({
        companyId: this.routeCompanyId,
      }).then(() => {
        if (!isEmptyId(this.routeLocationId)) {
          this.actionSetOwnUserLocation(this.routeLocationId);
        }
      });
      await this.actionGetSettings({ companyId: this.routeCompanyId });
      this.setThemeColor(this.getterSettings.color);
    }
  }

  get routeCompanyId(): string {
    return (this.$route.params.companyId as string) || '';
  }
  get routeLocationId(): string {
    return (this.$route.query.locationId as string) || '';
  }
  get showOwnUserLocationSelection(): boolean {
    const { isLoggedIn, isAttendee, currentRouteName } = this;
    return (
      (isLoggedIn && !isAttendee) || currentRouteName === ROUTES.public_view
    );
  }
}

export default toNative(AppComponent);
