import { MutationTree } from 'vuex';
import { SettingsState } from './types';
import settings, { FileValue, Settings } from '@/shared/model/settings';

export const mutations: MutationTree<SettingsState> = {
  setSettingss(state, payload: Settings) {
    state.settingss = { ...settings.parse(payload) };
  },
  resetSettingss(state) {
    state.settingss = { ...settings.parse({}) };
  },
  setImportedFiles(state, payload: FileValue) {
    state.importedFiles.items = payload.value.map((x) => settings.fileParse(x));
    state.importedFiles.total = payload['@odata.count'] || 0;
    state.importedFiles.isLoading = false;
  },
  setImportedFilesTotal(state, payload: number) {
    state.importedFiles.total = payload;
  },
  setImportedFilesIsLoading(state, payload: boolean) {
    state.importedFiles.isLoading = payload;
  },

  setExportedFiles(state, payload: FileValue) {
    state.exportedFiles.items = payload.value.map((x) => settings.fileParse(x));
    state.exportedFiles.total = payload['@odata.count'] || 0;
    state.exportedFiles.isLoading = false;
  },
  setExportedFilesTotal(state, payload: number) {
    state.exportedFiles.total = payload;
  },
  setExportedFilesIsLoading(state, payload: boolean) {
    state.exportedFiles.isLoading = payload;
  },
};
