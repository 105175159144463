import { GetterTree } from 'vuex';
import { ReferenceParentState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<ReferenceParentState, RootState> = {
  ['getReferenceParent']: state => state.referenceParent,
  ['getReferenceParents']: state => state.referenceParents,
  ['getReferenceParentsIsLoading']: state => state.referenceParents?.isLoading,
  ['getReferenceParentsTotal']: state => state.referenceParents?.total,
  ['getReferenceParentsSearchParams']: state =>
    state.referenceParents?.searchParams,
};
