import { GetterTree } from 'vuex';
import { AttendeeCourseState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<AttendeeCourseState, RootState> = {
  ['getAttendeeCourses']: state => state.attendeeCourses,
  ['getAttendeeCoursesIsLoading']: state => state.attendeeCourses?.isLoading,
  ['getAttendeeCoursesTotal']: state => state.attendeeCourses?.total,
  ['getAttendeeCoursesSearchParams']: state =>
    state.attendeeCourses?.searchParams,
};
