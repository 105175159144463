import { MutationTree } from 'vuex';
import { AvailabilityState } from './types';
import availability, { Value } from '@/shared/model/availability';

export const mutations: MutationTree<AvailabilityState> = {
  setAvailabilities(state, payload: Value) {
    state.availabilities.items = payload.value.map((x) =>
      availability.parse(x)
    );
    state.availabilities.total = payload['@odata.count'] || 0;
    state.availabilities.isLoading = false;
  },
  setAvailabilitiesTotal(state, payload: number) {
    state.availabilities.total = payload;
  },
  setAvailabilitiesIsLoading(state, payload: boolean) {
    state.availabilities.isLoading = payload;
  },
  resetAvailabilitiesPaging(state) {
    state.availabilities.searchParams.dataOption.page = 1;
    state.availabilities.searchParams.dataOption.itemsPerPage = 10;
    state.availabilities.searchParams.dataOption.filter = '';
    state.availabilities.searchParams.filter = '';
  },
};
