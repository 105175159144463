import { ActionTree } from 'vuex';
import { LocationState } from './types';
import { RootState } from '../../types';
import { defaultBackendLocation } from '@/shared/backend/location';
import { Logger } from 'fsts';
import moduleUtils from '../moduleUtils';
import { Value, Location } from '@/shared/model/location';
import { AxiosResponse } from 'axios';
import { i18nGlobal } from '@/i18n';
import { CONST } from '../../../utils/constants';
import { createSearchParams } from '@/shared/model/searchParams';

const logger = new Logger('actions.locations');
export const actions: ActionTree<LocationState, RootState> = {
  getAllLocations(
    { commit, dispatch, getters, rootGetters },
    payload?: { searchParams?: any; companyId?: string }
  ) {
    commit('setLocationsIsLoading', true);
    const companyId = payload?.companyId || CONST.emptyGuid;
    const anonymous = companyId !== CONST.emptyGuid;
    const promise = anonymous
      ? defaultBackendLocation.getLocationsAnonymous(companyId)
      : defaultBackendLocation.getAllLocations();
    return promise
      .then((response: AxiosResponse<Value>) => {
        if (anonymous) {
          commit('setOwnUserLocations', response.data);
          commit('setAllLocations', response.data);
        } else {
          commit('setAllLocations', response.data);
        }

        commit('setLocationsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setLocationsIsLoading', false);
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  getLocations(
    { commit, dispatch, getters, rootGetters },
    payload?: {
      searchParams?: any;
      departmentId?: string;
      active?: boolean;
      alwaysIncludedLocationsIds?: string[];
      companyId?: string;
    }
  ) {
    commit('setLocationsIsLoading', true);
    let searchParams =
      payload?.searchParams ?? getters.getLocationsSearchParams;
    return defaultBackendLocation
      .getLocations(
        searchParams,
        payload?.departmentId,
        payload?.active,
        payload?.alwaysIncludedLocationsIds,
        payload?.companyId
      )
      .then((response: AxiosResponse<Value>) => {
        commit('setLocations', response.data);
        commit('setLocationsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setLocationsIsLoading', false);
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  getAllLocationsAlphabetically(
    { dispatch },
    payload?: {
      active?: boolean;
      alwaysIncludedLocationsIds?: string[];
      companyId?: string;
    }
  ) {
    return dispatch('getLocations', {
      searchParams: createSearchParams(-1, 1, ['name'], [false]),
      active: payload?.active,
      alwaysIncludedLocationsIds: payload?.alwaysIncludedLocationsIds,
      companyId: payload?.companyId,
    });
  },
  getLocation({ commit, dispatch }, id: string) {
    return defaultBackendLocation
      .getLocation(id)
      .then((response: AxiosResponse<Location>) => {
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  async getLocationAnonymous({ commit, dispatch }, id: string) {
    try {
      const response: AxiosResponse<Location> =
        await defaultBackendLocation.getLocationAnonymous(id);
      commit('setLocation', response.data);
      return response.data;
    } catch (e) {
      moduleUtils.error('error', commit, e, logger);
      throw e;
    }
  },

  updateLocation({ commit, dispatch }, file: Location) {
    return defaultBackendLocation
      .updateLocation(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Location;
          }>
        ) => {
          moduleUtils.ok(i18nGlobal.t(`success.location_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteLocation({ commit, dispatch }, id: string) {
    return defaultBackendLocation
      .deleteLocation(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Location;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  getOwnUserLocations({ commit }, isAttendee?: boolean) {
    commit('setOwnUserLocationsLoaded', false);
    return defaultBackendLocation
      .getOwnUserLocations()
      .then((response: AxiosResponse<Value>) => {
        commit('setOwnUserLocations', response.data);
        if (!isAttendee) commit('addOwnUserAllLocation');
        commit('setOwnUserLocationsLoaded', true);

        return response.data.value;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  setSelectedOwnUserLocation({ commit, getters }, payload: string) {
    let locations: Location[] = getters.getOwnUserLocations;
    let index = locations.findIndex((loc: Location) => loc.id == payload);

    commit('setSelectedOwnUserLocationIndex', index);
  },
  clearOwnUserLocations({ commit }) {
    commit('setOwnUserLocations', []);
    commit('setOwnUserLocationsLoaded', false);
  },
  checkIfLocationIsInUse({ commit }, locationId: string) {
    return defaultBackendLocation
      .checkIfLocationIsInUse(locationId)
      .then(resp => resp.data)
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  }
};
