
import { GetterTree } from 'vuex';
import { CourseState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<CourseState, RootState> = {
  ['getCourses']: (state) => state.courses,
  ['getCoursesIsLoading']: (state) => state.courses?.isLoading,
  ['getCoursesTotal']: (state) => state.courses?.total,
  ['getCoursesSearchParams']: (state) => state.courses?.searchParams,
};
