import { ActionTree } from 'vuex';
import { MembershipTypeCostOverrideState } from './types';
import { RootState } from '../../types';
import { Logger } from 'fsts';
import moduleUtils from '../moduleUtils';
import { AxiosResponse } from 'axios';
import { DefaultBackendMembershipTypeCostOverride } from '@/shared/backend/membershipTypeCostOverride';
import { SearchParams } from '@/shared/model/searchParams';
import {
  MembershipTypeCostOverride,
  Value,
} from '@/shared/model/membershipTypeCostOverride';

const logger = new Logger('actions.membershipTypeCostOverrides');

export const actions: ActionTree<MembershipTypeCostOverrideState, RootState> = {
  getCostOverrides(
    { commit, getters },
    payload: { searchParams?: SearchParams; membershipTypeId?: string }
  ) {
    let searchParams =
      payload.searchParams ?? getters.getCostOverridesSearchParams;
    commit('setCostOverridesIsLoading', true);

    return DefaultBackendMembershipTypeCostOverride.getMembershipTypeCostOverrides(
      searchParams,
      payload.membershipTypeId
    )
      .then((resp: AxiosResponse<Value>) => {
        commit('setCostOverrides', resp.data);
        return resp.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setCostOverridesIsLoading', false);
      });
  },
  postMultipleCostOverrides(
    { commit },
    payload: {
      membershipTypeId: string;
      costOverrides: MembershipTypeCostOverride[];
    }
  ) {
    return DefaultBackendMembershipTypeCostOverride.postMultipleMembershipTypeCostOverrides(
      payload.membershipTypeId,
      payload.costOverrides
    )
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
          }>
        ) => {
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
};
