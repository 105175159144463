import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Reference } from '../model/reference';
import { Company } from '../model/company';
import applicationUser, { ApplicationUser } from '../model/applicationUser';
import paymentMandateData, { PaymentMandateData } from '../model/paymentMandateData';
import { i18nGlobal } from '@/i18n';
import contactFuncs, { Contact } from '../model/contact';
import { MembershipTypeCostOverride } from '../model/membershipTypeCostOverride';
import { CostDiscount } from '../model/costDiscount';
import { ReferenceParent } from '../model/referenceParent';

export interface BackendAttendeeRegisterFull {
  getCompanyByLocationId(locationId: string): AxiosPromise<Company>;
  getMembershipTypesByCompanyId(companyId: string): AxiosPromise<Reference[]>;
  getCostsByCompanyId(companyId: string): AxiosPromise<Reference[]>;
  getMemberStatusesByCompanyId(companyId: string): AxiosPromise<Reference[]>;
  getMembershipTypeCostOverridesByCompanyId(
    companyId: string
  ): AxiosPromise<MembershipTypeCostOverride[]>;
  getActiveCostDiscountsByCompanyId(
    companyId: string
  ): AxiosPromise<CostDiscount[]>;
  getCostMembershipTypesByCompanyId(
    companyId: string
  ): AxiosPromise<ReferenceParent[]>;
  submitMembershipApplication(
    newAttendee: ApplicationUser,
    locationId: string,
    membershipTypeIds: string[],
    paymentMandateData: PaymentMandateData,
    contact: Contact
  ): AxiosPromise<{ id: string }>;
  uploadDocuments(formData: FormData): AxiosPromise;
}

export const defaultBackendAttendeeRegisterFull: BackendAttendeeRegisterFull = {
  getCompanyByLocationId(locationId: string): AxiosPromise<Company> {
    let url = `${URLS.attendeeRegisterFull}/GetCompanyByLocationId/${locationId}`;
    return instance.get<Company>(url);
  },
  getMembershipTypesByCompanyId(companyId: string): AxiosPromise<Reference[]> {
    let url = `${URLS.attendeeRegisterFull}/GetMembershipTypesByCompanyId/${companyId}`;
    return instance.get<Reference[]>(url);
  },
  getCostsByCompanyId(companyId: string): AxiosPromise<Reference[]> {
    let url = `${URLS.attendeeRegisterFull}/GetCostsByCompanyId/${companyId}`;
    return instance.get<Reference[]>(url);
  },
  getMemberStatusesByCompanyId(companyId: string): AxiosPromise<Reference[]> {
    let url = `${URLS.attendeeRegisterFull}/GetMemberStatusesByCompanyId/${companyId}`;
    return instance.get<Reference[]>(url);
  },
  getMembershipTypeCostOverridesByCompanyId(
    companyId: string
  ): AxiosPromise<MembershipTypeCostOverride[]> {
    let url = `${URLS.attendeeRegisterFull}/GetMembershipTypeCostOverridesByCompanyId/${companyId}`;
    return instance.get<MembershipTypeCostOverride[]>(url);
  },
  getActiveCostDiscountsByCompanyId(
    companyId: string
  ): AxiosPromise<CostDiscount[]> {
    let url = `${URLS.attendeeRegisterFull}/GetActiveCostDiscountsByCompanyId/${companyId}`;
    return instance.get<CostDiscount[]>(url);
  },
  getCostMembershipTypesByCompanyId(
    companyId: string
  ): AxiosPromise<ReferenceParent[]> {
    let url = `${URLS.attendeeRegisterFull}/GetCostMembershipTypes/${companyId}`;
    return instance.get<ReferenceParent[]>(url);
  },
  submitMembershipApplication(
    newAttendee: ApplicationUser,
    locationId: string,
    membershipTypeIds: string[],
    paymentMandate: PaymentMandateData,
    contact: Contact
  ): AxiosPromise<{ id: string }> {
    let url = `${URLS.attendeeRegisterFull}/RegisterAttendee/${i18nGlobal.locale.value}`;

    return instance.post<{ id: string }>(url, {
      InputAppUser: applicationUser.toAPI(newAttendee),
      LocationId: locationId,
      ExistingAttendeeId: newAttendee.attendeeId || null,
      MembershipTypeIds: membershipTypeIds,
      PaymentMandate: paymentMandateData.toAPI(paymentMandate),
      Contact: contactFuncs.toAPI(contact),
    });
  },
  uploadDocuments(formData: FormData): AxiosPromise {
    let url = `${URLS.attendeeRegisterFull}/UploadDocuments/${i18nGlobal.locale.value}`;
    return instance.post(url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      transformRequest: (data) => data,
      // This workaround is unfortunately needed in the newer versions of Axios :(
      // https://github.com/axios/axios/issues/4406
    });
  },
};
