import { GetterTree } from 'vuex';
import { LocationState } from './types';
import { RootState } from '../../types';
import { isEmptyId } from '@/shared/utils/generalUtils';

export const getters: GetterTree<LocationState, RootState> = {
  ['getLocations']: state => state.locations,
  ['getLocation']: state => state.location,
  ['getLocationsIsLoading']: state => state.locations?.isLoading,
  ['getLocationsTotal']: state => state.locations?.total,
  ['getLocationsSearchParams']: state => state.locations?.searchParams,
  ['getOwnUserLocations']: state => state.ownUserLocations,
  ['getSelectedOwnUserLocation']: state =>
    state.ownUserLocations[state.selectedOwnUserLocationIndex],
  ['getSelectedOwnUserLocationId']: state => {
    if (state.ownUserLocations.length == 0) {
      return undefined;
    }
    if (state.selectedOwnUserLocationIndex == -1) {
      return undefined;
    }
    if (
      isEmptyId(state.ownUserLocations[state.selectedOwnUserLocationIndex].id)
    ) {
      return undefined;
    }
    return state.ownUserLocations[state.selectedOwnUserLocationIndex].id;
  },
  ['isOwnUserLocationsLoaded']: state => state.ownUserLocationsLoaded,
};
