import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-52373147"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "d-flex ml-auto justify-end"
}

export function render(_ctx, _cache) {
  const _component_v_icon = _resolveComponent("v-icon")
  const _component_v_btn = _resolveComponent("v-btn")

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      id: _ctx.expandableElementContainerName,
      class: "clipped-text"
    }, [
      _createElementVNode("div", { ref: _ctx.divRefName }, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ], 512)
    ], 8, _hoisted_1),
    (!_ctx.lowContent)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.mobile)
            ? (_openBlock(), _createBlock(_component_v_btn, {
                key: 0,
                variant: "tonal",
                block: "",
                flat: "",
                "max-height": "25px",
                onClick: _ctx.toggle
              }, {
                default: _withCtx(() => [
                  (!_ctx.isOpened)
                    ? (_openBlock(), _createBlock(_component_v_icon, { key: 0 }, {
                        default: _withCtx(() => [
                          _createTextVNode("mdi-chevron-down")
                        ]),
                        _: 1
                      }))
                    : (_openBlock(), _createBlock(_component_v_icon, { key: 1 }, {
                        default: _withCtx(() => [
                          _createTextVNode("mdi-chevron-up")
                        ]),
                        _: 1
                      }))
                ]),
                _: 1
              }, 8, ["onClick"]))
            : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_v_btn, {
                  variant: "text",
                  small: "",
                  color: "primary",
                  onClick: _ctx.toggle
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.isOpened ? _ctx.i18n.t('show_less') : _ctx.i18n.t('show_more')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]))
        ]))
      : _createCommentVNode("", true)
  ]))
}