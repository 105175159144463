import { ActionTree } from 'vuex';
import { SettingsState } from './types';
import { RootState } from '../../types';
import { defaultBackendSettings } from '@/shared/backend/settings';
import { Logger } from 'fsts';
import moduleUtils from '../moduleUtils';
import { FileValue, ImportTemplate, Settings } from '@/shared/model/settings';
import { AxiosResponse } from 'axios';
import { i18nGlobal } from '@/i18n';
import DownloadUtils from '@/shared/utils/downloadUtils';
import { CONST } from '@/shared/utils/constants';

const logger = new Logger('actions.settingss');
export const actions: ActionTree<SettingsState, RootState> = {
  resetSettingss({ commit }) {
    commit('resetSettingss');
  },

  getSettings({ commit, dispatch }, payload?: { companyId?: string }) {
    const companyId = payload?.companyId || CONST.emptyGuid;
    return defaultBackendSettings
      .getSettings(companyId)
      .then((response: AxiosResponse<Settings>) => {
        commit('setSettingss', response.data);
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  getSettingsByLocationId({ commit, dispatch }, locationId: string) {
    return defaultBackendSettings
      .getSettingsByLocationId(locationId)
      .then((response: AxiosResponse<Settings>) => {
        commit('setSettingss', response.data);
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  async updateLogo({ commit }, file: any) {
    const data = new FormData();
    data.append('File', file, file.name);

    return await defaultBackendSettings
      .updateLogo(data)
      .then((response) => {
        moduleUtils.ok(i18nGlobal.t(`success.upload_file`), commit, logger);
        return response.data;
      })
      .catch((e) => {
        moduleUtils.error(i18nGlobal.t(`error.upload_file`), commit, e, logger);
        throw e;
      });
  },
  updateSettings({ commit, dispatch }, file: Settings) {
    return defaultBackendSettings
      .updateSettings(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Settings;
          }>
        ) => {
          commit('setSettingss', response.data);
          moduleUtils.ok(i18nGlobal.t(`success.settings_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteSettings({ commit, dispatch }, id: string) {
    return defaultBackendSettings
      .deleteSettings(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Settings;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },

  async importExcel(
    { commit },
    payload: { file: any; importTemplate: ImportTemplate; culture: string }
  ) {
    const data = new FormData();
    data.append('File', payload.file, payload.file.name);

    return await defaultBackendSettings
      .importExcel(payload)
      .then((response) => {
        if (response.data.success) {
          moduleUtils.ok(i18nGlobal.t(`success.import_file`), commit, logger);
        }
        return response.data;
      })
      .catch((e) => {
        moduleUtils.error(i18nGlobal.t(`error.import_file`), commit, e, logger);
        return { success: false, log: [] };
      });
  },

  async exportExcel(
    { commit },
    payload: { importTemplate: ImportTemplate; culture: string }
  ) {
    return await defaultBackendSettings
      .exportExcel(payload)
      .then((response) => {
        if (response.data?.log?.includes('no data to export')) {
          moduleUtils.ok(
            i18nGlobal.t(`success.export_file_no_data`),
            commit,
            logger
          );
        } else if (response.data.success) {
          moduleUtils.ok(i18nGlobal.t(`success.export_file`), commit, logger);
        }
        return response.data;
      })
      .catch((e) => {
        moduleUtils.error(i18nGlobal.t(`error.export_file`), commit, e, logger);
        return { success: false, log: [] };
      });
  },

  getImportedFiles({ commit, getters }, payload?: { searchParams?: any }) {
    commit('setImportedFilesIsLoading', true);
    return defaultBackendSettings
      .getImportedFiles(payload?.searchParams)
      .then((response: AxiosResponse<FileValue>) => {
        commit('setImportedFiles', response.data);
        commit('setImportedFilesIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setImportedFilesIsLoading', false);
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },

  getExportedFiles({ commit, getters }, payload?: { searchParams?: any }) {
    commit('setExportedFilesIsLoading', true);
    return defaultBackendSettings
      .getExportedFiles(payload?.searchParams)
      .then((response: AxiosResponse<FileValue>) => {
        commit('setExportedFiles', response.data);
        commit('setExportedFilesIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setExportedFilesIsLoading', false);
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },

  async downloadTemplate(
    { commit },
    payload: { template: ImportTemplate; culture: string }
  ) {
    try {
      await defaultBackendSettings
        .downloadTemplate(payload.template, payload.culture)
        .then((response) => {
          let fileName = DownloadUtils.getFileName(
            response.headers['content-disposition']
          );
          const type = response.headers['content-type'];
          if (DownloadUtils.isIeOrEdge(window)) {
            const file = new File([response.data], fileName, {
              type: type,
            });
            (window.navigator as any).msSaveOrOpenBlob(file, fileName);
          } else {
            const blob = new Blob([response.data], { type: type });
            const url = DownloadUtils.createDownloadLink(blob, fileName);
            window.URL.revokeObjectURL(url);
          }
        });
    } catch (e) {
      moduleUtils.error(i18nGlobal.t(`error.download_file`), commit, e, logger);
    }
  },

  async downloadFile({ commit }, fileName: string) {
    try {
      await defaultBackendSettings.downloadFile(fileName).then((response) => {
        let fileName = DownloadUtils.getFileName(
          response.headers['content-disposition']
        );
        const type = response.headers['content-type'];
        if (DownloadUtils.isIeOrEdge(window)) {
          const file = new File([response.data], fileName, {
            type: type,
          });
          (window.navigator as any).msSaveOrOpenBlob(file, fileName);
        } else {
          const blob = new Blob([response.data], { type: type });
          const url = DownloadUtils.createDownloadLink(blob, fileName);
          window.URL.revokeObjectURL(url);
        }
      });
    } catch (e) {
      moduleUtils.error(i18nGlobal.t(`error.download_file`), commit, e, logger);
    }
  },
  async downloadExportedFile({ commit }, fileName: string) {
    try {
      await defaultBackendSettings
        .downloadExportedFile(fileName)
        .then((response) => {
          let fileName = DownloadUtils.getFileName(
            response.headers['content-disposition']
          );
          const type = response.headers['content-type'];
          if (DownloadUtils.isIeOrEdge(window)) {
            const file = new File([response.data], fileName, {
              type: type,
            });
            (window.navigator as any).msSaveOrOpenBlob(file, fileName);
          } else {
            const blob = new Blob([response.data], { type: type });
            const url = DownloadUtils.createDownloadLink(blob, fileName);
            window.URL.revokeObjectURL(url);
          }
        });
    } catch (e) {
      moduleUtils.error(i18nGlobal.t(`error.download_file`), commit, e, logger);
    }
  },

  async deleteFile({ commit }, fileName: string) {
    try {
      await defaultBackendSettings.deleteFile(fileName);
      moduleUtils.ok(i18nGlobal.t(`success.delete_file`), commit);
    } catch (e) {
      moduleUtils.error(i18nGlobal.t(`error.delete_file`), commit, e, logger);
    }
  },
  async deleteExportedFile({ commit }, fileName: string) {
    try {
      await defaultBackendSettings.deleteExportedFile(fileName);
      moduleUtils.ok(i18nGlobal.t(`success.delete_file`), commit);
    } catch (e) {
      moduleUtils.error(i18nGlobal.t(`error.delete_file`), commit, e, logger);
    }
  },
};
