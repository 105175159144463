import { AUTHORITIES } from '../store/modules/auth';
import appointment, { Appointment } from './appointment';
import roomPresence, { RoomPresence } from './roomPresence';

export interface Presence {
  id?: string; // System.Guid
  applicationUserId?: string; // System.Guid
  attendeeId?: string;
  fullName: string;
  isOnline: boolean;
  locationId?: string; // System.Guid
  locationName: string;
  dateEnter: string; // System.DateTime
  dateLeave?: string; // System.DateTime
  date: string; // System.DateTime
  creatorId?: string; // System.Guid?
  createdDate?: string; // System.DateTime
  text: string;
  role?: AUTHORITIES;
  userLogo: string;
  todayAppointments: Appointment[];
  roomPresences: RoomPresence[];
  roomPresencesDto?: RoomPresence[];
}

export type PresenceTypeEnum = 0 | 1 | 2;
export const PresenceTypeEnum = {
  ENTER: 0 as PresenceTypeEnum,
  LEAVE: 1 as PresenceTypeEnum,
  NOT_SET: 2 as PresenceTypeEnum,
};

export interface Value {
  value: Presence[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<Presence>): any {
  return {
    Id: data?.id || undefined,
    ApplicationUserId: data?.applicationUserId || undefined,
    AttendeeId: data?.attendeeId || undefined,
    Date: data?.dateEnter || '',
    CreatorId: data?.creatorId || '',
    CreatedDate: data?.createdDate || '',
    Text: data?.text || undefined,
  };
}

function parse(data?: Partial<Presence>): Presence {
  return {
    id: data?.id || undefined,
    applicationUserId: data?.applicationUserId || undefined,
    attendeeId: data?.attendeeId || undefined,
    isOnline: data?.isOnline || false,
    fullName: data?.fullName || '',
    locationName: data?.locationName || '',
    locationId: data?.locationId || undefined,
    dateEnter: data?.dateEnter || '',
    dateLeave: data?.dateLeave,
    date: data?.date || '',
    createdDate: data?.createdDate || '',
    text: data?.text || '',
    role: data?.role,
    userLogo: data?.userLogo || '',
    todayAppointments: data?.todayAppointments
      ? data.todayAppointments.map((item) => appointment.parse(item))
      : [],
    roomPresences: data?.roomPresencesDto
      ? data.roomPresencesDto.map((item) => roomPresence.parse(item))
      : [],
  };
}

export default {
  parse,
  toAPI: toAPI,
};
