import { ActionTree } from 'vuex';
import { LocationAttendeeState } from './types';
import { RootState } from '../../types';
import { Logger } from 'fsts';
import moduleUtils from '../moduleUtils';
import { AxiosResponse } from 'axios';
import { SearchParams } from '@/shared/model/searchParams';
import { defaultBackendLocationAttendee } from '@/shared/backend/locationAttendee';
import { Value } from '@/shared/model/locationAttendee';
import { createSearchParams } from '@/shared/model/searchParams';

const logger = new Logger('actions.locationAttendee');
export const actions: ActionTree<LocationAttendeeState, RootState> = {
  getLocationAttendees(
    { commit, getters },
    payload: {
      searchParams?: SearchParams;
      attendeeId?: string;
    }
  ) {
    commit('setLocationAttendeesIsLoading', true);
    let searchParams =
      payload.searchParams ?? getters.getLocationAttendeesSearchParams;

    return defaultBackendLocationAttendee
      .getLocationAttendees(searchParams, payload.attendeeId)
      .then((response: AxiosResponse<Value>) => {
        commit('setLocationAttendees', response.data);
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setLocationAttendeesIsLoading', false);
      });
  },
  getLocationAttendeesNonPaginated(
    { dispatch },
    payload: { attendeeId: string }
  ) {
    return dispatch('getLocationAttendees', {
      attendeeId: payload.attendeeId,
      searchParams: createSearchParams(250),
    });
  },
  clearLocationAttendees({ commit }) {
    commit('setLocationAttendees', { value: [] });
  },
  postMultipleLocationAttendees(
    { commit },
    payload: {
      locationIds: string[];
      attendeeId: string;
    }
  ) {
    return defaultBackendLocationAttendee
      .postMultipleLocationAttendees(payload.locationIds, payload.attendeeId)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
          }>
        ) => {
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
};
