import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import reference, {
  CostTypeEnum,
  getCostTypeText,
  Reference,
  Value,
} from '../model/reference';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '../utils/constants';

const logger = new Logger('backend.Reference');
export interface BackendReference {
  getReference(id: string): AxiosPromise<Reference>;
  getReferences: (
    searchParams: SearchParams,
    tableName: string,
    costType?: CostTypeEnum,
    onlyActive?: boolean,
  ) => AxiosPromise<Value>;
  getAllReferences: (tableName: string) => AxiosPromise<Value>;
  deleteReference(id: string, tableName: string): AxiosPromise;
  updateReference(Reference: Reference, tableName: string): AxiosPromise<any>;
}

export const defaultBackendReference: BackendReference = {
  getReference(id: string): AxiosPromise<Reference> {
    let url = `${URLS.referenceOdata}/${id}`;
    return instance.get<Reference>(url);
  },
  getReferences(
    searchParams: SearchParams,
    tableName: string,
    costType?: CostTypeEnum,
    onlyActive?: boolean,
  ): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');

    if (costType != undefined) {
      odfb.eq(CONST.Type, getCostTypeText(costType));
    }

    if (onlyActive != undefined) {
      odfb.eq(CONST.Active, true, false);
    }

    let url = DefaultBackendHelper.makeUrl(
      `${URLS.referenceOdata}/GetByTableName(tableName='${tableName}')`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getReferences${url}`);
    return instance.get<Value>(url);
  },

  getAllReferences(tableName: string): AxiosPromise<Value> {
    let url = `${URLS.reference}/GetAllByTableName/${tableName}`;
    logger.log(`getAllReferences${url}`);
    return instance.get<Value>(url);
  },

  deleteReference(id: string, tableName: string): AxiosPromise {
    logger.debug('deleteReference');
    return instance.delete(`${URLS.reference}/${id}/${tableName}`);
  },
  updateReference(Reference: Reference, tableName: string): AxiosPromise<any> {
    logger.debug('updateReference');
    if (Reference.id != undefined) {
      return instance.put<Reference>(
        `${URLS.reference}/${tableName}`,
        reference.toAPI(Reference)
      );
    } else {
      return instance.post<Reference>(
        `${URLS.reference}/${tableName}`,
        reference.toAPI(Reference)
      );
    }
  },
};
