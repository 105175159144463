import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { DefaultBackendHelper } from '../utils/backendHelper';
import department, { Department, Value } from '../model/department';
import { isEmptyId } from '../utils/generalUtils';
import { SearchParams } from '../model/searchParams';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '../utils/constants';

const logger = new Logger('backend.Department');
export interface BackendDepartment {
  getDepartment(id: string): AxiosPromise<Department>;
  getDepartments: (
    searchParams: SearchParams,
    companyId?: string
  ) => AxiosPromise<Value>;
  getAllDepartments: (payload?: { companyId?: string }) => AxiosPromise<Value>;
  getOwnUserDepartments: () => AxiosPromise<Value>;
  deleteDepartment(id: string): AxiosPromise;
  updateDepartment(Department: Department): AxiosPromise<any>;
}

export const defaultBackendDepartment: BackendDepartment = {
  getDepartment(id: string): AxiosPromise<Department> {
    let url = `${URLS.departmentOdata}/${id}`;
    return instance.get<Department>(url);
  },
  getDepartments(
    searchParams: SearchParams,
    companyId?: string
  ): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');

    let baseUrl =
      companyId != undefined
        ? `${URLS.departmentOdata}/GetOdataAnonymous(companyId=${companyId})`
        : `${URLS.departmentOdata}`;

    let url = DefaultBackendHelper.makeUrl(
      baseUrl,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getDepartments${url}`);
    return instance.get<Value>(url);
  },
  getAllDepartments(payload?: { companyId?: string }): AxiosPromise<Value> {
    const companyId = payload?.companyId || CONST.emptyGuid;
    let url = DefaultBackendHelper.makeUrl(`${URLS.department}/GetAll`);
    if (companyId !== CONST.emptyGuid) {
      url = DefaultBackendHelper.makeUrl(
        `${URLS.department}/GetAllAnonymous/${companyId}`
      );
    }
    logger.log(`getAllDepartments${url}`);
    return instance.get<Value>(url);
  },
  getOwnUserDepartments(): AxiosPromise<Value> {
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.department}/GetOwnUserDepartments`
    );
    logger.log(`getOwnUserDepartments${url}`);
    return instance.get<Value>(url);
  },
  deleteDepartment(id: string): AxiosPromise {
    logger.debug('deleteDepartment');
    return instance.delete(`${URLS.department}/${id}`);
  },
  updateDepartment(Department: Department): AxiosPromise<any> {
    logger.debug('updateDepartment');
    if (!isEmptyId(Department.id)) {
      return instance.put<Department>(
        `${URLS.department}/${Department.id}`,
        department.toAPI(Department)
      );
    } else {
      return instance.post<Department>(
        `${URLS.department}`,
        department.toAPI(Department)
      );
    }
  },
};
