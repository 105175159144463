import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import qrCode, { QrCode, Value } from '../model/qrCode';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/constants';

const logger = new Logger('backend.QrCode');
export interface BackendQrCode {
  getQrCode(id: string): AxiosPromise<QrCode>;
  getLatestQrCode(courseId: string): AxiosPromise<QrCode>;
  getQrCodes: (
    searchParams: SearchParams,
    locationId: string,
    courseId: string,
    active?: boolean,
    availableAtDate?: string
  ) => AxiosPromise<Value>;
  deleteQrCode(id: string): AxiosPromise;
  updateQrCode(QrCode: QrCode): AxiosPromise<any>;
  getCoordinatesFromAddress: (address: string) => AxiosPromise;
}

export const defaultBackendQrCode: BackendQrCode = {
  getQrCode(id: string): AxiosPromise<QrCode> {
    let url = `${URLS.qrCodeOdata}/${id}`;
    return instance.get<QrCode>(url);
  },
  getLatestQrCode(courseId: string): AxiosPromise<QrCode> {
    let url = `${URLS.qrCode}/GetLatest/${courseId}`;
    logger.log(`getLatestQrCode${url}`);
    return instance.get<QrCode>(url);
  },
  getQrCodes(
    searchParams: SearchParams,
    locationId: string,
    courseId: string,
    active?: boolean,
    availableAtDate?: string
  ): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    let getByUrl = `${URLS.qrCodeOdata}/GetByLocationId(locationId=${locationId})`;

    if (courseId != CONST.emptyGuid) {
      getByUrl = `${URLS.qrCodeOdata}/GetByCourseId(courseId=${courseId})`;
    }

    if (active != undefined) {
      odfb.eq(CONST.Active, active, false);
    }

    if (availableAtDate != undefined) {
      odfb.ge(CONST.ValidTill, availableAtDate, false);
      odfb.le(CONST.ValidFrom, availableAtDate, false);
    }

    let url = DefaultBackendHelper.makeUrl(
      `${getByUrl}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getQrCodes${url}`);
    return instance.get<Value>(url);
  },

  deleteQrCode(id: string): AxiosPromise {
    logger.debug('deleteQrCode');
    return instance.delete(`${URLS.qrCode}/${id}`);
  },
  updateQrCode(QrCode: QrCode): AxiosPromise<any> {
    logger.debug('updateQrCode');
    if (QrCode.id != '' && QrCode.id != undefined) {
      return instance.put<QrCode>(`${URLS.qrCode}`, qrCode.toAPI(QrCode));
    } else {
      return instance.post<QrCode>(`${URLS.qrCode}`, qrCode.toAPI(QrCode));
    }
  },
  getCoordinatesFromAddress(address: string): AxiosPromise {
    return instance.get(
      `${URLS.qrCode}/GetCoordinatesFromAddress/${encodeURIComponent(address)}`
    );
  },
};
