import { ActionTree, Commit } from 'vuex';
import { DocumentState } from './types';
import { RootState } from '../../types';
import { i18nGlobal } from '@/i18n';
import { Logger } from 'fsts';
import moduleUtils from '../moduleUtils';
import { AxiosResponse } from 'axios';
import { defaultBackendDocument } from '@/shared/backend/document';
import document, { Document, DocumentEmpty, Value } from '@/shared/model/document';
import DownloadUtils from '@/shared/utils/downloadUtils';

const logger = new Logger('actions');
export const actions: ActionTree<DocumentState, RootState> = {
  getDocument({ commit, dispatch }, id: string) {
    return defaultBackendDocument
      .getDocument(id)
      .then((response: AxiosResponse<Document>) => {
        commit('setDocument', document.parse(response.data));
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error(
          i18nGlobal.t(`error.load_data_failed`),
          commit,
          e,
          logger
        );
      });
  },

  getDocuments(
    { commit, dispatch },
    payload: {
      searchParams?: any;
      parentId?: string;
      onlyVisibleForUser: boolean;
    }
  ) {
    commit('setDocumentsIsLoading', true);
    return defaultBackendDocument
      .getDocuments(
        payload.searchParams,
        payload.parentId,
        payload.onlyVisibleForUser
      )
      .then((response: AxiosResponse<Value>) => {
        commit('setDocuments', response.data);
        commit('setDocumentsIsLoading', false);
      })
      .catch((e: any) => {
        commit('setDocumentsIsLoading', false);
        moduleUtils.error(
          i18nGlobal.t(`error.load_data_failed`),
          commit,
          e,
          logger
        );
      });
  },
  getDocumentsNew(
    { commit, dispatch, getters },
    payload: { searchParams?: any }
  ) {
    commit('setDocumentsNewIsLoading', true);
    let searchParams =
      payload?.searchParams ?? getters.getDocumentsNewSearchParams;
    return defaultBackendDocument
      .getDocumentsNew(searchParams)
      .then((response: AxiosResponse<Value>) => {
        commit('setDocumentsNew', response.data);
        commit('setDocumentsNewIsLoading', false);
      })
      .catch((e: any) => {
        commit('setDocumentsNewIsLoading', false);
        moduleUtils.error(
          i18nGlobal.t(`error.load_data_failed`),
          commit,
          e,
          logger
        );
      });
  },

  getDocumentByCompany({ commit }, payload: { companyId: string, documentType: string }): Promise<Document> {
    return defaultBackendDocument
      .getDocumentByCompany(payload.companyId, payload.documentType)
      .then((response: AxiosResponse<Document>) => {
        return document.parse(response.data);
      })
      .catch((e: any) => {
        //Not found
        return { ...DocumentEmpty };
      });
  },

  updateDocument({ commit, dispatch }, cm: Document) {
    return defaultBackendDocument
      .updateDocument(cm)
      .then((response) => {
        moduleUtils.ok(i18nGlobal.t(`success.document.update`), commit);
        return response.data;
      })
      .catch((e) => {
        moduleUtils.error(
          i18nGlobal.t(`error.document.update`),
          commit,
          e,
          logger
        );
        throw new Error(i18nGlobal.t(`error.document.update`));
      });
  },
  publishDocument(
    { commit, dispatch },
    payload: { id: string; active: boolean }
  ) {
    return defaultBackendDocument
      .publishDocument(payload.id, payload.active)
      .then((response) => {
        moduleUtils.ok(i18nGlobal.t(`success.document.update`), commit);
        return response.data;
      })
      .catch((e) => {
        moduleUtils.error(
          i18nGlobal.t(`error.document.update`),
          commit,
          e,
          logger
        );
        throw new Error(i18nGlobal.t(`error.document.update`));
      });
  },

  async uploadDocument({ commit }, payload: any) {
    const data = new FormData();
    data.append('File', payload.document, payload.document.name);
    data.append('Id', payload.id);
    data.append('ParentId', payload.parentId || ''); //  Fix `undefined` error when upload from `Document-data` tab (NOT dialog)

    return await defaultBackendDocument
      .uploadDocument(data)
      .then((response) => {
        moduleUtils.ok(i18nGlobal.t(`success.upload_file`), commit, logger);
        return response.data;
      })
      .catch((e) => {
        commit('resetDocument');
        moduleUtils.error(i18nGlobal.t(`error.upload_file`), commit, e, logger);
        throw e;
      });
  },
  async downloadDocument({ commit }, payload: any) {
    try {
      await defaultBackendDocument
        .downloadDocument(payload)
        .then((response) => {
          let fileName = DownloadUtils.getFileName(
            response.headers['content-disposition']
          );
          const type = response.headers['content-type'];
          if (DownloadUtils.isIeOrEdge(window)) {
            const file = new File([response.data], fileName, {
              type: type,
            });
            (window.navigator as any).msSaveOrOpenBlob(file, fileName);
          } else {
            const blob = new Blob([response.data], { type: type });
            const url = DownloadUtils.createDownloadLink(blob, fileName);
            window.URL.revokeObjectURL(url);
          }
        });
    } catch (e) {
      logger.error(e);
      moduleUtils.error(i18nGlobal.t(`error.download_file`), commit, e, logger);
      throw e;
    }
  },
  async downloadDocumentInNewTab({ commit }, documentId: string) {
    try {
      await defaultBackendDocument
        .downloadDocument(documentId)
        .then((response) => {
          let fileName = DownloadUtils.getFileName(
            response.headers['content-disposition']
          );
          const type = response.headers['content-type'];
          const blob = new Blob([response.data], { type: type });
          const url = DownloadUtils.createDownloadLink(blob, fileName);
          window.open(url, '_blank');
        });
    } catch (e) {
      logger.error(e);
      moduleUtils.error(i18nGlobal.t(`error.download_file`), commit, e, logger);
      throw e;
    }
  },
  deleteDocument({ commit, dispatch }, id: string) {
    return defaultBackendDocument
      .deleteDocument(id)
      .then((response) => {
        return response;
      })
      .catch((e) => {
        moduleUtils.error(i18nGlobal.t(`error.document.delete`), commit, e);
      });
  },

  deleteDocumentFile({ commit, dispatch }, documentId: string) {
    return defaultBackendDocument
      .deleteDocumentFile(documentId)
      .then((response) => {
        commit('resetDocument');
        return response.data;
      })
      .catch((e) => {
        moduleUtils.error(
          i18nGlobal.t(`error.document_file.delete`),
          commit,
          e
        );
        throw new Error(e);
      });
  },

  getDocumentCategories({ commit, dispatch }, payload: { searchParams?: any }) {
    commit('setDocumentCategoriesIsLoading', true);
    return defaultBackendDocument
      .getCategories(payload.searchParams)
      .then((response) => {
        commit('setDocumentCategoriesIsLoading', false);
        commit('setDocumentCategories', response.data.value);
      })
      .catch((e: any) => {
        commit('setDocumentCategoriesIsLoading', false);
        moduleUtils.error(
          i18nGlobal.t(`error.load_data_failed`),
          commit,
          e,
          logger
        );
      });
  },
};
