import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams, createSearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import taskRequest, {
  TaskRequest,
  TaskRequestStatus,
  TaskRequestTable,
  Value,
} from '../model/taskRequest';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/constants';
import { isEmptyId } from '../utils/generalUtils';

const logger = new Logger('backend.TaskRequest');
export interface BackendTaskRequest {
  getTaskRequest(id: string): AxiosPromise<TaskRequest>;
  getTaskRequests: (
    searchParams: SearchParams,
    type?: TaskRequestTable
  ) => AxiosPromise<Value>;
  getTaskRequestsAttendances(
    searchParams: SearchParams,
    startDate: string,
    endDate: string,
    statuses: TaskRequestStatus[],
    locationId?: string,
    departmentId?: string,
  ): AxiosPromise<Value>;
  getTaskRequestsAttendancesOpenCount(
    locationId?: string,
    departmentId?: string,
  ): AxiosPromise<Value>;
  getTaskRequestsTotalOpenCount(): AxiosPromise<Value>;
  deleteTaskRequest(id: string): AxiosPromise;
  updateTaskRequest(TaskRequest: TaskRequest): AxiosPromise<any>;
  createTaskRequestAttendances(attendancesIds: string[]): AxiosPromise;
  updateTaskRequestAttendancesStatusMultiple(
    attendancesIds: string[],
    status: TaskRequestStatus
  ): AxiosPromise;
  updateAttendancesStatusMultiple(
    attendancesIds: string[],
    status: TaskRequestStatus
  ): AxiosPromise;
}

export const defaultBackendTaskRequest: BackendTaskRequest = {
  getTaskRequest(id: string): AxiosPromise<TaskRequest> {
    let url = `${URLS.taskRequestOdata}/${id}`;
    return instance.get<TaskRequest>(url);
  },

  getTaskRequests(
    searchParams: SearchParams,
    type?: TaskRequestTable
  ): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    if (type) {
      odfb.contains(CONST.TypeDto, type);
    }
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.taskRequestOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getTaskRequests${url}`);
    return instance.get<Value>(url);
  },

  getTaskRequestsAttendances(
    searchParams: SearchParams,
    startDate: string,
    endDate: string,
    statuses: TaskRequestStatus[],
    locationId?: string,
    departmentId?: string,
  ): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    if (startDate !== '' && endDate !== '') {
      odfb.ge(CONST.CreatedDate, startDate, false);
      odfb.lt(CONST.CreatedDate, endDate, false);
    }

    if (statuses.length > 0) {
      let odfbStati = ODataFilterBuilder('or');
      statuses.forEach((x) => odfbStati.eq(CONST.Status, x));
      odfb.and(odfbStati);
    }

    let baseUrl = locationId != undefined
      ? `${URLS.taskRequestsAttendancesOdata}/GetInLocation(locationId=${locationId})`
      : departmentId != undefined
      ? `${URLS.taskRequestsAttendancesOdata}/GetInDepartment(departmentId=${departmentId})`
      : `${URLS.taskRequestsAttendancesOdata}`;

    let url = DefaultBackendHelper.makeUrl(
      baseUrl,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getTaskRequestsAttendances${url}`);
    return instance.get<Value>(url);
  },
  getTaskRequestsAttendancesOpenCount(
    locationId?: string,
    departmentId?: string,
  ): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.Status, 'OPEN');
    const searchParams = createSearchParams(0);

    let baseUrl = locationId != undefined
      ? `${URLS.taskRequestsAttendancesOdata}/GetInLocation(locationId=${locationId})`
      : departmentId != undefined
      ? `${URLS.taskRequestsAttendancesOdata}/GetInDepartment(departmentId=${departmentId})`
      : `${URLS.taskRequestsAttendancesOdata}`;

    let url = DefaultBackendHelper.makeUrl(
      baseUrl,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getTaskRequestsAttendancesOpenCount${url}`);
    return instance.get<Value>(url);
  },

  getTaskRequestsTotalOpenCount(): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.Status, 'OPEN');
    const searchParams = createSearchParams(0);
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.taskRequestOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getTaskRequestsTotalOpenCount${url}`);
    return instance.get<Value>(url);
  },

  deleteTaskRequest(id: string): AxiosPromise {
    logger.debug('deleteTaskRequest');
    return instance.delete(`${URLS.taskRequest}/${id}`);
  },
  updateTaskRequest(TaskRequest: TaskRequest): AxiosPromise<any> {
    logger.debug('updateTaskRequest');
    if (isEmptyId(TaskRequest.id)) {
      return instance.put<TaskRequest>(
        `${URLS.taskRequest}`,
        taskRequest.toAPI(TaskRequest)
      );
    } else {
      return instance.post<TaskRequest>(
        `${URLS.taskRequest}`,
        taskRequest.toAPI(TaskRequest)
      );
    }
  },
  createTaskRequestAttendances(attendancesIds: string[]): AxiosPromise {
    return instance.post(`${URLS.taskRequest}/AddAttendancess`, {
      AttendancessIds: attendancesIds,
    });
  },
  updateTaskRequestAttendancesStatusMultiple(
    attendancesIds: string[],
    status: TaskRequestStatus
  ): AxiosPromise {
    return instance.put(`${URLS.taskRequest}/UpdateTaskRequestStatusMultiple`, {
      ReferencesId: attendancesIds,
      Status: status,
    });
  },
  updateAttendancesStatusMultiple(
    attendancesIds: string[],
    status: TaskRequestStatus
  ): AxiosPromise {
    return instance.put(`${URLS.taskRequest}/UpdateAttendancesStatusMultiple`, {
      AttendancessIds: attendancesIds,
      Status: status,
    });
  },
};
