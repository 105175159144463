import { ActionTree } from 'vuex';
import { AppointmentTrainerState } from './types';
import { RootState } from '../../types';
import { defaultBackendAppointmentTrainer } from '@/shared/backend/appointmentTrainer';
import { Logger } from 'fsts';
import moduleUtils from '../moduleUtils';
import { Value, AppointmentTrainer } from '@/shared/model/appointmentTrainer';
import { AxiosResponse } from 'axios';
import { CONST } from '@/shared/utils/constants';

const logger = new Logger('actions.appointmentTrainers');
export const actions: ActionTree<AppointmentTrainerState, RootState> = {
  getAppointmentTrainers(
    { commit, dispatch, getters, rootGetters },
    payload?: { searchParams?: any; appointmentId?: string }
  ) {
    commit('setAppointmentTrainersIsLoading', true);
    let searchParams =
      payload?.searchParams ?? getters.getAppointmentTrainersSearchParams;
    let appointmentId = payload?.appointmentId ?? CONST.emptyGuid;
    return defaultBackendAppointmentTrainer
      .getAppointmentTrainers(searchParams, appointmentId)
      .then((response: AxiosResponse<Value>) => {
        commit('setAppointmentTrainers', response.data);
        commit('setAppointmentTrainersIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setAppointmentTrainersIsLoading', false);
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  getAppointmentTrainer({ commit, dispatch }, id: string) {
    return defaultBackendAppointmentTrainer
      .getAppointmentTrainer(id)
      .then((response: AxiosResponse<AppointmentTrainer>) => {
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateAppointmentTrainer({ commit, dispatch }, file: AppointmentTrainer) {
    return defaultBackendAppointmentTrainer
      .updateAppointmentTrainer(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: AppointmentTrainer;
          }>
        ) => {
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  addAppointmentTrainersByDateTimeId(
    { commit, dispatch },
    payload: { dateTimeId: string; doNotChangeExistingAppointments?: boolean }
  ) {
    return defaultBackendAppointmentTrainer
      .addAppointmentTrainersByDateTimeId(
        payload.dateTimeId,
        payload.doNotChangeExistingAppointments ?? false
      )
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: AppointmentTrainer;
          }>
        ) => {
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  addMultipleAppointmentTrainers(
    { commit, dispatch },
    payload: { appointmentId?: string; trainersId?: string[] }
  ) {
    let appointmentId = payload?.appointmentId ?? CONST.emptyGuid;
    let trainersId = payload?.trainersId ?? [];
    return defaultBackendAppointmentTrainer
      .addMultipleAppointmentTrainers(appointmentId, trainersId)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: AppointmentTrainer;
          }>
        ) => {
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteAppointmentTrainer({ commit, dispatch }, id: string) {
    return defaultBackendAppointmentTrainer
      .deleteAppointmentTrainer(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: AppointmentTrainer;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
};
