import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import tariff, { Tariff, Value } from '../model/tariff';
import ODataFilterBuilder from 'odata-filter-builder';

const logger = new Logger('backend.Tariff');
export interface BackendTariff {
  getTariff(id: string): AxiosPromise<Tariff>;
  getTariffs: (searchParams: SearchParams) => AxiosPromise<Value>;
  deleteTariff(id: string): AxiosPromise;
  updateTariff(Tariff: Tariff): AxiosPromise<any>;
}

export const defaultBackendTariff: BackendTariff = {
  getTariff(id: string): AxiosPromise<Tariff> {
    let url = `${URLS.tariffOdata}/${id}`;
    return instance.get<Tariff>(url);
  },
  getTariffs(searchParams: SearchParams): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    // odfb.eq(CONST.OrganisationId, organisationId, false);
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.tariffOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getTariffs${url}`);
    return instance.get<Value>(url);
  },

  deleteTariff(id: string): AxiosPromise {
    logger.debug('deleteTariff');
    return instance.delete(`${URLS.tariff}/${id}`);
  },
  updateTariff(Tariff: Tariff): AxiosPromise<any> {
    logger.debug('updateTariff');
    if (Tariff.id != undefined) {
      return instance.put<Tariff>(`${URLS.tariff}`, tariff.toAPI(Tariff));
    } else {
      return instance.post<Tariff>(`${URLS.tariff}`, tariff.toAPI(Tariff));
    }
  },
};
