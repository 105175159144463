import DateUtils from '../utils/dateUtils';
import { Attendance } from './attendance';
import { CourseTypeEnum } from './course';
import { ReferenceParent } from './referenceParent';

interface AppointmentMembershipTypeAvailability {
  membershipTypeId: string;
  membershipTypeName: string;
  cost: number;
}

export enum AppointmentNotificationSetting {
  All = 1,
  OnlyAttendees = 2,
  OnlyTrainers = 3,
  None = 4,
}

export interface Appointment {
  logo: string;
  id?: string; // System.Guid
  dateTimeId?: string; // System.Guid
  courseId?: string; // System.Guid
  bookableAsSeries: boolean;
  roomId?: string; // System.Guid
  roomName: string;
  isPassed: boolean;
  readonly isPassedWithTime: boolean;
  date: string; // System.DateTime
  timeFrom: string; // System.TimeSpan
  timeTill: string; // System.TimeSpan
  readonly time: string;
  readonly dateAndTime: string;
  timeFromString?: string; // System.TimeSpan
  timeTillString?: string; // System.TimeSpan
  trainerFullNames: string;
  trainerIds: string;
  readonly trainerIdArray: string[];
  attendeeIds: string;
  readonly attendeeIdArray: string[];
  text: string;
  countOfAttendancesForTheDate: number;
  countOfSeriesCourseAttendees: number;
  readonly isFull: boolean;
  readonly isCapacityExceeded: boolean;
  readonly isSeriesCourseBookedUp: boolean;
  courseName: string;
  courseType: CourseTypeEnum;
  courseDescription: string;
  courseColor: string;
  locationName: string;
  locationId?: string;
  courseMax: number; // The same as maxAttendees. Kept for compatibility sake
  maxAttendees: number;
  companyId?: string; // System.Guid
  createdDate: string; // System.DateTime
  isCancelled: boolean;
  courseActive: boolean;
  neededEquipmentText?: string;
  courseTimesDateFrom: string; //System.DateTime
  courseTimesDateTill: string; //System.DateTill
  membershipTypeAvailabilities: AppointmentMembershipTypeAvailability[];
  readonly availableForMembershipTypeNames: string[];
  isAvailableForAttendee(membershipTypes: ReferenceParent[]): boolean;
  getCostForAttendee(membershipTypes: ReferenceParent[]): number;
  isRegisteredForAttendee(attendeeId: string): boolean;
  isRegisteredForCurrentUser: boolean;
  countOfMessages: number;
  attendances: Attendance[];
}

export interface Value {
  value: Appointment[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<Appointment>): any {
  return {
    Id: data?.id || undefined,
    DateTimeId: data?.dateTimeId || undefined,
    CourseId: data?.courseId || undefined,
    RoomId: data?.roomId || undefined,
    Date: data?.date || '',
    TimeFrom: data?.timeFrom || '',
    TimeTill: data?.timeTill || '',
    MaxAttendees: data?.maxAttendees || 0,
    Text: data?.text || '',
    CompanyId: data?.companyId || undefined,
  };
}

function parse(data?: Partial<Appointment>): Appointment {
  let courseType: any = data?.courseType || CourseTypeEnum.RegularCourse;
  return {
    id: data?.id || undefined,
    logo: data?.logo || '',
    text: data?.text || '',
    dateTimeId: data?.dateTimeId || undefined,
    courseId: data?.courseId || undefined,
    bookableAsSeries: data?.bookableAsSeries || false,
    roomId: data?.roomId || undefined,
    isPassed: data?.isPassed || false,
    get isPassedWithTime() {
      return (
        this.isPassed ||
        this.dateAndTime < DateUtils.getCurrentLocalDateAsIsoString()
      );
    },
    roomName: data?.roomName || '',
    date: data?.date || '',
    timeFrom: data?.timeFromString?.substring(0, 5) || data?.timeFrom || '',
    timeTill: data?.timeTillString?.substring(0, 5) || data?.timeTill || '',
    get time() {
      return `${this.timeFrom} - ${this.timeTill}`;
    },
    get dateAndTime() {
      return `${this.date.substring(0, 10)} ${this.timeFrom} - ${
        this.timeTill
      }`;
    },
    trainerFullNames: data?.trainerFullNames || '',
    trainerIds: data?.trainerIds || '',
    get trainerIdArray() {
      if (!this.trainerIds) return [];
      return this.trainerIds.split(';');
    },
    attendeeIds: data?.attendeeIds || '',
    get attendeeIdArray() {
      if (!this.attendeeIds) return [];
      return this.attendeeIds.split(';');
    },
    countOfAttendancesForTheDate: data?.countOfAttendancesForTheDate || 0,
    countOfSeriesCourseAttendees: data?.countOfSeriesCourseAttendees || 0,
    get isFull() {
      return this.countOfAttendancesForTheDate >= this.maxAttendees;
    },
    get isCapacityExceeded() {
      return this.countOfAttendancesForTheDate > this.maxAttendees;
    },
    get isSeriesCourseBookedUp() {
      return this.countOfSeriesCourseAttendees >= this.maxAttendees;
    },
    courseMax: data?.maxAttendees || 0,
    maxAttendees: data?.maxAttendees || 0,
    courseName: data?.courseName || '',
    courseType:
      typeof courseType == 'string'
        ? CourseTypeEnum[courseType as keyof typeof CourseTypeEnum]
        : (courseType as CourseTypeEnum),
    courseDescription: data?.courseDescription || '',
    courseColor: data?.courseColor || '#D0D0D0',
    locationName: data?.locationName || '',
    locationId: data?.locationId || undefined,
    companyId: data?.companyId || undefined,
    createdDate: data?.createdDate || '',
    isCancelled: data?.isCancelled || false,
    courseActive: data?.courseActive || false,
    neededEquipmentText: data?.neededEquipmentText || undefined,
    courseTimesDateFrom: data?.courseTimesDateFrom || '',
    courseTimesDateTill: data?.courseTimesDateTill || '',
    membershipTypeAvailabilities: data?.membershipTypeAvailabilities || [],
    get availableForMembershipTypeNames(): string[] {
      return this.membershipTypeAvailabilities.map((x) => x.membershipTypeName);
    },
    isAvailableForAttendee(membershipTypes: ReferenceParent[]) {
      if (!membershipTypes) return false;
      if (this.membershipTypeAvailabilities.length == 0) return true;
      let activeMembershipTypeIds = membershipTypes
        .filter((x) => x.isActiveAtDate(this.date))
        .map((x) => x.referenceId!);

      let availability = this.membershipTypeAvailabilities.find((x) =>
        activeMembershipTypeIds.includes(x.membershipTypeId)
      );
      return availability !== undefined;
    },
    getCostForAttendee(membershipTypes: ReferenceParent[]): number {
      if (this.membershipTypeAvailabilities.length == 0) return 0;
      let activeMembershipTypeIds = membershipTypes
        .filter((x) => x.isActiveAtDate(this.date))
        .map((x) => x.referenceId!);

      let costs = this.membershipTypeAvailabilities
        .filter((x) => activeMembershipTypeIds.includes(x.membershipTypeId))
        .map((x) => x.cost)
        .sort();
      if (costs.length == 0) return 0;
      return costs[0];
    },
    isRegisteredForAttendee(attendeeId: string): boolean {
      return this.attendeeIdArray.includes(attendeeId);
    },
    isRegisteredForCurrentUser: false,
    countOfMessages: data?.countOfMessages || 0,
    attendances: data?.attendances ?? [],
  };
}

export default {
  parse,
  toAPI: toAPI,
};
