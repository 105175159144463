import { CostDiscountState } from './types';

export const state = (): CostDiscountState => initialState();

export const initialState = (): CostDiscountState => ({
  costDiscounts: {
    items: [],
    isLoading: false,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: -1,
        sortBy: ['dateFrom'],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: [],
    },
  },
});
