import { MutationTree } from 'vuex';
import { InvoicesState } from './types';
import invoice, { Value } from '@/shared/model/invoice';
import { SearchParams } from '@/shared/model/searchParams';
import { deepClone } from '@/shared/utils/generalUtils';
import { DataOptions } from '@/shared/model/dataOptions';

export const mutations: MutationTree<InvoicesState> = {
  setInvoices(state, payload: Value) {
    state.invoices.items = payload.value.map((x) => invoice.parse(x));
    state.invoices.total = payload['@odata.count'] || 0;
    state.invoices.isLoading = false;
  },
  setInvoicesTotal(state, payload: number) {
    state.invoices.total = payload;
  },
  setInvoicesIsLoading(state, payload: boolean) {
    state.invoices.isLoading = payload;
  },
  setSearchParams(state, payload: SearchParams) {
    state.invoices.searchParams = deepClone(payload);
  },
  setPage(state, payload: number) {
    state.invoices.searchParams.dataOption.page = payload;
  },
  setFilter(state, payload: string) {
    state.invoices.searchParams.filter = payload;
  },
  setDataOption(state, payload: DataOptions) {
    state.invoices.searchParams.dataOption = deepClone(payload);
  },
  resetInvoicesPaging(state) {
    state.invoices.searchParams.dataOption.page = 1;
    state.invoices.searchParams.dataOption.itemsPerPage = 10;
    state.invoices.searchParams.dataOption.filter = '';
    state.invoices.searchParams.filter = '';
  },
};
