import { EmployeeState } from './types';

export const state = (): EmployeeState => initialState();

export const initialState = (): EmployeeState => ({
  employees: {
    items: [],
    isLoading: false,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['isOnline', 'active', 'lastName', 'firstName'],
        sortDesc: [true, true, false, false],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: [
        'email',
        'firstName',
        'lastName',
        'userName',
        'departmentNames',
        'phone',
        'locations',
      ],
    },
  },
});
