import { GetterTree } from 'vuex';
import { CheckInState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<CheckInState, RootState> = {
  ['getCourseTrainers']: (state) => state.courseTrainers,
  ['getCourseTrainersIsLoading']: (state) => state.courseTrainers?.isLoading,
  ['getCourseTrainersTotal']: (state) => state.courseTrainers?.total,
  ['getCourseTrainersSearchParams']: (state) =>
    state.courseTrainers?.searchParams,
};
