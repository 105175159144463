import { MutationTree } from 'vuex';
import { NewsRefState } from './types';
import newsRef, { NewsRef, Value } from '@/shared/model/newsRef';

export const mutations: MutationTree<NewsRefState> = {
  setNewsRefs(state, payload: Value) {
    state.newsRefs.items = payload.value.map(x => newsRef.parse(x));
    state.newsRefs.total = payload['@odata.count'] || 0;
    state.newsRefs.isLoading = false;
  },
  setAllNewsRefs(state, payload: NewsRef[]) {
    state.newsRefs.items = payload.map(newsRef.parse);
    state.newsRefs.total = payload.length;
    state.newsRefs.isLoading = false;
  },
  clearNewsRefs(state) {
    state.newsRefs.items = [];
    state.newsRefs.total = 0;
    state.newsRefs.isLoading = false;
  },
  setNewsRefsTotal(state, payload: number) {
    state.newsRefs.total = payload;
  },
  setNewsRefsIsLoading(state, payload: boolean) {
    state.newsRefs.isLoading = payload;
  },
};
