import { LocationEmployeeState } from './types';

export const state = (): LocationEmployeeState => initialState();

export const initialState = (): LocationEmployeeState => ({
  locationEmployees: {
    //TODO remove when was made backend
    items: [],
    isLoading: false,
    total: 2, //TODO remove when was made backend
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['active'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: ['firstName', 'lastName'],
    },
  },
});
