import { AttendanceState } from './types';

export const state = (): AttendanceState => initialState();

export const initialState = (): AttendanceState => ({
  attendances: {
    items: [],
    isLoading: false,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['bookingDate'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: [
        'attendeeFullName',
        'trainerFullName',
        'locationName',
        'courseName',
        'departmentNames',
        'roomName',
        'timeFrom',
        'timeTill',
      ],
    },
  },
});
