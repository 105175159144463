import { ActionTree } from 'vuex';
import { RootState } from '../../types';
import { Logger } from 'fsts';
import { ReportState } from './types';
import { defaultBackendReport } from '@/shared/backend/report';
import { Report } from '@/shared/model/report';
import moduleUtils from '../moduleUtils';
import { AxiosResponse } from 'axios';
import { i18nGlobal } from '@/i18n';
import DownloadUtils from '@/shared/utils/downloadUtils';

const logger = new Logger('actions.reports');
export const actions: ActionTree<ReportState, RootState> = {
  fillReport({ commit }, payload: Report) {
    payload.fractions = [];
    commit('setReportIsLoading', true);
    return defaultBackendReport
      .fillReport(payload)
      .then((response: AxiosResponse<Report>) => {
        commit('setReport', response.data);
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setReportIsLoading', false);
      });
  },
  async exportToExcel({ commit }, payload: Report) {
    let culture = i18nGlobal.locale.value == 'de' ? 'de-DE' : 'en-US';
    try {
      await defaultBackendReport
        .exportToExcel(payload, culture)
        .then((response) => {
          let fileName = DownloadUtils.getFileName(
            response.headers['content-disposition']
          );
          const type = response.headers['content-type'];
          if (DownloadUtils.isIeOrEdge(window)) {
            const file = new File([response.data], fileName, {
              type: type,
            });
            (window.navigator as any).msSaveOrOpenBlob(file, fileName);
          } else {
            const blob = new Blob([response.data], { type: type });
            const url = DownloadUtils.createDownloadLink(blob, fileName);
            window.URL.revokeObjectURL(url);
          }
        });
    } catch (e) {
      moduleUtils.error(i18nGlobal.t(`error.download_file`), commit, e, logger);
    }
  },
};
