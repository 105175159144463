import { InvoicesState } from './types';

export const state = (): InvoicesState => initialState();

export const initialState = (): InvoicesState => ({
  invoices: {
    items: [],
    isLoading: false,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['periodStartDate'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: ['attendeeFullName', 'memberNumber', 'invoiceNumberString'],
    },
  },
});
