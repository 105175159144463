export interface LocationAttendee {
  id?: string; // System.Guid
  locationId: string; // System.Guid
  attendeeId: string; // System.Guid
  companyId?: string; // System.Guid
}

export interface Value {
  value: LocationAttendee[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

function parse(data?: Partial<LocationAttendee>): LocationAttendee {
  return {
    id: data?.id || undefined,
    locationId: data?.locationId || '',
    attendeeId: data?.attendeeId || '',
    companyId: data?.companyId || undefined,
  };
}

export default {
  parse,
};
