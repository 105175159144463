import { ReportState } from './types';
import { InitialReport } from '@/shared/model/report';
import { deepClone } from '@/shared/utils/generalUtils';

export const state = (): ReportState => initialState();

export const initialState = (): ReportState => ({
  report: deepClone(InitialReport),
  loading: false,
});
