import { MutationTree } from 'vuex';
import { AppointmentState } from './types';
import appointment, { Appointment, Value } from '@/shared/model/appointment';
import { SearchParams } from '@/shared/model/searchParams';
import { deepClone } from '@/shared/utils/generalUtils';
import { DataOptions } from '@/shared/model/dataOptions';
import { Filters } from '@/shared/model/calendar';

export const mutations: MutationTree<AppointmentState> = {
  setAppointments(state, payload: Value) {
    state.appointments.items = payload.value.map((x) => appointment.parse(x));
    state.appointments.total = payload['@odata.count'] || 0;
    state.appointments.isLoading = false;
  },
  setAppointment(state, payload: Appointment) {
    state.appointment = appointment.parse(payload);
  },
  setAppointmentsTotal(state, payload: number) {
    state.appointments.total = payload;
  },
  setAppointmentsIsLoading(state, payload: boolean) {
    state.appointments.isLoading = payload;
  },
  setSearchParams(state, payload: SearchParams) {
    state.appointments.searchParams = deepClone(payload);
  },
  setPage(state, payload: number) {
    state.appointments.searchParams.dataOption.page = payload;
  },
  setItemsPerPage(state, payload: number) {
    state.appointments.searchParams.dataOption.itemsPerPage = payload;
  },
  setFilter(state, payload: string) {
    state.appointments.searchParams.filter = payload;
  },
  setDataOption(state, payload: DataOptions) {
    state.appointments.searchParams.dataOption = deepClone(payload);
  },
  setSelectedFiltersForCalendar(state, payload: Filters) {
    state.selectedFiltersForCalendar = deepClone(payload);
  },
  resetAppointmentsPaging(state) {
    state.appointments.searchParams.dataOption.page = 1;
    state.appointments.searchParams.dataOption.itemsPerPage = 10;
    state.appointments.searchParams.dataOption.filter = '';
    state.appointments.searchParams.filter = '';
  },
};
