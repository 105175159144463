import { TaskRequestState } from './types';

export const state = (): TaskRequestState => initialState();

export const initialState = (): TaskRequestState => ({
  taskRequestsAttendancesOpenCount: 0,
  taskRequestsTotalOpenCount: 0,
  taskRequests: {
    items: [],
    isLoading: false,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: [
        'courseName',
        'attendeeFullName',
        'trainerFullName',
        'locationName',
        'roomName',
      ],
    },
  },
});
