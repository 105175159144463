import { Component, Prop, Vue, toNative, Setup } from 'vue-facing-decorator';
import { useI18n } from 'vue-i18n';
import $ from 'jquery';

const locales = require('./locales.json');

@Component({ name: 'expandable-element', components: {} })
class ExpandableElement extends Vue {
  @Setup((props, ctx) => useI18n({ messages: locales }))
  private i18n!: any;

  @Prop({ default: 500 })
  private baseHeight!: number;
  private fullHeight: number = 1000;

  @Prop({ default: false })
  private opened!: boolean;
  private isOpened: boolean = false;

  private divElement!: HTMLDivElement;
  private lowContent: boolean = true;

  private isAnimating: boolean = false;
  private identifier: number = Math.round(Math.random() * 1000);

  mounted() {
    this.isOpened = this.opened;
    $(`#${this.expandableElementContainerName}`).css(
      'max-height',
      `${this.baseHeight}px`
    );

    let ref = this.$refs[this.divRefName];
    if (ref instanceof HTMLDivElement) {
      this.divElement = ref;

      //A small hack. The contained element only grows to its full size after mounted.
      setTimeout(this.delayedSetup, 200);
    }
  }

  delayedSetup() {
    this.fullHeight = this.divElement.clientHeight + 100;
    this.lowContent = this.fullHeight <= this.baseHeight;

    if (this.isOpened) {
      $(`#${this.expandableElementContainerName}`).css(
        'max-height',
        `${this.fullHeight}px`
      );
    }
  }

  private toggle() {
    this.isOpened = !this.isOpened;

    if (!this.isAnimating) {
      if (this.isOpened) {
        this.openAnimation();
      } else {
        this.closeAnimation();
      }
    }
  }

  private openAnimation() {
    this.isAnimating = true;
    $(`#${this.expandableElementContainerName}`).animate(
      { 'max-height': `${this.fullHeight}px` },
      this.fullHeight - this.baseHeight,
      () => {
        if (!this.isOpened) this.closeAnimation();
        else this.isAnimating = false;
      }
    );
  }

  private closeAnimation() {
    this.isAnimating = true;
    $(`#${this.expandableElementContainerName}`).animate(
      { 'max-height': `${this.baseHeight}px` },
      this.fullHeight - this.baseHeight,
      () => {
        if (this.isOpened) this.openAnimation();
        else this.isAnimating = false;
      }
    );
  }

  get mobile() {
    return this.$vuetify.display.mobile;
  }

  get divRefName() {
    return `divRef${this.identifier}`;
  }

  get expandableElementContainerName() {
    return `expandable${this.identifier}`;
  }
}

export default toNative(ExpandableElement);
