import { DocumentState } from './types';

export const state = (): DocumentState => initialState();

export const initialState = (): DocumentState => ({
  document: undefined,
  documents: [],
  documentsIsLoading: false,
  documentsTotal: 0,
  documentsSearchParams: {
    dataOption: {
      page: 1,
      itemsPerPage: 10,
      sortBy: ['createdDate'],
      sortDesc: [true],
      groupBy: [],
      groupDesc: [],
      multiSort: true,
      mustSort: false,
    },
    filter: '',
    orClauseFieldsIds: ['title'],
  },
  documentsNew: [],
  documentsNewIsLoading: false,
  documentsNewTotal: 0,
  documentsNewSearchParams: {
    dataOption: {
      page: 1,
      itemsPerPage: 5,
      sortBy: [],
      sortDesc: [false],
      groupBy: [],
      groupDesc: [],
      multiSort: true,
      mustSort: false,
    },
    filter: '',
    orClauseFieldsIds: ['title'],
  },
  documentCategories: [],
  documentCategoriesIsLoading: false,
  documentCategoriesSearchParams: {
    dataOption: {
      page: 1,
      itemsPerPage: 10,
      sortBy: ['usageCount', 'name'],
      sortDesc: [true, false],
      groupBy: [],
      groupDesc: [],
      multiSort: true,
      mustSort: false,
    },
    filter: '',
    orClauseFieldsIds: ['name'],
  },
});
