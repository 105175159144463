import { ActionTree } from 'vuex';
import { TrainerState } from './types';
import { RootState } from '../../types';
import { defaultBackendTrainer } from '@/shared/backend/trainer';
import { Logger } from 'fsts';
import moduleUtils from '../moduleUtils';
import { Value, Trainer, TrainerSkillsCoverage } from '@/shared/model/trainer';
import { AxiosResponse } from 'axios';
import { i18nGlobal } from '@/i18n';
import { CONST } from '@/shared/utils/constants';

const logger = new Logger('actions.trainers');
export const actions: ActionTree<TrainerState, RootState> = {
  getAllTrainers(
    { commit, dispatch, getters, rootGetters },
    payload?: {
      locationIds?: string[];
      departmentIds?: string[];
      employedAfter?: string;
      employedBefore?: string;
      alwaysIncludedTrainersIds?: string[];
      noStore?: boolean;
      companyId?: string;
    }
  ) {
    commit('setTrainersIsLoading', true);
    const locationIds = payload?.locationIds || undefined;
    const departmentIds = payload?.departmentIds || undefined;
    const employedAfter = payload?.employedAfter || undefined;
    const employedBefore = payload?.employedBefore || undefined;
    const alwaysIncludedTrainersIds =
      payload?.alwaysIncludedTrainersIds || undefined;
    const companyId = payload?.companyId ?? CONST.emptyGuid;

    return defaultBackendTrainer
      .getAllTrainers(
        locationIds,
        departmentIds,
        employedAfter,
        employedBefore,
        alwaysIncludedTrainersIds,
        companyId
      )
      .then((response: AxiosResponse<Value>) => {
        commit('setTrainersIsLoading', false);
        if (payload?.noStore) return response.data;

        commit('setAllTrainers', response.data);
        return response.data;
      })
      .catch((e: any) => {
        commit('setTrainersIsLoading', false);
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  getTrainers(
    { commit, dispatch, getters, rootGetters },
    payload?: {
      searchParams?: any;
      locationId?: string;
      departmentId?: string;
      skillIds?: string[];
      noStore?: boolean;
      trainerIds?: string[];
    }
  ) {
    commit('setTrainersIsLoading', true);
    let searchParams = payload?.searchParams ?? getters.getTrainersSearchParams;
    return defaultBackendTrainer
      .getTrainers(
        searchParams,
        payload?.locationId,
        payload?.departmentId,
        payload?.skillIds,
        payload?.trainerIds
      )
      .then((response: AxiosResponse<Value>) => {
        commit('setTrainersIsLoading', false);
        if (payload?.noStore) return response.data;

        commit('setTrainers', response.data);
        return response.data;
      })
      .catch((e: any) => {
        commit('setTrainersIsLoading', false);
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  getTrainersWithAllLocations(
    { commit },
    payload: {
      locationIds: string[];
      employedAfter?: string;
    }
  ) {
    commit('setTrainersIsLoading', true);
    return defaultBackendTrainer
      .getTrainersWithAllLocations(payload.locationIds, payload.employedAfter)
      .then((response: AxiosResponse<Value>) => {
        commit('setAllTrainers', response.data);
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setTrainersIsLoading', false);
      });
  },
  getTrainer({ commit, dispatch }, id: string) {
    return dispatch('getTrainerByIdAndCompanyId', { trainerId: id });
  },
  getTrainerByIdAndCompanyId(
    { commit },
    payload: { trainerId: string; companyId?: string }
  ) {
    return defaultBackendTrainer
      .getTrainer(payload.trainerId, payload.companyId)
      .then((response: AxiosResponse<Trainer>) => {
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateTrainer({ commit, dispatch }, file: Trainer) {
    return defaultBackendTrainer
      .updateTrainer(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Trainer;
          }>
        ) => {
          moduleUtils.ok(i18nGlobal.t(`success.trainer_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteTrainer({ commit, dispatch }, id: string) {
    return defaultBackendTrainer
      .deleteTrainer(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Trainer;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  getTrainerSkillCoverages(
    { commit },
    payload: { skillIds: string[]; trainerIds: string[] }
  ) {
    if (payload.skillIds.length == 0) {
      return new Promise((resolve, reject) => {
        let res = payload.trainerIds.map((id) => {
          return {
            trainerId: id,
            percentage: 100,
          };
        });
        resolve(res);
      });
    }
    return defaultBackendTrainer
      .getSkillCoverages(payload.skillIds, payload.trainerIds)
      .then((response: AxiosResponse<TrainerSkillsCoverage[]>) => {
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
};
