
import { GetterTree } from 'vuex';
import { NoteState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<NoteState, RootState> = {
  ['getNotes']: (state) => state.notes,
  ['getNotesIsLoading']: (state) => state.notes?.isLoading,
  ['getNotesTotal']: (state) => state.notes?.total,
  ['getNotesSearchParams']: (state) => state.notes?.searchParams,
};
