import { ActionTree } from 'vuex';
import { CostDiscountState } from './types';
import { RootState } from '../../types';
import { Logger } from 'fsts';
import moduleUtils from '../moduleUtils';
import { AxiosResponse } from 'axios';
import { SearchParams } from '@/shared/model/searchParams';
import { DefaultBackendCostDiscount } from '@/shared/backend/costDiscount';
import { CostDiscount, Value } from '@/shared/model/costDiscount';

const logger = new Logger('actions.costDiscounts');

export const actions: ActionTree<CostDiscountState, RootState> = {
  getCostDiscounts(
    { commit, getters },
    payload: { searchParams?: SearchParams; costId?: string }
  ) {
    let searchParams =
      payload.searchParams ?? getters.getCostDiscountsSearchParams;
    commit('setCostDiscountsIsLoading', true);

    return DefaultBackendCostDiscount.getCostDiscounts(
      searchParams,
      payload.costId
    )
      .then((resp: AxiosResponse<Value>) => {
        commit('setCostDiscounts', resp.data);
        return resp.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setCostDiscountsIsLoading', false);
      });
  },

  postMultipleCostDiscounts(
    { commit },
    payload: {
      costId: string;
      costDiscounts: CostDiscount[];
    }
  ) {
    return DefaultBackendCostDiscount.postMultipleCostDiscounts(
      payload.costId,
      payload.costDiscounts
    )
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
          }>
        ) => {
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
};
