import { ActionTree } from 'vuex';
import { DepartmentState } from './types';
import { RootState } from '../../types';
import { defaultBackendDepartment } from '@/shared/backend/department';
import { Logger } from 'fsts';
import moduleUtils from '../moduleUtils';
import { Value, Department } from '@/shared/model/department';
import { AxiosResponse } from 'axios';
import { i18nGlobal } from '@/i18n';
import { createSearchParams } from '@/shared/model/searchParams';

const logger = new Logger('actions.departments');
export const actions: ActionTree<DepartmentState, RootState> = {
  getDepartments(
    { commit, getters },
    payload?: { searchParams?: any; companyId?: string }
  ) {
    commit('setDepartmentsIsLoading', true);
    let searchParams =
      payload?.searchParams ?? getters.getDepartmentsSearchParams;
    return defaultBackendDepartment
      .getDepartments(searchParams, payload?.companyId)
      .then((response: AxiosResponse<Value>) => {
        commit('setDepartments', response.data);
        commit('setDepartmentsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setDepartmentsIsLoading', false);
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  getAllDepartments({ commit }, payload?: { companyId?: string }) {
    commit('setDepartmentsIsLoading', true);
    return defaultBackendDepartment
      .getAllDepartments(payload)
      .then((response: AxiosResponse<Value>) => {
        commit('setAllDepartments', response.data);
        commit('setDepartmentsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setDepartmentsIsLoading', false);
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  getAllDepartmentsAlphabetically(
    { dispatch },
    payload?: { companyId?: string }
  ) {
    return dispatch('getDepartments', {
      searchParams: createSearchParams(-1, 1, ['name'], [false]),
      companyId: payload?.companyId,
    });
  },
  getDepartment({ commit }, id: string) {
    return defaultBackendDepartment
      .getDepartment(id)
      .then((response: AxiosResponse<Department>) => {
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateDepartment({ commit }, department: Department) {
    return defaultBackendDepartment
      .updateDepartment(department)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Department;
          }>
        ) => {
          moduleUtils.ok(i18nGlobal.t(`success.department_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteDepartment({ commit }, id: string) {
    return defaultBackendDepartment
      .deleteDepartment(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Department;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  getOwnUserDepartments({ commit }, isAttendee?: boolean) {
    commit('setOwnUserDepartmentsLoaded', false);
    return defaultBackendDepartment
      .getOwnUserDepartments()
      .then((response: AxiosResponse<Value>) => {
        commit('setOwnUserDepartments', response.data);
        if (!isAttendee) commit('addOwnUserAllDepartment');
        commit('setOwnUserDepartmentsLoaded', true);

        return response.data.value;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  setSelectedOwnUserDepartment({ commit, getters }, payload: string) {
    let departments: Department[] = getters.getOwnUserDepartments;
    let index = departments.findIndex((dep: Department) => dep.id == payload);

    commit('setSelectedOwnUserDepartmentIndex', index);
  },
  clearOwnUserDepartments({ commit }) {
    commit('setOwnUserDepartments', []);
    commit('setOwnUserDepartmentsLoaded', false);
  },
};
