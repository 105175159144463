export interface AppointmentTrainer {
  id?: string; // System.Guid
  appointmentId?: string; // System.Guid
  trainerId?: string; // System.Guid
  companyId?: string; // System.Guid
  createdDate: string; // System.DateTime
  creatorId?: string; // System.Guid
}

export interface Value {
  value: AppointmentTrainer[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<AppointmentTrainer>): any {
  return {
    Id: data?.id || undefined,
    AppointmentId: data?.appointmentId || undefined,
    TrainerId: data?.trainerId || undefined,
    CompanyId: data?.companyId || undefined,
  };
}

function parse(data?: Partial<AppointmentTrainer>): AppointmentTrainer {
  return {
    id: data?.id || undefined,
    appointmentId: data?.appointmentId || undefined,
    trainerId: data?.trainerId || undefined,
    companyId: data?.companyId || undefined,
    createdDate: data?.createdDate || '',
    creatorId: data?.creatorId || undefined,
  };
}

export default {
  parse,
  toAPI: toAPI,
};
