import { GetterTree } from 'vuex';
import { RoomPresenceState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<RoomPresenceState, RootState> = {
  ['getRoomPresences']: state => state.roomPresences,
  ['getRoomPresencesIsLoading']: state => state.roomPresences?.isLoading,
  ['getRoomPresencesTotal']: state => state.roomPresences?.total,
  ['getRoomPresencesSearchParams']: state => state.roomPresences?.searchParams,
};
