import { MembershipApplicationState } from './types';
import attendee from '@/shared/model/attendee';
import location from '@/shared/model/location';
import company from '@/shared/model/company';
import { PaymentInterval, PaymentType } from '@/shared/model/paymentMandateData';
import DateUtils from '@/shared/utils/dateUtils';

export const state = (): MembershipApplicationState => initialState();

export const initialState = (): MembershipApplicationState => ({
  attendee: attendee.parse({}),
  location: location.parse({}),
  company: company.parse({}),
  membershipTypes: [],
  costs: [],
  memberStatuses: [],
  membershipTypeCostOverrides: [],
  costDiscounts: [],
  costMembershipTypes: [],
  isLoading: false,
  applicationConfirmationData: {
    email: '',
    firstName: '',
    lastName: '',
    address: '',
    userName: '',
    subscriptions: [],
    subscriptionPrices: [],
    oneTimeCostItems: [],
    oneTimeCostPrices: [],
    periodicFixedCostItems: [],
    periodicFixedCostPrices: [],
    yearlyFixedCostItems: [],
    yearlyFixedCostPrices: [],
    totalCosts: '',
    periodicCostFactor: '',
    iban: '',
    accountHolder: '',
    paymentType: PaymentType.DebitMandate,
    paymentInterval: PaymentInterval.Monthly,
    fromDate: DateUtils.getTodayDateAsIsoString(),
  },
});
