import { CONST } from '../utils/constants';
import DateUtils from '../utils/dateUtils';

export interface CostDiscount {
  id?: string; // System.Guid
  costId?: string; // System.Guid
  altName: string;
  altCost: number;
  dateFrom: string; // System.DateTime
  dateTill: string; // System.DateTime
  companyId: string; // System.Guid
  creatorId?: string; // System.Guid
  createdDate: string; // System.DateTime
}

export interface Value {
  value: CostDiscount[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

function arrayToAPI(overrides: CostDiscount[]): any {
  return overrides.map((x) => {
    return {
      AltName: x.altName || undefined,
      AltCost: x.altCost,
      DateFrom: x.dateFrom,
      DateTill: x.dateTill,
    };
  });
}

function parse(data?: Partial<CostDiscount>): CostDiscount {
  return {
    id: data?.id || undefined,
    costId: data?.costId || undefined,
    altName: data?.altName || '',
    altCost: data?.altCost || 0,
    dateFrom: data?.dateFrom || DateUtils.getTodayDateAsIsoString(),
    dateTill: data?.dateTill || DateUtils.getTodayDateAsIsoString(),
    companyId: data?.companyId || CONST.emptyGuid,
    creatorId: data?.creatorId || undefined,
    createdDate: data?.createdDate || '',
  };
}

export default {
  parse,
  arrayToAPI,
};

export const EmptyCostDiscount: CostDiscount = parse({});
