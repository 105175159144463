import { CONST } from '../utils/constants';
import DateUtils from '../utils/dateUtils';

export interface WebPushMessage {
  id: string; //Guid
  tag: string;
  title: string;
  text: string;
  companyId: string; //Guid
  createdDate: string; //DateTime
  read: boolean;
}

function parse(data?: Partial<WebPushMessage>): WebPushMessage {
  return {
    id: data?.id || CONST.emptyGuid,
    tag: data?.tag || '',
    title: data?.title || '',
    text: data?.text || '',
    companyId: data?.companyId || CONST.emptyGuid,
    createdDate: data?.createdDate || DateUtils.getNowDate().toISOString(),
    read: data?.read ?? true,
  };
}

export interface Value {
  value: WebPushMessage[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

export default {
  parse,
};
