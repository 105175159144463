import { GetterTree } from 'vuex';
import { StripeState } from './types';
import { RootState } from '../../types';
import { StripeSubscription, StripeSubscriptionItem } from '@/shared/model/stripe';

export const getters: GetterTree<StripeState, RootState> = {
  isLoadingEmployeeProjectConfiguration: state =>
    state.isLoadingTasksConfiguration,

  getIsLoadingSubscriptionDetails(state): boolean {
    return state.isLoadingSubscriptionDetails;
  },
  getSubscription(state): StripeSubscription {
    return state.subscription;
  },
  getSubscriptionItem(state): StripeSubscriptionItem {
    return state.subscriptionItem;
  }
};
