import { ActionTree } from 'vuex';
import { AppointmentMessageState } from './types';
import { RootState } from '../../types';
import { defaultBackendAppointmentMessage } from '@/shared/backend/appointmentMessage';
import { Logger } from 'fsts';
import moduleUtils from '../moduleUtils';
import { AppointmentMessage } from '@/shared/model/appointmentMessage';
import { AxiosResponse } from 'axios';
import { i18nGlobal } from '@/i18n';

const logger = new Logger('actions.appointmentMessages');
export const actions: ActionTree<AppointmentMessageState, RootState> = {
  getMessages(
    { commit },
    payload: { appointmentId: string; append?: boolean; fromDate?: string }
  ) {
    commit('setMessagesIsLoading', true);
    if (!payload.append) commit('setMessages', []);

    return defaultBackendAppointmentMessage
      .getAppointmentMessagesByAppointmentId(
        payload.appointmentId,
        payload.fromDate
      )
      .then((appointmentMessages) => {
        if (payload.append) commit('appendMessages', appointmentMessages.data);
        else commit('setMessages', appointmentMessages.data);
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => commit('setMessagesIsLoading', false));
  },
  updateMessage({ commit }, payload: AppointmentMessage) {
    commit('setMessagesIsLoading', true);

    return defaultBackendAppointmentMessage
      .updateAppointmentMessage(payload)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: AppointmentMessage;
          }>
        ) => {
          moduleUtils.ok(
            i18nGlobal.t(`success.appointmentmessage_update`),
            commit
          );
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => commit('setMessagesIsLoading', false));
  },
};
