export type NoteTypeEnum = 0 | 1;
export const NoteTypeEnum = {
  Default: 0 as NoteTypeEnum,
  CheckInNote: 1 as NoteTypeEnum,
};

export interface Note {
  id?: string; // System.Guid
  name: string; // string
  text: string; // string
  creatorFullName: string;
  parentId?: string; // System.Guid?
  type: NoteTypeEnum;
  status: number; //ubyte
  companyId?: string; // System.Guid
  creatorId?: string; // System.Guid?
  createdDate: string; // System.DateTime
}

export interface Value {
  value: Note[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<Note>): any {
  return {
    Id: data?.id || undefined,
    Name: data?.name || '',
    Text: data?.text || '',
    ParentId: data?.parentId || undefined,
    Type: data?.type || NoteTypeEnum.Default,
    Status: data?.status || 0,
    CompanyId: data?.companyId || undefined,
  };
}

function parse(data?: Partial<Note>): Note {
  let typeKey = data?.type || getNoteTypeText(NoteTypeEnum.Default);
  return {
    id: data?.id || undefined,
    name: data?.name || '',
    text: data?.text || '',
    creatorFullName: data?.creatorFullName || '',
    parentId: data?.parentId || undefined,
    type: NoteTypeEnum[typeKey as keyof typeof NoteTypeEnum],
    status: data?.status || 0,
    companyId: data?.companyId || undefined,
    creatorId: data?.creatorId || undefined,
    createdDate: data?.createdDate || '',
  };
}

function getNoteTypeText(type: NoteTypeEnum) {
  return Object.keys(NoteTypeEnum)[
    Object.values(NoteTypeEnum).findIndex((entry) => entry == type)
  ];
}

export default {
  parse,
  toAPI: toAPI,
  getNoteTypeText,
};
