import { MutationTree } from 'vuex';
import { DepartmentState } from './types';
import department, {
  Value,
  Department,
  AllDepartment,
} from '@/shared/model/department';

export const mutations: MutationTree<DepartmentState> = {
  setDepartments(state, payload: Value) {
    state.departments.items = payload.value.map((x) => department.parse(x));
    state.departments.total = payload['@odata.count'] || 0;
    state.departments.isLoading = false;
  },
  setAllDepartments(state, payload: Department[]) {
    state.departments.items = payload.map((x) => department.parse(x));
    state.departments.total = payload.length;
    state.departments.isLoading = false;
  },
  setDepartmentsTotal(state, payload: number) {
    state.departments.total = payload;
  },
  setDepartmentsIsLoading(state, payload: boolean) {
    state.departments.isLoading = payload;
  },
  resetDepartmentsPaging(state) {
    state.departments.searchParams.dataOption.page = 1;
    state.departments.searchParams.dataOption.itemsPerPage = 10;
    state.departments.searchParams.dataOption.filter = '';
    state.departments.searchParams.filter = '';
  },
  setOwnUserDepartments(state, payload: Partial<Department>[]) {
    state.ownUserDepartments = payload.map((dep) => department.parse(dep));
  },
  setSelectedOwnUserDepartmentIndex(state, payload: number) {
    state.selectedOwnUserDepartmentIndex = payload;
  },
  addOwnUserAllDepartment(state) {
    state.ownUserDepartments.unshift({ ...AllDepartment });
  },
  setOwnUserDepartmentsLoaded(state, payload: boolean) {
    state.ownUserDepartmentsLoaded = payload;
  },
};
