import { DataOptions, getDefault } from './dataOptions';
import { deepClone } from '../utils/generalUtils';

export interface SearchParams {
  dataOption: DataOptions;
  orClauseFieldsIds: string[];
  filter: string;
  view?: string;
}

export const SearchParamsEmpty: SearchParams = {
  dataOption: { ...getDefault },
  orClauseFieldsIds: [],
  filter: '',
};

export function createSearchParams(
  pagesize: number = 10,
  page: number = 1,
  sortBy: string[] = [],
  sortDesc: boolean[] = []
): SearchParams {
  let searchParams: SearchParams = deepClone(SearchParamsEmpty);

  searchParams.dataOption.itemsPerPage = pagesize;
  searchParams.dataOption.page = page;
  searchParams.dataOption.sortBy = sortBy;
  searchParams.dataOption.sortDesc = sortDesc;
  searchParams.dataOption.multiSort = sortBy.length > 1;

  return searchParams;
}
