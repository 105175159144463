
import { GetterTree } from 'vuex';
import { NewsState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<NewsState, RootState> = {
  ['getNewss']: (state) => state.newss,
  ['getNewssIsLoading']: (state) => state.newss?.isLoading,
  ['getNewssTotal']: (state) => state.newss?.total,
  ['getNewssSearchParams']: (state) => state.newss?.searchParams,
};
