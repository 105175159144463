import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import room, { Room, Value } from '../model/room';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/constants';

const logger = new Logger('backend.Room');
export interface BackendRoom {
  getRoom(id: string): AxiosPromise<Room>;
  getRooms: (
    searchParams: SearchParams,
    locationId: string,
    availableAt?: string
  ) => AxiosPromise<Value>;
  getRoomsOData: (
    searchParams: SearchParams,
    locationIds: string[],
    companyId: string
  ) => AxiosPromise<Value>;
  deleteRoom(id: string): AxiosPromise;
  updateRoom(Room: Room): AxiosPromise<any>;
}

export const defaultBackendRoom: BackendRoom = {
  getRoom(id: string): AxiosPromise<Room> {
    let url = `${URLS.roomOdata}/${id}`;
    logger.log(`getRoom: ${url}`);
    return instance.get<Room>(url);
  },
  getRooms(
    searchParams: SearchParams,
    locationId: string,
    availableAt?: string
  ): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    odfb.eq(CONST.LocationId, locationId, false);

    let baseUrl =
      availableAt != undefined
        ? `${URLS.roomOdata}/GetAvailableAt(availableAt=${availableAt},locationId=${locationId})`
        : `${URLS.roomOdata}`;

    let url = DefaultBackendHelper.makeUrl(
      baseUrl,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getRooms${url}`);
    return instance.get<Value>(url);
  },

  getRoomsOData(
    searchParams: SearchParams,
    locationIds: string[],
    companyId: string
  ): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('or');
    searchParams.dataOption.itemsPerPage = -1;
    locationIds.forEach((x) => odfb.eq(CONST.LocationId, x, false));
    const defaultUrl =
      companyId === CONST.emptyGuid
        ? `${URLS.roomOdata}`
        : `${URLS.roomOdata}/GetOdataAnonymous(companyId=${companyId})`;

    let url = DefaultBackendHelper.makeUrl(
      defaultUrl,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getRooms${url}`);
    return instance.get<Value>(url);
  },

  deleteRoom(id: string): AxiosPromise {
    logger.debug('deleteRoom');
    return instance.delete(`${URLS.room}/${id}`);
  },
  updateRoom(Room: Room): AxiosPromise<any> {
    logger.debug('updateRoom');
    if (Room.id != '') {
      return instance.put<Room>(`${URLS.room}`, room.toAPI(Room));
    } else {
      return instance.post<Room>(`${URLS.room}`, room.toAPI(Room));
    }
  },
};
