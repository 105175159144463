import { GetterTree } from 'vuex';
import { ContactState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<ContactState, RootState> = {
  ['getContacts']: state => state.contacts,
  ['getContactsIsLoading']: state => state.contacts?.isLoading,
  ['getContactsTotal']: state => state.contacts?.total,
  ['getContactsSearchParams']: state => state.contacts?.searchParams,
};
