import { MutationTree } from 'vuex';
import { DepartmentResponsibleState } from './types';
import departmentResponsible, { Value } from '@/shared/model/departmentResponsible';

export const mutations: MutationTree<DepartmentResponsibleState> = {
  setDepartmentResponsibles(state, payload: Value) {
    state.departmentResponsibles.items = payload.value.map(x =>
      departmentResponsible.parse(x)
    );
    state.departmentResponsibles.total = payload['@odata.count'] || 0;
    state.departmentResponsibles.isLoading = false;
  },
  setDepartmentResponsiblesTotal(state, payload: number) {
    state.departmentResponsibles.total = payload;
  },
  setDepartmentResponsiblesIsLoading(state, payload: boolean) {
    state.departmentResponsibles.isLoading = payload;
  },
};
