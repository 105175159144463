<script setup lang="ts">
// v-img does not display svg - images anymore. This new component solves it by using an internal browser <img> - tag
import { computed } from 'vue';

// A vector circle has no antialiasing in the browser, so using a png instead
const roundMask = require('@/assets/mask-circle.png');

const props = defineProps({
  src: {
    type: String,
    default: '',
  },
  width: {
    type: [String, Number],
    default: undefined,
  },
  height: {
    type: [String, Number],
    default: undefined,
  },
  size: {
    type: [String, Number],
    default: undefined,
  },
  round: {
    type: Boolean,
    default: false,
  },
});

const widthInternal = computed(() => {
  return props.width ?? props.size ?? -1;
});

const heightInternal = computed(() => {
  return props.height ?? props.size ?? -1;
});
</script>

<template>
  <img
    :src="src"
    :width="widthInternal"
    :height="heightInternal"
    :style="
      round
        ? `mask-image: url(${roundMask}); mask-size: ${widthInternal}px ${heightInternal}px;`
        : ``
    "
  />
</template>
