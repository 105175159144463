import { DateTimeManagement } from './types';

export const state = (): DateTimeManagement => initialState();

export const initialState = (): DateTimeManagement => ({
  dateTimes: {
    items: [],
    isLoading: false,
    total: 2,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['dateFrom'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: ['courseName', 'name', 'trainerFullNames'],
    },
  },
});
