
import { GetterTree } from 'vuex';
import { AppointmentTrainerState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<AppointmentTrainerState, RootState> = {
  ['getAppointmentTrainers']: (state) => state.appointmentTrainers,
  ['getAppointmentTrainersIsLoading']: (state) => state.appointmentTrainers?.isLoading,
  ['getAppointmentTrainersTotal']: (state) => state.appointmentTrainers?.total,
  ['getAppointmentTrainersSearchParams']: (state) => state.appointmentTrainers?.searchParams,
};
