import { ActionTree } from 'vuex';
import { WebPushState } from './types';
import { RootState } from '../../types';
import { defaultBackendWebPush } from '@/shared/backend/webPush';
import { Logger } from 'fsts';
import moduleUtils from '../moduleUtils';
import { AxiosResponse } from 'axios';
import { WebpushSubscriptionCheckResponse } from '@/shared/model/webPushSubscription';
import { urlBase64ToUint8Array } from '@/shared/utils/generalUtils';
import { i18nGlobal } from '@/i18n';

const logger = new Logger('actions.webPush');
export const actions: ActionTree<WebPushState, RootState> = {
  async askForNotificationPermission({ commit, getters }) {
    if ((getters.getPermissionState as PermissionState) != 'granted') {
      let permissionPromise = new Promise(function (resolve, reject) {
        const permissionResult = Notification.requestPermission(function (
          result
        ) {
          resolve(result);
        });

        if (permissionResult) {
          permissionResult.then(resolve, reject);
        }
      });

      let permissionGiven = await permissionPromise;
      commit('setPermissionState', permissionGiven);
      return permissionGiven == 'granted';
    }
    return true;
  },
  async subscribe({ commit, getters }) {
    if (getters.hasPushSubscription) {
      return defaultBackendWebPush
        .SaveSubscription({
          pushSubscription: getters.getPushSubscription,
          deactivated: false,
        })
        .then(() =>
          moduleUtils.ok(
            i18nGlobal.t('success.webpush_subscription_update'),
            commit
          )
        )
        .catch((e: any) => {
          moduleUtils.error('error', commit, e, logger);
          throw e;
        });
    } else {
      const subscribeOptions = {
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(getters.getPublicVAPIDKey),
      };

      return navigator.serviceWorker.ready
        .then((serviceWorkerRegistration) => {
          serviceWorkerRegistration.pushManager
            .subscribe(subscribeOptions)
            .then((pushSubscription) => {
              defaultBackendWebPush
                .SaveSubscription({
                  pushSubscription: pushSubscription,
                  deactivated: false,
                })
                .then(() =>
                  moduleUtils.ok(
                    i18nGlobal.t('success.webpush_subscription_update'),
                    commit
                  )
                )
                .catch((e: any) => {
                  moduleUtils.error('error', commit, e, logger);
                  throw e;
                });
            })
            .catch((e: any) => {
              moduleUtils.error('error', commit, e, logger);
              throw e;
            });
        })
        .catch((e: any) => {
          moduleUtils.error('error', commit, e, logger);
          throw e;
        });
    }
  },
  deactivateSubscription({ commit, getters }) {
    return defaultBackendWebPush
      .SaveSubscription({
        pushSubscription: getters.getPushSubscription || undefined,
        deactivated: true,
      })
      .then(() =>
        moduleUtils.ok(
          i18nGlobal.t('success.webpush_subscription_update'),
          commit
        )
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  checkSubscriptionStatus(
    { commit },
    payload: { existingSubscriptionEndpoint?: string }
  ) {
    return defaultBackendWebPush
      .checkSubscriptionStatus(payload.existingSubscriptionEndpoint)
      .then((resp: AxiosResponse<WebpushSubscriptionCheckResponse>) => {
        commit('setWebpushSubscriptionCheckResponse', resp.data);
        return resp.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  checkBrowserCompatibility({ commit }) {
    if (
      !('serviceWorker' in navigator) ||
      !('PushManager' in window) ||
      !('permissionState' in window.PushManager.prototype)
    ) {
      commit('markBrowserAsIncompatible');
    }
  },
  retrieveSubscriptionInBrowser({ commit }) {
    return new Promise(function (resolve, reject) {
      navigator.serviceWorker.ready
        .then((serviceWorkerRegistration) => {
          serviceWorkerRegistration.pushManager
            .getSubscription()
            .then((subscription) => {
              commit('setPushSubscription', subscription ?? null);
              resolve(subscription);
            })
            .catch((e) => reject(e));
        })
        .catch((e) => reject(e));
    });
  },
  retrievePermissionStateInBrowser({ commit }) {
    return new Promise(function (resolve, reject) {
      navigator.serviceWorker.ready
        .then((serviceWorkerRegistration) => {
          serviceWorkerRegistration.pushManager
            .permissionState({ userVisibleOnly: true })
            .then((permissionState) => {
              commit('setPermissionState', permissionState);
              resolve(permissionState);
            })
            .catch((e) => reject(e));
        })
        .catch((e) => reject(e));
    });
  },
  retrievePermissionStateAndSubscriptionInBrowser({ dispatch }) {
    return Promise.all([
      dispatch('retrieveSubscriptionInBrowser'),
      dispatch('retrievePermissionStateInBrowser'),
    ]);
  },
  getMessages({ commit, getters }) {
    return defaultBackendWebPush
      .getMessages(getters.getMessagesSearchParams)
      .then((response) => {
        commit('setMessages', response.data);
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  markAllMessagesRead({ commit }) {
    return defaultBackendWebPush.markAllMessagesRead().catch((e: any) => {
      moduleUtils.error('error', commit, e, logger);
      throw e;
    });
  },
};
