export type WebpushSubscriptionCheckStatus = 0 | 1 | 2 | 3 | 4 | 5 | 6;
export const WebpushSubscriptionCheckStatus = {
  NonSubscribed: 0 as WebpushSubscriptionCheckStatus,
  Subscribed: 1 as WebpushSubscriptionCheckStatus,
  SubscribedSameUserOtherDevice: 2 as WebpushSubscriptionCheckStatus,
  SubscribedSameDeviceOtherUser: 3 as WebpushSubscriptionCheckStatus,
  DeactivatedInSettings: 4 as WebpushSubscriptionCheckStatus,
  DeniedInOwnDialog: 5 as WebpushSubscriptionCheckStatus,
};

export interface WebpushSubscriptionCheckResponse {
  status: WebpushSubscriptionCheckStatus;
  publicVapidKey: string;
}

export interface WebPushSubscription {
  deactivated: boolean;
  pushSubscription?: PushSubscription; // The push subscription object as provided by the browser
}

function toAPI(webPushSubscription: Partial<WebPushSubscription>) {
  // A workaround to get hold of KeyP256dh and KeyAuth as strings
  let serializedDeserializedSubscription = webPushSubscription.pushSubscription
    ? JSON.parse(JSON.stringify(webPushSubscription.pushSubscription))
    : undefined;
  return {
    Deactivated: webPushSubscription.deactivated ?? false,
    Endpoint: webPushSubscription.pushSubscription?.endpoint || undefined,
    KeyP256dh: serializedDeserializedSubscription?.keys.p256dh || undefined,
    KeyAuth: serializedDeserializedSubscription?.keys.auth || undefined,
  };
}

export default {
  toAPI,
};
