import { Composer, createI18n } from 'vue-i18n';

function loadLocaleMessages(): any {
  const locales = require.context(
    './locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages: any = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

export const localeMessages = loadLocaleMessages();

const i18n = createI18n({
  locale: (process.env.VUE_APP_I18N_LOCALE as string) || 'de',
  fallbackLocale: (process.env.VUE_APP_I18N_FALLBACK_LOCALE as string) || 'de',
  silentTranslationWarn: true,
  messages: localeMessages,
  legacy: false, // Otherwise it cannot be integrated with vuetify 3
});

export default i18n;
export const i18nGlobal = i18n.global as Composer;
