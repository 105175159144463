import { MutationTree } from 'vuex';
import { AppointmentTrainerState } from './types';
import appointmentTrainer, { Value } from '@/shared/model/appointmentTrainer';

export const mutations: MutationTree<AppointmentTrainerState> = {
  setAppointmentTrainers(state, payload: Value) {
    state.appointmentTrainers.items = payload.value.map((x) =>
      appointmentTrainer.parse(x)
    );
    state.appointmentTrainers.total = payload['@odata.count'] || 0;
    state.appointmentTrainers.isLoading = false;
  },
  setAppointmentTrainersTotal(state, payload: number) {
    state.appointmentTrainers.total = payload;
  },
  setAppointmentTrainersIsLoading(state, payload: boolean) {
    state.appointmentTrainers.isLoading = payload;
  },
};
