import { MutationTree } from 'vuex';
import { NoteState } from './types';
import note, { Note, Value } from '@/shared/model/note';

export const mutations: MutationTree<NoteState> = {
  setNotes(state, payload: Value) {
    state.notes.items = payload.value.map((x) => note.parse(x));
    state.notes.total = payload['@odata.count'] || 0;
    state.notes.isLoading = false;
  },
  setNotesTotal(state, payload: number) {
    state.notes.total = payload;
  },
  setNotesIsLoading(state, payload: boolean) {
    state.notes.isLoading = payload;
  },
  resetNotesPaging(state) {
    state.notes.searchParams.dataOption.page = 1;
    state.notes.searchParams.dataOption.itemsPerPage = 10;
    state.notes.searchParams.dataOption.filter = '';
    state.notes.searchParams.filter = '';
  },
};
