import { CONST } from '../utils/constants';

export interface DepartmentRef {
  id: string; //Guid
  name: string;
  companyId: string; //Guid
  departmentId: string; //Guid
  userId?: string; //Guid
  locationId?: string; //Guid
  courseId?: string; //Guid
  attendeeId?: string; //Guid
}

export interface Value {
  value: DepartmentRef[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<DepartmentRef>): any {
  return {
    Id: data?.id || CONST.emptyGuid,
    DepartmentId: data?.departmentId || CONST.emptyGuid,
    UserId: data?.userId,
    LocationId: data?.locationId,
    CourseId: data?.courseId,
    AttendeeId: data?.attendeeId,
  };
}

function parse(data?: Partial<DepartmentRef>): DepartmentRef {
  return {
    id: data?.id || CONST.emptyGuid,
    name: data?.name || '',
    companyId: data?.companyId || CONST.emptyGuid,
    departmentId: data?.departmentId || CONST.emptyGuid,
    userId: data?.userId,
    locationId: data?.locationId,
    courseId: data?.courseId,
    attendeeId: data?.attendeeId,
  };
}

export default {
  parse,
  toAPI: toAPI,
};
