import { MutationTree } from 'vuex';
import { AppointmentMessageState } from './types';
import appointmentMessage, {
  AppointmentMessage,
} from '@/shared/model/appointmentMessage';

export const mutations: MutationTree<AppointmentMessageState> = {
  setMessages(state, payload: AppointmentMessage[]) {
    state.messages = payload.map(x => appointmentMessage.parse(x));
  },
  appendMessages(state, payload: AppointmentMessage[]) {
    state.messages = state.messages.concat(
      payload
        .filter(x => state.messages.find(y => y.id == x.id) == undefined)
        .map(x => appointmentMessage.parse(x))
    );
  },
  setMessagesIsLoading(state, payload: boolean) {
    state.isLoading = payload;
  },
};
