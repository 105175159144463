<v-dialog
  v-model="dialog"
  persistent
  :max-width="options.width"
  :z-index="options.zIndex"
  @keydown.esc="dialog = false"
>
  <v-card>
    <v-toolbar dark :color="options.color" dense flat>
      <v-toolbar-title class="text-white">{{ title }}</v-toolbar-title>
    </v-toolbar>
    <v-card-text v-show="!!message" class="pa-4">{{ message }}</v-card-text>
    <v-radio-group
      v-if="radioEntries.length > 0"
      v-model="selectedRadioEntry"
      class="ml-4 mt-n2"
    >
      <v-radio
        v-for="(entry, index) in radioEntries"
        :key="index"
        :value="index"
        :label="entry"
      >
      </v-radio>
    </v-radio-group>
    <v-card-actions class="pt-0">
      <v-spacer></v-spacer>
      <v-btn color="grey" v-if="!messageBox" @click.native="cancel">
        {{ options.cancelText != undefined ? options.cancelText :
        i18n.t('cancel') }}
      </v-btn>
      <v-btn color="primary darken-1" text @click.native="agree">
        {{ options.okText != undefined ? options.okText : i18n.t('ok') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
