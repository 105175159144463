export default class DownloadUtils {
  public static getFileName(contentDisposition: string) {
    let fileName = contentDisposition
      .split(';')[2]
      .split('=')[1]
      .split("''")[1];
    fileName = decodeURIComponent(fileName);
    return fileName;
  }

  public static createDownloadLink = function (blob: Blob, fileName: string) {
    const url = window.URL.createObjectURL(blob);
    const linkElement = document.createElement('a');
    if (typeof linkElement.download === 'undefined') {
      (window as any).location = url;
      return url;
    }
    linkElement.href = url;
    linkElement.setAttribute('download', fileName);
    document.body.appendChild(linkElement);
    linkElement.click();
    linkElement.remove();
    return url;
  };

  public static isIeOrEdge(window: Window) {
    return !!(window.navigator && (window.navigator as any).msSaveOrOpenBlob);
  }
  public static utf8_to_b64(str: string) {
    return window.btoa(unescape(encodeURIComponent(str)));
  }

  public static b64_to_utf8(str: string) {
    return decodeURIComponent(escape(window.atob(str)));
  }
}
