import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { DefaultBackendHelper } from '../utils/backendHelper';
import { Value } from '../model/locationResponsible';
import { isEmptyId } from '../utils/generalUtils';
import { SearchParams } from '../model/searchParams';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/constants';

const logger = new Logger('backend.LocationResponsible');
export interface BackendLocationResponsible {
  getLocationResponsibles: (
    searchParams: SearchParams,
    locationId?: string
  ) => AxiosPromise<Value>;
  postMultipleLocationResponsibles(
    responsibleIds: string[],
    locationId: string
  ): AxiosPromise<any>;
}

export const defaultBackendLocationResponsible: BackendLocationResponsible = {
  getLocationResponsibles: (
    searchParams: SearchParams,
    locationId?: string
  ): AxiosPromise<Value> => {
    var odfb = ODataFilterBuilder('and');

    if (!isEmptyId(locationId)) {
      odfb.eq(CONST.LocationId, locationId, false);
    }

    let url = DefaultBackendHelper.makeUrl(
      URLS.locationResponsibleOdata,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );

    logger.log(`getLocationResponsible${url}`);
    return instance.get<Value>(url);
  },
  postMultipleLocationResponsibles(
    responsibleIds: string[],
    locationId: string
  ): AxiosPromise<any> {
    logger.debug('postMultipleLocationResponsibles');
    return instance.post(`${URLS.locationResponsible}/AddMultiple`, {
      ResponsibleIds: responsibleIds,
      LocationId: locationId,
    });
  },
};
