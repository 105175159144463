import { InvoicePositionsState } from "./types";

export const state = (): InvoicePositionsState => initialState();

export const initialState = (): InvoicePositionsState => ({
  invoicePositions: {
    items: [],
    isLoading: false,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["createdDate"],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: "",
      orClauseFieldsIds: [
        "attendeeFullName",
        "description",
        "memberNumber",
        "invoiceNumberString",
      ],
    },
  },
});
