import { ActionTree } from 'vuex';
import { LocationResponsibleState } from './types';
import { RootState } from '../../types';
import { Logger } from 'fsts';
import moduleUtils from '../moduleUtils';
import { AxiosResponse } from 'axios';
import { SearchParams } from '@/shared/model/searchParams';
import { defaultBackendLocationResponsible } from '@/shared/backend/locationResponsible';
import { Value } from '@/shared/model/locationResponsible';
import { createSearchParams } from '@/shared/model/searchParams';

const logger = new Logger('actions.locationResponsible');
export const actions: ActionTree<LocationResponsibleState, RootState> = {
  getLocationResponsibles(
    { commit, getters },
    payload: {
      searchParams?: SearchParams;
      locationId?: string;
    }
  ) {
    commit('setLocationResponsiblesIsLoading', true);
    let searchParams =
      payload.searchParams ?? getters.getLocationResponsiblesSearchParams;

    return defaultBackendLocationResponsible
      .getLocationResponsibles(searchParams, payload.locationId)
      .then((response: AxiosResponse<Value>) => {
        commit('setLocationResponsibles', response.data);
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setLocationResponsiblesIsLoading', false);
      });
  },
  getLocationResponsiblesNonPaginated(
    { dispatch },
    payload: { locationId: string }
  ) {
    return dispatch('getLocationResponsibles', {
      locationId: payload.locationId,
      searchParams: createSearchParams(-1),
    });
  },
  clearLocationResponsibles({ commit }) {
    commit('setLocationResponsibles', { value: [] });
  },
  postMultipleLocationResponsibles(
    { commit },
    payload: {
      responsibleIds: string[];
      locationId: string;
    }
  ) {
    return defaultBackendLocationResponsible
      .postMultipleLocationResponsibles(payload.responsibleIds, payload.locationId)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
          }>
        ) => {
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
};
