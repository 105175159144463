import { MutationTree } from 'vuex';
import { NewsState } from './types';
import news, { Value } from '@/shared/model/news';

export const mutations: MutationTree<NewsState> = {
  setNewss(state, payload: Value) {
    state.newss.items = payload.value.map((x) => news.parse(x));
    state.newss.total = payload['@odata.count'] || 0;
    state.newss.isLoading = false;
  },
  setNewssTotal(state, payload: number) {
    state.newss.total = payload;
  },
  setNewssIsLoading(state, payload: boolean) {
    state.newss.isLoading = payload;
  },
  setNewsPage(state, payload: number) {
    state.newss.searchParams.dataOption.page = payload;
  },
};
