
export interface StatisticsPreset {
  id?: string,
  name: string,
  jsonString: string,
  criterionVertical: string | undefined;
  criterionVerticalAdditional: string | undefined;
  criterionHorizontal: string | undefined;
  criterionHorizontalAdditional: string | undefined;
  criterionVerticalIds: string,
  criterionVerticalAdditionalIds: string,
  criterionHorizontalIds: string,
  criterionHorizontalAdditionalIds: string,
  criterionVerticalAgeRanges: string,
  criterionVerticalAdditionalAgeRanges: string,
  criterionHorizontalAgeRanges: string,
  criterionHorizontalAdditionalAgeRanges: string,
  criterionVerticalTimeRanges: string,
  criterionVerticalAdditionalTimeRanges: string,
  criterionHorizontalTimeRanges: string,
  criterionHorizontalAdditionalTimeRanges: string,
  createdDate: string,
  creatorId?: string,
  creatorFullName: string,
  companyId?: string,
}

export interface Value {
  value: StatisticsPreset[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

function parse(data?: Partial<StatisticsPreset>): StatisticsPreset {
  return {
    id: data?.id || undefined,
    name: data?.name || '',
    jsonString: data?.jsonString || '',
    criterionVertical: data?.criterionVertical || undefined,
    criterionVerticalAdditional: data?.criterionVerticalAdditional || undefined,
    criterionHorizontal: data?.criterionHorizontal || undefined,
    criterionHorizontalAdditional: data?.criterionHorizontalAdditional || undefined,
    criterionVerticalIds: data?.criterionVerticalIds || '[]',
    criterionVerticalAdditionalIds: data?.criterionVerticalAdditionalIds || '[]',
    criterionHorizontalIds: data?.criterionHorizontalIds || '[]',
    criterionHorizontalAdditionalIds: data?.criterionHorizontalAdditionalIds || '[]',
    criterionVerticalAgeRanges: data?.criterionVerticalAgeRanges || '[]',
    criterionVerticalAdditionalAgeRanges: data?.criterionVerticalAdditionalAgeRanges || '[]',
    criterionHorizontalAgeRanges: data?.criterionHorizontalAgeRanges || '[]',
    criterionHorizontalAdditionalAgeRanges: data?.criterionHorizontalAdditionalAgeRanges || '[]',
    criterionVerticalTimeRanges: data?.criterionVerticalTimeRanges || '[]',
    criterionVerticalAdditionalTimeRanges: data?.criterionVerticalAdditionalTimeRanges || '[]',
    criterionHorizontalTimeRanges: data?.criterionHorizontalTimeRanges || '[]',
    criterionHorizontalAdditionalTimeRanges: data?.criterionHorizontalAdditionalTimeRanges || '[]',
    createdDate: data?.createdDate || '',
    creatorId: data?.creatorId || undefined,
    creatorFullName: data?.creatorFullName || '',
    companyId: data?.companyId || undefined,
  };
}

function toAPI(data: Partial<StatisticsPreset>): any {
  return {
    Id: data?.id || undefined,
    Name: data?.name || '',
    JsonString: data?.jsonString || '',
    CriterionVertical: data?.criterionVertical || undefined,
    CriterionVerticalAdditional: data?.criterionVerticalAdditional || undefined,
    CriterionHorizontal: data?.criterionHorizontal || undefined,
    CriterionHorizontalAdditional: data?.criterionHorizontalAdditional || undefined,
    CriterionVerticalIds: data.criterionVerticalIds || '[]',
    CriterionVerticalAdditionalIds: data.criterionVerticalAdditionalIds || '[]',
    CriterionHorizontalIds: data.criterionHorizontalIds || '[]',
    CriterionHorizontalAdditionalIds: data.criterionHorizontalAdditionalIds || '[]',
    CriterionVerticalAgeRanges: data?.criterionVerticalAgeRanges || '[]',
    CriterionVerticalAdditionalAgeRanges: data?.criterionVerticalAdditionalAgeRanges || '[]',
    CriterionHorizontalAgeRanges: data?.criterionHorizontalAgeRanges || '[]',
    CriterionHorizontalAdditionalAgeRanges: data?.criterionHorizontalAdditionalAgeRanges || '[]',
    CriterionVerticalTimeRanges: data?.criterionVerticalTimeRanges || '[]',
    CriterionVerticalAdditionalTimeRanges: data?.criterionVerticalAdditionalTimeRanges || '[]',
    CriterionHorizontalTimeRanges: data?.criterionHorizontalTimeRanges || '[]',
    CriterionHorizontalAdditionalTimeRanges: data?.criterionHorizontalAdditionalTimeRanges || '[]',
    CreatedDate: data?.createdDate || '',
    CreatorId: data?.creatorId || undefined,
    CompanyId: data?.companyId || undefined,
  };
}

export default {
  parse,
  toAPI,
};
