import { GetterTree } from 'vuex';
import { TaskRequestState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<TaskRequestState, RootState> = {
  ['getTaskRequests']: state => state.taskRequests,
  ['getTaskRequestsIsLoading']: state => state.taskRequests?.isLoading,
  ['getTaskRequestsTotal']: state => state.taskRequests?.total,
  ['getTaskRequestsSearchParams']: state => state.taskRequests?.searchParams,
};
