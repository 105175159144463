import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import grp, {
  Document,
  Value,
  DocumentCategoriesOData,
} from '../model/document';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '../utils/constants';

const logger = new Logger('backend.documents');
export interface BackendDocument {
  getDocument(id: string): AxiosPromise<Document>;
  getDocuments: (
    searchParams: SearchParams,
    parentId?: string,
    onlyVisibleForUser?: boolean
  ) => AxiosPromise<Value>;
  getDocumentsNew: (searchParams: SearchParams) => AxiosPromise<Value>;
  getDocumentByCompany: (companyId: string, documentType: string) =>
    AxiosPromise<Document>;
  updateDocument(document: Document): AxiosPromise<Document>;
  publishDocument(id: string, active: boolean): AxiosPromise<Document>;
  uploadDocument(registerPayload: any): AxiosPromise<any>;
  downloadDocument(registerPayload: any): AxiosPromise<any>;
  deleteDocument(id: string): AxiosPromise;
  deleteDocumentFile(documentId: string): AxiosPromise;
  getCategories(
    searchParams: SearchParams
  ): AxiosPromise<DocumentCategoriesOData>;
}

export const defaultBackendDocument: BackendDocument = {
  getDocument(id: string): AxiosPromise<Document> {
    let url = DefaultBackendHelper.makeUrl(`${URLS.documentsOdata}/${id}`);
    return instance.get<Document>(url);
  },

  getDocuments(
    searchParams: SearchParams,
    parentId?: string,
    onlyVisibleForUser?: boolean
  ): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    odfb.in('ParentId', [parentId], false);

    if (onlyVisibleForUser) {
      odfb.eq(CONST.VisibleForUser, true, false);
    }

    let url = DefaultBackendHelper.makeUrl(
      `${URLS.documentsOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      parentId ? odfb : undefined
    );
    return instance.get<Value>(url);
  },
  getDocumentsNew(searchParams: SearchParams): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    odfb.gt('NotificationCount', 0, false);

    let url = DefaultBackendHelper.makeUrl(
      `${URLS.documentsOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    return instance.get<Value>(url);
  },

  getDocumentByCompany(companyId: string, documentType: string): AxiosPromise<Document> {
    let url = `${URLS.documents}/GetByCompany/${companyId}/${documentType}`
    return instance.get<Document>(url);
  },

  updateDocument(document: Document): AxiosPromise<Document> {
    return instance.put<Document>(
      `${URLS.documents}/update`,
      grp.toAPI(document)
    );
  },
  publishDocument(id: string, active: boolean): AxiosPromise<Document> {
    return instance.put<Document>(`${URLS.documents}/publish`, {
      id: id,
      Active: active,
    });
  },
  uploadDocument(registerPayload: FormData): AxiosPromise<any> {
    return instance.post<any>(`${URLS.documents}/post`, registerPayload, {
      headers: { 'Content-Type': 'multipart/form-data' },
      transformRequest: (data) => data,
      // This workaround is unfortunately needed in the newer versions of Axios :(
      // https://github.com/axios/axios/issues/4406
    });
  },
  downloadDocument(id: any): AxiosPromise<any> {
    return instance.get<any>(`${URLS.documents}/download/${id}`, {
      responseType: 'blob',
    });
  },
  deleteDocument(id: string): AxiosPromise {
    logger.debug('deleteDocument');
    return instance.delete(`${URLS.documents}/${id}`);
  },

  deleteDocumentFile(documentId: string): AxiosPromise {
    logger.debug('deleteDocumentFile');
    return instance.delete(
      `${URLS.documents}/DeleteDocumentFile/${documentId}`
    );
  },

  getCategories(
    searchParams: SearchParams
  ): AxiosPromise<DocumentCategoriesOData> {
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.documentCategoriesOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter
    );
    return instance.get<DocumentCategoriesOData>(url);
  },
};
